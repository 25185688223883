var b = typeof globalThis < "u" ? globalThis : typeof window < "u" ? window : typeof global < "u" ? global : typeof self < "u" ? self : {};
/*! *****************************************************************************
Copyright (C) Microsoft. All rights reserved.
Licensed under the Apache License, Version 2.0 (the "License"); you may not use
this file except in compliance with the License. You may obtain a copy of the
License at http://www.apache.org/licenses/LICENSE-2.0

THIS CODE IS PROVIDED ON AN *AS IS* BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
KIND, EITHER EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION ANY IMPLIED
WARRANTIES OR CONDITIONS OF TITLE, FITNESS FOR A PARTICULAR PURPOSE,
MERCHANTABLITY OR NON-INFRINGEMENT.

See the Apache Version 2.0 License for specific language governing permissions
and limitations under the License.
***************************************************************************** */
var Uo;
(function(e) {
  (function(t) {
    var n = typeof b == "object" ? b : typeof self == "object" ? self : typeof this == "object" ? this : Function("return this;")(), r = s(e);
    typeof n.Reflect > "u" ? n.Reflect = e : r = s(n.Reflect, r), t(r);
    function s(o, l) {
      return function(f, w) {
        typeof o[f] != "function" && Object.defineProperty(o, f, { configurable: !0, writable: !0, value: w }), l && l(f, w);
      };
    }
  })(function(t) {
    var n = Object.prototype.hasOwnProperty, r = typeof Symbol == "function", s = r && typeof Symbol.toPrimitive < "u" ? Symbol.toPrimitive : "@@toPrimitive", o = r && typeof Symbol.iterator < "u" ? Symbol.iterator : "@@iterator", l = typeof Object.create == "function", f = { __proto__: [] } instanceof Array, w = !l && !f, p = {
      // create an object in dictionary mode (a.k.a. "slow" mode in v8)
      create: l ? function() {
        return un(/* @__PURE__ */ Object.create(null));
      } : f ? function() {
        return un({ __proto__: null });
      } : function() {
        return un({});
      },
      has: w ? function(M, D) {
        return n.call(M, D);
      } : function(M, D) {
        return D in M;
      },
      get: w ? function(M, D) {
        return n.call(M, D) ? M[D] : void 0;
      } : function(M, D) {
        return M[D];
      }
    }, _ = Object.getPrototypeOf(Function), O = typeof process == "object" && process.env && process.env.REFLECT_METADATA_USE_MAP_POLYFILL === "true", F = !O && typeof Map == "function" && typeof Map.prototype.entries == "function" ? Map : on(), H = !O && typeof Set == "function" && typeof Set.prototype.entries == "function" ? Set : Ue(), E = !O && typeof WeakMap == "function" ? WeakMap : tt(), $ = new E();
    function W(M, D, I, B) {
      if (ke(I)) {
        if (!Nr(M))
          throw new TypeError();
        if (!Rr(D))
          throw new TypeError();
        return S(M, D);
      } else {
        if (!Nr(M))
          throw new TypeError();
        if (!Oe(D))
          throw new TypeError();
        if (!Oe(B) && !ke(B) && !ht(B))
          throw new TypeError();
        return ht(B) && (B = void 0), I = ot(I), g(M, D, I, B);
      }
    }
    t("decorate", W);
    function ve(M, D) {
      function I(B, V) {
        if (!Oe(B))
          throw new TypeError();
        if (!ke(V) && !da(V))
          throw new TypeError();
        le(M, D, B, V);
      }
      return I;
    }
    t("metadata", ve);
    function Z(M, D, I, B) {
      if (!Oe(I))
        throw new TypeError();
      return ke(B) || (B = ot(B)), le(M, D, I, B);
    }
    t("defineMetadata", Z);
    function U(M, D, I) {
      if (!Oe(D))
        throw new TypeError();
      return ke(I) || (I = ot(I)), k(M, D, I);
    }
    t("hasMetadata", U);
    function K(M, D, I) {
      if (!Oe(D))
        throw new TypeError();
      return ke(I) || (I = ot(I)), j(M, D, I);
    }
    t("hasOwnMetadata", K);
    function ie(M, D, I) {
      if (!Oe(D))
        throw new TypeError();
      return ke(I) || (I = ot(I)), G(M, D, I);
    }
    t("getMetadata", ie);
    function A(M, D, I) {
      if (!Oe(D))
        throw new TypeError();
      return ke(I) || (I = ot(I)), Q(M, D, I);
    }
    t("getOwnMetadata", A);
    function y(M, D) {
      if (!Oe(M))
        throw new TypeError();
      return ke(D) || (D = ot(D)), J(M, D);
    }
    t("getMetadataKeys", y);
    function h(M, D) {
      if (!Oe(M))
        throw new TypeError();
      return ke(D) || (D = ot(D)), Ie(M, D);
    }
    t("getOwnMetadataKeys", h);
    function v(M, D, I) {
      if (!Oe(D))
        throw new TypeError();
      ke(I) || (I = ot(I));
      var B = P(
        D,
        I,
        /*Create*/
        !1
      );
      if (ke(B) || !B.delete(M))
        return !1;
      if (B.size > 0)
        return !0;
      var V = $.get(D);
      return V.delete(I), V.size > 0 || $.delete(D), !0;
    }
    t("deleteMetadata", v);
    function S(M, D) {
      for (var I = M.length - 1; I >= 0; --I) {
        var B = M[I], V = B(D);
        if (!ke(V) && !ht(V)) {
          if (!Rr(V))
            throw new TypeError();
          D = V;
        }
      }
      return D;
    }
    function g(M, D, I, B) {
      for (var V = M.length - 1; V >= 0; --V) {
        var ee = M[V], ae = ee(D, I, B);
        if (!ke(ae) && !ht(ae)) {
          if (!Oe(ae))
            throw new TypeError();
          B = ae;
        }
      }
      return B;
    }
    function P(M, D, I) {
      var B = $.get(M);
      if (ke(B)) {
        if (!I)
          return;
        B = new F(), $.set(M, B);
      }
      var V = B.get(D);
      if (ke(V)) {
        if (!I)
          return;
        V = new F(), B.set(D, V);
      }
      return V;
    }
    function k(M, D, I) {
      var B = j(M, D, I);
      if (B)
        return !0;
      var V = Vn(D);
      return ht(V) ? !1 : k(M, V, I);
    }
    function j(M, D, I) {
      var B = P(
        D,
        I,
        /*Create*/
        !1
      );
      return ke(B) ? !1 : la(B.has(M));
    }
    function G(M, D, I) {
      var B = j(M, D, I);
      if (B)
        return Q(M, D, I);
      var V = Vn(D);
      if (!ht(V))
        return G(M, V, I);
    }
    function Q(M, D, I) {
      var B = P(
        D,
        I,
        /*Create*/
        !1
      );
      if (!ke(B))
        return B.get(M);
    }
    function le(M, D, I, B) {
      var V = P(
        I,
        B,
        /*Create*/
        !0
      );
      V.set(M, D);
    }
    function J(M, D) {
      var I = Ie(M, D), B = Vn(M);
      if (B === null)
        return I;
      var V = J(B, D);
      if (V.length <= 0)
        return I;
      if (I.length <= 0)
        return V;
      for (var ee = new H(), ae = [], se = 0, re = I; se < re.length; se++) {
        var de = re[se], fe = ee.has(de);
        fe || (ee.add(de), ae.push(de));
      }
      for (var _t = 0, ze = V; _t < ze.length; _t++) {
        var de = ze[_t], fe = ee.has(de);
        fe || (ee.add(de), ae.push(de));
      }
      return ae;
    }
    function Ie(M, D) {
      var I = [], B = P(
        M,
        D,
        /*Create*/
        !1
      );
      if (ke(B))
        return I;
      for (var V = B.keys(), ee = fa(V), ae = 0; ; ) {
        var se = _a(ee);
        if (!se)
          return I.length = ae, I;
        var re = ha(se);
        try {
          I[ae] = re;
        } catch (de) {
          try {
            ya(ee);
          } finally {
            throw de;
          }
        }
        ae++;
      }
    }
    function xe(M) {
      if (M === null)
        return 1;
      switch (typeof M) {
        case "undefined":
          return 0;
        case "boolean":
          return 2;
        case "string":
          return 3;
        case "symbol":
          return 4;
        case "number":
          return 5;
        case "object":
          return M === null ? 1 : 6;
        default:
          return 6;
      }
    }
    function ke(M) {
      return M === void 0;
    }
    function ht(M) {
      return M === null;
    }
    function $n(M) {
      return typeof M == "symbol";
    }
    function Oe(M) {
      return typeof M == "object" ? M !== null : typeof M == "function";
    }
    function X(M, D) {
      switch (xe(M)) {
        case 0:
          return M;
        case 1:
          return M;
        case 2:
          return M;
        case 3:
          return M;
        case 4:
          return M;
        case 5:
          return M;
      }
      var I = D === 3 ? "string" : D === 5 ? "number" : "default", B = kr(M, s);
      if (B !== void 0) {
        var V = B.call(M, I);
        if (Oe(V))
          throw new TypeError();
        return V;
      }
      return ca(M, I === "default" ? "number" : I);
    }
    function ca(M, D) {
      if (D === "string") {
        var I = M.toString;
        if (Kt(I)) {
          var B = I.call(M);
          if (!Oe(B))
            return B;
        }
        var V = M.valueOf;
        if (Kt(V)) {
          var B = V.call(M);
          if (!Oe(B))
            return B;
        }
      } else {
        var V = M.valueOf;
        if (Kt(V)) {
          var B = V.call(M);
          if (!Oe(B))
            return B;
        }
        var ee = M.toString;
        if (Kt(ee)) {
          var B = ee.call(M);
          if (!Oe(B))
            return B;
        }
      }
      throw new TypeError();
    }
    function la(M) {
      return !!M;
    }
    function bn(M) {
      return "" + M;
    }
    function ot(M) {
      var D = X(
        M,
        3
        /* String */
      );
      return $n(D) ? D : bn(D);
    }
    function Nr(M) {
      return Array.isArray ? Array.isArray(M) : M instanceof Object ? M instanceof Array : Object.prototype.toString.call(M) === "[object Array]";
    }
    function Kt(M) {
      return typeof M == "function";
    }
    function Rr(M) {
      return typeof M == "function";
    }
    function da(M) {
      switch (xe(M)) {
        case 3:
          return !0;
        case 4:
          return !0;
        default:
          return !1;
      }
    }
    function kr(M, D) {
      var I = M[D];
      if (I != null) {
        if (!Kt(I))
          throw new TypeError();
        return I;
      }
    }
    function fa(M) {
      var D = kr(M, o);
      if (!Kt(D))
        throw new TypeError();
      var I = D.call(M);
      if (!Oe(I))
        throw new TypeError();
      return I;
    }
    function ha(M) {
      return M.value;
    }
    function _a(M) {
      var D = M.next();
      return D.done ? !1 : D;
    }
    function ya(M) {
      var D = M.return;
      D && D.call(M);
    }
    function Vn(M) {
      var D = Object.getPrototypeOf(M);
      if (typeof M != "function" || M === _ || D !== _)
        return D;
      var I = M.prototype, B = I && Object.getPrototypeOf(I);
      if (B == null || B === Object.prototype)
        return D;
      var V = B.constructor;
      return typeof V != "function" || V === M ? D : V;
    }
    function on() {
      var M = {}, D = [], I = (
        /** @class */
        function() {
          function ae(se, re, de) {
            this._index = 0, this._keys = se, this._values = re, this._selector = de;
          }
          return ae.prototype["@@iterator"] = function() {
            return this;
          }, ae.prototype[o] = function() {
            return this;
          }, ae.prototype.next = function() {
            var se = this._index;
            if (se >= 0 && se < this._keys.length) {
              var re = this._selector(this._keys[se], this._values[se]);
              return se + 1 >= this._keys.length ? (this._index = -1, this._keys = D, this._values = D) : this._index++, { value: re, done: !1 };
            }
            return { value: void 0, done: !0 };
          }, ae.prototype.throw = function(se) {
            throw this._index >= 0 && (this._index = -1, this._keys = D, this._values = D), se;
          }, ae.prototype.return = function(se) {
            return this._index >= 0 && (this._index = -1, this._keys = D, this._values = D), { value: se, done: !0 };
          }, ae;
        }()
      );
      return (
        /** @class */
        function() {
          function ae() {
            this._keys = [], this._values = [], this._cacheKey = M, this._cacheIndex = -2;
          }
          return Object.defineProperty(ae.prototype, "size", {
            get: function() {
              return this._keys.length;
            },
            enumerable: !0,
            configurable: !0
          }), ae.prototype.has = function(se) {
            return this._find(
              se,
              /*insert*/
              !1
            ) >= 0;
          }, ae.prototype.get = function(se) {
            var re = this._find(
              se,
              /*insert*/
              !1
            );
            return re >= 0 ? this._values[re] : void 0;
          }, ae.prototype.set = function(se, re) {
            var de = this._find(
              se,
              /*insert*/
              !0
            );
            return this._values[de] = re, this;
          }, ae.prototype.delete = function(se) {
            var re = this._find(
              se,
              /*insert*/
              !1
            );
            if (re >= 0) {
              for (var de = this._keys.length, fe = re + 1; fe < de; fe++)
                this._keys[fe - 1] = this._keys[fe], this._values[fe - 1] = this._values[fe];
              return this._keys.length--, this._values.length--, se === this._cacheKey && (this._cacheKey = M, this._cacheIndex = -2), !0;
            }
            return !1;
          }, ae.prototype.clear = function() {
            this._keys.length = 0, this._values.length = 0, this._cacheKey = M, this._cacheIndex = -2;
          }, ae.prototype.keys = function() {
            return new I(this._keys, this._values, B);
          }, ae.prototype.values = function() {
            return new I(this._keys, this._values, V);
          }, ae.prototype.entries = function() {
            return new I(this._keys, this._values, ee);
          }, ae.prototype["@@iterator"] = function() {
            return this.entries();
          }, ae.prototype[o] = function() {
            return this.entries();
          }, ae.prototype._find = function(se, re) {
            return this._cacheKey !== se && (this._cacheIndex = this._keys.indexOf(this._cacheKey = se)), this._cacheIndex < 0 && re && (this._cacheIndex = this._keys.length, this._keys.push(se), this._values.push(void 0)), this._cacheIndex;
          }, ae;
        }()
      );
      function B(ae, se) {
        return ae;
      }
      function V(ae, se) {
        return se;
      }
      function ee(ae, se) {
        return [ae, se];
      }
    }
    function Ue() {
      return (
        /** @class */
        function() {
          function M() {
            this._map = new F();
          }
          return Object.defineProperty(M.prototype, "size", {
            get: function() {
              return this._map.size;
            },
            enumerable: !0,
            configurable: !0
          }), M.prototype.has = function(D) {
            return this._map.has(D);
          }, M.prototype.add = function(D) {
            return this._map.set(D, D), this;
          }, M.prototype.delete = function(D) {
            return this._map.delete(D);
          }, M.prototype.clear = function() {
            this._map.clear();
          }, M.prototype.keys = function() {
            return this._map.keys();
          }, M.prototype.values = function() {
            return this._map.values();
          }, M.prototype.entries = function() {
            return this._map.entries();
          }, M.prototype["@@iterator"] = function() {
            return this.keys();
          }, M.prototype[o] = function() {
            return this.keys();
          }, M;
        }()
      );
    }
    function tt() {
      var M = 16, D = p.create(), I = B();
      return (
        /** @class */
        function() {
          function re() {
            this._key = B();
          }
          return re.prototype.has = function(de) {
            var fe = V(
              de,
              /*create*/
              !1
            );
            return fe !== void 0 ? p.has(fe, this._key) : !1;
          }, re.prototype.get = function(de) {
            var fe = V(
              de,
              /*create*/
              !1
            );
            return fe !== void 0 ? p.get(fe, this._key) : void 0;
          }, re.prototype.set = function(de, fe) {
            var _t = V(
              de,
              /*create*/
              !0
            );
            return _t[this._key] = fe, this;
          }, re.prototype.delete = function(de) {
            var fe = V(
              de,
              /*create*/
              !1
            );
            return fe !== void 0 ? delete fe[this._key] : !1;
          }, re.prototype.clear = function() {
            this._key = B();
          }, re;
        }()
      );
      function B() {
        var re;
        do
          re = "@@WeakMap@@" + se();
        while (p.has(D, re));
        return D[re] = !0, re;
      }
      function V(re, de) {
        if (!n.call(re, I)) {
          if (!de)
            return;
          Object.defineProperty(re, I, { value: p.create() });
        }
        return re[I];
      }
      function ee(re, de) {
        for (var fe = 0; fe < de; ++fe)
          re[fe] = Math.random() * 255 | 0;
        return re;
      }
      function ae(re) {
        return typeof Uint8Array == "function" ? typeof crypto < "u" ? crypto.getRandomValues(new Uint8Array(re)) : typeof msCrypto < "u" ? msCrypto.getRandomValues(new Uint8Array(re)) : ee(new Uint8Array(re), re) : ee(new Array(re), re);
      }
      function se() {
        var re = ae(M);
        re[6] = re[6] & 79 | 64, re[8] = re[8] & 191 | 128;
        for (var de = "", fe = 0; fe < M; ++fe) {
          var _t = re[fe];
          (fe === 4 || fe === 6 || fe === 8) && (de += "-"), _t < 16 && (de += "0"), de += _t.toString(16).toLowerCase();
        }
        return de;
      }
    }
    function un(M) {
      return M.__ = void 0, delete M.__, M;
    }
  });
})(Uo || (Uo = {}));
var l_ = /* @__PURE__ */ ((e) => (e.pickup = "pickup", e.delivery = "delivery", e.on_the_spot = "on_the_spot", e))(l_ || {}), d_ = /* @__PURE__ */ ((e) => (e.PICKUP = "pickup", e.DELIVERY = "delivery", e.ON_THE_SPOT = "table", e))(d_ || {}), f_ = /* @__PURE__ */ ((e) => (e.PICKUP = "pickup", e.DELIVERY = "delivery", e.CUSTOM = "custom", e.PICKUP_POINT_CUSTOM = "pickup_point_custom", e))(f_ || {}), h_ = /* @__PURE__ */ ((e) => (e.DELIVERY_METHODS = "DELIVERY_METHODS", e.LIST = "LIST", e))(h_ || {}), __ = /* @__PURE__ */ ((e) => (e.CATEGORIES = "CATEGORIES", e.PRODUCTS = "PRODUCTS", e))(__ || {}), y_ = /* @__PURE__ */ ((e) => (e.MODAL = "MODAL", e.PAGE = "PAGE", e))(y_ || {}), m_ = /* @__PURE__ */ ((e) => (e.STYLE_ONE = "style1", e.STYLE_TWO = "style2", e.STYLE_THREE = "style3", e.STYLE_FOUR = "style4", e))(m_ || {});
class ui extends Error {
  constructor(t, n, r, s) {
    super(t), this.httpStatus = n, this.errors = r, this.body = s, Object.setPrototypeOf(this, ui.prototype);
  }
}
class p_ {
  /* ---- Base endpoints url ---- */
  static get BASE_URL() {
    return "https://api-dev.easyorderapp.com";
  }
}
class Re {
  static setBaseUrl(t) {
    this._baseUrl = t;
  }
  static getBaseUrl() {
    return this._baseUrl;
  }
  static setHeaders(t) {
    this._headers = t;
  }
  static addOrReplaceHeader(t, n) {
    this._headers[t] = n;
  }
  static deleteHeader(t) {
    delete this._headers[t];
  }
  static getAllHeaders() {
    return this._headers;
  }
  static getHeader(t) {
    return this._headers[t];
  }
}
Re._baseUrl = p_.BASE_URL;
Re._headers = {};
function Cu(e, t) {
  return function() {
    return e.apply(t, arguments);
  };
}
const { toString: g_ } = Object.prototype, { getPrototypeOf: bs } = Object, gi = ((e) => (t) => {
  const n = g_.call(t);
  return e[n] || (e[n] = n.slice(8, -1).toLowerCase());
})(/* @__PURE__ */ Object.create(null)), Tt = (e) => (e = e.toLowerCase(), (t) => gi(t) === e), vi = (e) => (t) => typeof t === e, { isArray: Un } = Array, dr = vi("undefined");
function v_(e) {
  return e !== null && !dr(e) && e.constructor !== null && !dr(e.constructor) && lt(e.constructor.isBuffer) && e.constructor.isBuffer(e);
}
const ju = Tt("ArrayBuffer");
function w_(e) {
  let t;
  return typeof ArrayBuffer < "u" && ArrayBuffer.isView ? t = ArrayBuffer.isView(e) : t = e && e.buffer && ju(e.buffer), t;
}
const S_ = vi("string"), lt = vi("function"), Lu = vi("number"), wi = (e) => e !== null && typeof e == "object", b_ = (e) => e === !0 || e === !1, ti = (e) => {
  if (gi(e) !== "object")
    return !1;
  const t = bs(e);
  return (t === null || t === Object.prototype || Object.getPrototypeOf(t) === null) && !(Symbol.toStringTag in e) && !(Symbol.iterator in e);
}, M_ = Tt("Date"), O_ = Tt("File"), D_ = Tt("Blob"), T_ = Tt("FileList"), A_ = (e) => wi(e) && lt(e.pipe), E_ = (e) => {
  let t;
  return e && (typeof FormData == "function" && e instanceof FormData || lt(e.append) && ((t = gi(e)) === "formdata" || // detect form-data instance
  t === "object" && lt(e.toString) && e.toString() === "[object FormData]"));
}, P_ = Tt("URLSearchParams"), N_ = (e) => e.trim ? e.trim() : e.replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, "");
function mr(e, t, { allOwnKeys: n = !1 } = {}) {
  if (e === null || typeof e > "u")
    return;
  let r, s;
  if (typeof e != "object" && (e = [e]), Un(e))
    for (r = 0, s = e.length; r < s; r++)
      t.call(null, e[r], r, e);
  else {
    const o = n ? Object.getOwnPropertyNames(e) : Object.keys(e), l = o.length;
    let f;
    for (r = 0; r < l; r++)
      f = o[r], t.call(null, e[f], f, e);
  }
}
function xu(e, t) {
  t = t.toLowerCase();
  const n = Object.keys(e);
  let r = n.length, s;
  for (; r-- > 0; )
    if (s = n[r], t === s.toLowerCase())
      return s;
  return null;
}
const Fu = (() => typeof globalThis < "u" ? globalThis : typeof self < "u" ? self : typeof window < "u" ? window : global)(), Bu = (e) => !dr(e) && e !== Fu;
function Qa() {
  const { caseless: e } = Bu(this) && this || {}, t = {}, n = (r, s) => {
    const o = e && xu(t, s) || s;
    ti(t[o]) && ti(r) ? t[o] = Qa(t[o], r) : ti(r) ? t[o] = Qa({}, r) : Un(r) ? t[o] = r.slice() : t[o] = r;
  };
  for (let r = 0, s = arguments.length; r < s; r++)
    arguments[r] && mr(arguments[r], n);
  return t;
}
const R_ = (e, t, n, { allOwnKeys: r } = {}) => (mr(t, (s, o) => {
  n && lt(s) ? e[o] = Cu(s, n) : e[o] = s;
}, { allOwnKeys: r }), e), k_ = (e) => (e.charCodeAt(0) === 65279 && (e = e.slice(1)), e), I_ = (e, t, n, r) => {
  e.prototype = Object.create(t.prototype, r), e.prototype.constructor = e, Object.defineProperty(e, "super", {
    value: t.prototype
  }), n && Object.assign(e.prototype, n);
}, Y_ = (e, t, n, r) => {
  let s, o, l;
  const f = {};
  if (t = t || {}, e == null)
    return t;
  do {
    for (s = Object.getOwnPropertyNames(e), o = s.length; o-- > 0; )
      l = s[o], (!r || r(l, e, t)) && !f[l] && (t[l] = e[l], f[l] = !0);
    e = n !== !1 && bs(e);
  } while (e && (!n || n(e, t)) && e !== Object.prototype);
  return t;
}, C_ = (e, t, n) => {
  e = String(e), (n === void 0 || n > e.length) && (n = e.length), n -= t.length;
  const r = e.indexOf(t, n);
  return r !== -1 && r === n;
}, j_ = (e) => {
  if (!e)
    return null;
  if (Un(e))
    return e;
  let t = e.length;
  if (!Lu(t))
    return null;
  const n = new Array(t);
  for (; t-- > 0; )
    n[t] = e[t];
  return n;
}, L_ = ((e) => (t) => e && t instanceof e)(typeof Uint8Array < "u" && bs(Uint8Array)), x_ = (e, t) => {
  const r = (e && e[Symbol.iterator]).call(e);
  let s;
  for (; (s = r.next()) && !s.done; ) {
    const o = s.value;
    t.call(e, o[0], o[1]);
  }
}, F_ = (e, t) => {
  let n;
  const r = [];
  for (; (n = e.exec(t)) !== null; )
    r.push(n);
  return r;
}, B_ = Tt("HTMLFormElement"), U_ = (e) => e.toLowerCase().replace(
  /[-_\s]([a-z\d])(\w*)/g,
  function(n, r, s) {
    return r.toUpperCase() + s;
  }
), Wo = (({ hasOwnProperty: e }) => (t, n) => e.call(t, n))(Object.prototype), W_ = Tt("RegExp"), Uu = (e, t) => {
  const n = Object.getOwnPropertyDescriptors(e), r = {};
  mr(n, (s, o) => {
    let l;
    (l = t(s, o, e)) !== !1 && (r[o] = l || s);
  }), Object.defineProperties(e, r);
}, H_ = (e) => {
  Uu(e, (t, n) => {
    if (lt(e) && ["arguments", "caller", "callee"].indexOf(n) !== -1)
      return !1;
    const r = e[n];
    if (lt(r)) {
      if (t.enumerable = !1, "writable" in t) {
        t.writable = !1;
        return;
      }
      t.set || (t.set = () => {
        throw Error("Can not rewrite read-only method '" + n + "'");
      });
    }
  });
}, G_ = (e, t) => {
  const n = {}, r = (s) => {
    s.forEach((o) => {
      n[o] = !0;
    });
  };
  return Un(e) ? r(e) : r(String(e).split(t)), n;
}, $_ = () => {
}, V_ = (e, t) => (e = +e, Number.isFinite(e) ? e : t), ja = "abcdefghijklmnopqrstuvwxyz", Ho = "0123456789", Wu = {
  DIGIT: Ho,
  ALPHA: ja,
  ALPHA_DIGIT: ja + ja.toUpperCase() + Ho
}, z_ = (e = 16, t = Wu.ALPHA_DIGIT) => {
  let n = "";
  const { length: r } = t;
  for (; e--; )
    n += t[Math.random() * r | 0];
  return n;
};
function q_(e) {
  return !!(e && lt(e.append) && e[Symbol.toStringTag] === "FormData" && e[Symbol.iterator]);
}
const J_ = (e) => {
  const t = new Array(10), n = (r, s) => {
    if (wi(r)) {
      if (t.indexOf(r) >= 0)
        return;
      if (!("toJSON" in r)) {
        t[s] = r;
        const o = Un(r) ? [] : {};
        return mr(r, (l, f) => {
          const w = n(l, s + 1);
          !dr(w) && (o[f] = w);
        }), t[s] = void 0, o;
      }
    }
    return r;
  };
  return n(e, 0);
}, Z_ = Tt("AsyncFunction"), K_ = (e) => e && (wi(e) || lt(e)) && lt(e.then) && lt(e.catch), N = {
  isArray: Un,
  isArrayBuffer: ju,
  isBuffer: v_,
  isFormData: E_,
  isArrayBufferView: w_,
  isString: S_,
  isNumber: Lu,
  isBoolean: b_,
  isObject: wi,
  isPlainObject: ti,
  isUndefined: dr,
  isDate: M_,
  isFile: O_,
  isBlob: D_,
  isRegExp: W_,
  isFunction: lt,
  isStream: A_,
  isURLSearchParams: P_,
  isTypedArray: L_,
  isFileList: T_,
  forEach: mr,
  merge: Qa,
  extend: R_,
  trim: N_,
  stripBOM: k_,
  inherits: I_,
  toFlatObject: Y_,
  kindOf: gi,
  kindOfTest: Tt,
  endsWith: C_,
  toArray: j_,
  forEachEntry: x_,
  matchAll: F_,
  isHTMLForm: B_,
  hasOwnProperty: Wo,
  hasOwnProp: Wo,
  // an alias to avoid ESLint no-prototype-builtins detection
  reduceDescriptors: Uu,
  freezeMethods: H_,
  toObjectSet: G_,
  toCamelCase: U_,
  noop: $_,
  toFiniteNumber: V_,
  findKey: xu,
  global: Fu,
  isContextDefined: Bu,
  ALPHABET: Wu,
  generateString: z_,
  isSpecCompliantForm: q_,
  toJSONObject: J_,
  isAsyncFn: Z_,
  isThenable: K_
};
function _e(e, t, n, r, s) {
  Error.call(this), Error.captureStackTrace ? Error.captureStackTrace(this, this.constructor) : this.stack = new Error().stack, this.message = e, this.name = "AxiosError", t && (this.code = t), n && (this.config = n), r && (this.request = r), s && (this.response = s);
}
N.inherits(_e, Error, {
  toJSON: function() {
    return {
      // Standard
      message: this.message,
      name: this.name,
      // Microsoft
      description: this.description,
      number: this.number,
      // Mozilla
      fileName: this.fileName,
      lineNumber: this.lineNumber,
      columnNumber: this.columnNumber,
      stack: this.stack,
      // Axios
      config: N.toJSONObject(this.config),
      code: this.code,
      status: this.response && this.response.status ? this.response.status : null
    };
  }
});
const Hu = _e.prototype, Gu = {};
[
  "ERR_BAD_OPTION_VALUE",
  "ERR_BAD_OPTION",
  "ECONNABORTED",
  "ETIMEDOUT",
  "ERR_NETWORK",
  "ERR_FR_TOO_MANY_REDIRECTS",
  "ERR_DEPRECATED",
  "ERR_BAD_RESPONSE",
  "ERR_BAD_REQUEST",
  "ERR_CANCELED",
  "ERR_NOT_SUPPORT",
  "ERR_INVALID_URL"
  // eslint-disable-next-line func-names
].forEach((e) => {
  Gu[e] = { value: e };
});
Object.defineProperties(_e, Gu);
Object.defineProperty(Hu, "isAxiosError", { value: !0 });
_e.from = (e, t, n, r, s, o) => {
  const l = Object.create(Hu);
  return N.toFlatObject(e, l, function(w) {
    return w !== Error.prototype;
  }, (f) => f !== "isAxiosError"), _e.call(l, e.message, t, n, r, s), l.cause = e, l.name = e.name, o && Object.assign(l, o), l;
};
const Q_ = null;
function Xa(e) {
  return N.isPlainObject(e) || N.isArray(e);
}
function $u(e) {
  return N.endsWith(e, "[]") ? e.slice(0, -2) : e;
}
function Go(e, t, n) {
  return e ? e.concat(t).map(function(s, o) {
    return s = $u(s), !n && o ? "[" + s + "]" : s;
  }).join(n ? "." : "") : t;
}
function X_(e) {
  return N.isArray(e) && !e.some(Xa);
}
const ey = N.toFlatObject(N, {}, null, function(t) {
  return /^is[A-Z]/.test(t);
});
function Si(e, t, n) {
  if (!N.isObject(e))
    throw new TypeError("target must be an object");
  t = t || new FormData(), n = N.toFlatObject(n, {
    metaTokens: !0,
    dots: !1,
    indexes: !1
  }, !1, function($, W) {
    return !N.isUndefined(W[$]);
  });
  const r = n.metaTokens, s = n.visitor || _, o = n.dots, l = n.indexes, w = (n.Blob || typeof Blob < "u" && Blob) && N.isSpecCompliantForm(t);
  if (!N.isFunction(s))
    throw new TypeError("visitor must be a function");
  function p(E) {
    if (E === null)
      return "";
    if (N.isDate(E))
      return E.toISOString();
    if (!w && N.isBlob(E))
      throw new _e("Blob is not supported. Use a Buffer instead.");
    return N.isArrayBuffer(E) || N.isTypedArray(E) ? w && typeof Blob == "function" ? new Blob([E]) : Buffer.from(E) : E;
  }
  function _(E, $, W) {
    let ve = E;
    if (E && !W && typeof E == "object") {
      if (N.endsWith($, "{}"))
        $ = r ? $ : $.slice(0, -2), E = JSON.stringify(E);
      else if (N.isArray(E) && X_(E) || (N.isFileList(E) || N.endsWith($, "[]")) && (ve = N.toArray(E)))
        return $ = $u($), ve.forEach(function(U, K) {
          !(N.isUndefined(U) || U === null) && t.append(
            // eslint-disable-next-line no-nested-ternary
            l === !0 ? Go([$], K, o) : l === null ? $ : $ + "[]",
            p(U)
          );
        }), !1;
    }
    return Xa(E) ? !0 : (t.append(Go(W, $, o), p(E)), !1);
  }
  const O = [], F = Object.assign(ey, {
    defaultVisitor: _,
    convertValue: p,
    isVisitable: Xa
  });
  function H(E, $) {
    if (!N.isUndefined(E)) {
      if (O.indexOf(E) !== -1)
        throw Error("Circular reference detected in " + $.join("."));
      O.push(E), N.forEach(E, function(ve, Z) {
        (!(N.isUndefined(ve) || ve === null) && s.call(
          t,
          ve,
          N.isString(Z) ? Z.trim() : Z,
          $,
          F
        )) === !0 && H(ve, $ ? $.concat(Z) : [Z]);
      }), O.pop();
    }
  }
  if (!N.isObject(e))
    throw new TypeError("data must be an object");
  return H(e), t;
}
function $o(e) {
  const t = {
    "!": "%21",
    "'": "%27",
    "(": "%28",
    ")": "%29",
    "~": "%7E",
    "%20": "+",
    "%00": "\0"
  };
  return encodeURIComponent(e).replace(/[!'()~]|%20|%00/g, function(r) {
    return t[r];
  });
}
function Ms(e, t) {
  this._pairs = [], e && Si(e, this, t);
}
const Vu = Ms.prototype;
Vu.append = function(t, n) {
  this._pairs.push([t, n]);
};
Vu.toString = function(t) {
  const n = t ? function(r) {
    return t.call(this, r, $o);
  } : $o;
  return this._pairs.map(function(s) {
    return n(s[0]) + "=" + n(s[1]);
  }, "").join("&");
};
function ty(e) {
  return encodeURIComponent(e).replace(/%3A/gi, ":").replace(/%24/g, "$").replace(/%2C/gi, ",").replace(/%20/g, "+").replace(/%5B/gi, "[").replace(/%5D/gi, "]");
}
function zu(e, t, n) {
  if (!t)
    return e;
  const r = n && n.encode || ty, s = n && n.serialize;
  let o;
  if (s ? o = s(t, n) : o = N.isURLSearchParams(t) ? t.toString() : new Ms(t, n).toString(r), o) {
    const l = e.indexOf("#");
    l !== -1 && (e = e.slice(0, l)), e += (e.indexOf("?") === -1 ? "?" : "&") + o;
  }
  return e;
}
class ny {
  constructor() {
    this.handlers = [];
  }
  /**
   * Add a new interceptor to the stack
   *
   * @param {Function} fulfilled The function to handle `then` for a `Promise`
   * @param {Function} rejected The function to handle `reject` for a `Promise`
   *
   * @return {Number} An ID used to remove interceptor later
   */
  use(t, n, r) {
    return this.handlers.push({
      fulfilled: t,
      rejected: n,
      synchronous: r ? r.synchronous : !1,
      runWhen: r ? r.runWhen : null
    }), this.handlers.length - 1;
  }
  /**
   * Remove an interceptor from the stack
   *
   * @param {Number} id The ID that was returned by `use`
   *
   * @returns {Boolean} `true` if the interceptor was removed, `false` otherwise
   */
  eject(t) {
    this.handlers[t] && (this.handlers[t] = null);
  }
  /**
   * Clear all interceptors from the stack
   *
   * @returns {void}
   */
  clear() {
    this.handlers && (this.handlers = []);
  }
  /**
   * Iterate over all the registered interceptors
   *
   * This method is particularly useful for skipping over any
   * interceptors that may have become `null` calling `eject`.
   *
   * @param {Function} fn The function to call for each interceptor
   *
   * @returns {void}
   */
  forEach(t) {
    N.forEach(this.handlers, function(r) {
      r !== null && t(r);
    });
  }
}
const Vo = ny, qu = {
  silentJSONParsing: !0,
  forcedJSONParsing: !0,
  clarifyTimeoutError: !1
}, ry = typeof URLSearchParams < "u" ? URLSearchParams : Ms, iy = typeof FormData < "u" ? FormData : null, ay = typeof Blob < "u" ? Blob : null, sy = (() => {
  let e;
  return typeof navigator < "u" && ((e = navigator.product) === "ReactNative" || e === "NativeScript" || e === "NS") ? !1 : typeof window < "u" && typeof document < "u";
})(), oy = (() => typeof WorkerGlobalScope < "u" && // eslint-disable-next-line no-undef
self instanceof WorkerGlobalScope && typeof self.importScripts == "function")(), Ot = {
  isBrowser: !0,
  classes: {
    URLSearchParams: ry,
    FormData: iy,
    Blob: ay
  },
  isStandardBrowserEnv: sy,
  isStandardBrowserWebWorkerEnv: oy,
  protocols: ["http", "https", "file", "blob", "url", "data"]
};
function uy(e, t) {
  return Si(e, new Ot.classes.URLSearchParams(), Object.assign({
    visitor: function(n, r, s, o) {
      return Ot.isNode && N.isBuffer(n) ? (this.append(r, n.toString("base64")), !1) : o.defaultVisitor.apply(this, arguments);
    }
  }, t));
}
function cy(e) {
  return N.matchAll(/\w+|\[(\w*)]/g, e).map((t) => t[0] === "[]" ? "" : t[1] || t[0]);
}
function ly(e) {
  const t = {}, n = Object.keys(e);
  let r;
  const s = n.length;
  let o;
  for (r = 0; r < s; r++)
    o = n[r], t[o] = e[o];
  return t;
}
function Ju(e) {
  function t(n, r, s, o) {
    let l = n[o++];
    const f = Number.isFinite(+l), w = o >= n.length;
    return l = !l && N.isArray(s) ? s.length : l, w ? (N.hasOwnProp(s, l) ? s[l] = [s[l], r] : s[l] = r, !f) : ((!s[l] || !N.isObject(s[l])) && (s[l] = []), t(n, r, s[l], o) && N.isArray(s[l]) && (s[l] = ly(s[l])), !f);
  }
  if (N.isFormData(e) && N.isFunction(e.entries)) {
    const n = {};
    return N.forEachEntry(e, (r, s) => {
      t(cy(r), s, n, 0);
    }), n;
  }
  return null;
}
function dy(e, t, n) {
  if (N.isString(e))
    try {
      return (t || JSON.parse)(e), N.trim(e);
    } catch (r) {
      if (r.name !== "SyntaxError")
        throw r;
    }
  return (n || JSON.stringify)(e);
}
const Os = {
  transitional: qu,
  adapter: ["xhr", "http"],
  transformRequest: [function(t, n) {
    const r = n.getContentType() || "", s = r.indexOf("application/json") > -1, o = N.isObject(t);
    if (o && N.isHTMLForm(t) && (t = new FormData(t)), N.isFormData(t))
      return s && s ? JSON.stringify(Ju(t)) : t;
    if (N.isArrayBuffer(t) || N.isBuffer(t) || N.isStream(t) || N.isFile(t) || N.isBlob(t))
      return t;
    if (N.isArrayBufferView(t))
      return t.buffer;
    if (N.isURLSearchParams(t))
      return n.setContentType("application/x-www-form-urlencoded;charset=utf-8", !1), t.toString();
    let f;
    if (o) {
      if (r.indexOf("application/x-www-form-urlencoded") > -1)
        return uy(t, this.formSerializer).toString();
      if ((f = N.isFileList(t)) || r.indexOf("multipart/form-data") > -1) {
        const w = this.env && this.env.FormData;
        return Si(
          f ? { "files[]": t } : t,
          w && new w(),
          this.formSerializer
        );
      }
    }
    return o || s ? (n.setContentType("application/json", !1), dy(t)) : t;
  }],
  transformResponse: [function(t) {
    const n = this.transitional || Os.transitional, r = n && n.forcedJSONParsing, s = this.responseType === "json";
    if (t && N.isString(t) && (r && !this.responseType || s)) {
      const l = !(n && n.silentJSONParsing) && s;
      try {
        return JSON.parse(t);
      } catch (f) {
        if (l)
          throw f.name === "SyntaxError" ? _e.from(f, _e.ERR_BAD_RESPONSE, this, null, this.response) : f;
      }
    }
    return t;
  }],
  /**
   * A timeout in milliseconds to abort a request. If set to 0 (default) a
   * timeout is not created.
   */
  timeout: 0,
  xsrfCookieName: "XSRF-TOKEN",
  xsrfHeaderName: "X-XSRF-TOKEN",
  maxContentLength: -1,
  maxBodyLength: -1,
  env: {
    FormData: Ot.classes.FormData,
    Blob: Ot.classes.Blob
  },
  validateStatus: function(t) {
    return t >= 200 && t < 300;
  },
  headers: {
    common: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": void 0
    }
  }
};
N.forEach(["delete", "get", "head", "post", "put", "patch"], (e) => {
  Os.headers[e] = {};
});
const Ds = Os, fy = N.toObjectSet([
  "age",
  "authorization",
  "content-length",
  "content-type",
  "etag",
  "expires",
  "from",
  "host",
  "if-modified-since",
  "if-unmodified-since",
  "last-modified",
  "location",
  "max-forwards",
  "proxy-authorization",
  "referer",
  "retry-after",
  "user-agent"
]), hy = (e) => {
  const t = {};
  let n, r, s;
  return e && e.split(`
`).forEach(function(l) {
    s = l.indexOf(":"), n = l.substring(0, s).trim().toLowerCase(), r = l.substring(s + 1).trim(), !(!n || t[n] && fy[n]) && (n === "set-cookie" ? t[n] ? t[n].push(r) : t[n] = [r] : t[n] = t[n] ? t[n] + ", " + r : r);
  }), t;
}, zo = Symbol("internals");
function tr(e) {
  return e && String(e).trim().toLowerCase();
}
function ni(e) {
  return e === !1 || e == null ? e : N.isArray(e) ? e.map(ni) : String(e);
}
function _y(e) {
  const t = /* @__PURE__ */ Object.create(null), n = /([^\s,;=]+)\s*(?:=\s*([^,;]+))?/g;
  let r;
  for (; r = n.exec(e); )
    t[r[1]] = r[2];
  return t;
}
const yy = (e) => /^[-_a-zA-Z0-9^`|~,!#$%&'*+.]+$/.test(e.trim());
function La(e, t, n, r, s) {
  if (N.isFunction(r))
    return r.call(this, t, n);
  if (s && (t = n), !!N.isString(t)) {
    if (N.isString(r))
      return t.indexOf(r) !== -1;
    if (N.isRegExp(r))
      return r.test(t);
  }
}
function my(e) {
  return e.trim().toLowerCase().replace(/([a-z\d])(\w*)/g, (t, n, r) => n.toUpperCase() + r);
}
function py(e, t) {
  const n = N.toCamelCase(" " + t);
  ["get", "set", "has"].forEach((r) => {
    Object.defineProperty(e, r + n, {
      value: function(s, o, l) {
        return this[r].call(this, t, s, o, l);
      },
      configurable: !0
    });
  });
}
class bi {
  constructor(t) {
    t && this.set(t);
  }
  set(t, n, r) {
    const s = this;
    function o(f, w, p) {
      const _ = tr(w);
      if (!_)
        throw new Error("header name must be a non-empty string");
      const O = N.findKey(s, _);
      (!O || s[O] === void 0 || p === !0 || p === void 0 && s[O] !== !1) && (s[O || w] = ni(f));
    }
    const l = (f, w) => N.forEach(f, (p, _) => o(p, _, w));
    return N.isPlainObject(t) || t instanceof this.constructor ? l(t, n) : N.isString(t) && (t = t.trim()) && !yy(t) ? l(hy(t), n) : t != null && o(n, t, r), this;
  }
  get(t, n) {
    if (t = tr(t), t) {
      const r = N.findKey(this, t);
      if (r) {
        const s = this[r];
        if (!n)
          return s;
        if (n === !0)
          return _y(s);
        if (N.isFunction(n))
          return n.call(this, s, r);
        if (N.isRegExp(n))
          return n.exec(s);
        throw new TypeError("parser must be boolean|regexp|function");
      }
    }
  }
  has(t, n) {
    if (t = tr(t), t) {
      const r = N.findKey(this, t);
      return !!(r && this[r] !== void 0 && (!n || La(this, this[r], r, n)));
    }
    return !1;
  }
  delete(t, n) {
    const r = this;
    let s = !1;
    function o(l) {
      if (l = tr(l), l) {
        const f = N.findKey(r, l);
        f && (!n || La(r, r[f], f, n)) && (delete r[f], s = !0);
      }
    }
    return N.isArray(t) ? t.forEach(o) : o(t), s;
  }
  clear(t) {
    const n = Object.keys(this);
    let r = n.length, s = !1;
    for (; r--; ) {
      const o = n[r];
      (!t || La(this, this[o], o, t, !0)) && (delete this[o], s = !0);
    }
    return s;
  }
  normalize(t) {
    const n = this, r = {};
    return N.forEach(this, (s, o) => {
      const l = N.findKey(r, o);
      if (l) {
        n[l] = ni(s), delete n[o];
        return;
      }
      const f = t ? my(o) : String(o).trim();
      f !== o && delete n[o], n[f] = ni(s), r[f] = !0;
    }), this;
  }
  concat(...t) {
    return this.constructor.concat(this, ...t);
  }
  toJSON(t) {
    const n = /* @__PURE__ */ Object.create(null);
    return N.forEach(this, (r, s) => {
      r != null && r !== !1 && (n[s] = t && N.isArray(r) ? r.join(", ") : r);
    }), n;
  }
  [Symbol.iterator]() {
    return Object.entries(this.toJSON())[Symbol.iterator]();
  }
  toString() {
    return Object.entries(this.toJSON()).map(([t, n]) => t + ": " + n).join(`
`);
  }
  get [Symbol.toStringTag]() {
    return "AxiosHeaders";
  }
  static from(t) {
    return t instanceof this ? t : new this(t);
  }
  static concat(t, ...n) {
    const r = new this(t);
    return n.forEach((s) => r.set(s)), r;
  }
  static accessor(t) {
    const r = (this[zo] = this[zo] = {
      accessors: {}
    }).accessors, s = this.prototype;
    function o(l) {
      const f = tr(l);
      r[f] || (py(s, l), r[f] = !0);
    }
    return N.isArray(t) ? t.forEach(o) : o(t), this;
  }
}
bi.accessor(["Content-Type", "Content-Length", "Accept", "Accept-Encoding", "User-Agent", "Authorization"]);
N.reduceDescriptors(bi.prototype, ({ value: e }, t) => {
  let n = t[0].toUpperCase() + t.slice(1);
  return {
    get: () => e,
    set(r) {
      this[n] = r;
    }
  };
});
N.freezeMethods(bi);
const Gt = bi;
function xa(e, t) {
  const n = this || Ds, r = t || n, s = Gt.from(r.headers);
  let o = r.data;
  return N.forEach(e, function(f) {
    o = f.call(n, o, s.normalize(), t ? t.status : void 0);
  }), s.normalize(), o;
}
function Zu(e) {
  return !!(e && e.__CANCEL__);
}
function pr(e, t, n) {
  _e.call(this, e ?? "canceled", _e.ERR_CANCELED, t, n), this.name = "CanceledError";
}
N.inherits(pr, _e, {
  __CANCEL__: !0
});
function gy(e, t, n) {
  const r = n.config.validateStatus;
  !n.status || !r || r(n.status) ? e(n) : t(new _e(
    "Request failed with status code " + n.status,
    [_e.ERR_BAD_REQUEST, _e.ERR_BAD_RESPONSE][Math.floor(n.status / 100) - 4],
    n.config,
    n.request,
    n
  ));
}
const vy = Ot.isStandardBrowserEnv ? (
  // Standard browser envs support document.cookie
  function() {
    return {
      write: function(n, r, s, o, l, f) {
        const w = [];
        w.push(n + "=" + encodeURIComponent(r)), N.isNumber(s) && w.push("expires=" + new Date(s).toGMTString()), N.isString(o) && w.push("path=" + o), N.isString(l) && w.push("domain=" + l), f === !0 && w.push("secure"), document.cookie = w.join("; ");
      },
      read: function(n) {
        const r = document.cookie.match(new RegExp("(^|;\\s*)(" + n + ")=([^;]*)"));
        return r ? decodeURIComponent(r[3]) : null;
      },
      remove: function(n) {
        this.write(n, "", Date.now() - 864e5);
      }
    };
  }()
) : (
  // Non standard browser env (web workers, react-native) lack needed support.
  function() {
    return {
      write: function() {
      },
      read: function() {
        return null;
      },
      remove: function() {
      }
    };
  }()
);
function wy(e) {
  return /^([a-z][a-z\d+\-.]*:)?\/\//i.test(e);
}
function Sy(e, t) {
  return t ? e.replace(/\/+$/, "") + "/" + t.replace(/^\/+/, "") : e;
}
function Ku(e, t) {
  return e && !wy(t) ? Sy(e, t) : t;
}
const by = Ot.isStandardBrowserEnv ? (
  // Standard browser envs have full support of the APIs needed to test
  // whether the request URL is of the same origin as current location.
  function() {
    const t = /(msie|trident)/i.test(navigator.userAgent), n = document.createElement("a");
    let r;
    function s(o) {
      let l = o;
      return t && (n.setAttribute("href", l), l = n.href), n.setAttribute("href", l), {
        href: n.href,
        protocol: n.protocol ? n.protocol.replace(/:$/, "") : "",
        host: n.host,
        search: n.search ? n.search.replace(/^\?/, "") : "",
        hash: n.hash ? n.hash.replace(/^#/, "") : "",
        hostname: n.hostname,
        port: n.port,
        pathname: n.pathname.charAt(0) === "/" ? n.pathname : "/" + n.pathname
      };
    }
    return r = s(window.location.href), function(l) {
      const f = N.isString(l) ? s(l) : l;
      return f.protocol === r.protocol && f.host === r.host;
    };
  }()
) : (
  // Non standard browser envs (web workers, react-native) lack needed support.
  function() {
    return function() {
      return !0;
    };
  }()
);
function My(e) {
  const t = /^([-+\w]{1,25})(:?\/\/|:)/.exec(e);
  return t && t[1] || "";
}
function Oy(e, t) {
  e = e || 10;
  const n = new Array(e), r = new Array(e);
  let s = 0, o = 0, l;
  return t = t !== void 0 ? t : 1e3, function(w) {
    const p = Date.now(), _ = r[o];
    l || (l = p), n[s] = w, r[s] = p;
    let O = o, F = 0;
    for (; O !== s; )
      F += n[O++], O = O % e;
    if (s = (s + 1) % e, s === o && (o = (o + 1) % e), p - l < t)
      return;
    const H = _ && p - _;
    return H ? Math.round(F * 1e3 / H) : void 0;
  };
}
function qo(e, t) {
  let n = 0;
  const r = Oy(50, 250);
  return (s) => {
    const o = s.loaded, l = s.lengthComputable ? s.total : void 0, f = o - n, w = r(f), p = o <= l;
    n = o;
    const _ = {
      loaded: o,
      total: l,
      progress: l ? o / l : void 0,
      bytes: f,
      rate: w || void 0,
      estimated: w && l && p ? (l - o) / w : void 0,
      event: s
    };
    _[t ? "download" : "upload"] = !0, e(_);
  };
}
const Dy = typeof XMLHttpRequest < "u", Ty = Dy && function(e) {
  return new Promise(function(n, r) {
    let s = e.data;
    const o = Gt.from(e.headers).normalize(), l = e.responseType;
    let f;
    function w() {
      e.cancelToken && e.cancelToken.unsubscribe(f), e.signal && e.signal.removeEventListener("abort", f);
    }
    let p;
    N.isFormData(s) && (Ot.isStandardBrowserEnv || Ot.isStandardBrowserWebWorkerEnv ? o.setContentType(!1) : o.getContentType(/^\s*multipart\/form-data/) ? N.isString(p = o.getContentType()) && o.setContentType(p.replace(/^\s*(multipart\/form-data);+/, "$1")) : o.setContentType("multipart/form-data"));
    let _ = new XMLHttpRequest();
    if (e.auth) {
      const E = e.auth.username || "", $ = e.auth.password ? unescape(encodeURIComponent(e.auth.password)) : "";
      o.set("Authorization", "Basic " + btoa(E + ":" + $));
    }
    const O = Ku(e.baseURL, e.url);
    _.open(e.method.toUpperCase(), zu(O, e.params, e.paramsSerializer), !0), _.timeout = e.timeout;
    function F() {
      if (!_)
        return;
      const E = Gt.from(
        "getAllResponseHeaders" in _ && _.getAllResponseHeaders()
      ), W = {
        data: !l || l === "text" || l === "json" ? _.responseText : _.response,
        status: _.status,
        statusText: _.statusText,
        headers: E,
        config: e,
        request: _
      };
      gy(function(Z) {
        n(Z), w();
      }, function(Z) {
        r(Z), w();
      }, W), _ = null;
    }
    if ("onloadend" in _ ? _.onloadend = F : _.onreadystatechange = function() {
      !_ || _.readyState !== 4 || _.status === 0 && !(_.responseURL && _.responseURL.indexOf("file:") === 0) || setTimeout(F);
    }, _.onabort = function() {
      _ && (r(new _e("Request aborted", _e.ECONNABORTED, e, _)), _ = null);
    }, _.onerror = function() {
      r(new _e("Network Error", _e.ERR_NETWORK, e, _)), _ = null;
    }, _.ontimeout = function() {
      let $ = e.timeout ? "timeout of " + e.timeout + "ms exceeded" : "timeout exceeded";
      const W = e.transitional || qu;
      e.timeoutErrorMessage && ($ = e.timeoutErrorMessage), r(new _e(
        $,
        W.clarifyTimeoutError ? _e.ETIMEDOUT : _e.ECONNABORTED,
        e,
        _
      )), _ = null;
    }, Ot.isStandardBrowserEnv) {
      const E = by(O) && e.xsrfCookieName && vy.read(e.xsrfCookieName);
      E && o.set(e.xsrfHeaderName, E);
    }
    s === void 0 && o.setContentType(null), "setRequestHeader" in _ && N.forEach(o.toJSON(), function($, W) {
      _.setRequestHeader(W, $);
    }), N.isUndefined(e.withCredentials) || (_.withCredentials = !!e.withCredentials), l && l !== "json" && (_.responseType = e.responseType), typeof e.onDownloadProgress == "function" && _.addEventListener("progress", qo(e.onDownloadProgress, !0)), typeof e.onUploadProgress == "function" && _.upload && _.upload.addEventListener("progress", qo(e.onUploadProgress)), (e.cancelToken || e.signal) && (f = (E) => {
      _ && (r(!E || E.type ? new pr(null, e, _) : E), _.abort(), _ = null);
    }, e.cancelToken && e.cancelToken.subscribe(f), e.signal && (e.signal.aborted ? f() : e.signal.addEventListener("abort", f)));
    const H = My(O);
    if (H && Ot.protocols.indexOf(H) === -1) {
      r(new _e("Unsupported protocol " + H + ":", _e.ERR_BAD_REQUEST, e));
      return;
    }
    _.send(s || null);
  });
}, es = {
  http: Q_,
  xhr: Ty
};
N.forEach(es, (e, t) => {
  if (e) {
    try {
      Object.defineProperty(e, "name", { value: t });
    } catch {
    }
    Object.defineProperty(e, "adapterName", { value: t });
  }
});
const Jo = (e) => `- ${e}`, Ay = (e) => N.isFunction(e) || e === null || e === !1, Qu = {
  getAdapter: (e) => {
    e = N.isArray(e) ? e : [e];
    const { length: t } = e;
    let n, r;
    const s = {};
    for (let o = 0; o < t; o++) {
      n = e[o];
      let l;
      if (r = n, !Ay(n) && (r = es[(l = String(n)).toLowerCase()], r === void 0))
        throw new _e(`Unknown adapter '${l}'`);
      if (r)
        break;
      s[l || "#" + o] = r;
    }
    if (!r) {
      const o = Object.entries(s).map(
        ([f, w]) => `adapter ${f} ` + (w === !1 ? "is not supported by the environment" : "is not available in the build")
      );
      let l = t ? o.length > 1 ? `since :
` + o.map(Jo).join(`
`) : " " + Jo(o[0]) : "as no adapter specified";
      throw new _e(
        "There is no suitable adapter to dispatch the request " + l,
        "ERR_NOT_SUPPORT"
      );
    }
    return r;
  },
  adapters: es
};
function Fa(e) {
  if (e.cancelToken && e.cancelToken.throwIfRequested(), e.signal && e.signal.aborted)
    throw new pr(null, e);
}
function Zo(e) {
  return Fa(e), e.headers = Gt.from(e.headers), e.data = xa.call(
    e,
    e.transformRequest
  ), ["post", "put", "patch"].indexOf(e.method) !== -1 && e.headers.setContentType("application/x-www-form-urlencoded", !1), Qu.getAdapter(e.adapter || Ds.adapter)(e).then(function(r) {
    return Fa(e), r.data = xa.call(
      e,
      e.transformResponse,
      r
    ), r.headers = Gt.from(r.headers), r;
  }, function(r) {
    return Zu(r) || (Fa(e), r && r.response && (r.response.data = xa.call(
      e,
      e.transformResponse,
      r.response
    ), r.response.headers = Gt.from(r.response.headers))), Promise.reject(r);
  });
}
const Ko = (e) => e instanceof Gt ? e.toJSON() : e;
function xn(e, t) {
  t = t || {};
  const n = {};
  function r(p, _, O) {
    return N.isPlainObject(p) && N.isPlainObject(_) ? N.merge.call({ caseless: O }, p, _) : N.isPlainObject(_) ? N.merge({}, _) : N.isArray(_) ? _.slice() : _;
  }
  function s(p, _, O) {
    if (N.isUndefined(_)) {
      if (!N.isUndefined(p))
        return r(void 0, p, O);
    } else
      return r(p, _, O);
  }
  function o(p, _) {
    if (!N.isUndefined(_))
      return r(void 0, _);
  }
  function l(p, _) {
    if (N.isUndefined(_)) {
      if (!N.isUndefined(p))
        return r(void 0, p);
    } else
      return r(void 0, _);
  }
  function f(p, _, O) {
    if (O in t)
      return r(p, _);
    if (O in e)
      return r(void 0, p);
  }
  const w = {
    url: o,
    method: o,
    data: o,
    baseURL: l,
    transformRequest: l,
    transformResponse: l,
    paramsSerializer: l,
    timeout: l,
    timeoutMessage: l,
    withCredentials: l,
    adapter: l,
    responseType: l,
    xsrfCookieName: l,
    xsrfHeaderName: l,
    onUploadProgress: l,
    onDownloadProgress: l,
    decompress: l,
    maxContentLength: l,
    maxBodyLength: l,
    beforeRedirect: l,
    transport: l,
    httpAgent: l,
    httpsAgent: l,
    cancelToken: l,
    socketPath: l,
    responseEncoding: l,
    validateStatus: f,
    headers: (p, _) => s(Ko(p), Ko(_), !0)
  };
  return N.forEach(Object.keys(Object.assign({}, e, t)), function(_) {
    const O = w[_] || s, F = O(e[_], t[_], _);
    N.isUndefined(F) && O !== f || (n[_] = F);
  }), n;
}
const Xu = "1.6.0", Ts = {};
["object", "boolean", "number", "function", "string", "symbol"].forEach((e, t) => {
  Ts[e] = function(r) {
    return typeof r === e || "a" + (t < 1 ? "n " : " ") + e;
  };
});
const Qo = {};
Ts.transitional = function(t, n, r) {
  function s(o, l) {
    return "[Axios v" + Xu + "] Transitional option '" + o + "'" + l + (r ? ". " + r : "");
  }
  return (o, l, f) => {
    if (t === !1)
      throw new _e(
        s(l, " has been removed" + (n ? " in " + n : "")),
        _e.ERR_DEPRECATED
      );
    return n && !Qo[l] && (Qo[l] = !0, console.warn(
      s(
        l,
        " has been deprecated since v" + n + " and will be removed in the near future"
      )
    )), t ? t(o, l, f) : !0;
  };
};
function Ey(e, t, n) {
  if (typeof e != "object")
    throw new _e("options must be an object", _e.ERR_BAD_OPTION_VALUE);
  const r = Object.keys(e);
  let s = r.length;
  for (; s-- > 0; ) {
    const o = r[s], l = t[o];
    if (l) {
      const f = e[o], w = f === void 0 || l(f, o, e);
      if (w !== !0)
        throw new _e("option " + o + " must be " + w, _e.ERR_BAD_OPTION_VALUE);
      continue;
    }
    if (n !== !0)
      throw new _e("Unknown option " + o, _e.ERR_BAD_OPTION);
  }
}
const ts = {
  assertOptions: Ey,
  validators: Ts
}, tn = ts.validators;
class ci {
  constructor(t) {
    this.defaults = t, this.interceptors = {
      request: new Vo(),
      response: new Vo()
    };
  }
  /**
   * Dispatch a request
   *
   * @param {String|Object} configOrUrl The config specific for this request (merged with this.defaults)
   * @param {?Object} config
   *
   * @returns {Promise} The Promise to be fulfilled
   */
  request(t, n) {
    typeof t == "string" ? (n = n || {}, n.url = t) : n = t || {}, n = xn(this.defaults, n);
    const { transitional: r, paramsSerializer: s, headers: o } = n;
    r !== void 0 && ts.assertOptions(r, {
      silentJSONParsing: tn.transitional(tn.boolean),
      forcedJSONParsing: tn.transitional(tn.boolean),
      clarifyTimeoutError: tn.transitional(tn.boolean)
    }, !1), s != null && (N.isFunction(s) ? n.paramsSerializer = {
      serialize: s
    } : ts.assertOptions(s, {
      encode: tn.function,
      serialize: tn.function
    }, !0)), n.method = (n.method || this.defaults.method || "get").toLowerCase();
    let l = o && N.merge(
      o.common,
      o[n.method]
    );
    o && N.forEach(
      ["delete", "get", "head", "post", "put", "patch", "common"],
      (E) => {
        delete o[E];
      }
    ), n.headers = Gt.concat(l, o);
    const f = [];
    let w = !0;
    this.interceptors.request.forEach(function($) {
      typeof $.runWhen == "function" && $.runWhen(n) === !1 || (w = w && $.synchronous, f.unshift($.fulfilled, $.rejected));
    });
    const p = [];
    this.interceptors.response.forEach(function($) {
      p.push($.fulfilled, $.rejected);
    });
    let _, O = 0, F;
    if (!w) {
      const E = [Zo.bind(this), void 0];
      for (E.unshift.apply(E, f), E.push.apply(E, p), F = E.length, _ = Promise.resolve(n); O < F; )
        _ = _.then(E[O++], E[O++]);
      return _;
    }
    F = f.length;
    let H = n;
    for (O = 0; O < F; ) {
      const E = f[O++], $ = f[O++];
      try {
        H = E(H);
      } catch (W) {
        $.call(this, W);
        break;
      }
    }
    try {
      _ = Zo.call(this, H);
    } catch (E) {
      return Promise.reject(E);
    }
    for (O = 0, F = p.length; O < F; )
      _ = _.then(p[O++], p[O++]);
    return _;
  }
  getUri(t) {
    t = xn(this.defaults, t);
    const n = Ku(t.baseURL, t.url);
    return zu(n, t.params, t.paramsSerializer);
  }
}
N.forEach(["delete", "get", "head", "options"], function(t) {
  ci.prototype[t] = function(n, r) {
    return this.request(xn(r || {}, {
      method: t,
      url: n,
      data: (r || {}).data
    }));
  };
});
N.forEach(["post", "put", "patch"], function(t) {
  function n(r) {
    return function(o, l, f) {
      return this.request(xn(f || {}, {
        method: t,
        headers: r ? {
          "Content-Type": "multipart/form-data"
        } : {},
        url: o,
        data: l
      }));
    };
  }
  ci.prototype[t] = n(), ci.prototype[t + "Form"] = n(!0);
});
const ri = ci;
class As {
  constructor(t) {
    if (typeof t != "function")
      throw new TypeError("executor must be a function.");
    let n;
    this.promise = new Promise(function(o) {
      n = o;
    });
    const r = this;
    this.promise.then((s) => {
      if (!r._listeners)
        return;
      let o = r._listeners.length;
      for (; o-- > 0; )
        r._listeners[o](s);
      r._listeners = null;
    }), this.promise.then = (s) => {
      let o;
      const l = new Promise((f) => {
        r.subscribe(f), o = f;
      }).then(s);
      return l.cancel = function() {
        r.unsubscribe(o);
      }, l;
    }, t(function(o, l, f) {
      r.reason || (r.reason = new pr(o, l, f), n(r.reason));
    });
  }
  /**
   * Throws a `CanceledError` if cancellation has been requested.
   */
  throwIfRequested() {
    if (this.reason)
      throw this.reason;
  }
  /**
   * Subscribe to the cancel signal
   */
  subscribe(t) {
    if (this.reason) {
      t(this.reason);
      return;
    }
    this._listeners ? this._listeners.push(t) : this._listeners = [t];
  }
  /**
   * Unsubscribe from the cancel signal
   */
  unsubscribe(t) {
    if (!this._listeners)
      return;
    const n = this._listeners.indexOf(t);
    n !== -1 && this._listeners.splice(n, 1);
  }
  /**
   * Returns an object that contains a new `CancelToken` and a function that, when called,
   * cancels the `CancelToken`.
   */
  static source() {
    let t;
    return {
      token: new As(function(s) {
        t = s;
      }),
      cancel: t
    };
  }
}
const Py = As;
function Ny(e) {
  return function(n) {
    return e.apply(null, n);
  };
}
function Ry(e) {
  return N.isObject(e) && e.isAxiosError === !0;
}
const ns = {
  Continue: 100,
  SwitchingProtocols: 101,
  Processing: 102,
  EarlyHints: 103,
  Ok: 200,
  Created: 201,
  Accepted: 202,
  NonAuthoritativeInformation: 203,
  NoContent: 204,
  ResetContent: 205,
  PartialContent: 206,
  MultiStatus: 207,
  AlreadyReported: 208,
  ImUsed: 226,
  MultipleChoices: 300,
  MovedPermanently: 301,
  Found: 302,
  SeeOther: 303,
  NotModified: 304,
  UseProxy: 305,
  Unused: 306,
  TemporaryRedirect: 307,
  PermanentRedirect: 308,
  BadRequest: 400,
  Unauthorized: 401,
  PaymentRequired: 402,
  Forbidden: 403,
  NotFound: 404,
  MethodNotAllowed: 405,
  NotAcceptable: 406,
  ProxyAuthenticationRequired: 407,
  RequestTimeout: 408,
  Conflict: 409,
  Gone: 410,
  LengthRequired: 411,
  PreconditionFailed: 412,
  PayloadTooLarge: 413,
  UriTooLong: 414,
  UnsupportedMediaType: 415,
  RangeNotSatisfiable: 416,
  ExpectationFailed: 417,
  ImATeapot: 418,
  MisdirectedRequest: 421,
  UnprocessableEntity: 422,
  Locked: 423,
  FailedDependency: 424,
  TooEarly: 425,
  UpgradeRequired: 426,
  PreconditionRequired: 428,
  TooManyRequests: 429,
  RequestHeaderFieldsTooLarge: 431,
  UnavailableForLegalReasons: 451,
  InternalServerError: 500,
  NotImplemented: 501,
  BadGateway: 502,
  ServiceUnavailable: 503,
  GatewayTimeout: 504,
  HttpVersionNotSupported: 505,
  VariantAlsoNegotiates: 506,
  InsufficientStorage: 507,
  LoopDetected: 508,
  NotExtended: 510,
  NetworkAuthenticationRequired: 511
};
Object.entries(ns).forEach(([e, t]) => {
  ns[t] = e;
});
const ky = ns;
function ec(e) {
  const t = new ri(e), n = Cu(ri.prototype.request, t);
  return N.extend(n, ri.prototype, t, { allOwnKeys: !0 }), N.extend(n, t, null, { allOwnKeys: !0 }), n.create = function(s) {
    return ec(xn(e, s));
  }, n;
}
const je = ec(Ds);
je.Axios = ri;
je.CanceledError = pr;
je.CancelToken = Py;
je.isCancel = Zu;
je.VERSION = Xu;
je.toFormData = Si;
je.AxiosError = _e;
je.Cancel = je.CanceledError;
je.all = function(t) {
  return Promise.all(t);
};
je.spread = Ny;
je.isAxiosError = Ry;
je.mergeConfig = xn;
je.AxiosHeaders = Gt;
je.formToJSON = (e) => Ju(N.isHTMLForm(e) ? new FormData(e) : e);
je.getAdapter = Qu.getAdapter;
je.HttpStatusCode = ky;
je.default = je;
const Iy = je;
class L {
  /**
   * perform the GET action
   * @param url
   * @param params
   */
  static async get(t, n) {
    return this._fetch({
      url: t,
      method: "GET",
      params: n,
      headers: Re.getAllHeaders()
    });
  }
  /**
   * perform the POST action
   * @param url
   * @param body
   * @param params
   */
  static async post(t, n, r) {
    return this._fetch({
      url: t,
      method: "POST",
      params: r,
      data: n,
      headers: Re.getAllHeaders()
    });
  }
  /**
   * perform the PATCH action
   * @param url
   * @param body
   * @param params
   */
  static async patch(t, n, r) {
    return this._fetch({
      url: t,
      method: "PATCH",
      params: r,
      data: n,
      headers: Re.getAllHeaders()
    });
  }
  /**
   * perform the PUT action
   * @param url
   * @param body
   * @param params
   */
  static async put(t, n, r) {
    return this._fetch({
      url: t,
      method: "PUT",
      params: r,
      data: n,
      headers: Re.getAllHeaders()
    });
  }
  /**
   * perform the DELETE action
   * @param url
   * @param params
   */
  static async delete(t, n) {
    return this._fetch({
      url: t,
      method: "DELETE",
      params: n,
      headers: Re.getAllHeaders()
    });
  }
  static async _fetch(t) {
    let n;
    try {
      return n = await Iy({
        ...t,
        url: `${Re.getBaseUrl()}${t.url}`
      }), n.status.toString()[0] === "2" ? Object.keys(n.data).length === 1 && "data" in n.data ? Promise.resolve(n.data.data) : Promise.resolve(n.data) : (this._log({
        url: t.url,
        params: t.params,
        headers: t.headers,
        response: n.data
      }), Promise.reject(
        new ui(
          n.data.message || "",
          n.status,
          n.data.errors,
          n.data
        )
      ));
    } catch (r) {
      return this._log({
        url: t.url,
        params: t.params,
        headers: t.headers,
        response: ""
      }), Promise.reject(new ui(
        r.response.data.message,
        r.response.status,
        r.response.data.errors,
        r.response.data
      ));
    }
  }
  static _log(t) {
    typeof window < "u" || console.error(t);
  }
}
class nn {
  static async loginAsClient(t) {
    const n = "/client/login";
    return L.post(n, t);
  }
  static async loginAsCustomer(t) {
    const n = "/customer/login";
    return L.post(n, t);
  }
  static async loginAsCustomerV2(t) {
    const n = "/storefront/auth/customer/login";
    return L.post(n, t);
  }
  static async loginAsGuest() {
    const t = "/customer_guest";
    return L.get(t);
  }
  static async initSocialLogin(t, n) {
    const r = `/storefront/auth/customer/login/${t}`;
    return L.post(r, { return: n });
  }
  static async checkSocialToken(t) {
    const n = "/customer/check_login_sm";
    return L.post(n, { session_token: t });
  }
  static async verifyApplePayload(t) {
    const n = "/app-apple-signin";
    return L.post(n, t);
  }
  static async logout() {
    const t = "/logout";
    return L.post(t);
  }
}
class Fb {
  static async loginAsClient(t) {
    return nn.loginAsClient(t);
  }
  static async loginAsCustomer(t, n = !1) {
    return n ? nn.loginAsCustomerV2(t) : nn.loginAsCustomer(t);
  }
  static async loginAsGuest() {
    return nn.loginAsGuest();
  }
  static async initSocialLogin(t, n) {
    return nn.initSocialLogin(t, n);
  }
  static async checkSocialToken(t) {
    return nn.checkSocialToken(t);
  }
  static async verifyApplePayload(t) {
    return nn.verifyApplePayload(t);
  }
  static async logout() {
    return nn.logout();
  }
}
class Yy {
  static async getLatest(t) {
    return L.get(`/storefront/mobile-apps/${t}/app-version`);
  }
}
class Cy {
  static async getLatest(t) {
    try {
      const { version: n } = await Yy.getLatest(t);
      return n;
    } catch {
      return null;
    }
  }
}
class jy {
  static async getByStoreId(t) {
    const n = `/brands/${t}/locations`;
    return L.get(n);
  }
}
class Bb {
  static async getByStoreId(t) {
    try {
      const n = await jy.getByStoreId(t);
      return Promise.resolve(n);
    } catch (n) {
      return Promise.reject(n);
    }
  }
}
class be {
  static async getOneById(t) {
    const n = `/customer/${t == null ? void 0 : t.toString()}`;
    return L.get(n);
  }
  static async getOneByUUID(t) {
    const n = `/storefront/customer/${t}`;
    return L.get(n);
  }
  static async create(t) {
    const n = "/customer";
    return L.post(n, t);
  }
  static async createV2(t) {
    const n = "/storefront/customer";
    return L.post(n, t);
  }
  static async resetPassword(t) {
    const n = "/customer/resetpassword";
    return L.post(n, { email: t });
  }
  static async resetPasswordV2(t) {
    const n = "/storefront/auth/customer/reset-password";
    return L.post(n, { email: t });
  }
  static async update(t, n) {
    const r = `/customer/${t}`;
    return L.put(r, n);
  }
  static async updateV2(t, n) {
    const r = `/storefront/customer/${t}`;
    return L.put(r, n);
  }
  static async updatePushNotificationsState(t, n) {
    const r = `/customer/${t.toString()}/push`;
    return L.put(r, { allow_push_notifications: n });
  }
  static async updatePushNotificationsStateV2(t, n) {
    const r = `/storefront/customer/${t}/push`;
    return L.patch(r, { allow_push_notifications: n });
  }
  static async checkLocation(t) {
    const n = `/storefront/customer/${t}/check-location`;
    return L.get(n);
  }
  static async linkFirm(t) {
    const n = `/customer/${t}/checkfirm`;
    return L.get(n);
  }
  /**
   * Not used anymore for non google account
   * @param email
   * @param google
   */
  static async checkEmailV2(t, n = !0) {
    const r = "/storefront/customer/check-email";
    return L.post(r, {
      email: t,
      google: n
    });
  }
  static async checkEmail(t, n = !0) {
    const r = "/customer/checkemail";
    return L.post(r, {
      email: t,
      google: n
    });
  }
  static async checkActivation(t) {
    const n = `/customer/${t}/checkactivation`;
    return L.get(n);
  }
  static async initSocialLogin(t) {
    const n = `/customer/login/${t}`;
    return L.post(n);
  }
  static async checkSocialToken(t) {
    const n = "/customer/check_login_sm";
    return L.post(n, {
      session_token: t
    });
  }
  static async saveDeviceToken(t, n) {
    const r = `/customer/${t}/device_token`;
    return L.post(r, {
      device_token: n
    });
  }
  static async checkAccessToken(t, n) {
    const r = "/customer/app-apple-signin";
    return L.post(r, {
      code: t,
      id_token: n
    });
  }
  static async getMessages(t) {
    const n = `/customer/${t}/shop_messages`;
    return L.get(n);
  }
  static async markMessageAsRead(t, n) {
    const r = `/customer/${t}/shop_messages/${n}`;
    return L.put(r);
  }
  static async getCustomerAddresses(t) {
    const n = `/customers/${t}/addresses`;
    return L.get(n);
  }
  static async updateCustomerAddress(t, n) {
    var s;
    const r = `/customers/${t}/addresses/${n.id}`;
    return L.put(r, {
      is_default: n.is_default,
      description: n.description,
      street: n.street,
      house_number: n.house_number,
      bus: n.bus,
      zip_code: n.zip_code,
      locality: n.locality,
      country_id: (s = n.country) == null ? void 0 : s.id
    });
  }
  static async addCustomerAddress(t, n) {
    var s;
    const r = `/customers/${t}/addresses`;
    return L.post(r, {
      is_default: n.is_default,
      description: n.description,
      street: n.street,
      house_number: n.house_number,
      bus: n.bus,
      zip_code: n.zip_code,
      locality: n.locality,
      country_id: (s = n.country) == null ? void 0 : s.id
    });
  }
  static async updateBusinessDetails(t, n) {
    const r = `/customer/${t}/vat`;
    return L.put(r, n);
  }
  static async deleteCustomer(t) {
    return L.delete(`/customers/${t}`);
  }
  static async deleteCustomerV2(t) {
    return L.delete(`/storefront/customer/${t}`);
  }
  static async getLoyaltyHistory() {
    return L.get("/storefront/loyalty/history");
  }
}
class Xo {
  static removeKeysFromObject(t, n) {
    return t.forEach((r) => {
      delete n[r];
    }), n;
  }
}
const eu = [
  "addresses",
  "street",
  "house_number",
  "zipcode",
  "locality",
  "country"
];
class Ub {
  static async getOneById(t) {
    try {
      const n = await be.getOneById(t);
      return Promise.resolve(n);
    } catch (n) {
      return Promise.reject(n);
    }
  }
  static async getOneByUUID(t) {
    try {
      const n = await be.getOneByUUID(t);
      return Promise.resolve(n);
    } catch (n) {
      return Promise.reject(n);
    }
  }
  static async resetPassword(t) {
    try {
      let n;
      return Re.getHeader("brandId") ? n = await be.resetPasswordV2(t) : n = await be.resetPassword(t), Promise.resolve(n);
    } catch (n) {
      return Promise.reject(n);
    }
  }
  static async create(t, n) {
    try {
      n && (t.google = !0);
      let r;
      return Re.getHeader("brandId") ? r = await be.createV2(t) : r = await be.create(t), Promise.resolve(r);
    } catch (r) {
      return Promise.reject(r);
    }
  }
  static async update(t, n) {
    try {
      const r = await be.update(t, Xo.removeKeysFromObject(eu, n));
      return Promise.resolve(r);
    } catch (r) {
      return Promise.reject(r);
    }
  }
  static async updateV2(t, n) {
    try {
      const r = await be.updateV2(t, Xo.removeKeysFromObject(eu, n));
      return Promise.resolve(r);
    } catch (r) {
      return Promise.reject(r);
    }
  }
  static async updatePushNotificationsState(t, n) {
    try {
      const r = await be.updatePushNotificationsState(t, n);
      return Promise.resolve(r);
    } catch (r) {
      return Promise.reject(r);
    }
  }
  static async updatePushNotificationsStateV2(t, n) {
    try {
      const r = await be.updatePushNotificationsStateV2(t, n);
      return Promise.resolve(r);
    } catch (r) {
      return Promise.reject(r);
    }
  }
  static async checkLocation(t) {
    try {
      const n = await be.checkLocation(t);
      return Promise.resolve(n);
    } catch (n) {
      return Promise.reject(n);
    }
  }
  static async linkFirm(t) {
    try {
      const n = await be.linkFirm(t);
      return Promise.resolve(n);
    } catch (n) {
      return Promise.reject(n);
    }
  }
  static async checkEmailV2(t, n) {
    try {
      const r = await be.checkEmailV2(t, n);
      return Promise.resolve(r);
    } catch (r) {
      return Promise.reject(r);
    }
  }
  static async checkEmail(t, n) {
    try {
      const r = await be.checkEmail(t, n);
      return Promise.resolve(r);
    } catch (r) {
      return Promise.reject(r);
    }
  }
  static async checkActivation(t) {
    try {
      return be.checkActivation(t);
    } catch (n) {
      return Promise.reject(n);
    }
  }
  static async initSocialLogin(t) {
    try {
      const n = await be.initSocialLogin(t);
      return Promise.resolve(n);
    } catch (n) {
      return Promise.reject(n);
    }
  }
  static async checkSocialToken(t) {
    try {
      const n = await be.checkSocialToken(t);
      return Promise.resolve(n);
    } catch (n) {
      return Promise.reject(n);
    }
  }
  static async saveDeviceToken(t, n) {
    try {
      const r = await be.saveDeviceToken(t, n);
      return Promise.resolve(r);
    } catch (r) {
      return Promise.reject(r);
    }
  }
  static async checkAccessToken(t, n) {
    try {
      const r = await be.checkAccessToken(t, n);
      return Promise.resolve(r);
    } catch (r) {
      return Promise.reject(r);
    }
  }
  static async getMessages(t) {
    try {
      const n = await be.getMessages(t);
      return Promise.resolve(n);
    } catch (n) {
      return Promise.reject(n);
    }
  }
  static async markMessageAsRead(t, n) {
    try {
      const r = await be.markMessageAsRead(t, n);
      return Promise.resolve(r);
    } catch (r) {
      return Promise.reject(r);
    }
  }
  static async getCustomerAddresses(t) {
    try {
      const n = await be.getCustomerAddresses(t);
      return Promise.resolve(n);
    } catch (n) {
      return Promise.reject(n);
    }
  }
  static async updateCustomerAddress(t, n) {
    try {
      const r = await be.updateCustomerAddress(t, n);
      return Promise.resolve(r);
    } catch (r) {
      return Promise.reject(r);
    }
  }
  static async addCustomerAddress(t, n) {
    try {
      const r = await be.addCustomerAddress(t, n);
      return Promise.resolve(r);
    } catch (r) {
      return Promise.reject(r);
    }
  }
  static async updateBusinessDetails(t, n) {
    try {
      const r = await be.updateBusinessDetails(t, n);
      return Promise.resolve(r);
    } catch (r) {
      return Promise.reject(r);
    }
  }
  static async deleteCustomer(t) {
    try {
      let n;
      return Re.getHeader("brandId") ? n = await be.deleteCustomerV2(t) : n = await be.deleteCustomer(t), Promise.resolve(n);
    } catch (n) {
      return Promise.reject(n);
    }
  }
  static async getLoyaltyHistory() {
    try {
      const t = await be.getLoyaltyHistory();
      return Promise.resolve(t);
    } catch (t) {
      return Promise.reject(t);
    }
  }
}
//! moment.js
//! version : 2.29.4
//! authors : Tim Wood, Iskren Chernev, Moment.js contributors
//! license : MIT
//! momentjs.com
var tc;
function R() {
  return tc.apply(null, arguments);
}
function Ly(e) {
  tc = e;
}
function vt(e) {
  return e instanceof Array || Object.prototype.toString.call(e) === "[object Array]";
}
function mn(e) {
  return e != null && Object.prototype.toString.call(e) === "[object Object]";
}
function pe(e, t) {
  return Object.prototype.hasOwnProperty.call(e, t);
}
function Es(e) {
  if (Object.getOwnPropertyNames)
    return Object.getOwnPropertyNames(e).length === 0;
  var t;
  for (t in e)
    if (pe(e, t))
      return !1;
  return !0;
}
function nt(e) {
  return e === void 0;
}
function zt(e) {
  return typeof e == "number" || Object.prototype.toString.call(e) === "[object Number]";
}
function gr(e) {
  return e instanceof Date || Object.prototype.toString.call(e) === "[object Date]";
}
function nc(e, t) {
  var n = [], r, s = e.length;
  for (r = 0; r < s; ++r)
    n.push(t(e[r], r));
  return n;
}
function rn(e, t) {
  for (var n in t)
    pe(t, n) && (e[n] = t[n]);
  return pe(t, "toString") && (e.toString = t.toString), pe(t, "valueOf") && (e.valueOf = t.valueOf), e;
}
function At(e, t, n, r) {
  return Tc(e, t, n, r, !0).utc();
}
function xy() {
  return {
    empty: !1,
    unusedTokens: [],
    unusedInput: [],
    overflow: -2,
    charsLeftOver: 0,
    nullInput: !1,
    invalidEra: null,
    invalidMonth: null,
    invalidFormat: !1,
    userInvalidated: !1,
    iso: !1,
    parsedDateParts: [],
    era: null,
    meridiem: null,
    rfc2822: !1,
    weekdayMismatch: !1
  };
}
function oe(e) {
  return e._pf == null && (e._pf = xy()), e._pf;
}
var rs;
Array.prototype.some ? rs = Array.prototype.some : rs = function(e) {
  var t = Object(this), n = t.length >>> 0, r;
  for (r = 0; r < n; r++)
    if (r in t && e.call(this, t[r], r, t))
      return !0;
  return !1;
};
function Ps(e) {
  if (e._isValid == null) {
    var t = oe(e), n = rs.call(t.parsedDateParts, function(s) {
      return s != null;
    }), r = !isNaN(e._d.getTime()) && t.overflow < 0 && !t.empty && !t.invalidEra && !t.invalidMonth && !t.invalidWeekday && !t.weekdayMismatch && !t.nullInput && !t.invalidFormat && !t.userInvalidated && (!t.meridiem || t.meridiem && n);
    if (e._strict && (r = r && t.charsLeftOver === 0 && t.unusedTokens.length === 0 && t.bigHour === void 0), Object.isFrozen == null || !Object.isFrozen(e))
      e._isValid = r;
    else
      return r;
  }
  return e._isValid;
}
function Mi(e) {
  var t = At(NaN);
  return e != null ? rn(oe(t), e) : oe(t).userInvalidated = !0, t;
}
var tu = R.momentProperties = [], Ba = !1;
function Ns(e, t) {
  var n, r, s, o = tu.length;
  if (nt(t._isAMomentObject) || (e._isAMomentObject = t._isAMomentObject), nt(t._i) || (e._i = t._i), nt(t._f) || (e._f = t._f), nt(t._l) || (e._l = t._l), nt(t._strict) || (e._strict = t._strict), nt(t._tzm) || (e._tzm = t._tzm), nt(t._isUTC) || (e._isUTC = t._isUTC), nt(t._offset) || (e._offset = t._offset), nt(t._pf) || (e._pf = oe(t)), nt(t._locale) || (e._locale = t._locale), o > 0)
    for (n = 0; n < o; n++)
      r = tu[n], s = t[r], nt(s) || (e[r] = s);
  return e;
}
function vr(e) {
  Ns(this, e), this._d = new Date(e._d != null ? e._d.getTime() : NaN), this.isValid() || (this._d = /* @__PURE__ */ new Date(NaN)), Ba === !1 && (Ba = !0, R.updateOffset(this), Ba = !1);
}
function wt(e) {
  return e instanceof vr || e != null && e._isAMomentObject != null;
}
function rc(e) {
  R.suppressDeprecationWarnings === !1 && typeof console < "u" && console.warn && console.warn("Deprecation warning: " + e);
}
function dt(e, t) {
  var n = !0;
  return rn(function() {
    if (R.deprecationHandler != null && R.deprecationHandler(null, e), n) {
      var r = [], s, o, l, f = arguments.length;
      for (o = 0; o < f; o++) {
        if (s = "", typeof arguments[o] == "object") {
          s += `
[` + o + "] ";
          for (l in arguments[0])
            pe(arguments[0], l) && (s += l + ": " + arguments[0][l] + ", ");
          s = s.slice(0, -2);
        } else
          s = arguments[o];
        r.push(s);
      }
      rc(
        e + `
Arguments: ` + Array.prototype.slice.call(r).join("") + `
` + new Error().stack
      ), n = !1;
    }
    return t.apply(this, arguments);
  }, t);
}
var nu = {};
function ic(e, t) {
  R.deprecationHandler != null && R.deprecationHandler(e, t), nu[e] || (rc(t), nu[e] = !0);
}
R.suppressDeprecationWarnings = !1;
R.deprecationHandler = null;
function Et(e) {
  return typeof Function < "u" && e instanceof Function || Object.prototype.toString.call(e) === "[object Function]";
}
function Fy(e) {
  var t, n;
  for (n in e)
    pe(e, n) && (t = e[n], Et(t) ? this[n] = t : this["_" + n] = t);
  this._config = e, this._dayOfMonthOrdinalParseLenient = new RegExp(
    (this._dayOfMonthOrdinalParse.source || this._ordinalParse.source) + "|" + /\d{1,2}/.source
  );
}
function is(e, t) {
  var n = rn({}, e), r;
  for (r in t)
    pe(t, r) && (mn(e[r]) && mn(t[r]) ? (n[r] = {}, rn(n[r], e[r]), rn(n[r], t[r])) : t[r] != null ? n[r] = t[r] : delete n[r]);
  for (r in e)
    pe(e, r) && !pe(t, r) && mn(e[r]) && (n[r] = rn({}, n[r]));
  return n;
}
function Rs(e) {
  e != null && this.set(e);
}
var as;
Object.keys ? as = Object.keys : as = function(e) {
  var t, n = [];
  for (t in e)
    pe(e, t) && n.push(t);
  return n;
};
var By = {
  sameDay: "[Today at] LT",
  nextDay: "[Tomorrow at] LT",
  nextWeek: "dddd [at] LT",
  lastDay: "[Yesterday at] LT",
  lastWeek: "[Last] dddd [at] LT",
  sameElse: "L"
};
function Uy(e, t, n) {
  var r = this._calendar[e] || this._calendar.sameElse;
  return Et(r) ? r.call(t, n) : r;
}
function Dt(e, t, n) {
  var r = "" + Math.abs(e), s = t - r.length, o = e >= 0;
  return (o ? n ? "+" : "" : "-") + Math.pow(10, Math.max(0, s)).toString().substr(1) + r;
}
var ks = /(\[[^\[]*\])|(\\)?([Hh]mm(ss)?|Mo|MM?M?M?|Do|DDDo|DD?D?D?|ddd?d?|do?|w[o|w]?|W[o|W]?|Qo?|N{1,5}|YYYYYY|YYYYY|YYYY|YY|y{2,4}|yo?|gg(ggg?)?|GG(GGG?)?|e|E|a|A|hh?|HH?|kk?|mm?|ss?|S{1,9}|x|X|zz?|ZZ?|.)/g, Kr = /(\[[^\[]*\])|(\\)?(LTS|LT|LL?L?L?|l{1,4})/g, Ua = {}, Cn = {};
function ne(e, t, n, r) {
  var s = r;
  typeof r == "string" && (s = function() {
    return this[r]();
  }), e && (Cn[e] = s), t && (Cn[t[0]] = function() {
    return Dt(s.apply(this, arguments), t[1], t[2]);
  }), n && (Cn[n] = function() {
    return this.localeData().ordinal(
      s.apply(this, arguments),
      e
    );
  });
}
function Wy(e) {
  return e.match(/\[[\s\S]/) ? e.replace(/^\[|\]$/g, "") : e.replace(/\\/g, "");
}
function Hy(e) {
  var t = e.match(ks), n, r;
  for (n = 0, r = t.length; n < r; n++)
    Cn[t[n]] ? t[n] = Cn[t[n]] : t[n] = Wy(t[n]);
  return function(s) {
    var o = "", l;
    for (l = 0; l < r; l++)
      o += Et(t[l]) ? t[l].call(s, e) : t[l];
    return o;
  };
}
function ii(e, t) {
  return e.isValid() ? (t = ac(t, e.localeData()), Ua[t] = Ua[t] || Hy(t), Ua[t](e)) : e.localeData().invalidDate();
}
function ac(e, t) {
  var n = 5;
  function r(s) {
    return t.longDateFormat(s) || s;
  }
  for (Kr.lastIndex = 0; n >= 0 && Kr.test(e); )
    e = e.replace(
      Kr,
      r
    ), Kr.lastIndex = 0, n -= 1;
  return e;
}
var Gy = {
  LTS: "h:mm:ss A",
  LT: "h:mm A",
  L: "MM/DD/YYYY",
  LL: "MMMM D, YYYY",
  LLL: "MMMM D, YYYY h:mm A",
  LLLL: "dddd, MMMM D, YYYY h:mm A"
};
function $y(e) {
  var t = this._longDateFormat[e], n = this._longDateFormat[e.toUpperCase()];
  return t || !n ? t : (this._longDateFormat[e] = n.match(ks).map(function(r) {
    return r === "MMMM" || r === "MM" || r === "DD" || r === "dddd" ? r.slice(1) : r;
  }).join(""), this._longDateFormat[e]);
}
var Vy = "Invalid date";
function zy() {
  return this._invalidDate;
}
var qy = "%d", Jy = /\d{1,2}/;
function Zy(e) {
  return this._ordinal.replace("%d", e);
}
var Ky = {
  future: "in %s",
  past: "%s ago",
  s: "a few seconds",
  ss: "%d seconds",
  m: "a minute",
  mm: "%d minutes",
  h: "an hour",
  hh: "%d hours",
  d: "a day",
  dd: "%d days",
  w: "a week",
  ww: "%d weeks",
  M: "a month",
  MM: "%d months",
  y: "a year",
  yy: "%d years"
};
function Qy(e, t, n, r) {
  var s = this._relativeTime[n];
  return Et(s) ? s(e, t, n, r) : s.replace(/%d/i, e);
}
function Xy(e, t) {
  var n = this._relativeTime[e > 0 ? "future" : "past"];
  return Et(n) ? n(t) : n.replace(/%s/i, t);
}
var cr = {};
function Ke(e, t) {
  var n = e.toLowerCase();
  cr[n] = cr[n + "s"] = cr[t] = e;
}
function ft(e) {
  return typeof e == "string" ? cr[e] || cr[e.toLowerCase()] : void 0;
}
function Is(e) {
  var t = {}, n, r;
  for (r in e)
    pe(e, r) && (n = ft(r), n && (t[n] = e[r]));
  return t;
}
var sc = {};
function Qe(e, t) {
  sc[e] = t;
}
function em(e) {
  var t = [], n;
  for (n in e)
    pe(e, n) && t.push({ unit: n, priority: sc[n] });
  return t.sort(function(r, s) {
    return r.priority - s.priority;
  }), t;
}
function Oi(e) {
  return e % 4 === 0 && e % 100 !== 0 || e % 400 === 0;
}
function ct(e) {
  return e < 0 ? Math.ceil(e) || 0 : Math.floor(e);
}
function ce(e) {
  var t = +e, n = 0;
  return t !== 0 && isFinite(t) && (n = ct(t)), n;
}
function Wn(e, t) {
  return function(n) {
    return n != null ? (oc(this, e, n), R.updateOffset(this, t), this) : li(this, e);
  };
}
function li(e, t) {
  return e.isValid() ? e._d["get" + (e._isUTC ? "UTC" : "") + t]() : NaN;
}
function oc(e, t, n) {
  e.isValid() && !isNaN(n) && (t === "FullYear" && Oi(e.year()) && e.month() === 1 && e.date() === 29 ? (n = ce(n), e._d["set" + (e._isUTC ? "UTC" : "") + t](
    n,
    e.month(),
    Ni(n, e.month())
  )) : e._d["set" + (e._isUTC ? "UTC" : "") + t](n));
}
function tm(e) {
  return e = ft(e), Et(this[e]) ? this[e]() : this;
}
function nm(e, t) {
  if (typeof e == "object") {
    e = Is(e);
    var n = em(e), r, s = n.length;
    for (r = 0; r < s; r++)
      this[n[r].unit](e[n[r].unit]);
  } else if (e = ft(e), Et(this[e]))
    return this[e](t);
  return this;
}
var uc = /\d/, at = /\d\d/, cc = /\d{3}/, Ys = /\d{4}/, Di = /[+-]?\d{6}/, Ee = /\d\d?/, lc = /\d\d\d\d?/, dc = /\d\d\d\d\d\d?/, Ti = /\d{1,3}/, Cs = /\d{1,4}/, Ai = /[+-]?\d{1,6}/, Hn = /\d+/, Ei = /[+-]?\d+/, rm = /Z|[+-]\d\d:?\d\d/gi, Pi = /Z|[+-]\d\d(?::?\d\d)?/gi, im = /[+-]?\d+(\.\d{1,3})?/, wr = /[0-9]{0,256}['a-z\u00A0-\u05FF\u0700-\uD7FF\uF900-\uFDCF\uFDF0-\uFF07\uFF10-\uFFEF]{1,256}|[\u0600-\u06FF\/]{1,256}(\s*?[\u0600-\u06FF]{1,256}){1,2}/i, di;
di = {};
function q(e, t, n) {
  di[e] = Et(t) ? t : function(r, s) {
    return r && n ? n : t;
  };
}
function am(e, t) {
  return pe(di, e) ? di[e](t._strict, t._locale) : new RegExp(sm(e));
}
function sm(e) {
  return it(
    e.replace("\\", "").replace(
      /\\(\[)|\\(\])|\[([^\]\[]*)\]|\\(.)/g,
      function(t, n, r, s, o) {
        return n || r || s || o;
      }
    )
  );
}
function it(e) {
  return e.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&");
}
var ss = {};
function Me(e, t) {
  var n, r = t, s;
  for (typeof e == "string" && (e = [e]), zt(t) && (r = function(o, l) {
    l[t] = ce(o);
  }), s = e.length, n = 0; n < s; n++)
    ss[e[n]] = r;
}
function Sr(e, t) {
  Me(e, function(n, r, s, o) {
    s._w = s._w || {}, t(n, s._w, s, o);
  });
}
function om(e, t, n) {
  t != null && pe(ss, e) && ss[e](t, n._a, n, e);
}
var Ze = 0, Wt = 1, Mt = 2, Be = 3, pt = 4, Ht = 5, yn = 6, um = 7, cm = 8;
function lm(e, t) {
  return (e % t + t) % t;
}
var Ce;
Array.prototype.indexOf ? Ce = Array.prototype.indexOf : Ce = function(e) {
  var t;
  for (t = 0; t < this.length; ++t)
    if (this[t] === e)
      return t;
  return -1;
};
function Ni(e, t) {
  if (isNaN(e) || isNaN(t))
    return NaN;
  var n = lm(t, 12);
  return e += (t - n) / 12, n === 1 ? Oi(e) ? 29 : 28 : 31 - n % 7 % 2;
}
ne("M", ["MM", 2], "Mo", function() {
  return this.month() + 1;
});
ne("MMM", 0, 0, function(e) {
  return this.localeData().monthsShort(this, e);
});
ne("MMMM", 0, 0, function(e) {
  return this.localeData().months(this, e);
});
Ke("month", "M");
Qe("month", 8);
q("M", Ee);
q("MM", Ee, at);
q("MMM", function(e, t) {
  return t.monthsShortRegex(e);
});
q("MMMM", function(e, t) {
  return t.monthsRegex(e);
});
Me(["M", "MM"], function(e, t) {
  t[Wt] = ce(e) - 1;
});
Me(["MMM", "MMMM"], function(e, t, n, r) {
  var s = n._locale.monthsParse(e, r, n._strict);
  s != null ? t[Wt] = s : oe(n).invalidMonth = e;
});
var dm = "January_February_March_April_May_June_July_August_September_October_November_December".split(
  "_"
), fc = "Jan_Feb_Mar_Apr_May_Jun_Jul_Aug_Sep_Oct_Nov_Dec".split("_"), hc = /D[oD]?(\[[^\[\]]*\]|\s)+MMMM?/, fm = wr, hm = wr;
function _m(e, t) {
  return e ? vt(this._months) ? this._months[e.month()] : this._months[(this._months.isFormat || hc).test(t) ? "format" : "standalone"][e.month()] : vt(this._months) ? this._months : this._months.standalone;
}
function ym(e, t) {
  return e ? vt(this._monthsShort) ? this._monthsShort[e.month()] : this._monthsShort[hc.test(t) ? "format" : "standalone"][e.month()] : vt(this._monthsShort) ? this._monthsShort : this._monthsShort.standalone;
}
function mm(e, t, n) {
  var r, s, o, l = e.toLocaleLowerCase();
  if (!this._monthsParse)
    for (this._monthsParse = [], this._longMonthsParse = [], this._shortMonthsParse = [], r = 0; r < 12; ++r)
      o = At([2e3, r]), this._shortMonthsParse[r] = this.monthsShort(
        o,
        ""
      ).toLocaleLowerCase(), this._longMonthsParse[r] = this.months(o, "").toLocaleLowerCase();
  return n ? t === "MMM" ? (s = Ce.call(this._shortMonthsParse, l), s !== -1 ? s : null) : (s = Ce.call(this._longMonthsParse, l), s !== -1 ? s : null) : t === "MMM" ? (s = Ce.call(this._shortMonthsParse, l), s !== -1 ? s : (s = Ce.call(this._longMonthsParse, l), s !== -1 ? s : null)) : (s = Ce.call(this._longMonthsParse, l), s !== -1 ? s : (s = Ce.call(this._shortMonthsParse, l), s !== -1 ? s : null));
}
function pm(e, t, n) {
  var r, s, o;
  if (this._monthsParseExact)
    return mm.call(this, e, t, n);
  for (this._monthsParse || (this._monthsParse = [], this._longMonthsParse = [], this._shortMonthsParse = []), r = 0; r < 12; r++) {
    if (s = At([2e3, r]), n && !this._longMonthsParse[r] && (this._longMonthsParse[r] = new RegExp(
      "^" + this.months(s, "").replace(".", "") + "$",
      "i"
    ), this._shortMonthsParse[r] = new RegExp(
      "^" + this.monthsShort(s, "").replace(".", "") + "$",
      "i"
    )), !n && !this._monthsParse[r] && (o = "^" + this.months(s, "") + "|^" + this.monthsShort(s, ""), this._monthsParse[r] = new RegExp(o.replace(".", ""), "i")), n && t === "MMMM" && this._longMonthsParse[r].test(e))
      return r;
    if (n && t === "MMM" && this._shortMonthsParse[r].test(e))
      return r;
    if (!n && this._monthsParse[r].test(e))
      return r;
  }
}
function _c(e, t) {
  var n;
  if (!e.isValid())
    return e;
  if (typeof t == "string") {
    if (/^\d+$/.test(t))
      t = ce(t);
    else if (t = e.localeData().monthsParse(t), !zt(t))
      return e;
  }
  return n = Math.min(e.date(), Ni(e.year(), t)), e._d["set" + (e._isUTC ? "UTC" : "") + "Month"](t, n), e;
}
function yc(e) {
  return e != null ? (_c(this, e), R.updateOffset(this, !0), this) : li(this, "Month");
}
function gm() {
  return Ni(this.year(), this.month());
}
function vm(e) {
  return this._monthsParseExact ? (pe(this, "_monthsRegex") || mc.call(this), e ? this._monthsShortStrictRegex : this._monthsShortRegex) : (pe(this, "_monthsShortRegex") || (this._monthsShortRegex = fm), this._monthsShortStrictRegex && e ? this._monthsShortStrictRegex : this._monthsShortRegex);
}
function wm(e) {
  return this._monthsParseExact ? (pe(this, "_monthsRegex") || mc.call(this), e ? this._monthsStrictRegex : this._monthsRegex) : (pe(this, "_monthsRegex") || (this._monthsRegex = hm), this._monthsStrictRegex && e ? this._monthsStrictRegex : this._monthsRegex);
}
function mc() {
  function e(l, f) {
    return f.length - l.length;
  }
  var t = [], n = [], r = [], s, o;
  for (s = 0; s < 12; s++)
    o = At([2e3, s]), t.push(this.monthsShort(o, "")), n.push(this.months(o, "")), r.push(this.months(o, "")), r.push(this.monthsShort(o, ""));
  for (t.sort(e), n.sort(e), r.sort(e), s = 0; s < 12; s++)
    t[s] = it(t[s]), n[s] = it(n[s]);
  for (s = 0; s < 24; s++)
    r[s] = it(r[s]);
  this._monthsRegex = new RegExp("^(" + r.join("|") + ")", "i"), this._monthsShortRegex = this._monthsRegex, this._monthsStrictRegex = new RegExp(
    "^(" + n.join("|") + ")",
    "i"
  ), this._monthsShortStrictRegex = new RegExp(
    "^(" + t.join("|") + ")",
    "i"
  );
}
ne("Y", 0, 0, function() {
  var e = this.year();
  return e <= 9999 ? Dt(e, 4) : "+" + e;
});
ne(0, ["YY", 2], 0, function() {
  return this.year() % 100;
});
ne(0, ["YYYY", 4], 0, "year");
ne(0, ["YYYYY", 5], 0, "year");
ne(0, ["YYYYYY", 6, !0], 0, "year");
Ke("year", "y");
Qe("year", 1);
q("Y", Ei);
q("YY", Ee, at);
q("YYYY", Cs, Ys);
q("YYYYY", Ai, Di);
q("YYYYYY", Ai, Di);
Me(["YYYYY", "YYYYYY"], Ze);
Me("YYYY", function(e, t) {
  t[Ze] = e.length === 2 ? R.parseTwoDigitYear(e) : ce(e);
});
Me("YY", function(e, t) {
  t[Ze] = R.parseTwoDigitYear(e);
});
Me("Y", function(e, t) {
  t[Ze] = parseInt(e, 10);
});
function lr(e) {
  return Oi(e) ? 366 : 365;
}
R.parseTwoDigitYear = function(e) {
  return ce(e) + (ce(e) > 68 ? 1900 : 2e3);
};
var pc = Wn("FullYear", !0);
function Sm() {
  return Oi(this.year());
}
function bm(e, t, n, r, s, o, l) {
  var f;
  return e < 100 && e >= 0 ? (f = new Date(e + 400, t, n, r, s, o, l), isFinite(f.getFullYear()) && f.setFullYear(e)) : f = new Date(e, t, n, r, s, o, l), f;
}
function fr(e) {
  var t, n;
  return e < 100 && e >= 0 ? (n = Array.prototype.slice.call(arguments), n[0] = e + 400, t = new Date(Date.UTC.apply(null, n)), isFinite(t.getUTCFullYear()) && t.setUTCFullYear(e)) : t = new Date(Date.UTC.apply(null, arguments)), t;
}
function fi(e, t, n) {
  var r = 7 + t - n, s = (7 + fr(e, 0, r).getUTCDay() - t) % 7;
  return -s + r - 1;
}
function gc(e, t, n, r, s) {
  var o = (7 + n - r) % 7, l = fi(e, r, s), f = 1 + 7 * (t - 1) + o + l, w, p;
  return f <= 0 ? (w = e - 1, p = lr(w) + f) : f > lr(e) ? (w = e + 1, p = f - lr(e)) : (w = e, p = f), {
    year: w,
    dayOfYear: p
  };
}
function hr(e, t, n) {
  var r = fi(e.year(), t, n), s = Math.floor((e.dayOfYear() - r - 1) / 7) + 1, o, l;
  return s < 1 ? (l = e.year() - 1, o = s + $t(l, t, n)) : s > $t(e.year(), t, n) ? (o = s - $t(e.year(), t, n), l = e.year() + 1) : (l = e.year(), o = s), {
    week: o,
    year: l
  };
}
function $t(e, t, n) {
  var r = fi(e, t, n), s = fi(e + 1, t, n);
  return (lr(e) - r + s) / 7;
}
ne("w", ["ww", 2], "wo", "week");
ne("W", ["WW", 2], "Wo", "isoWeek");
Ke("week", "w");
Ke("isoWeek", "W");
Qe("week", 5);
Qe("isoWeek", 5);
q("w", Ee);
q("ww", Ee, at);
q("W", Ee);
q("WW", Ee, at);
Sr(
  ["w", "ww", "W", "WW"],
  function(e, t, n, r) {
    t[r.substr(0, 1)] = ce(e);
  }
);
function Mm(e) {
  return hr(e, this._week.dow, this._week.doy).week;
}
var Om = {
  dow: 0,
  // Sunday is the first day of the week.
  doy: 6
  // The week that contains Jan 6th is the first week of the year.
};
function Dm() {
  return this._week.dow;
}
function Tm() {
  return this._week.doy;
}
function Am(e) {
  var t = this.localeData().week(this);
  return e == null ? t : this.add((e - t) * 7, "d");
}
function Em(e) {
  var t = hr(this, 1, 4).week;
  return e == null ? t : this.add((e - t) * 7, "d");
}
ne("d", 0, "do", "day");
ne("dd", 0, 0, function(e) {
  return this.localeData().weekdaysMin(this, e);
});
ne("ddd", 0, 0, function(e) {
  return this.localeData().weekdaysShort(this, e);
});
ne("dddd", 0, 0, function(e) {
  return this.localeData().weekdays(this, e);
});
ne("e", 0, 0, "weekday");
ne("E", 0, 0, "isoWeekday");
Ke("day", "d");
Ke("weekday", "e");
Ke("isoWeekday", "E");
Qe("day", 11);
Qe("weekday", 11);
Qe("isoWeekday", 11);
q("d", Ee);
q("e", Ee);
q("E", Ee);
q("dd", function(e, t) {
  return t.weekdaysMinRegex(e);
});
q("ddd", function(e, t) {
  return t.weekdaysShortRegex(e);
});
q("dddd", function(e, t) {
  return t.weekdaysRegex(e);
});
Sr(["dd", "ddd", "dddd"], function(e, t, n, r) {
  var s = n._locale.weekdaysParse(e, r, n._strict);
  s != null ? t.d = s : oe(n).invalidWeekday = e;
});
Sr(["d", "e", "E"], function(e, t, n, r) {
  t[r] = ce(e);
});
function Pm(e, t) {
  return typeof e != "string" ? e : isNaN(e) ? (e = t.weekdaysParse(e), typeof e == "number" ? e : null) : parseInt(e, 10);
}
function Nm(e, t) {
  return typeof e == "string" ? t.weekdaysParse(e) % 7 || 7 : isNaN(e) ? null : e;
}
function js(e, t) {
  return e.slice(t, 7).concat(e.slice(0, t));
}
var Rm = "Sunday_Monday_Tuesday_Wednesday_Thursday_Friday_Saturday".split("_"), vc = "Sun_Mon_Tue_Wed_Thu_Fri_Sat".split("_"), km = "Su_Mo_Tu_We_Th_Fr_Sa".split("_"), Im = wr, Ym = wr, Cm = wr;
function jm(e, t) {
  var n = vt(this._weekdays) ? this._weekdays : this._weekdays[e && e !== !0 && this._weekdays.isFormat.test(t) ? "format" : "standalone"];
  return e === !0 ? js(n, this._week.dow) : e ? n[e.day()] : n;
}
function Lm(e) {
  return e === !0 ? js(this._weekdaysShort, this._week.dow) : e ? this._weekdaysShort[e.day()] : this._weekdaysShort;
}
function xm(e) {
  return e === !0 ? js(this._weekdaysMin, this._week.dow) : e ? this._weekdaysMin[e.day()] : this._weekdaysMin;
}
function Fm(e, t, n) {
  var r, s, o, l = e.toLocaleLowerCase();
  if (!this._weekdaysParse)
    for (this._weekdaysParse = [], this._shortWeekdaysParse = [], this._minWeekdaysParse = [], r = 0; r < 7; ++r)
      o = At([2e3, 1]).day(r), this._minWeekdaysParse[r] = this.weekdaysMin(
        o,
        ""
      ).toLocaleLowerCase(), this._shortWeekdaysParse[r] = this.weekdaysShort(
        o,
        ""
      ).toLocaleLowerCase(), this._weekdaysParse[r] = this.weekdays(o, "").toLocaleLowerCase();
  return n ? t === "dddd" ? (s = Ce.call(this._weekdaysParse, l), s !== -1 ? s : null) : t === "ddd" ? (s = Ce.call(this._shortWeekdaysParse, l), s !== -1 ? s : null) : (s = Ce.call(this._minWeekdaysParse, l), s !== -1 ? s : null) : t === "dddd" ? (s = Ce.call(this._weekdaysParse, l), s !== -1 || (s = Ce.call(this._shortWeekdaysParse, l), s !== -1) ? s : (s = Ce.call(this._minWeekdaysParse, l), s !== -1 ? s : null)) : t === "ddd" ? (s = Ce.call(this._shortWeekdaysParse, l), s !== -1 || (s = Ce.call(this._weekdaysParse, l), s !== -1) ? s : (s = Ce.call(this._minWeekdaysParse, l), s !== -1 ? s : null)) : (s = Ce.call(this._minWeekdaysParse, l), s !== -1 || (s = Ce.call(this._weekdaysParse, l), s !== -1) ? s : (s = Ce.call(this._shortWeekdaysParse, l), s !== -1 ? s : null));
}
function Bm(e, t, n) {
  var r, s, o;
  if (this._weekdaysParseExact)
    return Fm.call(this, e, t, n);
  for (this._weekdaysParse || (this._weekdaysParse = [], this._minWeekdaysParse = [], this._shortWeekdaysParse = [], this._fullWeekdaysParse = []), r = 0; r < 7; r++) {
    if (s = At([2e3, 1]).day(r), n && !this._fullWeekdaysParse[r] && (this._fullWeekdaysParse[r] = new RegExp(
      "^" + this.weekdays(s, "").replace(".", "\\.?") + "$",
      "i"
    ), this._shortWeekdaysParse[r] = new RegExp(
      "^" + this.weekdaysShort(s, "").replace(".", "\\.?") + "$",
      "i"
    ), this._minWeekdaysParse[r] = new RegExp(
      "^" + this.weekdaysMin(s, "").replace(".", "\\.?") + "$",
      "i"
    )), this._weekdaysParse[r] || (o = "^" + this.weekdays(s, "") + "|^" + this.weekdaysShort(s, "") + "|^" + this.weekdaysMin(s, ""), this._weekdaysParse[r] = new RegExp(o.replace(".", ""), "i")), n && t === "dddd" && this._fullWeekdaysParse[r].test(e))
      return r;
    if (n && t === "ddd" && this._shortWeekdaysParse[r].test(e))
      return r;
    if (n && t === "dd" && this._minWeekdaysParse[r].test(e))
      return r;
    if (!n && this._weekdaysParse[r].test(e))
      return r;
  }
}
function Um(e) {
  if (!this.isValid())
    return e != null ? this : NaN;
  var t = this._isUTC ? this._d.getUTCDay() : this._d.getDay();
  return e != null ? (e = Pm(e, this.localeData()), this.add(e - t, "d")) : t;
}
function Wm(e) {
  if (!this.isValid())
    return e != null ? this : NaN;
  var t = (this.day() + 7 - this.localeData()._week.dow) % 7;
  return e == null ? t : this.add(e - t, "d");
}
function Hm(e) {
  if (!this.isValid())
    return e != null ? this : NaN;
  if (e != null) {
    var t = Nm(e, this.localeData());
    return this.day(this.day() % 7 ? t : t - 7);
  } else
    return this.day() || 7;
}
function Gm(e) {
  return this._weekdaysParseExact ? (pe(this, "_weekdaysRegex") || Ls.call(this), e ? this._weekdaysStrictRegex : this._weekdaysRegex) : (pe(this, "_weekdaysRegex") || (this._weekdaysRegex = Im), this._weekdaysStrictRegex && e ? this._weekdaysStrictRegex : this._weekdaysRegex);
}
function $m(e) {
  return this._weekdaysParseExact ? (pe(this, "_weekdaysRegex") || Ls.call(this), e ? this._weekdaysShortStrictRegex : this._weekdaysShortRegex) : (pe(this, "_weekdaysShortRegex") || (this._weekdaysShortRegex = Ym), this._weekdaysShortStrictRegex && e ? this._weekdaysShortStrictRegex : this._weekdaysShortRegex);
}
function Vm(e) {
  return this._weekdaysParseExact ? (pe(this, "_weekdaysRegex") || Ls.call(this), e ? this._weekdaysMinStrictRegex : this._weekdaysMinRegex) : (pe(this, "_weekdaysMinRegex") || (this._weekdaysMinRegex = Cm), this._weekdaysMinStrictRegex && e ? this._weekdaysMinStrictRegex : this._weekdaysMinRegex);
}
function Ls() {
  function e(_, O) {
    return O.length - _.length;
  }
  var t = [], n = [], r = [], s = [], o, l, f, w, p;
  for (o = 0; o < 7; o++)
    l = At([2e3, 1]).day(o), f = it(this.weekdaysMin(l, "")), w = it(this.weekdaysShort(l, "")), p = it(this.weekdays(l, "")), t.push(f), n.push(w), r.push(p), s.push(f), s.push(w), s.push(p);
  t.sort(e), n.sort(e), r.sort(e), s.sort(e), this._weekdaysRegex = new RegExp("^(" + s.join("|") + ")", "i"), this._weekdaysShortRegex = this._weekdaysRegex, this._weekdaysMinRegex = this._weekdaysRegex, this._weekdaysStrictRegex = new RegExp(
    "^(" + r.join("|") + ")",
    "i"
  ), this._weekdaysShortStrictRegex = new RegExp(
    "^(" + n.join("|") + ")",
    "i"
  ), this._weekdaysMinStrictRegex = new RegExp(
    "^(" + t.join("|") + ")",
    "i"
  );
}
function xs() {
  return this.hours() % 12 || 12;
}
function zm() {
  return this.hours() || 24;
}
ne("H", ["HH", 2], 0, "hour");
ne("h", ["hh", 2], 0, xs);
ne("k", ["kk", 2], 0, zm);
ne("hmm", 0, 0, function() {
  return "" + xs.apply(this) + Dt(this.minutes(), 2);
});
ne("hmmss", 0, 0, function() {
  return "" + xs.apply(this) + Dt(this.minutes(), 2) + Dt(this.seconds(), 2);
});
ne("Hmm", 0, 0, function() {
  return "" + this.hours() + Dt(this.minutes(), 2);
});
ne("Hmmss", 0, 0, function() {
  return "" + this.hours() + Dt(this.minutes(), 2) + Dt(this.seconds(), 2);
});
function wc(e, t) {
  ne(e, 0, 0, function() {
    return this.localeData().meridiem(
      this.hours(),
      this.minutes(),
      t
    );
  });
}
wc("a", !0);
wc("A", !1);
Ke("hour", "h");
Qe("hour", 13);
function Sc(e, t) {
  return t._meridiemParse;
}
q("a", Sc);
q("A", Sc);
q("H", Ee);
q("h", Ee);
q("k", Ee);
q("HH", Ee, at);
q("hh", Ee, at);
q("kk", Ee, at);
q("hmm", lc);
q("hmmss", dc);
q("Hmm", lc);
q("Hmmss", dc);
Me(["H", "HH"], Be);
Me(["k", "kk"], function(e, t, n) {
  var r = ce(e);
  t[Be] = r === 24 ? 0 : r;
});
Me(["a", "A"], function(e, t, n) {
  n._isPm = n._locale.isPM(e), n._meridiem = e;
});
Me(["h", "hh"], function(e, t, n) {
  t[Be] = ce(e), oe(n).bigHour = !0;
});
Me("hmm", function(e, t, n) {
  var r = e.length - 2;
  t[Be] = ce(e.substr(0, r)), t[pt] = ce(e.substr(r)), oe(n).bigHour = !0;
});
Me("hmmss", function(e, t, n) {
  var r = e.length - 4, s = e.length - 2;
  t[Be] = ce(e.substr(0, r)), t[pt] = ce(e.substr(r, 2)), t[Ht] = ce(e.substr(s)), oe(n).bigHour = !0;
});
Me("Hmm", function(e, t, n) {
  var r = e.length - 2;
  t[Be] = ce(e.substr(0, r)), t[pt] = ce(e.substr(r));
});
Me("Hmmss", function(e, t, n) {
  var r = e.length - 4, s = e.length - 2;
  t[Be] = ce(e.substr(0, r)), t[pt] = ce(e.substr(r, 2)), t[Ht] = ce(e.substr(s));
});
function qm(e) {
  return (e + "").toLowerCase().charAt(0) === "p";
}
var Jm = /[ap]\.?m?\.?/i, Zm = Wn("Hours", !0);
function Km(e, t, n) {
  return e > 11 ? n ? "pm" : "PM" : n ? "am" : "AM";
}
var bc = {
  calendar: By,
  longDateFormat: Gy,
  invalidDate: Vy,
  ordinal: qy,
  dayOfMonthOrdinalParse: Jy,
  relativeTime: Ky,
  months: dm,
  monthsShort: fc,
  week: Om,
  weekdays: Rm,
  weekdaysMin: km,
  weekdaysShort: vc,
  meridiemParse: Jm
}, Ne = {}, nr = {}, _r;
function Qm(e, t) {
  var n, r = Math.min(e.length, t.length);
  for (n = 0; n < r; n += 1)
    if (e[n] !== t[n])
      return n;
  return r;
}
function ru(e) {
  return e && e.toLowerCase().replace("_", "-");
}
function Xm(e) {
  for (var t = 0, n, r, s, o; t < e.length; ) {
    for (o = ru(e[t]).split("-"), n = o.length, r = ru(e[t + 1]), r = r ? r.split("-") : null; n > 0; ) {
      if (s = Ri(o.slice(0, n).join("-")), s)
        return s;
      if (r && r.length >= n && Qm(o, r) >= n - 1)
        break;
      n--;
    }
    t++;
  }
  return _r;
}
function ep(e) {
  return e.match("^[^/\\\\]*$") != null;
}
function Ri(e) {
  var t = null, n;
  if (Ne[e] === void 0 && typeof module < "u" && module && module.exports && ep(e))
    try {
      t = _r._abbr, n = require, n("./locale/" + e), sn(t);
    } catch {
      Ne[e] = null;
    }
  return Ne[e];
}
function sn(e, t) {
  var n;
  return e && (nt(t) ? n = Jt(e) : n = Fs(e, t), n ? _r = n : typeof console < "u" && console.warn && console.warn(
    "Locale " + e + " not found. Did you forget to load it?"
  )), _r._abbr;
}
function Fs(e, t) {
  if (t !== null) {
    var n, r = bc;
    if (t.abbr = e, Ne[e] != null)
      ic(
        "defineLocaleOverride",
        "use moment.updateLocale(localeName, config) to change an existing locale. moment.defineLocale(localeName, config) should only be used for creating a new locale See http://momentjs.com/guides/#/warnings/define-locale/ for more info."
      ), r = Ne[e]._config;
    else if (t.parentLocale != null)
      if (Ne[t.parentLocale] != null)
        r = Ne[t.parentLocale]._config;
      else if (n = Ri(t.parentLocale), n != null)
        r = n._config;
      else
        return nr[t.parentLocale] || (nr[t.parentLocale] = []), nr[t.parentLocale].push({
          name: e,
          config: t
        }), null;
    return Ne[e] = new Rs(is(r, t)), nr[e] && nr[e].forEach(function(s) {
      Fs(s.name, s.config);
    }), sn(e), Ne[e];
  } else
    return delete Ne[e], null;
}
function tp(e, t) {
  if (t != null) {
    var n, r, s = bc;
    Ne[e] != null && Ne[e].parentLocale != null ? Ne[e].set(is(Ne[e]._config, t)) : (r = Ri(e), r != null && (s = r._config), t = is(s, t), r == null && (t.abbr = e), n = new Rs(t), n.parentLocale = Ne[e], Ne[e] = n), sn(e);
  } else
    Ne[e] != null && (Ne[e].parentLocale != null ? (Ne[e] = Ne[e].parentLocale, e === sn() && sn(e)) : Ne[e] != null && delete Ne[e]);
  return Ne[e];
}
function Jt(e) {
  var t;
  if (e && e._locale && e._locale._abbr && (e = e._locale._abbr), !e)
    return _r;
  if (!vt(e)) {
    if (t = Ri(e), t)
      return t;
    e = [e];
  }
  return Xm(e);
}
function np() {
  return as(Ne);
}
function Bs(e) {
  var t, n = e._a;
  return n && oe(e).overflow === -2 && (t = n[Wt] < 0 || n[Wt] > 11 ? Wt : n[Mt] < 1 || n[Mt] > Ni(n[Ze], n[Wt]) ? Mt : n[Be] < 0 || n[Be] > 24 || n[Be] === 24 && (n[pt] !== 0 || n[Ht] !== 0 || n[yn] !== 0) ? Be : n[pt] < 0 || n[pt] > 59 ? pt : n[Ht] < 0 || n[Ht] > 59 ? Ht : n[yn] < 0 || n[yn] > 999 ? yn : -1, oe(e)._overflowDayOfYear && (t < Ze || t > Mt) && (t = Mt), oe(e)._overflowWeeks && t === -1 && (t = um), oe(e)._overflowWeekday && t === -1 && (t = cm), oe(e).overflow = t), e;
}
var rp = /^\s*((?:[+-]\d{6}|\d{4})-(?:\d\d-\d\d|W\d\d-\d|W\d\d|\d\d\d|\d\d))(?:(T| )(\d\d(?::\d\d(?::\d\d(?:[.,]\d+)?)?)?)([+-]\d\d(?::?\d\d)?|\s*Z)?)?$/, ip = /^\s*((?:[+-]\d{6}|\d{4})(?:\d\d\d\d|W\d\d\d|W\d\d|\d\d\d|\d\d|))(?:(T| )(\d\d(?:\d\d(?:\d\d(?:[.,]\d+)?)?)?)([+-]\d\d(?::?\d\d)?|\s*Z)?)?$/, ap = /Z|[+-]\d\d(?::?\d\d)?/, Qr = [
  ["YYYYYY-MM-DD", /[+-]\d{6}-\d\d-\d\d/],
  ["YYYY-MM-DD", /\d{4}-\d\d-\d\d/],
  ["GGGG-[W]WW-E", /\d{4}-W\d\d-\d/],
  ["GGGG-[W]WW", /\d{4}-W\d\d/, !1],
  ["YYYY-DDD", /\d{4}-\d{3}/],
  ["YYYY-MM", /\d{4}-\d\d/, !1],
  ["YYYYYYMMDD", /[+-]\d{10}/],
  ["YYYYMMDD", /\d{8}/],
  ["GGGG[W]WWE", /\d{4}W\d{3}/],
  ["GGGG[W]WW", /\d{4}W\d{2}/, !1],
  ["YYYYDDD", /\d{7}/],
  ["YYYYMM", /\d{6}/, !1],
  ["YYYY", /\d{4}/, !1]
], Wa = [
  ["HH:mm:ss.SSSS", /\d\d:\d\d:\d\d\.\d+/],
  ["HH:mm:ss,SSSS", /\d\d:\d\d:\d\d,\d+/],
  ["HH:mm:ss", /\d\d:\d\d:\d\d/],
  ["HH:mm", /\d\d:\d\d/],
  ["HHmmss.SSSS", /\d\d\d\d\d\d\.\d+/],
  ["HHmmss,SSSS", /\d\d\d\d\d\d,\d+/],
  ["HHmmss", /\d\d\d\d\d\d/],
  ["HHmm", /\d\d\d\d/],
  ["HH", /\d\d/]
], sp = /^\/?Date\((-?\d+)/i, op = /^(?:(Mon|Tue|Wed|Thu|Fri|Sat|Sun),?\s)?(\d{1,2})\s(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s(\d{2,4})\s(\d\d):(\d\d)(?::(\d\d))?\s(?:(UT|GMT|[ECMP][SD]T)|([Zz])|([+-]\d{4}))$/, up = {
  UT: 0,
  GMT: 0,
  EDT: -4 * 60,
  EST: -5 * 60,
  CDT: -5 * 60,
  CST: -6 * 60,
  MDT: -6 * 60,
  MST: -7 * 60,
  PDT: -7 * 60,
  PST: -8 * 60
};
function Mc(e) {
  var t, n, r = e._i, s = rp.exec(r) || ip.exec(r), o, l, f, w, p = Qr.length, _ = Wa.length;
  if (s) {
    for (oe(e).iso = !0, t = 0, n = p; t < n; t++)
      if (Qr[t][1].exec(s[1])) {
        l = Qr[t][0], o = Qr[t][2] !== !1;
        break;
      }
    if (l == null) {
      e._isValid = !1;
      return;
    }
    if (s[3]) {
      for (t = 0, n = _; t < n; t++)
        if (Wa[t][1].exec(s[3])) {
          f = (s[2] || " ") + Wa[t][0];
          break;
        }
      if (f == null) {
        e._isValid = !1;
        return;
      }
    }
    if (!o && f != null) {
      e._isValid = !1;
      return;
    }
    if (s[4])
      if (ap.exec(s[4]))
        w = "Z";
      else {
        e._isValid = !1;
        return;
      }
    e._f = l + (f || "") + (w || ""), Ws(e);
  } else
    e._isValid = !1;
}
function cp(e, t, n, r, s, o) {
  var l = [
    lp(e),
    fc.indexOf(t),
    parseInt(n, 10),
    parseInt(r, 10),
    parseInt(s, 10)
  ];
  return o && l.push(parseInt(o, 10)), l;
}
function lp(e) {
  var t = parseInt(e, 10);
  return t <= 49 ? 2e3 + t : t <= 999 ? 1900 + t : t;
}
function dp(e) {
  return e.replace(/\([^()]*\)|[\n\t]/g, " ").replace(/(\s\s+)/g, " ").replace(/^\s\s*/, "").replace(/\s\s*$/, "");
}
function fp(e, t, n) {
  if (e) {
    var r = vc.indexOf(e), s = new Date(
      t[0],
      t[1],
      t[2]
    ).getDay();
    if (r !== s)
      return oe(n).weekdayMismatch = !0, n._isValid = !1, !1;
  }
  return !0;
}
function hp(e, t, n) {
  if (e)
    return up[e];
  if (t)
    return 0;
  var r = parseInt(n, 10), s = r % 100, o = (r - s) / 100;
  return o * 60 + s;
}
function Oc(e) {
  var t = op.exec(dp(e._i)), n;
  if (t) {
    if (n = cp(
      t[4],
      t[3],
      t[2],
      t[5],
      t[6],
      t[7]
    ), !fp(t[1], n, e))
      return;
    e._a = n, e._tzm = hp(t[8], t[9], t[10]), e._d = fr.apply(null, e._a), e._d.setUTCMinutes(e._d.getUTCMinutes() - e._tzm), oe(e).rfc2822 = !0;
  } else
    e._isValid = !1;
}
function _p(e) {
  var t = sp.exec(e._i);
  if (t !== null) {
    e._d = /* @__PURE__ */ new Date(+t[1]);
    return;
  }
  if (Mc(e), e._isValid === !1)
    delete e._isValid;
  else
    return;
  if (Oc(e), e._isValid === !1)
    delete e._isValid;
  else
    return;
  e._strict ? e._isValid = !1 : R.createFromInputFallback(e);
}
R.createFromInputFallback = dt(
  "value provided is not in a recognized RFC2822 or ISO format. moment construction falls back to js Date(), which is not reliable across all browsers and versions. Non RFC2822/ISO date formats are discouraged. Please refer to http://momentjs.com/guides/#/warnings/js-date/ for more info.",
  function(e) {
    e._d = /* @__PURE__ */ new Date(e._i + (e._useUTC ? " UTC" : ""));
  }
);
function Rn(e, t, n) {
  return e ?? t ?? n;
}
function yp(e) {
  var t = new Date(R.now());
  return e._useUTC ? [
    t.getUTCFullYear(),
    t.getUTCMonth(),
    t.getUTCDate()
  ] : [t.getFullYear(), t.getMonth(), t.getDate()];
}
function Us(e) {
  var t, n, r = [], s, o, l;
  if (!e._d) {
    for (s = yp(e), e._w && e._a[Mt] == null && e._a[Wt] == null && mp(e), e._dayOfYear != null && (l = Rn(e._a[Ze], s[Ze]), (e._dayOfYear > lr(l) || e._dayOfYear === 0) && (oe(e)._overflowDayOfYear = !0), n = fr(l, 0, e._dayOfYear), e._a[Wt] = n.getUTCMonth(), e._a[Mt] = n.getUTCDate()), t = 0; t < 3 && e._a[t] == null; ++t)
      e._a[t] = r[t] = s[t];
    for (; t < 7; t++)
      e._a[t] = r[t] = e._a[t] == null ? t === 2 ? 1 : 0 : e._a[t];
    e._a[Be] === 24 && e._a[pt] === 0 && e._a[Ht] === 0 && e._a[yn] === 0 && (e._nextDay = !0, e._a[Be] = 0), e._d = (e._useUTC ? fr : bm).apply(
      null,
      r
    ), o = e._useUTC ? e._d.getUTCDay() : e._d.getDay(), e._tzm != null && e._d.setUTCMinutes(e._d.getUTCMinutes() - e._tzm), e._nextDay && (e._a[Be] = 24), e._w && typeof e._w.d < "u" && e._w.d !== o && (oe(e).weekdayMismatch = !0);
  }
}
function mp(e) {
  var t, n, r, s, o, l, f, w, p;
  t = e._w, t.GG != null || t.W != null || t.E != null ? (o = 1, l = 4, n = Rn(
    t.GG,
    e._a[Ze],
    hr(Ae(), 1, 4).year
  ), r = Rn(t.W, 1), s = Rn(t.E, 1), (s < 1 || s > 7) && (w = !0)) : (o = e._locale._week.dow, l = e._locale._week.doy, p = hr(Ae(), o, l), n = Rn(t.gg, e._a[Ze], p.year), r = Rn(t.w, p.week), t.d != null ? (s = t.d, (s < 0 || s > 6) && (w = !0)) : t.e != null ? (s = t.e + o, (t.e < 0 || t.e > 6) && (w = !0)) : s = o), r < 1 || r > $t(n, o, l) ? oe(e)._overflowWeeks = !0 : w != null ? oe(e)._overflowWeekday = !0 : (f = gc(n, r, s, o, l), e._a[Ze] = f.year, e._dayOfYear = f.dayOfYear);
}
R.ISO_8601 = function() {
};
R.RFC_2822 = function() {
};
function Ws(e) {
  if (e._f === R.ISO_8601) {
    Mc(e);
    return;
  }
  if (e._f === R.RFC_2822) {
    Oc(e);
    return;
  }
  e._a = [], oe(e).empty = !0;
  var t = "" + e._i, n, r, s, o, l, f = t.length, w = 0, p, _;
  for (s = ac(e._f, e._locale).match(ks) || [], _ = s.length, n = 0; n < _; n++)
    o = s[n], r = (t.match(am(o, e)) || [])[0], r && (l = t.substr(0, t.indexOf(r)), l.length > 0 && oe(e).unusedInput.push(l), t = t.slice(
      t.indexOf(r) + r.length
    ), w += r.length), Cn[o] ? (r ? oe(e).empty = !1 : oe(e).unusedTokens.push(o), om(o, r, e)) : e._strict && !r && oe(e).unusedTokens.push(o);
  oe(e).charsLeftOver = f - w, t.length > 0 && oe(e).unusedInput.push(t), e._a[Be] <= 12 && oe(e).bigHour === !0 && e._a[Be] > 0 && (oe(e).bigHour = void 0), oe(e).parsedDateParts = e._a.slice(0), oe(e).meridiem = e._meridiem, e._a[Be] = pp(
    e._locale,
    e._a[Be],
    e._meridiem
  ), p = oe(e).era, p !== null && (e._a[Ze] = e._locale.erasConvertYear(p, e._a[Ze])), Us(e), Bs(e);
}
function pp(e, t, n) {
  var r;
  return n == null ? t : e.meridiemHour != null ? e.meridiemHour(t, n) : (e.isPM != null && (r = e.isPM(n), r && t < 12 && (t += 12), !r && t === 12 && (t = 0)), t);
}
function gp(e) {
  var t, n, r, s, o, l, f = !1, w = e._f.length;
  if (w === 0) {
    oe(e).invalidFormat = !0, e._d = /* @__PURE__ */ new Date(NaN);
    return;
  }
  for (s = 0; s < w; s++)
    o = 0, l = !1, t = Ns({}, e), e._useUTC != null && (t._useUTC = e._useUTC), t._f = e._f[s], Ws(t), Ps(t) && (l = !0), o += oe(t).charsLeftOver, o += oe(t).unusedTokens.length * 10, oe(t).score = o, f ? o < r && (r = o, n = t) : (r == null || o < r || l) && (r = o, n = t, l && (f = !0));
  rn(e, n || t);
}
function vp(e) {
  if (!e._d) {
    var t = Is(e._i), n = t.day === void 0 ? t.date : t.day;
    e._a = nc(
      [t.year, t.month, n, t.hour, t.minute, t.second, t.millisecond],
      function(r) {
        return r && parseInt(r, 10);
      }
    ), Us(e);
  }
}
function wp(e) {
  var t = new vr(Bs(Dc(e)));
  return t._nextDay && (t.add(1, "d"), t._nextDay = void 0), t;
}
function Dc(e) {
  var t = e._i, n = e._f;
  return e._locale = e._locale || Jt(e._l), t === null || n === void 0 && t === "" ? Mi({ nullInput: !0 }) : (typeof t == "string" && (e._i = t = e._locale.preparse(t)), wt(t) ? new vr(Bs(t)) : (gr(t) ? e._d = t : vt(n) ? gp(e) : n ? Ws(e) : Sp(e), Ps(e) || (e._d = null), e));
}
function Sp(e) {
  var t = e._i;
  nt(t) ? e._d = new Date(R.now()) : gr(t) ? e._d = new Date(t.valueOf()) : typeof t == "string" ? _p(e) : vt(t) ? (e._a = nc(t.slice(0), function(n) {
    return parseInt(n, 10);
  }), Us(e)) : mn(t) ? vp(e) : zt(t) ? e._d = new Date(t) : R.createFromInputFallback(e);
}
function Tc(e, t, n, r, s) {
  var o = {};
  return (t === !0 || t === !1) && (r = t, t = void 0), (n === !0 || n === !1) && (r = n, n = void 0), (mn(e) && Es(e) || vt(e) && e.length === 0) && (e = void 0), o._isAMomentObject = !0, o._useUTC = o._isUTC = s, o._l = n, o._i = e, o._f = t, o._strict = r, wp(o);
}
function Ae(e, t, n, r) {
  return Tc(e, t, n, r, !1);
}
var bp = dt(
  "moment().min is deprecated, use moment.max instead. http://momentjs.com/guides/#/warnings/min-max/",
  function() {
    var e = Ae.apply(null, arguments);
    return this.isValid() && e.isValid() ? e < this ? this : e : Mi();
  }
), Mp = dt(
  "moment().max is deprecated, use moment.min instead. http://momentjs.com/guides/#/warnings/min-max/",
  function() {
    var e = Ae.apply(null, arguments);
    return this.isValid() && e.isValid() ? e > this ? this : e : Mi();
  }
);
function Ac(e, t) {
  var n, r;
  if (t.length === 1 && vt(t[0]) && (t = t[0]), !t.length)
    return Ae();
  for (n = t[0], r = 1; r < t.length; ++r)
    (!t[r].isValid() || t[r][e](n)) && (n = t[r]);
  return n;
}
function Op() {
  var e = [].slice.call(arguments, 0);
  return Ac("isBefore", e);
}
function Dp() {
  var e = [].slice.call(arguments, 0);
  return Ac("isAfter", e);
}
var Tp = function() {
  return Date.now ? Date.now() : +/* @__PURE__ */ new Date();
}, rr = [
  "year",
  "quarter",
  "month",
  "week",
  "day",
  "hour",
  "minute",
  "second",
  "millisecond"
];
function Ap(e) {
  var t, n = !1, r, s = rr.length;
  for (t in e)
    if (pe(e, t) && !(Ce.call(rr, t) !== -1 && (e[t] == null || !isNaN(e[t]))))
      return !1;
  for (r = 0; r < s; ++r)
    if (e[rr[r]]) {
      if (n)
        return !1;
      parseFloat(e[rr[r]]) !== ce(e[rr[r]]) && (n = !0);
    }
  return !0;
}
function Ep() {
  return this._isValid;
}
function Pp() {
  return St(NaN);
}
function ki(e) {
  var t = Is(e), n = t.year || 0, r = t.quarter || 0, s = t.month || 0, o = t.week || t.isoWeek || 0, l = t.day || 0, f = t.hour || 0, w = t.minute || 0, p = t.second || 0, _ = t.millisecond || 0;
  this._isValid = Ap(t), this._milliseconds = +_ + p * 1e3 + // 1000
  w * 6e4 + // 1000 * 60
  f * 1e3 * 60 * 60, this._days = +l + o * 7, this._months = +s + r * 3 + n * 12, this._data = {}, this._locale = Jt(), this._bubble();
}
function ai(e) {
  return e instanceof ki;
}
function os(e) {
  return e < 0 ? Math.round(-1 * e) * -1 : Math.round(e);
}
function Np(e, t, n) {
  var r = Math.min(e.length, t.length), s = Math.abs(e.length - t.length), o = 0, l;
  for (l = 0; l < r; l++)
    (n && e[l] !== t[l] || !n && ce(e[l]) !== ce(t[l])) && o++;
  return o + s;
}
function Ec(e, t) {
  ne(e, 0, 0, function() {
    var n = this.utcOffset(), r = "+";
    return n < 0 && (n = -n, r = "-"), r + Dt(~~(n / 60), 2) + t + Dt(~~n % 60, 2);
  });
}
Ec("Z", ":");
Ec("ZZ", "");
q("Z", Pi);
q("ZZ", Pi);
Me(["Z", "ZZ"], function(e, t, n) {
  n._useUTC = !0, n._tzm = Hs(Pi, e);
});
var Rp = /([\+\-]|\d\d)/gi;
function Hs(e, t) {
  var n = (t || "").match(e), r, s, o;
  return n === null ? null : (r = n[n.length - 1] || [], s = (r + "").match(Rp) || ["-", 0, 0], o = +(s[1] * 60) + ce(s[2]), o === 0 ? 0 : s[0] === "+" ? o : -o);
}
function Gs(e, t) {
  var n, r;
  return t._isUTC ? (n = t.clone(), r = (wt(e) || gr(e) ? e.valueOf() : Ae(e).valueOf()) - n.valueOf(), n._d.setTime(n._d.valueOf() + r), R.updateOffset(n, !1), n) : Ae(e).local();
}
function us(e) {
  return -Math.round(e._d.getTimezoneOffset());
}
R.updateOffset = function() {
};
function kp(e, t, n) {
  var r = this._offset || 0, s;
  if (!this.isValid())
    return e != null ? this : NaN;
  if (e != null) {
    if (typeof e == "string") {
      if (e = Hs(Pi, e), e === null)
        return this;
    } else
      Math.abs(e) < 16 && !n && (e = e * 60);
    return !this._isUTC && t && (s = us(this)), this._offset = e, this._isUTC = !0, s != null && this.add(s, "m"), r !== e && (!t || this._changeInProgress ? Rc(
      this,
      St(e - r, "m"),
      1,
      !1
    ) : this._changeInProgress || (this._changeInProgress = !0, R.updateOffset(this, !0), this._changeInProgress = null)), this;
  } else
    return this._isUTC ? r : us(this);
}
function Ip(e, t) {
  return e != null ? (typeof e != "string" && (e = -e), this.utcOffset(e, t), this) : -this.utcOffset();
}
function Yp(e) {
  return this.utcOffset(0, e);
}
function Cp(e) {
  return this._isUTC && (this.utcOffset(0, e), this._isUTC = !1, e && this.subtract(us(this), "m")), this;
}
function jp() {
  if (this._tzm != null)
    this.utcOffset(this._tzm, !1, !0);
  else if (typeof this._i == "string") {
    var e = Hs(rm, this._i);
    e != null ? this.utcOffset(e) : this.utcOffset(0, !0);
  }
  return this;
}
function Lp(e) {
  return this.isValid() ? (e = e ? Ae(e).utcOffset() : 0, (this.utcOffset() - e) % 60 === 0) : !1;
}
function xp() {
  return this.utcOffset() > this.clone().month(0).utcOffset() || this.utcOffset() > this.clone().month(5).utcOffset();
}
function Fp() {
  if (!nt(this._isDSTShifted))
    return this._isDSTShifted;
  var e = {}, t;
  return Ns(e, this), e = Dc(e), e._a ? (t = e._isUTC ? At(e._a) : Ae(e._a), this._isDSTShifted = this.isValid() && Np(e._a, t.toArray()) > 0) : this._isDSTShifted = !1, this._isDSTShifted;
}
function Bp() {
  return this.isValid() ? !this._isUTC : !1;
}
function Up() {
  return this.isValid() ? this._isUTC : !1;
}
function Pc() {
  return this.isValid() ? this._isUTC && this._offset === 0 : !1;
}
var Wp = /^(-|\+)?(?:(\d*)[. ])?(\d+):(\d+)(?::(\d+)(\.\d*)?)?$/, Hp = /^(-|\+)?P(?:([-+]?[0-9,.]*)Y)?(?:([-+]?[0-9,.]*)M)?(?:([-+]?[0-9,.]*)W)?(?:([-+]?[0-9,.]*)D)?(?:T(?:([-+]?[0-9,.]*)H)?(?:([-+]?[0-9,.]*)M)?(?:([-+]?[0-9,.]*)S)?)?$/;
function St(e, t) {
  var n = e, r = null, s, o, l;
  return ai(e) ? n = {
    ms: e._milliseconds,
    d: e._days,
    M: e._months
  } : zt(e) || !isNaN(+e) ? (n = {}, t ? n[t] = +e : n.milliseconds = +e) : (r = Wp.exec(e)) ? (s = r[1] === "-" ? -1 : 1, n = {
    y: 0,
    d: ce(r[Mt]) * s,
    h: ce(r[Be]) * s,
    m: ce(r[pt]) * s,
    s: ce(r[Ht]) * s,
    ms: ce(os(r[yn] * 1e3)) * s
    // the millisecond decimal point is included in the match
  }) : (r = Hp.exec(e)) ? (s = r[1] === "-" ? -1 : 1, n = {
    y: fn(r[2], s),
    M: fn(r[3], s),
    w: fn(r[4], s),
    d: fn(r[5], s),
    h: fn(r[6], s),
    m: fn(r[7], s),
    s: fn(r[8], s)
  }) : n == null ? n = {} : typeof n == "object" && ("from" in n || "to" in n) && (l = Gp(
    Ae(n.from),
    Ae(n.to)
  ), n = {}, n.ms = l.milliseconds, n.M = l.months), o = new ki(n), ai(e) && pe(e, "_locale") && (o._locale = e._locale), ai(e) && pe(e, "_isValid") && (o._isValid = e._isValid), o;
}
St.fn = ki.prototype;
St.invalid = Pp;
function fn(e, t) {
  var n = e && parseFloat(e.replace(",", "."));
  return (isNaN(n) ? 0 : n) * t;
}
function iu(e, t) {
  var n = {};
  return n.months = t.month() - e.month() + (t.year() - e.year()) * 12, e.clone().add(n.months, "M").isAfter(t) && --n.months, n.milliseconds = +t - +e.clone().add(n.months, "M"), n;
}
function Gp(e, t) {
  var n;
  return e.isValid() && t.isValid() ? (t = Gs(t, e), e.isBefore(t) ? n = iu(e, t) : (n = iu(t, e), n.milliseconds = -n.milliseconds, n.months = -n.months), n) : { milliseconds: 0, months: 0 };
}
function Nc(e, t) {
  return function(n, r) {
    var s, o;
    return r !== null && !isNaN(+r) && (ic(
      t,
      "moment()." + t + "(period, number) is deprecated. Please use moment()." + t + "(number, period). See http://momentjs.com/guides/#/warnings/add-inverted-param/ for more info."
    ), o = n, n = r, r = o), s = St(n, r), Rc(this, s, e), this;
  };
}
function Rc(e, t, n, r) {
  var s = t._milliseconds, o = os(t._days), l = os(t._months);
  e.isValid() && (r = r ?? !0, l && _c(e, li(e, "Month") + l * n), o && oc(e, "Date", li(e, "Date") + o * n), s && e._d.setTime(e._d.valueOf() + s * n), r && R.updateOffset(e, o || l));
}
var $p = Nc(1, "add"), Vp = Nc(-1, "subtract");
function kc(e) {
  return typeof e == "string" || e instanceof String;
}
function zp(e) {
  return wt(e) || gr(e) || kc(e) || zt(e) || Jp(e) || qp(e) || e === null || e === void 0;
}
function qp(e) {
  var t = mn(e) && !Es(e), n = !1, r = [
    "years",
    "year",
    "y",
    "months",
    "month",
    "M",
    "days",
    "day",
    "d",
    "dates",
    "date",
    "D",
    "hours",
    "hour",
    "h",
    "minutes",
    "minute",
    "m",
    "seconds",
    "second",
    "s",
    "milliseconds",
    "millisecond",
    "ms"
  ], s, o, l = r.length;
  for (s = 0; s < l; s += 1)
    o = r[s], n = n || pe(e, o);
  return t && n;
}
function Jp(e) {
  var t = vt(e), n = !1;
  return t && (n = e.filter(function(r) {
    return !zt(r) && kc(e);
  }).length === 0), t && n;
}
function Zp(e) {
  var t = mn(e) && !Es(e), n = !1, r = [
    "sameDay",
    "nextDay",
    "lastDay",
    "nextWeek",
    "lastWeek",
    "sameElse"
  ], s, o;
  for (s = 0; s < r.length; s += 1)
    o = r[s], n = n || pe(e, o);
  return t && n;
}
function Kp(e, t) {
  var n = e.diff(t, "days", !0);
  return n < -6 ? "sameElse" : n < -1 ? "lastWeek" : n < 0 ? "lastDay" : n < 1 ? "sameDay" : n < 2 ? "nextDay" : n < 7 ? "nextWeek" : "sameElse";
}
function Qp(e, t) {
  arguments.length === 1 && (arguments[0] ? zp(arguments[0]) ? (e = arguments[0], t = void 0) : Zp(arguments[0]) && (t = arguments[0], e = void 0) : (e = void 0, t = void 0));
  var n = e || Ae(), r = Gs(n, this).startOf("day"), s = R.calendarFormat(this, r) || "sameElse", o = t && (Et(t[s]) ? t[s].call(this, n) : t[s]);
  return this.format(
    o || this.localeData().calendar(s, this, Ae(n))
  );
}
function Xp() {
  return new vr(this);
}
function eg(e, t) {
  var n = wt(e) ? e : Ae(e);
  return this.isValid() && n.isValid() ? (t = ft(t) || "millisecond", t === "millisecond" ? this.valueOf() > n.valueOf() : n.valueOf() < this.clone().startOf(t).valueOf()) : !1;
}
function tg(e, t) {
  var n = wt(e) ? e : Ae(e);
  return this.isValid() && n.isValid() ? (t = ft(t) || "millisecond", t === "millisecond" ? this.valueOf() < n.valueOf() : this.clone().endOf(t).valueOf() < n.valueOf()) : !1;
}
function ng(e, t, n, r) {
  var s = wt(e) ? e : Ae(e), o = wt(t) ? t : Ae(t);
  return this.isValid() && s.isValid() && o.isValid() ? (r = r || "()", (r[0] === "(" ? this.isAfter(s, n) : !this.isBefore(s, n)) && (r[1] === ")" ? this.isBefore(o, n) : !this.isAfter(o, n))) : !1;
}
function rg(e, t) {
  var n = wt(e) ? e : Ae(e), r;
  return this.isValid() && n.isValid() ? (t = ft(t) || "millisecond", t === "millisecond" ? this.valueOf() === n.valueOf() : (r = n.valueOf(), this.clone().startOf(t).valueOf() <= r && r <= this.clone().endOf(t).valueOf())) : !1;
}
function ig(e, t) {
  return this.isSame(e, t) || this.isAfter(e, t);
}
function ag(e, t) {
  return this.isSame(e, t) || this.isBefore(e, t);
}
function sg(e, t, n) {
  var r, s, o;
  if (!this.isValid())
    return NaN;
  if (r = Gs(e, this), !r.isValid())
    return NaN;
  switch (s = (r.utcOffset() - this.utcOffset()) * 6e4, t = ft(t), t) {
    case "year":
      o = si(this, r) / 12;
      break;
    case "month":
      o = si(this, r);
      break;
    case "quarter":
      o = si(this, r) / 3;
      break;
    case "second":
      o = (this - r) / 1e3;
      break;
    case "minute":
      o = (this - r) / 6e4;
      break;
    case "hour":
      o = (this - r) / 36e5;
      break;
    case "day":
      o = (this - r - s) / 864e5;
      break;
    case "week":
      o = (this - r - s) / 6048e5;
      break;
    default:
      o = this - r;
  }
  return n ? o : ct(o);
}
function si(e, t) {
  if (e.date() < t.date())
    return -si(t, e);
  var n = (t.year() - e.year()) * 12 + (t.month() - e.month()), r = e.clone().add(n, "months"), s, o;
  return t - r < 0 ? (s = e.clone().add(n - 1, "months"), o = (t - r) / (r - s)) : (s = e.clone().add(n + 1, "months"), o = (t - r) / (s - r)), -(n + o) || 0;
}
R.defaultFormat = "YYYY-MM-DDTHH:mm:ssZ";
R.defaultFormatUtc = "YYYY-MM-DDTHH:mm:ss[Z]";
function og() {
  return this.clone().locale("en").format("ddd MMM DD YYYY HH:mm:ss [GMT]ZZ");
}
function ug(e) {
  if (!this.isValid())
    return null;
  var t = e !== !0, n = t ? this.clone().utc() : this;
  return n.year() < 0 || n.year() > 9999 ? ii(
    n,
    t ? "YYYYYY-MM-DD[T]HH:mm:ss.SSS[Z]" : "YYYYYY-MM-DD[T]HH:mm:ss.SSSZ"
  ) : Et(Date.prototype.toISOString) ? t ? this.toDate().toISOString() : new Date(this.valueOf() + this.utcOffset() * 60 * 1e3).toISOString().replace("Z", ii(n, "Z")) : ii(
    n,
    t ? "YYYY-MM-DD[T]HH:mm:ss.SSS[Z]" : "YYYY-MM-DD[T]HH:mm:ss.SSSZ"
  );
}
function cg() {
  if (!this.isValid())
    return "moment.invalid(/* " + this._i + " */)";
  var e = "moment", t = "", n, r, s, o;
  return this.isLocal() || (e = this.utcOffset() === 0 ? "moment.utc" : "moment.parseZone", t = "Z"), n = "[" + e + '("]', r = 0 <= this.year() && this.year() <= 9999 ? "YYYY" : "YYYYYY", s = "-MM-DD[T]HH:mm:ss.SSS", o = t + '[")]', this.format(n + r + s + o);
}
function lg(e) {
  e || (e = this.isUtc() ? R.defaultFormatUtc : R.defaultFormat);
  var t = ii(this, e);
  return this.localeData().postformat(t);
}
function dg(e, t) {
  return this.isValid() && (wt(e) && e.isValid() || Ae(e).isValid()) ? St({ to: this, from: e }).locale(this.locale()).humanize(!t) : this.localeData().invalidDate();
}
function fg(e) {
  return this.from(Ae(), e);
}
function hg(e, t) {
  return this.isValid() && (wt(e) && e.isValid() || Ae(e).isValid()) ? St({ from: this, to: e }).locale(this.locale()).humanize(!t) : this.localeData().invalidDate();
}
function _g(e) {
  return this.to(Ae(), e);
}
function Ic(e) {
  var t;
  return e === void 0 ? this._locale._abbr : (t = Jt(e), t != null && (this._locale = t), this);
}
var Yc = dt(
  "moment().lang() is deprecated. Instead, use moment().localeData() to get the language configuration. Use moment().locale() to change languages.",
  function(e) {
    return e === void 0 ? this.localeData() : this.locale(e);
  }
);
function Cc() {
  return this._locale;
}
var hi = 1e3, jn = 60 * hi, _i = 60 * jn, jc = (365 * 400 + 97) * 24 * _i;
function Ln(e, t) {
  return (e % t + t) % t;
}
function Lc(e, t, n) {
  return e < 100 && e >= 0 ? new Date(e + 400, t, n) - jc : new Date(e, t, n).valueOf();
}
function xc(e, t, n) {
  return e < 100 && e >= 0 ? Date.UTC(e + 400, t, n) - jc : Date.UTC(e, t, n);
}
function yg(e) {
  var t, n;
  if (e = ft(e), e === void 0 || e === "millisecond" || !this.isValid())
    return this;
  switch (n = this._isUTC ? xc : Lc, e) {
    case "year":
      t = n(this.year(), 0, 1);
      break;
    case "quarter":
      t = n(
        this.year(),
        this.month() - this.month() % 3,
        1
      );
      break;
    case "month":
      t = n(this.year(), this.month(), 1);
      break;
    case "week":
      t = n(
        this.year(),
        this.month(),
        this.date() - this.weekday()
      );
      break;
    case "isoWeek":
      t = n(
        this.year(),
        this.month(),
        this.date() - (this.isoWeekday() - 1)
      );
      break;
    case "day":
    case "date":
      t = n(this.year(), this.month(), this.date());
      break;
    case "hour":
      t = this._d.valueOf(), t -= Ln(
        t + (this._isUTC ? 0 : this.utcOffset() * jn),
        _i
      );
      break;
    case "minute":
      t = this._d.valueOf(), t -= Ln(t, jn);
      break;
    case "second":
      t = this._d.valueOf(), t -= Ln(t, hi);
      break;
  }
  return this._d.setTime(t), R.updateOffset(this, !0), this;
}
function mg(e) {
  var t, n;
  if (e = ft(e), e === void 0 || e === "millisecond" || !this.isValid())
    return this;
  switch (n = this._isUTC ? xc : Lc, e) {
    case "year":
      t = n(this.year() + 1, 0, 1) - 1;
      break;
    case "quarter":
      t = n(
        this.year(),
        this.month() - this.month() % 3 + 3,
        1
      ) - 1;
      break;
    case "month":
      t = n(this.year(), this.month() + 1, 1) - 1;
      break;
    case "week":
      t = n(
        this.year(),
        this.month(),
        this.date() - this.weekday() + 7
      ) - 1;
      break;
    case "isoWeek":
      t = n(
        this.year(),
        this.month(),
        this.date() - (this.isoWeekday() - 1) + 7
      ) - 1;
      break;
    case "day":
    case "date":
      t = n(this.year(), this.month(), this.date() + 1) - 1;
      break;
    case "hour":
      t = this._d.valueOf(), t += _i - Ln(
        t + (this._isUTC ? 0 : this.utcOffset() * jn),
        _i
      ) - 1;
      break;
    case "minute":
      t = this._d.valueOf(), t += jn - Ln(t, jn) - 1;
      break;
    case "second":
      t = this._d.valueOf(), t += hi - Ln(t, hi) - 1;
      break;
  }
  return this._d.setTime(t), R.updateOffset(this, !0), this;
}
function pg() {
  return this._d.valueOf() - (this._offset || 0) * 6e4;
}
function gg() {
  return Math.floor(this.valueOf() / 1e3);
}
function vg() {
  return new Date(this.valueOf());
}
function wg() {
  var e = this;
  return [
    e.year(),
    e.month(),
    e.date(),
    e.hour(),
    e.minute(),
    e.second(),
    e.millisecond()
  ];
}
function Sg() {
  var e = this;
  return {
    years: e.year(),
    months: e.month(),
    date: e.date(),
    hours: e.hours(),
    minutes: e.minutes(),
    seconds: e.seconds(),
    milliseconds: e.milliseconds()
  };
}
function bg() {
  return this.isValid() ? this.toISOString() : null;
}
function Mg() {
  return Ps(this);
}
function Og() {
  return rn({}, oe(this));
}
function Dg() {
  return oe(this).overflow;
}
function Tg() {
  return {
    input: this._i,
    format: this._f,
    locale: this._locale,
    isUTC: this._isUTC,
    strict: this._strict
  };
}
ne("N", 0, 0, "eraAbbr");
ne("NN", 0, 0, "eraAbbr");
ne("NNN", 0, 0, "eraAbbr");
ne("NNNN", 0, 0, "eraName");
ne("NNNNN", 0, 0, "eraNarrow");
ne("y", ["y", 1], "yo", "eraYear");
ne("y", ["yy", 2], 0, "eraYear");
ne("y", ["yyy", 3], 0, "eraYear");
ne("y", ["yyyy", 4], 0, "eraYear");
q("N", $s);
q("NN", $s);
q("NNN", $s);
q("NNNN", Lg);
q("NNNNN", xg);
Me(
  ["N", "NN", "NNN", "NNNN", "NNNNN"],
  function(e, t, n, r) {
    var s = n._locale.erasParse(e, r, n._strict);
    s ? oe(n).era = s : oe(n).invalidEra = e;
  }
);
q("y", Hn);
q("yy", Hn);
q("yyy", Hn);
q("yyyy", Hn);
q("yo", Fg);
Me(["y", "yy", "yyy", "yyyy"], Ze);
Me(["yo"], function(e, t, n, r) {
  var s;
  n._locale._eraYearOrdinalRegex && (s = e.match(n._locale._eraYearOrdinalRegex)), n._locale.eraYearOrdinalParse ? t[Ze] = n._locale.eraYearOrdinalParse(e, s) : t[Ze] = parseInt(e, 10);
});
function Ag(e, t) {
  var n, r, s, o = this._eras || Jt("en")._eras;
  for (n = 0, r = o.length; n < r; ++n) {
    switch (typeof o[n].since) {
      case "string":
        s = R(o[n].since).startOf("day"), o[n].since = s.valueOf();
        break;
    }
    switch (typeof o[n].until) {
      case "undefined":
        o[n].until = 1 / 0;
        break;
      case "string":
        s = R(o[n].until).startOf("day").valueOf(), o[n].until = s.valueOf();
        break;
    }
  }
  return o;
}
function Eg(e, t, n) {
  var r, s, o = this.eras(), l, f, w;
  for (e = e.toUpperCase(), r = 0, s = o.length; r < s; ++r)
    if (l = o[r].name.toUpperCase(), f = o[r].abbr.toUpperCase(), w = o[r].narrow.toUpperCase(), n)
      switch (t) {
        case "N":
        case "NN":
        case "NNN":
          if (f === e)
            return o[r];
          break;
        case "NNNN":
          if (l === e)
            return o[r];
          break;
        case "NNNNN":
          if (w === e)
            return o[r];
          break;
      }
    else if ([l, f, w].indexOf(e) >= 0)
      return o[r];
}
function Pg(e, t) {
  var n = e.since <= e.until ? 1 : -1;
  return t === void 0 ? R(e.since).year() : R(e.since).year() + (t - e.offset) * n;
}
function Ng() {
  var e, t, n, r = this.localeData().eras();
  for (e = 0, t = r.length; e < t; ++e)
    if (n = this.clone().startOf("day").valueOf(), r[e].since <= n && n <= r[e].until || r[e].until <= n && n <= r[e].since)
      return r[e].name;
  return "";
}
function Rg() {
  var e, t, n, r = this.localeData().eras();
  for (e = 0, t = r.length; e < t; ++e)
    if (n = this.clone().startOf("day").valueOf(), r[e].since <= n && n <= r[e].until || r[e].until <= n && n <= r[e].since)
      return r[e].narrow;
  return "";
}
function kg() {
  var e, t, n, r = this.localeData().eras();
  for (e = 0, t = r.length; e < t; ++e)
    if (n = this.clone().startOf("day").valueOf(), r[e].since <= n && n <= r[e].until || r[e].until <= n && n <= r[e].since)
      return r[e].abbr;
  return "";
}
function Ig() {
  var e, t, n, r, s = this.localeData().eras();
  for (e = 0, t = s.length; e < t; ++e)
    if (n = s[e].since <= s[e].until ? 1 : -1, r = this.clone().startOf("day").valueOf(), s[e].since <= r && r <= s[e].until || s[e].until <= r && r <= s[e].since)
      return (this.year() - R(s[e].since).year()) * n + s[e].offset;
  return this.year();
}
function Yg(e) {
  return pe(this, "_erasNameRegex") || Vs.call(this), e ? this._erasNameRegex : this._erasRegex;
}
function Cg(e) {
  return pe(this, "_erasAbbrRegex") || Vs.call(this), e ? this._erasAbbrRegex : this._erasRegex;
}
function jg(e) {
  return pe(this, "_erasNarrowRegex") || Vs.call(this), e ? this._erasNarrowRegex : this._erasRegex;
}
function $s(e, t) {
  return t.erasAbbrRegex(e);
}
function Lg(e, t) {
  return t.erasNameRegex(e);
}
function xg(e, t) {
  return t.erasNarrowRegex(e);
}
function Fg(e, t) {
  return t._eraYearOrdinalRegex || Hn;
}
function Vs() {
  var e = [], t = [], n = [], r = [], s, o, l = this.eras();
  for (s = 0, o = l.length; s < o; ++s)
    t.push(it(l[s].name)), e.push(it(l[s].abbr)), n.push(it(l[s].narrow)), r.push(it(l[s].name)), r.push(it(l[s].abbr)), r.push(it(l[s].narrow));
  this._erasRegex = new RegExp("^(" + r.join("|") + ")", "i"), this._erasNameRegex = new RegExp("^(" + t.join("|") + ")", "i"), this._erasAbbrRegex = new RegExp("^(" + e.join("|") + ")", "i"), this._erasNarrowRegex = new RegExp(
    "^(" + n.join("|") + ")",
    "i"
  );
}
ne(0, ["gg", 2], 0, function() {
  return this.weekYear() % 100;
});
ne(0, ["GG", 2], 0, function() {
  return this.isoWeekYear() % 100;
});
function Ii(e, t) {
  ne(0, [e, e.length], 0, t);
}
Ii("gggg", "weekYear");
Ii("ggggg", "weekYear");
Ii("GGGG", "isoWeekYear");
Ii("GGGGG", "isoWeekYear");
Ke("weekYear", "gg");
Ke("isoWeekYear", "GG");
Qe("weekYear", 1);
Qe("isoWeekYear", 1);
q("G", Ei);
q("g", Ei);
q("GG", Ee, at);
q("gg", Ee, at);
q("GGGG", Cs, Ys);
q("gggg", Cs, Ys);
q("GGGGG", Ai, Di);
q("ggggg", Ai, Di);
Sr(
  ["gggg", "ggggg", "GGGG", "GGGGG"],
  function(e, t, n, r) {
    t[r.substr(0, 2)] = ce(e);
  }
);
Sr(["gg", "GG"], function(e, t, n, r) {
  t[r] = R.parseTwoDigitYear(e);
});
function Bg(e) {
  return Fc.call(
    this,
    e,
    this.week(),
    this.weekday(),
    this.localeData()._week.dow,
    this.localeData()._week.doy
  );
}
function Ug(e) {
  return Fc.call(
    this,
    e,
    this.isoWeek(),
    this.isoWeekday(),
    1,
    4
  );
}
function Wg() {
  return $t(this.year(), 1, 4);
}
function Hg() {
  return $t(this.isoWeekYear(), 1, 4);
}
function Gg() {
  var e = this.localeData()._week;
  return $t(this.year(), e.dow, e.doy);
}
function $g() {
  var e = this.localeData()._week;
  return $t(this.weekYear(), e.dow, e.doy);
}
function Fc(e, t, n, r, s) {
  var o;
  return e == null ? hr(this, r, s).year : (o = $t(e, r, s), t > o && (t = o), Vg.call(this, e, t, n, r, s));
}
function Vg(e, t, n, r, s) {
  var o = gc(e, t, n, r, s), l = fr(o.year, 0, o.dayOfYear);
  return this.year(l.getUTCFullYear()), this.month(l.getUTCMonth()), this.date(l.getUTCDate()), this;
}
ne("Q", 0, "Qo", "quarter");
Ke("quarter", "Q");
Qe("quarter", 7);
q("Q", uc);
Me("Q", function(e, t) {
  t[Wt] = (ce(e) - 1) * 3;
});
function zg(e) {
  return e == null ? Math.ceil((this.month() + 1) / 3) : this.month((e - 1) * 3 + this.month() % 3);
}
ne("D", ["DD", 2], "Do", "date");
Ke("date", "D");
Qe("date", 9);
q("D", Ee);
q("DD", Ee, at);
q("Do", function(e, t) {
  return e ? t._dayOfMonthOrdinalParse || t._ordinalParse : t._dayOfMonthOrdinalParseLenient;
});
Me(["D", "DD"], Mt);
Me("Do", function(e, t) {
  t[Mt] = ce(e.match(Ee)[0]);
});
var Bc = Wn("Date", !0);
ne("DDD", ["DDDD", 3], "DDDo", "dayOfYear");
Ke("dayOfYear", "DDD");
Qe("dayOfYear", 4);
q("DDD", Ti);
q("DDDD", cc);
Me(["DDD", "DDDD"], function(e, t, n) {
  n._dayOfYear = ce(e);
});
function qg(e) {
  var t = Math.round(
    (this.clone().startOf("day") - this.clone().startOf("year")) / 864e5
  ) + 1;
  return e == null ? t : this.add(e - t, "d");
}
ne("m", ["mm", 2], 0, "minute");
Ke("minute", "m");
Qe("minute", 14);
q("m", Ee);
q("mm", Ee, at);
Me(["m", "mm"], pt);
var Jg = Wn("Minutes", !1);
ne("s", ["ss", 2], 0, "second");
Ke("second", "s");
Qe("second", 15);
q("s", Ee);
q("ss", Ee, at);
Me(["s", "ss"], Ht);
var Zg = Wn("Seconds", !1);
ne("S", 0, 0, function() {
  return ~~(this.millisecond() / 100);
});
ne(0, ["SS", 2], 0, function() {
  return ~~(this.millisecond() / 10);
});
ne(0, ["SSS", 3], 0, "millisecond");
ne(0, ["SSSS", 4], 0, function() {
  return this.millisecond() * 10;
});
ne(0, ["SSSSS", 5], 0, function() {
  return this.millisecond() * 100;
});
ne(0, ["SSSSSS", 6], 0, function() {
  return this.millisecond() * 1e3;
});
ne(0, ["SSSSSSS", 7], 0, function() {
  return this.millisecond() * 1e4;
});
ne(0, ["SSSSSSSS", 8], 0, function() {
  return this.millisecond() * 1e5;
});
ne(0, ["SSSSSSSSS", 9], 0, function() {
  return this.millisecond() * 1e6;
});
Ke("millisecond", "ms");
Qe("millisecond", 16);
q("S", Ti, uc);
q("SS", Ti, at);
q("SSS", Ti, cc);
var an, Uc;
for (an = "SSSS"; an.length <= 9; an += "S")
  q(an, Hn);
function Kg(e, t) {
  t[yn] = ce(("0." + e) * 1e3);
}
for (an = "S"; an.length <= 9; an += "S")
  Me(an, Kg);
Uc = Wn("Milliseconds", !1);
ne("z", 0, 0, "zoneAbbr");
ne("zz", 0, 0, "zoneName");
function Qg() {
  return this._isUTC ? "UTC" : "";
}
function Xg() {
  return this._isUTC ? "Coordinated Universal Time" : "";
}
var C = vr.prototype;
C.add = $p;
C.calendar = Qp;
C.clone = Xp;
C.diff = sg;
C.endOf = mg;
C.format = lg;
C.from = dg;
C.fromNow = fg;
C.to = hg;
C.toNow = _g;
C.get = tm;
C.invalidAt = Dg;
C.isAfter = eg;
C.isBefore = tg;
C.isBetween = ng;
C.isSame = rg;
C.isSameOrAfter = ig;
C.isSameOrBefore = ag;
C.isValid = Mg;
C.lang = Yc;
C.locale = Ic;
C.localeData = Cc;
C.max = Mp;
C.min = bp;
C.parsingFlags = Og;
C.set = nm;
C.startOf = yg;
C.subtract = Vp;
C.toArray = wg;
C.toObject = Sg;
C.toDate = vg;
C.toISOString = ug;
C.inspect = cg;
typeof Symbol < "u" && Symbol.for != null && (C[Symbol.for("nodejs.util.inspect.custom")] = function() {
  return "Moment<" + this.format() + ">";
});
C.toJSON = bg;
C.toString = og;
C.unix = gg;
C.valueOf = pg;
C.creationData = Tg;
C.eraName = Ng;
C.eraNarrow = Rg;
C.eraAbbr = kg;
C.eraYear = Ig;
C.year = pc;
C.isLeapYear = Sm;
C.weekYear = Bg;
C.isoWeekYear = Ug;
C.quarter = C.quarters = zg;
C.month = yc;
C.daysInMonth = gm;
C.week = C.weeks = Am;
C.isoWeek = C.isoWeeks = Em;
C.weeksInYear = Gg;
C.weeksInWeekYear = $g;
C.isoWeeksInYear = Wg;
C.isoWeeksInISOWeekYear = Hg;
C.date = Bc;
C.day = C.days = Um;
C.weekday = Wm;
C.isoWeekday = Hm;
C.dayOfYear = qg;
C.hour = C.hours = Zm;
C.minute = C.minutes = Jg;
C.second = C.seconds = Zg;
C.millisecond = C.milliseconds = Uc;
C.utcOffset = kp;
C.utc = Yp;
C.local = Cp;
C.parseZone = jp;
C.hasAlignedHourOffset = Lp;
C.isDST = xp;
C.isLocal = Bp;
C.isUtcOffset = Up;
C.isUtc = Pc;
C.isUTC = Pc;
C.zoneAbbr = Qg;
C.zoneName = Xg;
C.dates = dt(
  "dates accessor is deprecated. Use date instead.",
  Bc
);
C.months = dt(
  "months accessor is deprecated. Use month instead",
  yc
);
C.years = dt(
  "years accessor is deprecated. Use year instead",
  pc
);
C.zone = dt(
  "moment().zone is deprecated, use moment().utcOffset instead. http://momentjs.com/guides/#/warnings/zone/",
  Ip
);
C.isDSTShifted = dt(
  "isDSTShifted is deprecated. See http://momentjs.com/guides/#/warnings/dst-shifted/ for more information",
  Fp
);
function ev(e) {
  return Ae(e * 1e3);
}
function tv() {
  return Ae.apply(null, arguments).parseZone();
}
function Wc(e) {
  return e;
}
var ge = Rs.prototype;
ge.calendar = Uy;
ge.longDateFormat = $y;
ge.invalidDate = zy;
ge.ordinal = Zy;
ge.preparse = Wc;
ge.postformat = Wc;
ge.relativeTime = Qy;
ge.pastFuture = Xy;
ge.set = Fy;
ge.eras = Ag;
ge.erasParse = Eg;
ge.erasConvertYear = Pg;
ge.erasAbbrRegex = Cg;
ge.erasNameRegex = Yg;
ge.erasNarrowRegex = jg;
ge.months = _m;
ge.monthsShort = ym;
ge.monthsParse = pm;
ge.monthsRegex = wm;
ge.monthsShortRegex = vm;
ge.week = Mm;
ge.firstDayOfYear = Tm;
ge.firstDayOfWeek = Dm;
ge.weekdays = jm;
ge.weekdaysMin = xm;
ge.weekdaysShort = Lm;
ge.weekdaysParse = Bm;
ge.weekdaysRegex = Gm;
ge.weekdaysShortRegex = $m;
ge.weekdaysMinRegex = Vm;
ge.isPM = qm;
ge.meridiem = Km;
function yi(e, t, n, r) {
  var s = Jt(), o = At().set(r, t);
  return s[n](o, e);
}
function Hc(e, t, n) {
  if (zt(e) && (t = e, e = void 0), e = e || "", t != null)
    return yi(e, t, n, "month");
  var r, s = [];
  for (r = 0; r < 12; r++)
    s[r] = yi(e, r, n, "month");
  return s;
}
function zs(e, t, n, r) {
  typeof e == "boolean" ? (zt(t) && (n = t, t = void 0), t = t || "") : (t = e, n = t, e = !1, zt(t) && (n = t, t = void 0), t = t || "");
  var s = Jt(), o = e ? s._week.dow : 0, l, f = [];
  if (n != null)
    return yi(t, (n + o) % 7, r, "day");
  for (l = 0; l < 7; l++)
    f[l] = yi(t, (l + o) % 7, r, "day");
  return f;
}
function nv(e, t) {
  return Hc(e, t, "months");
}
function rv(e, t) {
  return Hc(e, t, "monthsShort");
}
function iv(e, t, n) {
  return zs(e, t, n, "weekdays");
}
function av(e, t, n) {
  return zs(e, t, n, "weekdaysShort");
}
function sv(e, t, n) {
  return zs(e, t, n, "weekdaysMin");
}
sn("en", {
  eras: [
    {
      since: "0001-01-01",
      until: 1 / 0,
      offset: 1,
      name: "Anno Domini",
      narrow: "AD",
      abbr: "AD"
    },
    {
      since: "0000-12-31",
      until: -1 / 0,
      offset: 1,
      name: "Before Christ",
      narrow: "BC",
      abbr: "BC"
    }
  ],
  dayOfMonthOrdinalParse: /\d{1,2}(th|st|nd|rd)/,
  ordinal: function(e) {
    var t = e % 10, n = ce(e % 100 / 10) === 1 ? "th" : t === 1 ? "st" : t === 2 ? "nd" : t === 3 ? "rd" : "th";
    return e + n;
  }
});
R.lang = dt(
  "moment.lang is deprecated. Use moment.locale instead.",
  sn
);
R.langData = dt(
  "moment.langData is deprecated. Use moment.localeData instead.",
  Jt
);
var xt = Math.abs;
function ov() {
  var e = this._data;
  return this._milliseconds = xt(this._milliseconds), this._days = xt(this._days), this._months = xt(this._months), e.milliseconds = xt(e.milliseconds), e.seconds = xt(e.seconds), e.minutes = xt(e.minutes), e.hours = xt(e.hours), e.months = xt(e.months), e.years = xt(e.years), this;
}
function Gc(e, t, n, r) {
  var s = St(t, n);
  return e._milliseconds += r * s._milliseconds, e._days += r * s._days, e._months += r * s._months, e._bubble();
}
function uv(e, t) {
  return Gc(this, e, t, 1);
}
function cv(e, t) {
  return Gc(this, e, t, -1);
}
function au(e) {
  return e < 0 ? Math.floor(e) : Math.ceil(e);
}
function lv() {
  var e = this._milliseconds, t = this._days, n = this._months, r = this._data, s, o, l, f, w;
  return e >= 0 && t >= 0 && n >= 0 || e <= 0 && t <= 0 && n <= 0 || (e += au(cs(n) + t) * 864e5, t = 0, n = 0), r.milliseconds = e % 1e3, s = ct(e / 1e3), r.seconds = s % 60, o = ct(s / 60), r.minutes = o % 60, l = ct(o / 60), r.hours = l % 24, t += ct(l / 24), w = ct($c(t)), n += w, t -= au(cs(w)), f = ct(n / 12), n %= 12, r.days = t, r.months = n, r.years = f, this;
}
function $c(e) {
  return e * 4800 / 146097;
}
function cs(e) {
  return e * 146097 / 4800;
}
function dv(e) {
  if (!this.isValid())
    return NaN;
  var t, n, r = this._milliseconds;
  if (e = ft(e), e === "month" || e === "quarter" || e === "year")
    switch (t = this._days + r / 864e5, n = this._months + $c(t), e) {
      case "month":
        return n;
      case "quarter":
        return n / 3;
      case "year":
        return n / 12;
    }
  else
    switch (t = this._days + Math.round(cs(this._months)), e) {
      case "week":
        return t / 7 + r / 6048e5;
      case "day":
        return t + r / 864e5;
      case "hour":
        return t * 24 + r / 36e5;
      case "minute":
        return t * 1440 + r / 6e4;
      case "second":
        return t * 86400 + r / 1e3;
      case "millisecond":
        return Math.floor(t * 864e5) + r;
      default:
        throw new Error("Unknown unit " + e);
    }
}
function fv() {
  return this.isValid() ? this._milliseconds + this._days * 864e5 + this._months % 12 * 2592e6 + ce(this._months / 12) * 31536e6 : NaN;
}
function Zt(e) {
  return function() {
    return this.as(e);
  };
}
var hv = Zt("ms"), _v = Zt("s"), yv = Zt("m"), mv = Zt("h"), pv = Zt("d"), gv = Zt("w"), vv = Zt("M"), wv = Zt("Q"), Sv = Zt("y");
function bv() {
  return St(this);
}
function Mv(e) {
  return e = ft(e), this.isValid() ? this[e + "s"]() : NaN;
}
function wn(e) {
  return function() {
    return this.isValid() ? this._data[e] : NaN;
  };
}
var Ov = wn("milliseconds"), Dv = wn("seconds"), Tv = wn("minutes"), Av = wn("hours"), Ev = wn("days"), Pv = wn("months"), Nv = wn("years");
function Rv() {
  return ct(this.days() / 7);
}
var Ut = Math.round, Yn = {
  ss: 44,
  // a few seconds to seconds
  s: 45,
  // seconds to minute
  m: 45,
  // minutes to hour
  h: 22,
  // hours to day
  d: 26,
  // days to month/week
  w: null,
  // weeks to month
  M: 11
  // months to year
};
function kv(e, t, n, r, s) {
  return s.relativeTime(t || 1, !!n, e, r);
}
function Iv(e, t, n, r) {
  var s = St(e).abs(), o = Ut(s.as("s")), l = Ut(s.as("m")), f = Ut(s.as("h")), w = Ut(s.as("d")), p = Ut(s.as("M")), _ = Ut(s.as("w")), O = Ut(s.as("y")), F = o <= n.ss && ["s", o] || o < n.s && ["ss", o] || l <= 1 && ["m"] || l < n.m && ["mm", l] || f <= 1 && ["h"] || f < n.h && ["hh", f] || w <= 1 && ["d"] || w < n.d && ["dd", w];
  return n.w != null && (F = F || _ <= 1 && ["w"] || _ < n.w && ["ww", _]), F = F || p <= 1 && ["M"] || p < n.M && ["MM", p] || O <= 1 && ["y"] || ["yy", O], F[2] = t, F[3] = +e > 0, F[4] = r, kv.apply(null, F);
}
function Yv(e) {
  return e === void 0 ? Ut : typeof e == "function" ? (Ut = e, !0) : !1;
}
function Cv(e, t) {
  return Yn[e] === void 0 ? !1 : t === void 0 ? Yn[e] : (Yn[e] = t, e === "s" && (Yn.ss = t - 1), !0);
}
function jv(e, t) {
  if (!this.isValid())
    return this.localeData().invalidDate();
  var n = !1, r = Yn, s, o;
  return typeof e == "object" && (t = e, e = !1), typeof e == "boolean" && (n = e), typeof t == "object" && (r = Object.assign({}, Yn, t), t.s != null && t.ss == null && (r.ss = t.s - 1)), s = this.localeData(), o = Iv(this, !n, r, s), n && (o = s.pastFuture(+this, o)), s.postformat(o);
}
var Ha = Math.abs;
function Pn(e) {
  return (e > 0) - (e < 0) || +e;
}
function Yi() {
  if (!this.isValid())
    return this.localeData().invalidDate();
  var e = Ha(this._milliseconds) / 1e3, t = Ha(this._days), n = Ha(this._months), r, s, o, l, f = this.asSeconds(), w, p, _, O;
  return f ? (r = ct(e / 60), s = ct(r / 60), e %= 60, r %= 60, o = ct(n / 12), n %= 12, l = e ? e.toFixed(3).replace(/\.?0+$/, "") : "", w = f < 0 ? "-" : "", p = Pn(this._months) !== Pn(f) ? "-" : "", _ = Pn(this._days) !== Pn(f) ? "-" : "", O = Pn(this._milliseconds) !== Pn(f) ? "-" : "", w + "P" + (o ? p + o + "Y" : "") + (n ? p + n + "M" : "") + (t ? _ + t + "D" : "") + (s || r || e ? "T" : "") + (s ? O + s + "H" : "") + (r ? O + r + "M" : "") + (e ? O + l + "S" : "")) : "P0D";
}
var ye = ki.prototype;
ye.isValid = Ep;
ye.abs = ov;
ye.add = uv;
ye.subtract = cv;
ye.as = dv;
ye.asMilliseconds = hv;
ye.asSeconds = _v;
ye.asMinutes = yv;
ye.asHours = mv;
ye.asDays = pv;
ye.asWeeks = gv;
ye.asMonths = vv;
ye.asQuarters = wv;
ye.asYears = Sv;
ye.valueOf = fv;
ye._bubble = lv;
ye.clone = bv;
ye.get = Mv;
ye.milliseconds = Ov;
ye.seconds = Dv;
ye.minutes = Tv;
ye.hours = Av;
ye.days = Ev;
ye.weeks = Rv;
ye.months = Pv;
ye.years = Nv;
ye.humanize = jv;
ye.toISOString = Yi;
ye.toString = Yi;
ye.toJSON = Yi;
ye.locale = Ic;
ye.localeData = Cc;
ye.toIsoString = dt(
  "toIsoString() is deprecated. Please use toISOString() instead (notice the capitals)",
  Yi
);
ye.lang = Yc;
ne("X", 0, 0, "unix");
ne("x", 0, 0, "valueOf");
q("x", Ei);
q("X", im);
Me("X", function(e, t, n) {
  n._d = new Date(parseFloat(e) * 1e3);
});
Me("x", function(e, t, n) {
  n._d = new Date(ce(e));
});
//! moment.js
R.version = "2.29.4";
Ly(Ae);
R.fn = C;
R.min = Op;
R.max = Dp;
R.now = Tp;
R.utc = At;
R.unix = ev;
R.months = nv;
R.isDate = gr;
R.locale = sn;
R.invalid = Mi;
R.duration = St;
R.isMoment = wt;
R.weekdays = iv;
R.parseZone = tv;
R.localeData = Jt;
R.isDuration = ai;
R.monthsShort = rv;
R.weekdaysMin = sv;
R.defineLocale = Fs;
R.updateLocale = tp;
R.locales = np;
R.weekdaysShort = av;
R.normalizeUnits = ft;
R.relativeTimeRounding = Yv;
R.relativeTimeThreshold = Cv;
R.calendarFormat = Kp;
R.prototype = C;
R.HTML5_FMT = {
  DATETIME_LOCAL: "YYYY-MM-DDTHH:mm",
  // <input type="datetime-local" />
  DATETIME_LOCAL_SECONDS: "YYYY-MM-DDTHH:mm:ss",
  // <input type="datetime-local" step="1" />
  DATETIME_LOCAL_MS: "YYYY-MM-DDTHH:mm:ss.SSS",
  // <input type="datetime-local" step="0.001" />
  DATE: "YYYY-MM-DD",
  // <input type="date" />
  TIME: "HH:mm",
  // <input type="time" />
  TIME_SECONDS: "HH:mm:ss",
  // <input type="time" step="1" />
  TIME_MS: "HH:mm:ss.SSS",
  // <input type="time" step="0.001" />
  WEEK: "GGGG-[W]WW",
  // <input type="week" />
  MONTH: "YYYY-MM"
  // <input type="month" />
};
class He {
  static async findFirm(t) {
    return L.get(
      `/storefront/firm?${t}`
    );
  }
  static async findByAddress(t) {
    return L.post(
      "/firms/store-locator",
      {
        ...t,
        country: t.country.name
      }
    );
  }
  static async getCashRegister() {
    const t = "/firms_cashregister";
    return L.get(t);
  }
  static async getAnnouncements() {
    const t = "/firm/announcements";
    return L.get(t);
  }
  static async getNewsFeed(t) {
    const n = `/firm/newsfeed?limit=${t}`;
    return L.get(n);
  }
  static async getLastModification() {
    const t = "/firm/lastmodification";
    return L.get(t);
  }
  static async getReservations() {
    const t = "/firm/reservations";
    return L.get(t);
  }
  static async getMobileAppStoreSettings(t) {
    const n = `/firm/${t}/mobile-app-store-settings`;
    return L.get(n);
  }
  /**
   * @TODO will have to refactor this in order to have a "get" with url parameters...
   * @param date
   * @param deliveryMethod
   * @param products
   * @param categories
   * @param pickupPointId
   */
  static async getTimeslots(t, n, r, s, o) {
    const l = "/firm/timeslots";
    return L.post(l, {
      date: R(t).format("YYYY-MM-DD"),
      products: r,
      categories: s,
      delivery_method: n,
      firm_pickup_point_id: o
    });
  }
  /**
   * @TODO will have to refactor this in order to have a "get" with url parameters
   * @param timeslotsId
   * @param date
   * @param deliveryMethod
   * @param products
   * @param categories
   * @param pickupPointId
   */
  static async getTimeIntervals(t, n, r, s, o, l) {
    const f = `/firm/timeslots/${t}`;
    return L.post(f, {
      date: R(n).format("YYYY-MM-DD"),
      products: s,
      categories: o,
      delivery_method: r,
      firm_pickup_point_id: l
    });
  }
  static async checkPassword(t) {
    const n = "/firm/checkpassword";
    return L.post(n, { password: t });
  }
  static async delete(t) {
    const n = `/firm/${t}`;
    return L.delete(n);
  }
  static async create(t) {
    const n = `/firm/${t.id}`;
    return L.post(n, t);
  }
  static async partialUpdate(t) {
    const n = `/firm/${t.id}`;
    return L.patch(n, t);
  }
  static async update(t) {
    const n = `/firm/${t.id}`;
    return L.put(n, t);
  }
  static async getCustomLinks(t) {
    const n = `/storefront/firm/${t}/custom-links`;
    return L.get(n);
  }
  static async getGuestFields(t, n) {
    const r = `/storefront/delivery-methods/${t}/guest-fields`, s = {};
    return n && (s.pickupPointId = n.toString()), L.get(r, s);
  }
}
class Wb {
  static async findFirm(t) {
    try {
      const n = [];
      for (const [s, o] of Object.entries(t))
        n.push(`${s}=${o}`);
      const r = await He.findFirm(n.join("&"));
      return Promise.resolve(r);
    } catch (n) {
      return Promise.reject(n);
    }
  }
  static async findByAddress(t) {
    try {
      const n = await He.findByAddress(t);
      return Promise.resolve(n);
    } catch (n) {
      return Promise.reject(n);
    }
  }
  static async getCashRegister() {
    try {
      const t = await He.getCashRegister();
      return Promise.resolve(t);
    } catch (t) {
      return Promise.reject(t);
    }
  }
  static async getAnnouncements() {
    try {
      const t = await He.getAnnouncements();
      return Promise.resolve(t);
    } catch (t) {
      return Promise.reject(t);
    }
  }
  static async getNewsFeed(t) {
    try {
      const n = await He.getNewsFeed(t);
      return Promise.resolve(n);
    } catch (n) {
      return Promise.reject(n);
    }
  }
  static async getLastModification() {
    try {
      const t = await He.getLastModification();
      return Promise.resolve(t);
    } catch (t) {
      return Promise.reject(t);
    }
  }
  static async getReservations() {
    try {
      const t = await He.getReservations();
      return Promise.resolve(t);
    } catch (t) {
      return Promise.reject(t);
    }
  }
  static async getMobileAppStoreSettings(t) {
    try {
      const n = await He.getMobileAppStoreSettings(t);
      return Promise.resolve(n);
    } catch (n) {
      return Promise.reject(n);
    }
  }
  static async getTimeslots(t, n, r, s, o) {
    try {
      const l = await He.getTimeslots(t, n, r, s, o);
      return Promise.resolve(l);
    } catch (l) {
      return Promise.reject(l);
    }
  }
  static async getTimeIntervals(t, n, r, s, o, l) {
    try {
      const f = await He.getTimeIntervals(t, n, r, s, o, l);
      return Promise.resolve(f);
    } catch (f) {
      return Promise.reject(f);
    }
  }
  static async checkPassword(t) {
    try {
      const n = await He.checkPassword(t);
      return Promise.resolve(n);
    } catch (n) {
      return Promise.reject(n);
    }
  }
  static async delete(t) {
    try {
      return He.delete(t);
    } catch (n) {
      return Promise.reject(n);
    }
  }
  static async create(t) {
    try {
      return He.create(t);
    } catch (n) {
      return Promise.reject(n);
    }
  }
  static async partialUpdate(t) {
    try {
      return He.partialUpdate(t);
    } catch (n) {
      return Promise.reject(n);
    }
  }
  static async update(t) {
    try {
      return He.update(t);
    } catch (n) {
      return Promise.reject(n);
    }
  }
  static async getCustomLinks(t) {
    try {
      return He.getCustomLinks(t);
    } catch (n) {
      return Promise.reject(n);
    }
  }
  static async getGuestFields(t, n) {
    try {
      return He.getGuestFields(t, n);
    } catch (r) {
      return Promise.reject(r);
    }
  }
}
class Lv {
  static async getCategories() {
    const t = "/inventory/product-categories";
    return L.get(t);
  }
}
class Hb {
  static async getCategories() {
    try {
      const t = await Lv.getCategories();
      return Promise.resolve(t);
    } catch (t) {
      return Promise.reject(t);
    }
  }
}
class ir {
  static async getSuggestedLocations(t) {
    return L.get(`/storefront/marketplace/${t}/locations?suggested=true`);
  }
  static async getMarketplaceCategories(t) {
    return L.get(`/storefront/marketplace/${t}/categories`);
  }
  static async getMarketplaceLocation(t, n) {
    return L.get(`/storefront/marketplace/${t}/locations/${n}`);
  }
  static async getMarketplaceLocations(t, n, r, s) {
    const o = `/storefront/marketplace/${t}/locations`, l = {};
    return n && (l.category = n.toString()), r && (l.name = r), s.length > 0 && (l.filters = s.map((f) => f.uuid).join(",")), L.get(o, l);
  }
  static async getMarketplaceFilters(t, n) {
    return L.get(`/storefront/marketplace/${t}/filters`, { category: n });
  }
}
class Gb {
  /**
   * @TODO remove that when the endpoint will be DONE
   * https://easyorder.atlassian.net/browse/EOAPP-3343
   * @returns {Promise<Array<Location>>}
   * @param id
   */
  static async getSuggestedLocations(t) {
    try {
      const n = await ir.getSuggestedLocations(t);
      return Promise.resolve(n);
    } catch (n) {
      return Promise.reject(n);
    }
  }
  static async getMarketplaceCategories(t) {
    try {
      const n = await ir.getMarketplaceCategories(t);
      return Promise.resolve(n);
    } catch (n) {
      return Promise.reject(n);
    }
  }
  static async getMarketplaceLocation(t, n) {
    try {
      const r = await ir.getMarketplaceLocation(t, n);
      return Promise.resolve(r);
    } catch (r) {
      return Promise.reject(r);
    }
  }
  static async getMarketplaceLocations(t, n, r, s) {
    try {
      const o = await ir.getMarketplaceLocations(t, n, r, s);
      return Promise.resolve(o);
    } catch (o) {
      return Promise.reject(o);
    }
  }
  static async getMarketplaceFilters(t, n) {
    try {
      const r = await ir.getMarketplaceFilters(t, n);
      return Promise.resolve(r);
    } catch (r) {
      return Promise.reject(r);
    }
  }
}
class Je {
  static async getFirstAvailableFulfilmentTime(t) {
    const n = "/storefront/order/first-available-fulfilment-time";
    return L.post(n, t);
  }
  static async calculateTime(t) {
    const n = "/order/calculate";
    return L.post(n, t);
  }
  static async calculateOrderDelivery(t) {
    const n = "/order/calculate_delivery";
    return L.post(n, t);
  }
  static async fetchDeliveryAddressCosts(t) {
    return L.post("/storefront/fulfilment/calculate-delivery", t);
  }
  static async confirmTime(t) {
    const n = "/order/calculate?confirm=1";
    return L.post(n, t);
  }
  static async create(t) {
    const n = "/order";
    return L.post(n, {
      ...t,
      process_timestamp: R(t.process_timestamp).toISOString(!0)
    });
  }
  static async getDetails(t) {
    const n = `/orders/${t}`;
    return L.get(n);
  }
  static async delete(t) {
    const n = `/orders/${t}`;
    return L.delete(n);
  }
  static async getStatus(t) {
    const n = `/orders/${t}/payment_status`;
    return L.get(n);
  }
  static async getPendingOrders() {
    const t = "/orders/pending";
    return L.get(t);
  }
  static async getPastOrders() {
    const t = "/orders/past";
    return L.get(t);
  }
  static async getStorefrontOrders() {
    return L.get("/storefront/order/history");
  }
  static async checkCoupon(t, n) {
    const r = "/coupon";
    return L.post(r, { coupon_code: t, total: n });
  }
  static getReceivedStatus() {
    return "NEW";
  }
  static getReadyStatus() {
    return "READY";
  }
  static getPickedUpStatus() {
    return "HANDLED";
  }
}
class $b {
  static async getFirstAvailableFulfilmentTime(t) {
    try {
      const n = await Je.getFirstAvailableFulfilmentTime(t);
      return Promise.resolve(n);
    } catch (n) {
      return Promise.reject(n);
    }
  }
  static async calculateTime(t) {
    try {
      const n = await Je.calculateTime(t);
      return Promise.resolve(n);
    } catch (n) {
      return Promise.reject(n);
    }
  }
  static async calculateOrderDelivery(t) {
    try {
      const n = await Je.calculateOrderDelivery(t);
      return Promise.resolve(n);
    } catch (n) {
      return Promise.reject(n);
    }
  }
  static async fetchDeliveryAddressCosts(t) {
    try {
      const n = await Je.fetchDeliveryAddressCosts({
        ...t,
        country_id: Number(t.country.id)
      });
      return Promise.resolve(n);
    } catch (n) {
      return Promise.reject(n);
    }
  }
  static async confirmTime(t) {
    try {
      const n = await Je.confirmTime(t);
      return Promise.resolve(n);
    } catch (n) {
      return Promise.reject(n);
    }
  }
  static async create(t) {
    try {
      const n = await Je.create(t);
      return Promise.resolve(n);
    } catch (n) {
      return Promise.reject(n);
    }
  }
  static async getDetails(t) {
    try {
      const n = await Je.getDetails(t);
      return Promise.resolve(n);
    } catch (n) {
      return Promise.reject(n);
    }
  }
  static async delete(t) {
    try {
      const n = await Je.delete(t);
      return Promise.resolve(n);
    } catch (n) {
      return Promise.reject(n);
    }
  }
  static async getStatus(t) {
    try {
      const n = await Je.getStatus(t);
      return Promise.resolve(n);
    } catch (n) {
      return Promise.reject(n);
    }
  }
  static async getPendingOrders() {
    try {
      const t = await Je.getPendingOrders();
      return Promise.resolve(t);
    } catch (t) {
      return Promise.reject(t);
    }
  }
  static async getPastOrders() {
    try {
      const t = await Je.getPastOrders();
      return Promise.resolve(t);
    } catch (t) {
      return Promise.reject(t);
    }
  }
  static async getStorefrontOrders() {
    try {
      const t = await Je.getStorefrontOrders();
      return Promise.resolve(t);
    } catch (t) {
      return Promise.reject(t);
    }
  }
  static async checkCoupon(t, n) {
    try {
      const r = await Je.checkCoupon(t, n);
      return Promise.resolve(r);
    } catch (r) {
      return Promise.reject(r);
    }
  }
  static orderIsReceived(t) {
    return t === Je.getReceivedStatus();
  }
  static orderIsReady(t) {
    return t === Je.getReadyStatus();
  }
  static orderIsPickedUp(t) {
    return t === Je.getPickedUpStatus();
  }
}
var ut = /* @__PURE__ */ ((e) => (e[e.custom = -1] = "custom", e[e.ogone = 2] = "ogone", e[e.ideal = 3] = "ideal", e[e.ems = 5] = "ems", e[e.stripe = 6] = "stripe", e[e.paynovate = 9] = "paynovate", e[e.mollie = 10] = "mollie", e[e.europabank = 11] = "europabank", e[e.adyen = 12] = "adyen", e))(ut || {});
class Xr {
  static async pay(t, n, r) {
    const s = `/order/${n}/pay/${ut[t]}`;
    return L.post(s, r);
  }
  static async payOnline(t, n, r) {
    const s = `/payment/order/${n}/pay/${ut[t]}`;
    return L.post(s, r);
  }
  static async getPaymentStatus(t) {
    const n = `/orders/${t}/payment_status`;
    return L.get(n);
  }
}
class Vb {
  static async pay(t, n, r) {
    try {
      switch (t) {
        case ut.ems:
        case ut.stripe:
          return Xr.pay(t, n, this._generateBackendReturnUrls(!1, r));
        case ut.ideal:
        case ut.mollie:
        case ut.ogone:
        case ut.paynovate:
          return Xr.pay(t, n, this._generateBackendReturnUrls(!0, r));
        case ut.adyen:
        case ut.europabank:
          return Xr.payOnline(t, n, this._generateOnlinePaymentReturnUrls(r));
        case ut.custom:
          throw new Error("This payment method isn't implemented");
        default:
          throw new Error("This payment method isn't possible");
      }
    } catch (s) {
      return Promise.reject(s);
    }
  }
  static async getPaymentStatus(t) {
    try {
      const n = await Xr.getPaymentStatus(t);
      return Promise.resolve(n);
    } catch (n) {
      return Promise.reject(n);
    }
  }
  static _generateOnlinePaymentReturnUrls(t) {
    return {
      returnUrl: t.acceptUrl
    };
  }
  static _generateBackendReturnUrls(t = !1, n) {
    const { acceptUrl: r, declineUrl: s, cancelUrl: o, exceptionUrl: l } = n;
    return t ? {
      acceptUrl: r,
      declineUrl: s,
      cancelUrl: o,
      exceptionUrl: l
    } : {
      acceptUrl: `${Re.getBaseUrl()}/return-payment-ems?scheme=${r}`,
      cancelUrl: `${Re.getBaseUrl()}/return-payment-ems?scheme=${o}`,
      exceptionUrl: `${Re.getBaseUrl()}/return-payment-ems?scheme=${l}`
    };
  }
}
class mi {
  static remove(t) {
    const n = {};
    return Object.keys(t).forEach((r) => {
      typeof t[r] == "object" && t[r] !== null && !Array.isArray(t[r]) ? n[r] = mi.remove(t[r]) : t[r] !== void 0 && (n[r] = t[r]);
    }), n;
  }
}
class xv {
  static isValid(t) {
    return !t.includes("no-image");
  }
}
class br {
  static mapToKnownEntities(t, n = []) {
    this._reset();
    for (const r of n)
      this._mapProduct(r);
    for (const r of t)
      this._mapCategory(r);
    return {
      categories: this._categories,
      products: this._products,
      modifierGroups: this._modifierGroups,
      modifiers: this._modifiers
    };
  }
  static _reset() {
    this._categories = {}, this._products = {}, this._modifierGroups = {}, this._modifiers = {};
  }
  static _mapCategory(t) {
    var n;
    this._categories[t.category_id.toString()] = mi.remove({
      id: t.category_id.toString(),
      name: {
        [Re.getHeader("language")]: t.name
      },
      description: {
        [Re.getHeader("language")]: t.description
      },
      sort: t.sequence,
      useFirmTimes: t.use_firm_times,
      images: t.images,
      subcategories: t.subcategories.map((r) => r.category_id),
      availabilities: t.availabilities,
      parent_id: t.parent_category_id || null,
      products: Array.from(new Set((n = t.products) == null ? void 0 : n.map((r) => {
        var s;
        return (s = r.parent_product_groups) != null && s.length ? r.parent_product_groups : r.id;
      }).flat()).values())
    });
    for (const r of t.subcategories)
      this._mapCategory(r);
    for (const r of t.products)
      this._mapProduct(r);
  }
  static _mapProduct(t) {
    var r, s;
    const n = [
      ...t.option_groups || [],
      ...t.ingredient_groups || []
    ];
    if (this._products[t.id] = mi.remove({
      id: t.id,
      name: {
        [Re.getHeader("language")]: t.name
      },
      description: {
        [Re.getHeader("language")]: t.description
      },
      featured: t.featured,
      popular: t.popular,
      sort: t.sequence,
      unitInterval: t.unit_interval,
      images: t.images,
      modifierGroups: n.map((o) => `${t.id}-${o.id}`),
      price: t.price,
      vatPrice: t.price_vat_incl,
      unit: t.unit,
      inStock: t.in_stock,
      snooze: {
        start: t.snooze_start,
        end: t.snooze_end
      },
      weight: t.weight,
      barcode: t.barcode,
      package: t.package,
      extraPoints: t.extra_points,
      hasPromotionalPrice: t.has_promotional_price,
      categoryId: ((r = t.category) == null ? void 0 : r.category_id) || 0,
      allergens: t.allergenes,
      useFirmTimes: t.use_firm_times,
      min: t.min,
      max: t.max,
      calories: t.calories,
      promotion: t.promotion,
      subProductsId: [],
      parentProductsId: t.parent_product_groups || []
    }), (s = t.parent_product_groups) != null && s.length)
      for (const o of t.parent_product_groups)
        this._products[o].subProductsId.push(t.id);
    n.forEach((o) => {
      this._buildModifierGroup(o, t.id);
    });
  }
  static _buildModifierGroup(t, n) {
    if ("ingredients" in t) {
      this._buildModifierGroupFromIngredientGroup(t, n);
      return;
    }
    this._buildModifierGroupFromOptionGroup(t, n);
  }
  static _buildModifierGroupFromIngredientGroup(t, n) {
    this._modifierGroups[this._buildModifierGroupId(n, t)] = {
      id: this._buildModifierGroupId(n, t),
      type: "selection",
      sort: t.sequence,
      name: {
        [Re.getHeader("language")]: t.name
      },
      description: {
        [Re.getHeader("language")]: t.description
      },
      isRequired: t.min_selection > 0,
      isUpsellGroup: !1,
      isMultipleChoice: !0,
      multipleChoiceMin: t.min_selection,
      multipleChoiceMax: t.max_selection,
      multipleChoiceMultiselectMax: t.multi_max,
      multipleChoiceInterval: 1,
      modifiers: t.ingredients.map((r) => this._buildModifierId(this._buildModifierGroupId(n, t), r))
    }, t.ingredients.forEach((r) => this._buildModifierFromIngredientOrOption(r, this._buildModifierId(this._buildModifierGroupId(n, t), r)));
  }
  static _buildModifierGroupFromOptionGroup(t, n) {
    this._modifierGroups[this._buildModifierGroupId(n, t)] = {
      id: this._buildModifierGroupId(n, t),
      type: "selection",
      sort: t.sequence,
      name: {
        [Re.getHeader("language")]: t.name
      },
      description: {
        [Re.getHeader("language")]: t.description
      },
      isRequired: !0,
      isUpsellGroup: !1,
      isMultipleChoice: !1,
      multipleChoiceMin: null,
      multipleChoiceMax: null,
      multipleChoiceMultiselectMax: null,
      multipleChoiceInterval: null,
      modifiers: t.options.map((r) => this._buildModifierId(this._buildModifierGroupId(n, t), r))
    }, t.options.map((r) => this._buildModifierFromIngredientOrOption(r, this._buildModifierId(this._buildModifierGroupId(n, t), r)));
  }
  static _buildModifierFromIngredientOrOption(t, n) {
    this._modifiers[n] = {
      id: n,
      isDefault: t.selected,
      isLocked: !1,
      sort: 0,
      inStock: t.in_stock,
      name: {
        [Re.getHeader("language")]: t.name
      },
      description: {
        [Re.getHeader("language")]: t.description || ""
      },
      price: t.price,
      snooze: {
        start: t.snooze_start,
        end: t.snooze_end
      },
      images: t.image ? [this._mapImage(t.image)] : [],
      ingredientType: "type" in t ? t.type : "",
      type: "ingredient_group_id" in t ? "ingredient" : "option",
      allergens: []
    };
  }
  static _buildModifierGroupId(t, n) {
    return `${t}-${n.id.toString()}`;
  }
  static _buildModifierId(t, n) {
    return `${t}-${n.id.toString()}`;
  }
  static _mapImage(t) {
    return {
      main: !1,
      detail: t,
      thumb: t,
      is_default: xv.isValid(t)
    };
  }
}
br._categories = {};
br._products = {};
br._modifierGroups = {};
br._modifiers = {};
class su {
  static async getProductData() {
    const t = "/sync/products";
    return L.get(t);
  }
  static async getCategory(t) {
    const n = `/category/${t}`;
    return L.get(n);
  }
}
class zb {
  static async getProductData() {
    try {
      const { data: t, product_groups: n } = await su.getProductData();
      return Promise.resolve(br.mapToKnownEntities(t, n));
    } catch (t) {
      return Promise.reject(t);
    }
  }
  static async getCategory(t) {
    try {
      const { data: n } = await su.getCategory(t);
      return Promise.resolve(n);
    } catch (n) {
      return Promise.reject(n);
    }
  }
}
class ou {
  static async getTimeslots(t) {
    const n = "/firm/reservations/time";
    return L.post(n, t);
  }
  static async confirmReservation(t) {
    const n = "/firm/reservations/confirm";
    return L.post(n, t);
  }
}
class qb {
  static async getTimeslots(t) {
    try {
      const n = await ou.getTimeslots(t);
      return Promise.resolve(n);
    } catch (n) {
      return Promise.reject(n);
    }
  }
  static async confirmReservation(t) {
    try {
      const n = await ou.confirmReservation(t);
      return Promise.resolve(n);
    } catch (n) {
      return Promise.reject(n);
    }
  }
}
let ei;
const Fv = new Uint8Array(16);
function Bv() {
  if (!ei && (ei = typeof crypto < "u" && crypto.getRandomValues && crypto.getRandomValues.bind(crypto), !ei))
    throw new Error("crypto.getRandomValues() not supported. See https://github.com/uuidjs/uuid#getrandomvalues-not-supported");
  return ei(Fv);
}
const Ge = [];
for (let e = 0; e < 256; ++e)
  Ge.push((e + 256).toString(16).slice(1));
function Uv(e, t = 0) {
  return (Ge[e[t + 0]] + Ge[e[t + 1]] + Ge[e[t + 2]] + Ge[e[t + 3]] + "-" + Ge[e[t + 4]] + Ge[e[t + 5]] + "-" + Ge[e[t + 6]] + Ge[e[t + 7]] + "-" + Ge[e[t + 8]] + Ge[e[t + 9]] + "-" + Ge[e[t + 10]] + Ge[e[t + 11]] + Ge[e[t + 12]] + Ge[e[t + 13]] + Ge[e[t + 14]] + Ge[e[t + 15]]).toLowerCase();
}
const Wv = typeof crypto < "u" && crypto.randomUUID && crypto.randomUUID.bind(crypto), uu = {
  randomUUID: Wv
};
function Hv(e, t, n) {
  if (uu.randomUUID && !t && !e)
    return uu.randomUUID();
  e = e || {};
  const r = e.random || (e.rng || Bv)();
  if (r[6] = r[6] & 15 | 64, r[8] = r[8] & 63 | 128, t) {
    n = n || 0;
    for (let s = 0; s < 16; ++s)
      t[n + s] = r[s];
    return t;
  }
  return Uv(r);
}
class Jb {
  static generate() {
    return Hv();
  }
}
var cu = "inversify:paramtypes", Gv = "design:paramtypes", $v = "Cannot apply @injectable decorator multiple times.";
function Vv() {
  return function(e) {
    if (Reflect.hasOwnMetadata(cu, e))
      throw new Error($v);
    var t = Reflect.getMetadata(Gv, e) || [];
    return Reflect.defineMetadata(cu, t, e), e;
  };
}
const ls = (e, t) => t.some((n) => e instanceof n);
let lu, du;
function zv() {
  return lu || (lu = [
    IDBDatabase,
    IDBObjectStore,
    IDBIndex,
    IDBCursor,
    IDBTransaction
  ]);
}
function qv() {
  return du || (du = [
    IDBCursor.prototype.advance,
    IDBCursor.prototype.continue,
    IDBCursor.prototype.continuePrimaryKey
  ]);
}
const ds = /* @__PURE__ */ new WeakMap(), Ga = /* @__PURE__ */ new WeakMap(), Ci = /* @__PURE__ */ new WeakMap();
function Jv(e) {
  const t = new Promise((n, r) => {
    const s = () => {
      e.removeEventListener("success", o), e.removeEventListener("error", l);
    }, o = () => {
      n(pn(e.result)), s();
    }, l = () => {
      r(e.error), s();
    };
    e.addEventListener("success", o), e.addEventListener("error", l);
  });
  return Ci.set(t, e), t;
}
function Zv(e) {
  if (ds.has(e))
    return;
  const t = new Promise((n, r) => {
    const s = () => {
      e.removeEventListener("complete", o), e.removeEventListener("error", l), e.removeEventListener("abort", l);
    }, o = () => {
      n(), s();
    }, l = () => {
      r(e.error || new DOMException("AbortError", "AbortError")), s();
    };
    e.addEventListener("complete", o), e.addEventListener("error", l), e.addEventListener("abort", l);
  });
  ds.set(e, t);
}
let fs = {
  get(e, t, n) {
    if (e instanceof IDBTransaction) {
      if (t === "done")
        return ds.get(e);
      if (t === "store")
        return n.objectStoreNames[1] ? void 0 : n.objectStore(n.objectStoreNames[0]);
    }
    return pn(e[t]);
  },
  set(e, t, n) {
    return e[t] = n, !0;
  },
  has(e, t) {
    return e instanceof IDBTransaction && (t === "done" || t === "store") ? !0 : t in e;
  }
};
function Vc(e) {
  fs = e(fs);
}
function Kv(e) {
  return qv().includes(e) ? function(...t) {
    return e.apply(hs(this), t), pn(this.request);
  } : function(...t) {
    return pn(e.apply(hs(this), t));
  };
}
function Qv(e) {
  return typeof e == "function" ? Kv(e) : (e instanceof IDBTransaction && Zv(e), ls(e, zv()) ? new Proxy(e, fs) : e);
}
function pn(e) {
  if (e instanceof IDBRequest)
    return Jv(e);
  if (Ga.has(e))
    return Ga.get(e);
  const t = Qv(e);
  return t !== e && (Ga.set(e, t), Ci.set(t, e)), t;
}
const hs = (e) => Ci.get(e);
function Xv(e, t, { blocked: n, upgrade: r, blocking: s, terminated: o } = {}) {
  const l = indexedDB.open(e, t), f = pn(l);
  return r && l.addEventListener("upgradeneeded", (w) => {
    r(pn(l.result), w.oldVersion, w.newVersion, pn(l.transaction), w);
  }), n && l.addEventListener("blocked", (w) => n(
    // Casting due to https://github.com/microsoft/TypeScript-DOM-lib-generator/pull/1405
    w.oldVersion,
    w.newVersion,
    w
  )), f.then((w) => {
    o && w.addEventListener("close", () => o()), s && w.addEventListener("versionchange", (p) => s(p.oldVersion, p.newVersion, p));
  }).catch(() => {
  }), f;
}
const ew = ["get", "getKey", "getAll", "getAllKeys", "count"], tw = ["put", "add", "delete", "clear"], $a = /* @__PURE__ */ new Map();
function fu(e, t) {
  if (!(e instanceof IDBDatabase && !(t in e) && typeof t == "string"))
    return;
  if ($a.get(t))
    return $a.get(t);
  const n = t.replace(/FromIndex$/, ""), r = t !== n, s = tw.includes(n);
  if (
    // Bail if the target doesn't exist on the target. Eg, getAll isn't in Edge.
    !(n in (r ? IDBIndex : IDBObjectStore).prototype) || !(s || ew.includes(n))
  )
    return;
  const o = async function(l, ...f) {
    const w = this.transaction(l, s ? "readwrite" : "readonly");
    let p = w.store;
    return r && (p = p.index(f.shift())), (await Promise.all([
      p[n](...f),
      s && w.done
    ]))[0];
  };
  return $a.set(t, o), o;
}
Vc((e) => ({
  ...e,
  get: (t, n, r) => fu(t, n) || e.get(t, n, r),
  has: (t, n) => !!fu(t, n) || e.has(t, n)
}));
const nw = ["continue", "continuePrimaryKey", "advance"], hu = {}, _s = /* @__PURE__ */ new WeakMap(), zc = /* @__PURE__ */ new WeakMap(), rw = {
  get(e, t) {
    if (!nw.includes(t))
      return e[t];
    let n = hu[t];
    return n || (n = hu[t] = function(...r) {
      _s.set(this, zc.get(this)[t](...r));
    }), n;
  }
};
async function* iw(...e) {
  let t = this;
  if (t instanceof IDBCursor || (t = await t.openCursor(...e)), !t)
    return;
  t = t;
  const n = new Proxy(t, rw);
  for (zc.set(n, t), Ci.set(n, hs(t)); t; )
    yield n, t = await (_s.get(n) || t.continue()), _s.delete(n);
}
function _u(e, t) {
  return t === Symbol.asyncIterator && ls(e, [IDBIndex, IDBObjectStore, IDBCursor]) || t === "iterate" && ls(e, [IDBIndex, IDBObjectStore]);
}
Vc((e) => ({
  ...e,
  get(t, n, r) {
    return _u(t, n) ? iw : e.get(t, n, r);
  },
  has(t, n) {
    return _u(t, n) || e.has(t, n);
  }
}));
var aw = Object.defineProperty, sw = Object.getOwnPropertyDescriptor, ow = (e, t, n, r) => {
  for (var s = r > 1 ? void 0 : r ? sw(t, n) : t, o = e.length - 1, l; o >= 0; o--)
    (l = e[o]) && (s = (r ? l(t, n, s) : l(s)) || s);
  return r && s && aw(t, n, s), s;
};
let ys = class {
  constructor() {
    this._store = void 0;
  }
  async createStore() {
    this._store = await Xv("easy-order", 1, {
      upgrade(e) {
        e.createObjectStore("app");
      }
    });
  }
  async delMany(e) {
    this._store || await this.createStore();
    const t = this._store.transaction("app", "readwrite"), n = t.objectStore("app");
    for (const r of e)
      await n.delete(r);
    return t.done;
  }
  async get(e) {
    return this._store || await this.createStore(), this._store.get("app", e);
  }
  async set(e, t) {
    return this._store || await this.createStore(), this._store.put("app", t, e);
  }
};
ys = ow([
  Vv()
], ys);
class Zb {
  constructor(t) {
    this._announcements = t, this._indexDbService = new ys();
  }
  async getSeenAnnouncementsId() {
    return await this._indexDbService.get("seen-announcements") || [];
  }
  async getUnseenAnnouncements() {
    const t = await this.getSeenAnnouncementsId();
    return this._announcements.filter(
      (n) => t.indexOf(n.id) === -1
    );
  }
  async markAllAsSeen() {
    const t = await this.getSeenAnnouncementsId();
    await this._indexDbService.set("seen-announcements", [
      ...t,
      ...this._announcements.map((n) => n.id)
    ]);
  }
}
const uw = (e) => ji(e) && !Jc(e), qs = (e) => gn(e, /iPad/i) ? !0 : gn(e, /Macintosh/i) && ji(e), cw = (e) => gn(e, /iPhone/i), lw = (e) => gn(e, /iPhone|iPod/i) || qs(e), qc = (e) => gn(e, /android|sink/i), dw = (e) => qc(e) && !gn(e, /mobile/i), fw = (e) => {
  const t = e.innerWidth, n = e.innerHeight, r = Math.min(t, n), s = Math.max(t, n);
  return r > 390 && r < 520 && s > 620 && s < 800;
}, hw = (e) => {
  const t = e.innerWidth, n = e.innerHeight, r = Math.min(t, n), s = Math.max(t, n);
  return qs(e) || dw(e) || r > 460 && r < 820 && s > 780 && s < 1400;
}, ji = (e) => pw(e, "(any-pointer:coarse)"), _w = (e) => !ji(e), Jc = (e) => Zc(e) || Kc(e), Zc = (e) => !!(e.cordova || e.phonegap || e.PhoneGap), Kc = (e) => {
  const t = e.Capacitor;
  return !!(t != null && t.isNative);
}, yw = (e) => gn(e, /electron/i), mw = (e) => {
  var t;
  return ((t = e.matchMedia) == null ? void 0 : t.call(e, "(display-mode: standalone)").matches) || e.navigator.standalone;
}, gn = (e, t) => t.test(e.navigator.userAgent), pw = (e, t) => {
  var n;
  return (n = e.matchMedia) == null ? void 0 : n.call(e, t).matches;
}, yu = (e = window) => ({
  isIpad: qs(e),
  isIphone: cw(e),
  isIOS: lw(e),
  isAndroid: qc(e),
  isPhablet: fw(e),
  isTablet: hw(e),
  isCordova: Zc(e),
  isCapacitorNative: Kc(e),
  isElectron: yw(e),
  isPWA: mw(e),
  isMobile: ji(e),
  isMobileWeb: uw(e),
  isDesktop: _w(e),
  isHybrid: Jc(e)
});
class Kb {
  constructor(t, n) {
    this._indexDbService = t, this._firm = n;
  }
  /**
   * This method will check if we're on a multifim and if an update of the app is needed
   * @returns {boolean}
   */
  getAppHref() {
    return this._getPlatformAppHref(this._firm);
  }
  /**
   * This method will check if we're on a multifim and if an update of the app is needed
   * if the dashboard says 0 it means it's disabled
   * @returns {boolean}
   */
  isCurrentVersionLowerThanTheLatest(t) {
    const n = this._getPlatformVersion(this._firm);
    return !n || n === "0" ? !1 : this._compareVersion(n, t);
  }
  async isLiveUpdateAvailable(t, n) {
    const r = await this._indexDbService.get("inAppVersion"), s = await Cy.getLatest(t);
    return s === null ? {
      needsUpdate: !1,
      latestVersion: ""
    } : r ? {
      needsUpdate: this._isCurrentVersionDifferentThanTheOneAssigned(r, s),
      latestVersion: s
    } : {
      needsUpdate: this._isCurrentVersionDifferentThanTheOneAssigned(n, s),
      latestVersion: s
    };
  }
  _isCurrentVersionDifferentThanTheOneAssigned(t, n) {
    const [r, s, o] = t.split("."), [l, f, w] = n.split(".");
    return r !== l ? !1 : s !== f || o !== w;
  }
  /**
   * This method will compare versions
   * it also support comparing 2 digits with 3digits and so
   * @param {string} minimal
   * @param {string} actual
   * @returns {boolean}
   */
  _compareVersion(t, n) {
    const [r, s, o] = t.split("."), [l, f, w] = n.split(".");
    return parseInt(r) !== parseInt(l) ? parseInt(r) > parseInt(l) : parseInt(s) !== parseInt(f) ? parseInt(s) > parseInt(f) : parseInt(o) > parseInt(w);
  }
  /**
   * This method will return the version depending on the platform
   * @param {AppVersions} version
   * @returns {string}
   */
  _getPlatformVersion(t) {
    return yu().isAndroid ? t == null ? void 0 : t.android_app_version : t == null ? void 0 : t.ios_app_version;
  }
  /**
   * This method will return the store url depending on the platform
   * @param {AppVersions} version
   * @returns {string}
   */
  _getPlatformAppHref(t) {
    return yu().isAndroid ? t == null ? void 0 : t.android_app_url : t == null ? void 0 : t.ios_app_url;
  }
}
class Qb {
  constructor(t, n = null) {
    this._firm = null, this._customer = null, this._firm = t, this._customer = n;
  }
  getCurrentPointsPercentage() {
    return !this.isLoyaltyEnabled() || !this._customer ? 0 : this._firm.settings.loyalty.points_required === 0 ? 100 : this._customer.loyalty.current_points / this._firm.settings.loyalty.points_required * 100;
  }
  getPointsLeftToEarn() {
    return this.isLoyaltyEnabled() ? this._customer ? this._firm.settings.loyalty.points_required - this._customer.loyalty.current_points : this._firm.settings.loyalty.points_required : 0;
  }
  getRequiredPoints() {
    return this.isLoyaltyEnabled() ? this._firm.settings.loyalty.points_required : 0;
  }
  getPointPerOrderPrice() {
    var t, n;
    return ((n = (t = this._firm) == null ? void 0 : t.settings.loyalty) == null ? void 0 : n.point_per_order_price) || 0;
  }
  isLoyaltyEnabled() {
    var t;
    return this._firm ? this._firm.settings.modules.use_loyalty && !!((t = this._firm.settings.loyalty) != null && t.active) : !1;
  }
  getLoyaltyProductFromList(t) {
    var n, r, s, o;
    return !this.isLoyaltyEnabled() || !this._customer || this.isLoyaltyDiscount() || !((n = this._customer.loyalty) != null && n.add_loyalty_to_order) ? null : ((o = (s = (r = this._firm) == null ? void 0 : r.settings) == null ? void 0 : s.loyalty) == null ? void 0 : o.loyalty_type) === "product" ? t.find((l) => {
      var f, w, p, _;
      return l.id === ((_ = (p = (w = (f = this._firm) == null ? void 0 : f.settings) == null ? void 0 : w.loyalty) == null ? void 0 : p.free_product) == null ? void 0 : _.id);
    }) || null : t.find((l) => {
      var f, w, p, _;
      return l.categoryId === ((_ = (p = (w = (f = this._firm) == null ? void 0 : f.settings) == null ? void 0 : w.loyalty) == null ? void 0 : p.free_product_category) == null ? void 0 : _.id);
    }) || null;
  }
  isProductEligible(t) {
    var n, r, s, o, l, f, w, p, _, O, F, H;
    return !this.isLoyaltyEnabled() || !this._customer || this.isLoyaltyDiscount() || !((n = this._customer.loyalty) != null && n.add_loyalty_to_order) ? !1 : ((o = (s = (r = this._firm) == null ? void 0 : r.settings) == null ? void 0 : s.loyalty) == null ? void 0 : o.loyalty_type) === "product" ? t.id === ((p = (w = (f = (l = this._firm) == null ? void 0 : l.settings) == null ? void 0 : f.loyalty) == null ? void 0 : w.free_product) == null ? void 0 : p.id) : t.categoryId === ((H = (F = (O = (_ = this._firm) == null ? void 0 : _.settings) == null ? void 0 : O.loyalty) == null ? void 0 : F.free_product_category) == null ? void 0 : H.id);
  }
  isLoyaltyDiscount() {
    var t, n, r;
    return this.isLoyaltyEnabled() ? ((r = (n = (t = this._firm) == null ? void 0 : t.settings) == null ? void 0 : n.loyalty) == null ? void 0 : r.loyalty_type) === "discount" : !1;
  }
}
function Sn(e) {
  throw new Error('Could not dynamically require "' + e + '". Please configure the dynamicRequireTargets or/and ignoreDynamicRequires option of @rollup/plugin-commonjs appropriately for this require call to work.');
}
var pi = {}, gw = {
  get exports() {
    return pi;
  },
  set exports(e) {
    pi = e;
  }
}, mu;
function Gn() {
  return mu || (mu = 1, function(e, t) {
    (function(n, r) {
      e.exports = r();
    })(b, function() {
      var n;
      function r() {
        return n.apply(null, arguments);
      }
      function s(i) {
        n = i;
      }
      function o(i) {
        return i instanceof Array || Object.prototype.toString.call(i) === "[object Array]";
      }
      function l(i) {
        return i != null && Object.prototype.toString.call(i) === "[object Object]";
      }
      function f(i, a) {
        return Object.prototype.hasOwnProperty.call(i, a);
      }
      function w(i) {
        if (Object.getOwnPropertyNames)
          return Object.getOwnPropertyNames(i).length === 0;
        var a;
        for (a in i)
          if (f(i, a))
            return !1;
        return !0;
      }
      function p(i) {
        return i === void 0;
      }
      function _(i) {
        return typeof i == "number" || Object.prototype.toString.call(i) === "[object Number]";
      }
      function O(i) {
        return i instanceof Date || Object.prototype.toString.call(i) === "[object Date]";
      }
      function F(i, a) {
        var u = [], c, d = i.length;
        for (c = 0; c < d; ++c)
          u.push(a(i[c], c));
        return u;
      }
      function H(i, a) {
        for (var u in a)
          f(a, u) && (i[u] = a[u]);
        return f(a, "toString") && (i.toString = a.toString), f(a, "valueOf") && (i.valueOf = a.valueOf), i;
      }
      function E(i, a, u, c) {
        return vo(i, a, u, c, !0).utc();
      }
      function $() {
        return {
          empty: !1,
          unusedTokens: [],
          unusedInput: [],
          overflow: -2,
          charsLeftOver: 0,
          nullInput: !1,
          invalidEra: null,
          invalidMonth: null,
          invalidFormat: !1,
          userInvalidated: !1,
          iso: !1,
          parsedDateParts: [],
          era: null,
          meridiem: null,
          rfc2822: !1,
          weekdayMismatch: !1
        };
      }
      function W(i) {
        return i._pf == null && (i._pf = $()), i._pf;
      }
      var ve;
      Array.prototype.some ? ve = Array.prototype.some : ve = function(i) {
        var a = Object(this), u = a.length >>> 0, c;
        for (c = 0; c < u; c++)
          if (c in a && i.call(this, a[c], c, a))
            return !0;
        return !1;
      };
      function Z(i) {
        if (i._isValid == null) {
          var a = W(i), u = ve.call(a.parsedDateParts, function(d) {
            return d != null;
          }), c = !isNaN(i._d.getTime()) && a.overflow < 0 && !a.empty && !a.invalidEra && !a.invalidMonth && !a.invalidWeekday && !a.weekdayMismatch && !a.nullInput && !a.invalidFormat && !a.userInvalidated && (!a.meridiem || a.meridiem && u);
          if (i._strict && (c = c && a.charsLeftOver === 0 && a.unusedTokens.length === 0 && a.bigHour === void 0), Object.isFrozen == null || !Object.isFrozen(i))
            i._isValid = c;
          else
            return c;
        }
        return i._isValid;
      }
      function U(i) {
        var a = E(NaN);
        return i != null ? H(W(a), i) : W(a).userInvalidated = !0, a;
      }
      var K = r.momentProperties = [], ie = !1;
      function A(i, a) {
        var u, c, d, m = K.length;
        if (p(a._isAMomentObject) || (i._isAMomentObject = a._isAMomentObject), p(a._i) || (i._i = a._i), p(a._f) || (i._f = a._f), p(a._l) || (i._l = a._l), p(a._strict) || (i._strict = a._strict), p(a._tzm) || (i._tzm = a._tzm), p(a._isUTC) || (i._isUTC = a._isUTC), p(a._offset) || (i._offset = a._offset), p(a._pf) || (i._pf = W(a)), p(a._locale) || (i._locale = a._locale), m > 0)
          for (u = 0; u < m; u++)
            c = K[u], d = a[c], p(d) || (i[c] = d);
        return i;
      }
      function y(i) {
        A(this, i), this._d = new Date(i._d != null ? i._d.getTime() : NaN), this.isValid() || (this._d = /* @__PURE__ */ new Date(NaN)), ie === !1 && (ie = !0, r.updateOffset(this), ie = !1);
      }
      function h(i) {
        return i instanceof y || i != null && i._isAMomentObject != null;
      }
      function v(i) {
        r.suppressDeprecationWarnings === !1 && typeof console < "u" && console.warn && console.warn("Deprecation warning: " + i);
      }
      function S(i, a) {
        var u = !0;
        return H(function() {
          if (r.deprecationHandler != null && r.deprecationHandler(null, i), u) {
            var c = [], d, m, T, te = arguments.length;
            for (m = 0; m < te; m++) {
              if (d = "", typeof arguments[m] == "object") {
                d += `
[` + m + "] ";
                for (T in arguments[0])
                  f(arguments[0], T) && (d += T + ": " + arguments[0][T] + ", ");
                d = d.slice(0, -2);
              } else
                d = arguments[m];
              c.push(d);
            }
            v(
              i + `
Arguments: ` + Array.prototype.slice.call(c).join("") + `
` + new Error().stack
            ), u = !1;
          }
          return a.apply(this, arguments);
        }, a);
      }
      var g = {};
      function P(i, a) {
        r.deprecationHandler != null && r.deprecationHandler(i, a), g[i] || (v(a), g[i] = !0);
      }
      r.suppressDeprecationWarnings = !1, r.deprecationHandler = null;
      function k(i) {
        return typeof Function < "u" && i instanceof Function || Object.prototype.toString.call(i) === "[object Function]";
      }
      function j(i) {
        var a, u;
        for (u in i)
          f(i, u) && (a = i[u], k(a) ? this[u] = a : this["_" + u] = a);
        this._config = i, this._dayOfMonthOrdinalParseLenient = new RegExp(
          (this._dayOfMonthOrdinalParse.source || this._ordinalParse.source) + "|" + /\d{1,2}/.source
        );
      }
      function G(i, a) {
        var u = H({}, i), c;
        for (c in a)
          f(a, c) && (l(i[c]) && l(a[c]) ? (u[c] = {}, H(u[c], i[c]), H(u[c], a[c])) : a[c] != null ? u[c] = a[c] : delete u[c]);
        for (c in i)
          f(i, c) && !f(a, c) && l(i[c]) && (u[c] = H({}, u[c]));
        return u;
      }
      function Q(i) {
        i != null && this.set(i);
      }
      var le;
      Object.keys ? le = Object.keys : le = function(i) {
        var a, u = [];
        for (a in i)
          f(i, a) && u.push(a);
        return u;
      };
      var J = {
        sameDay: "[Today at] LT",
        nextDay: "[Tomorrow at] LT",
        nextWeek: "dddd [at] LT",
        lastDay: "[Yesterday at] LT",
        lastWeek: "[Last] dddd [at] LT",
        sameElse: "L"
      };
      function Ie(i, a, u) {
        var c = this._calendar[i] || this._calendar.sameElse;
        return k(c) ? c.call(a, u) : c;
      }
      function xe(i, a, u) {
        var c = "" + Math.abs(i), d = a - c.length, m = i >= 0;
        return (m ? u ? "+" : "" : "-") + Math.pow(10, Math.max(0, d)).toString().substr(1) + c;
      }
      var ke = /(\[[^\[]*\])|(\\)?([Hh]mm(ss)?|Mo|MM?M?M?|Do|DDDo|DD?D?D?|ddd?d?|do?|w[o|w]?|W[o|W]?|Qo?|N{1,5}|YYYYYY|YYYYY|YYYY|YY|y{2,4}|yo?|gg(ggg?)?|GG(GGG?)?|e|E|a|A|hh?|HH?|kk?|mm?|ss?|S{1,9}|x|X|zz?|ZZ?|.)/g, ht = /(\[[^\[]*\])|(\\)?(LTS|LT|LL?L?L?|l{1,4})/g, $n = {}, Oe = {};
      function X(i, a, u, c) {
        var d = c;
        typeof c == "string" && (d = function() {
          return this[c]();
        }), i && (Oe[i] = d), a && (Oe[a[0]] = function() {
          return xe(d.apply(this, arguments), a[1], a[2]);
        }), u && (Oe[u] = function() {
          return this.localeData().ordinal(
            d.apply(this, arguments),
            i
          );
        });
      }
      function ca(i) {
        return i.match(/\[[\s\S]/) ? i.replace(/^\[|\]$/g, "") : i.replace(/\\/g, "");
      }
      function la(i) {
        var a = i.match(ke), u, c;
        for (u = 0, c = a.length; u < c; u++)
          Oe[a[u]] ? a[u] = Oe[a[u]] : a[u] = ca(a[u]);
        return function(d) {
          var m = "", T;
          for (T = 0; T < c; T++)
            m += k(a[T]) ? a[T].call(d, i) : a[T];
          return m;
        };
      }
      function bn(i, a) {
        return i.isValid() ? (a = ot(a, i.localeData()), $n[a] = $n[a] || la(a), $n[a](i)) : i.localeData().invalidDate();
      }
      function ot(i, a) {
        var u = 5;
        function c(d) {
          return a.longDateFormat(d) || d;
        }
        for (ht.lastIndex = 0; u >= 0 && ht.test(i); )
          i = i.replace(
            ht,
            c
          ), ht.lastIndex = 0, u -= 1;
        return i;
      }
      var Nr = {
        LTS: "h:mm:ss A",
        LT: "h:mm A",
        L: "MM/DD/YYYY",
        LL: "MMMM D, YYYY",
        LLL: "MMMM D, YYYY h:mm A",
        LLLL: "dddd, MMMM D, YYYY h:mm A"
      };
      function Kt(i) {
        var a = this._longDateFormat[i], u = this._longDateFormat[i.toUpperCase()];
        return a || !u ? a : (this._longDateFormat[i] = u.match(ke).map(function(c) {
          return c === "MMMM" || c === "MM" || c === "DD" || c === "dddd" ? c.slice(1) : c;
        }).join(""), this._longDateFormat[i]);
      }
      var Rr = "Invalid date";
      function da() {
        return this._invalidDate;
      }
      var kr = "%d", fa = /\d{1,2}/;
      function ha(i) {
        return this._ordinal.replace("%d", i);
      }
      var _a = {
        future: "in %s",
        past: "%s ago",
        s: "a few seconds",
        ss: "%d seconds",
        m: "a minute",
        mm: "%d minutes",
        h: "an hour",
        hh: "%d hours",
        d: "a day",
        dd: "%d days",
        w: "a week",
        ww: "%d weeks",
        M: "a month",
        MM: "%d months",
        y: "a year",
        yy: "%d years"
      };
      function ya(i, a, u, c) {
        var d = this._relativeTime[u];
        return k(d) ? d(i, a, u, c) : d.replace(/%d/i, i);
      }
      function Vn(i, a) {
        var u = this._relativeTime[i > 0 ? "future" : "past"];
        return k(u) ? u(a) : u.replace(/%s/i, a);
      }
      var on = {};
      function Ue(i, a) {
        var u = i.toLowerCase();
        on[u] = on[u + "s"] = on[a] = i;
      }
      function tt(i) {
        return typeof i == "string" ? on[i] || on[i.toLowerCase()] : void 0;
      }
      function un(i) {
        var a = {}, u, c;
        for (c in i)
          f(i, c) && (u = tt(c), u && (a[u] = i[c]));
        return a;
      }
      var M = {};
      function D(i, a) {
        M[i] = a;
      }
      function I(i) {
        var a = [], u;
        for (u in i)
          f(i, u) && a.push({ unit: u, priority: M[u] });
        return a.sort(function(c, d) {
          return c.priority - d.priority;
        }), a;
      }
      function B(i) {
        return i % 4 === 0 && i % 100 !== 0 || i % 400 === 0;
      }
      function V(i) {
        return i < 0 ? Math.ceil(i) || 0 : Math.floor(i);
      }
      function ee(i) {
        var a = +i, u = 0;
        return a !== 0 && isFinite(a) && (u = V(a)), u;
      }
      function ae(i, a) {
        return function(u) {
          return u != null ? (re(this, i, u), r.updateOffset(this, a), this) : se(this, i);
        };
      }
      function se(i, a) {
        return i.isValid() ? i._d["get" + (i._isUTC ? "UTC" : "") + a]() : NaN;
      }
      function re(i, a, u) {
        i.isValid() && !isNaN(u) && (a === "FullYear" && B(i.year()) && i.month() === 1 && i.date() === 29 ? (u = ee(u), i._d["set" + (i._isUTC ? "UTC" : "") + a](
          u,
          i.month(),
          Fr(u, i.month())
        )) : i._d["set" + (i._isUTC ? "UTC" : "") + a](u));
      }
      function de(i) {
        return i = tt(i), k(this[i]) ? this[i]() : this;
      }
      function fe(i, a) {
        if (typeof i == "object") {
          i = un(i);
          var u = I(i), c, d = u.length;
          for (c = 0; c < d; c++)
            this[u[c].unit](i[u[c].unit]);
        } else if (i = tt(i), k(this[i]))
          return this[i](a);
        return this;
      }
      var _t = /\d/, ze = /\d\d/, eo = /\d{3}/, ma = /\d{4}/, Ir = /[+-]?\d{6}/, De = /\d\d?/, to = /\d\d\d\d?/, no = /\d\d\d\d\d\d?/, Yr = /\d{1,3}/, pa = /\d{1,4}/, Cr = /[+-]?\d{1,6}/, Mn = /\d+/, jr = /[+-]?\d+/, Pl = /Z|[+-]\d\d:?\d\d/gi, Lr = /Z|[+-]\d\d(?::?\d\d)?/gi, Nl = /[+-]?\d+(\.\d{1,3})?/, zn = /[0-9]{0,256}['a-z\u00A0-\u05FF\u0700-\uD7FF\uF900-\uFDCF\uFDF0-\uFF07\uFF10-\uFFEF]{1,256}|[\u0600-\u06FF\/]{1,256}(\s*?[\u0600-\u06FF]{1,256}){1,2}/i, xr;
      xr = {};
      function z(i, a, u) {
        xr[i] = k(a) ? a : function(c, d) {
          return c && u ? u : a;
        };
      }
      function Rl(i, a) {
        return f(xr, i) ? xr[i](a._strict, a._locale) : new RegExp(kl(i));
      }
      function kl(i) {
        return rt(
          i.replace("\\", "").replace(
            /\\(\[)|\\(\])|\[([^\]\[]*)\]|\\(.)/g,
            function(a, u, c, d, m) {
              return u || c || d || m;
            }
          )
        );
      }
      function rt(i) {
        return i.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&");
      }
      var ga = {};
      function we(i, a) {
        var u, c = a, d;
        for (typeof i == "string" && (i = [i]), _(a) && (c = function(m, T) {
          T[a] = ee(m);
        }), d = i.length, u = 0; u < d; u++)
          ga[i[u]] = c;
      }
      function qn(i, a) {
        we(i, function(u, c, d, m) {
          d._w = d._w || {}, a(u, d._w, d, m);
        });
      }
      function Il(i, a, u) {
        a != null && f(ga, i) && ga[i](a, u._a, u, i);
      }
      var qe = 0, Nt = 1, bt = 2, Fe = 3, yt = 4, Rt = 5, cn = 6, Yl = 7, Cl = 8;
      function jl(i, a) {
        return (i % a + a) % a;
      }
      var Ye;
      Array.prototype.indexOf ? Ye = Array.prototype.indexOf : Ye = function(i) {
        var a;
        for (a = 0; a < this.length; ++a)
          if (this[a] === i)
            return a;
        return -1;
      };
      function Fr(i, a) {
        if (isNaN(i) || isNaN(a))
          return NaN;
        var u = jl(a, 12);
        return i += (a - u) / 12, u === 1 ? B(i) ? 29 : 28 : 31 - u % 7 % 2;
      }
      X("M", ["MM", 2], "Mo", function() {
        return this.month() + 1;
      }), X("MMM", 0, 0, function(i) {
        return this.localeData().monthsShort(this, i);
      }), X("MMMM", 0, 0, function(i) {
        return this.localeData().months(this, i);
      }), Ue("month", "M"), D("month", 8), z("M", De), z("MM", De, ze), z("MMM", function(i, a) {
        return a.monthsShortRegex(i);
      }), z("MMMM", function(i, a) {
        return a.monthsRegex(i);
      }), we(["M", "MM"], function(i, a) {
        a[Nt] = ee(i) - 1;
      }), we(["MMM", "MMMM"], function(i, a, u, c) {
        var d = u._locale.monthsParse(i, c, u._strict);
        d != null ? a[Nt] = d : W(u).invalidMonth = i;
      });
      var Ll = "January_February_March_April_May_June_July_August_September_October_November_December".split(
        "_"
      ), ro = "Jan_Feb_Mar_Apr_May_Jun_Jul_Aug_Sep_Oct_Nov_Dec".split("_"), io = /D[oD]?(\[[^\[\]]*\]|\s)+MMMM?/, xl = zn, Fl = zn;
      function Bl(i, a) {
        return i ? o(this._months) ? this._months[i.month()] : this._months[(this._months.isFormat || io).test(a) ? "format" : "standalone"][i.month()] : o(this._months) ? this._months : this._months.standalone;
      }
      function Ul(i, a) {
        return i ? o(this._monthsShort) ? this._monthsShort[i.month()] : this._monthsShort[io.test(a) ? "format" : "standalone"][i.month()] : o(this._monthsShort) ? this._monthsShort : this._monthsShort.standalone;
      }
      function Wl(i, a, u) {
        var c, d, m, T = i.toLocaleLowerCase();
        if (!this._monthsParse)
          for (this._monthsParse = [], this._longMonthsParse = [], this._shortMonthsParse = [], c = 0; c < 12; ++c)
            m = E([2e3, c]), this._shortMonthsParse[c] = this.monthsShort(
              m,
              ""
            ).toLocaleLowerCase(), this._longMonthsParse[c] = this.months(m, "").toLocaleLowerCase();
        return u ? a === "MMM" ? (d = Ye.call(this._shortMonthsParse, T), d !== -1 ? d : null) : (d = Ye.call(this._longMonthsParse, T), d !== -1 ? d : null) : a === "MMM" ? (d = Ye.call(this._shortMonthsParse, T), d !== -1 ? d : (d = Ye.call(this._longMonthsParse, T), d !== -1 ? d : null)) : (d = Ye.call(this._longMonthsParse, T), d !== -1 ? d : (d = Ye.call(this._shortMonthsParse, T), d !== -1 ? d : null));
      }
      function Hl(i, a, u) {
        var c, d, m;
        if (this._monthsParseExact)
          return Wl.call(this, i, a, u);
        for (this._monthsParse || (this._monthsParse = [], this._longMonthsParse = [], this._shortMonthsParse = []), c = 0; c < 12; c++) {
          if (d = E([2e3, c]), u && !this._longMonthsParse[c] && (this._longMonthsParse[c] = new RegExp(
            "^" + this.months(d, "").replace(".", "") + "$",
            "i"
          ), this._shortMonthsParse[c] = new RegExp(
            "^" + this.monthsShort(d, "").replace(".", "") + "$",
            "i"
          )), !u && !this._monthsParse[c] && (m = "^" + this.months(d, "") + "|^" + this.monthsShort(d, ""), this._monthsParse[c] = new RegExp(m.replace(".", ""), "i")), u && a === "MMMM" && this._longMonthsParse[c].test(i))
            return c;
          if (u && a === "MMM" && this._shortMonthsParse[c].test(i))
            return c;
          if (!u && this._monthsParse[c].test(i))
            return c;
        }
      }
      function ao(i, a) {
        var u;
        if (!i.isValid())
          return i;
        if (typeof a == "string") {
          if (/^\d+$/.test(a))
            a = ee(a);
          else if (a = i.localeData().monthsParse(a), !_(a))
            return i;
        }
        return u = Math.min(i.date(), Fr(i.year(), a)), i._d["set" + (i._isUTC ? "UTC" : "") + "Month"](a, u), i;
      }
      function so(i) {
        return i != null ? (ao(this, i), r.updateOffset(this, !0), this) : se(this, "Month");
      }
      function Gl() {
        return Fr(this.year(), this.month());
      }
      function $l(i) {
        return this._monthsParseExact ? (f(this, "_monthsRegex") || oo.call(this), i ? this._monthsShortStrictRegex : this._monthsShortRegex) : (f(this, "_monthsShortRegex") || (this._monthsShortRegex = xl), this._monthsShortStrictRegex && i ? this._monthsShortStrictRegex : this._monthsShortRegex);
      }
      function Vl(i) {
        return this._monthsParseExact ? (f(this, "_monthsRegex") || oo.call(this), i ? this._monthsStrictRegex : this._monthsRegex) : (f(this, "_monthsRegex") || (this._monthsRegex = Fl), this._monthsStrictRegex && i ? this._monthsStrictRegex : this._monthsRegex);
      }
      function oo() {
        function i(T, te) {
          return te.length - T.length;
        }
        var a = [], u = [], c = [], d, m;
        for (d = 0; d < 12; d++)
          m = E([2e3, d]), a.push(this.monthsShort(m, "")), u.push(this.months(m, "")), c.push(this.months(m, "")), c.push(this.monthsShort(m, ""));
        for (a.sort(i), u.sort(i), c.sort(i), d = 0; d < 12; d++)
          a[d] = rt(a[d]), u[d] = rt(u[d]);
        for (d = 0; d < 24; d++)
          c[d] = rt(c[d]);
        this._monthsRegex = new RegExp("^(" + c.join("|") + ")", "i"), this._monthsShortRegex = this._monthsRegex, this._monthsStrictRegex = new RegExp(
          "^(" + u.join("|") + ")",
          "i"
        ), this._monthsShortStrictRegex = new RegExp(
          "^(" + a.join("|") + ")",
          "i"
        );
      }
      X("Y", 0, 0, function() {
        var i = this.year();
        return i <= 9999 ? xe(i, 4) : "+" + i;
      }), X(0, ["YY", 2], 0, function() {
        return this.year() % 100;
      }), X(0, ["YYYY", 4], 0, "year"), X(0, ["YYYYY", 5], 0, "year"), X(0, ["YYYYYY", 6, !0], 0, "year"), Ue("year", "y"), D("year", 1), z("Y", jr), z("YY", De, ze), z("YYYY", pa, ma), z("YYYYY", Cr, Ir), z("YYYYYY", Cr, Ir), we(["YYYYY", "YYYYYY"], qe), we("YYYY", function(i, a) {
        a[qe] = i.length === 2 ? r.parseTwoDigitYear(i) : ee(i);
      }), we("YY", function(i, a) {
        a[qe] = r.parseTwoDigitYear(i);
      }), we("Y", function(i, a) {
        a[qe] = parseInt(i, 10);
      });
      function Jn(i) {
        return B(i) ? 366 : 365;
      }
      r.parseTwoDigitYear = function(i) {
        return ee(i) + (ee(i) > 68 ? 1900 : 2e3);
      };
      var uo = ae("FullYear", !0);
      function zl() {
        return B(this.year());
      }
      function ql(i, a, u, c, d, m, T) {
        var te;
        return i < 100 && i >= 0 ? (te = new Date(i + 400, a, u, c, d, m, T), isFinite(te.getFullYear()) && te.setFullYear(i)) : te = new Date(i, a, u, c, d, m, T), te;
      }
      function Zn(i) {
        var a, u;
        return i < 100 && i >= 0 ? (u = Array.prototype.slice.call(arguments), u[0] = i + 400, a = new Date(Date.UTC.apply(null, u)), isFinite(a.getUTCFullYear()) && a.setUTCFullYear(i)) : a = new Date(Date.UTC.apply(null, arguments)), a;
      }
      function Br(i, a, u) {
        var c = 7 + a - u, d = (7 + Zn(i, 0, c).getUTCDay() - a) % 7;
        return -d + c - 1;
      }
      function co(i, a, u, c, d) {
        var m = (7 + u - c) % 7, T = Br(i, c, d), te = 1 + 7 * (a - 1) + m + T, ue, Se;
        return te <= 0 ? (ue = i - 1, Se = Jn(ue) + te) : te > Jn(i) ? (ue = i + 1, Se = te - Jn(i)) : (ue = i, Se = te), {
          year: ue,
          dayOfYear: Se
        };
      }
      function Kn(i, a, u) {
        var c = Br(i.year(), a, u), d = Math.floor((i.dayOfYear() - c - 1) / 7) + 1, m, T;
        return d < 1 ? (T = i.year() - 1, m = d + kt(T, a, u)) : d > kt(i.year(), a, u) ? (m = d - kt(i.year(), a, u), T = i.year() + 1) : (T = i.year(), m = d), {
          week: m,
          year: T
        };
      }
      function kt(i, a, u) {
        var c = Br(i, a, u), d = Br(i + 1, a, u);
        return (Jn(i) - c + d) / 7;
      }
      X("w", ["ww", 2], "wo", "week"), X("W", ["WW", 2], "Wo", "isoWeek"), Ue("week", "w"), Ue("isoWeek", "W"), D("week", 5), D("isoWeek", 5), z("w", De), z("ww", De, ze), z("W", De), z("WW", De, ze), qn(
        ["w", "ww", "W", "WW"],
        function(i, a, u, c) {
          a[c.substr(0, 1)] = ee(i);
        }
      );
      function Jl(i) {
        return Kn(i, this._week.dow, this._week.doy).week;
      }
      var Zl = {
        dow: 0,
        // Sunday is the first day of the week.
        doy: 6
        // The week that contains Jan 6th is the first week of the year.
      };
      function Kl() {
        return this._week.dow;
      }
      function Ql() {
        return this._week.doy;
      }
      function Xl(i) {
        var a = this.localeData().week(this);
        return i == null ? a : this.add((i - a) * 7, "d");
      }
      function ed(i) {
        var a = Kn(this, 1, 4).week;
        return i == null ? a : this.add((i - a) * 7, "d");
      }
      X("d", 0, "do", "day"), X("dd", 0, 0, function(i) {
        return this.localeData().weekdaysMin(this, i);
      }), X("ddd", 0, 0, function(i) {
        return this.localeData().weekdaysShort(this, i);
      }), X("dddd", 0, 0, function(i) {
        return this.localeData().weekdays(this, i);
      }), X("e", 0, 0, "weekday"), X("E", 0, 0, "isoWeekday"), Ue("day", "d"), Ue("weekday", "e"), Ue("isoWeekday", "E"), D("day", 11), D("weekday", 11), D("isoWeekday", 11), z("d", De), z("e", De), z("E", De), z("dd", function(i, a) {
        return a.weekdaysMinRegex(i);
      }), z("ddd", function(i, a) {
        return a.weekdaysShortRegex(i);
      }), z("dddd", function(i, a) {
        return a.weekdaysRegex(i);
      }), qn(["dd", "ddd", "dddd"], function(i, a, u, c) {
        var d = u._locale.weekdaysParse(i, c, u._strict);
        d != null ? a.d = d : W(u).invalidWeekday = i;
      }), qn(["d", "e", "E"], function(i, a, u, c) {
        a[c] = ee(i);
      });
      function td(i, a) {
        return typeof i != "string" ? i : isNaN(i) ? (i = a.weekdaysParse(i), typeof i == "number" ? i : null) : parseInt(i, 10);
      }
      function nd(i, a) {
        return typeof i == "string" ? a.weekdaysParse(i) % 7 || 7 : isNaN(i) ? null : i;
      }
      function va(i, a) {
        return i.slice(a, 7).concat(i.slice(0, a));
      }
      var rd = "Sunday_Monday_Tuesday_Wednesday_Thursday_Friday_Saturday".split("_"), lo = "Sun_Mon_Tue_Wed_Thu_Fri_Sat".split("_"), id = "Su_Mo_Tu_We_Th_Fr_Sa".split("_"), ad = zn, sd = zn, od = zn;
      function ud(i, a) {
        var u = o(this._weekdays) ? this._weekdays : this._weekdays[i && i !== !0 && this._weekdays.isFormat.test(a) ? "format" : "standalone"];
        return i === !0 ? va(u, this._week.dow) : i ? u[i.day()] : u;
      }
      function cd(i) {
        return i === !0 ? va(this._weekdaysShort, this._week.dow) : i ? this._weekdaysShort[i.day()] : this._weekdaysShort;
      }
      function ld(i) {
        return i === !0 ? va(this._weekdaysMin, this._week.dow) : i ? this._weekdaysMin[i.day()] : this._weekdaysMin;
      }
      function dd(i, a, u) {
        var c, d, m, T = i.toLocaleLowerCase();
        if (!this._weekdaysParse)
          for (this._weekdaysParse = [], this._shortWeekdaysParse = [], this._minWeekdaysParse = [], c = 0; c < 7; ++c)
            m = E([2e3, 1]).day(c), this._minWeekdaysParse[c] = this.weekdaysMin(
              m,
              ""
            ).toLocaleLowerCase(), this._shortWeekdaysParse[c] = this.weekdaysShort(
              m,
              ""
            ).toLocaleLowerCase(), this._weekdaysParse[c] = this.weekdays(m, "").toLocaleLowerCase();
        return u ? a === "dddd" ? (d = Ye.call(this._weekdaysParse, T), d !== -1 ? d : null) : a === "ddd" ? (d = Ye.call(this._shortWeekdaysParse, T), d !== -1 ? d : null) : (d = Ye.call(this._minWeekdaysParse, T), d !== -1 ? d : null) : a === "dddd" ? (d = Ye.call(this._weekdaysParse, T), d !== -1 || (d = Ye.call(this._shortWeekdaysParse, T), d !== -1) ? d : (d = Ye.call(this._minWeekdaysParse, T), d !== -1 ? d : null)) : a === "ddd" ? (d = Ye.call(this._shortWeekdaysParse, T), d !== -1 || (d = Ye.call(this._weekdaysParse, T), d !== -1) ? d : (d = Ye.call(this._minWeekdaysParse, T), d !== -1 ? d : null)) : (d = Ye.call(this._minWeekdaysParse, T), d !== -1 || (d = Ye.call(this._weekdaysParse, T), d !== -1) ? d : (d = Ye.call(this._shortWeekdaysParse, T), d !== -1 ? d : null));
      }
      function fd(i, a, u) {
        var c, d, m;
        if (this._weekdaysParseExact)
          return dd.call(this, i, a, u);
        for (this._weekdaysParse || (this._weekdaysParse = [], this._minWeekdaysParse = [], this._shortWeekdaysParse = [], this._fullWeekdaysParse = []), c = 0; c < 7; c++) {
          if (d = E([2e3, 1]).day(c), u && !this._fullWeekdaysParse[c] && (this._fullWeekdaysParse[c] = new RegExp(
            "^" + this.weekdays(d, "").replace(".", "\\.?") + "$",
            "i"
          ), this._shortWeekdaysParse[c] = new RegExp(
            "^" + this.weekdaysShort(d, "").replace(".", "\\.?") + "$",
            "i"
          ), this._minWeekdaysParse[c] = new RegExp(
            "^" + this.weekdaysMin(d, "").replace(".", "\\.?") + "$",
            "i"
          )), this._weekdaysParse[c] || (m = "^" + this.weekdays(d, "") + "|^" + this.weekdaysShort(d, "") + "|^" + this.weekdaysMin(d, ""), this._weekdaysParse[c] = new RegExp(m.replace(".", ""), "i")), u && a === "dddd" && this._fullWeekdaysParse[c].test(i))
            return c;
          if (u && a === "ddd" && this._shortWeekdaysParse[c].test(i))
            return c;
          if (u && a === "dd" && this._minWeekdaysParse[c].test(i))
            return c;
          if (!u && this._weekdaysParse[c].test(i))
            return c;
        }
      }
      function hd(i) {
        if (!this.isValid())
          return i != null ? this : NaN;
        var a = this._isUTC ? this._d.getUTCDay() : this._d.getDay();
        return i != null ? (i = td(i, this.localeData()), this.add(i - a, "d")) : a;
      }
      function _d(i) {
        if (!this.isValid())
          return i != null ? this : NaN;
        var a = (this.day() + 7 - this.localeData()._week.dow) % 7;
        return i == null ? a : this.add(i - a, "d");
      }
      function yd(i) {
        if (!this.isValid())
          return i != null ? this : NaN;
        if (i != null) {
          var a = nd(i, this.localeData());
          return this.day(this.day() % 7 ? a : a - 7);
        } else
          return this.day() || 7;
      }
      function md(i) {
        return this._weekdaysParseExact ? (f(this, "_weekdaysRegex") || wa.call(this), i ? this._weekdaysStrictRegex : this._weekdaysRegex) : (f(this, "_weekdaysRegex") || (this._weekdaysRegex = ad), this._weekdaysStrictRegex && i ? this._weekdaysStrictRegex : this._weekdaysRegex);
      }
      function pd(i) {
        return this._weekdaysParseExact ? (f(this, "_weekdaysRegex") || wa.call(this), i ? this._weekdaysShortStrictRegex : this._weekdaysShortRegex) : (f(this, "_weekdaysShortRegex") || (this._weekdaysShortRegex = sd), this._weekdaysShortStrictRegex && i ? this._weekdaysShortStrictRegex : this._weekdaysShortRegex);
      }
      function gd(i) {
        return this._weekdaysParseExact ? (f(this, "_weekdaysRegex") || wa.call(this), i ? this._weekdaysMinStrictRegex : this._weekdaysMinRegex) : (f(this, "_weekdaysMinRegex") || (this._weekdaysMinRegex = od), this._weekdaysMinStrictRegex && i ? this._weekdaysMinStrictRegex : this._weekdaysMinRegex);
      }
      function wa() {
        function i(Xe, Lt) {
          return Lt.length - Xe.length;
        }
        var a = [], u = [], c = [], d = [], m, T, te, ue, Se;
        for (m = 0; m < 7; m++)
          T = E([2e3, 1]).day(m), te = rt(this.weekdaysMin(T, "")), ue = rt(this.weekdaysShort(T, "")), Se = rt(this.weekdays(T, "")), a.push(te), u.push(ue), c.push(Se), d.push(te), d.push(ue), d.push(Se);
        a.sort(i), u.sort(i), c.sort(i), d.sort(i), this._weekdaysRegex = new RegExp("^(" + d.join("|") + ")", "i"), this._weekdaysShortRegex = this._weekdaysRegex, this._weekdaysMinRegex = this._weekdaysRegex, this._weekdaysStrictRegex = new RegExp(
          "^(" + c.join("|") + ")",
          "i"
        ), this._weekdaysShortStrictRegex = new RegExp(
          "^(" + u.join("|") + ")",
          "i"
        ), this._weekdaysMinStrictRegex = new RegExp(
          "^(" + a.join("|") + ")",
          "i"
        );
      }
      function Sa() {
        return this.hours() % 12 || 12;
      }
      function vd() {
        return this.hours() || 24;
      }
      X("H", ["HH", 2], 0, "hour"), X("h", ["hh", 2], 0, Sa), X("k", ["kk", 2], 0, vd), X("hmm", 0, 0, function() {
        return "" + Sa.apply(this) + xe(this.minutes(), 2);
      }), X("hmmss", 0, 0, function() {
        return "" + Sa.apply(this) + xe(this.minutes(), 2) + xe(this.seconds(), 2);
      }), X("Hmm", 0, 0, function() {
        return "" + this.hours() + xe(this.minutes(), 2);
      }), X("Hmmss", 0, 0, function() {
        return "" + this.hours() + xe(this.minutes(), 2) + xe(this.seconds(), 2);
      });
      function fo(i, a) {
        X(i, 0, 0, function() {
          return this.localeData().meridiem(
            this.hours(),
            this.minutes(),
            a
          );
        });
      }
      fo("a", !0), fo("A", !1), Ue("hour", "h"), D("hour", 13);
      function ho(i, a) {
        return a._meridiemParse;
      }
      z("a", ho), z("A", ho), z("H", De), z("h", De), z("k", De), z("HH", De, ze), z("hh", De, ze), z("kk", De, ze), z("hmm", to), z("hmmss", no), z("Hmm", to), z("Hmmss", no), we(["H", "HH"], Fe), we(["k", "kk"], function(i, a, u) {
        var c = ee(i);
        a[Fe] = c === 24 ? 0 : c;
      }), we(["a", "A"], function(i, a, u) {
        u._isPm = u._locale.isPM(i), u._meridiem = i;
      }), we(["h", "hh"], function(i, a, u) {
        a[Fe] = ee(i), W(u).bigHour = !0;
      }), we("hmm", function(i, a, u) {
        var c = i.length - 2;
        a[Fe] = ee(i.substr(0, c)), a[yt] = ee(i.substr(c)), W(u).bigHour = !0;
      }), we("hmmss", function(i, a, u) {
        var c = i.length - 4, d = i.length - 2;
        a[Fe] = ee(i.substr(0, c)), a[yt] = ee(i.substr(c, 2)), a[Rt] = ee(i.substr(d)), W(u).bigHour = !0;
      }), we("Hmm", function(i, a, u) {
        var c = i.length - 2;
        a[Fe] = ee(i.substr(0, c)), a[yt] = ee(i.substr(c));
      }), we("Hmmss", function(i, a, u) {
        var c = i.length - 4, d = i.length - 2;
        a[Fe] = ee(i.substr(0, c)), a[yt] = ee(i.substr(c, 2)), a[Rt] = ee(i.substr(d));
      });
      function wd(i) {
        return (i + "").toLowerCase().charAt(0) === "p";
      }
      var Sd = /[ap]\.?m?\.?/i, bd = ae("Hours", !0);
      function Md(i, a, u) {
        return i > 11 ? u ? "pm" : "PM" : u ? "am" : "AM";
      }
      var _o = {
        calendar: J,
        longDateFormat: Nr,
        invalidDate: Rr,
        ordinal: kr,
        dayOfMonthOrdinalParse: fa,
        relativeTime: _a,
        months: Ll,
        monthsShort: ro,
        week: Zl,
        weekdays: rd,
        weekdaysMin: id,
        weekdaysShort: lo,
        meridiemParse: Sd
      }, Pe = {}, Qn = {}, Xn;
      function Od(i, a) {
        var u, c = Math.min(i.length, a.length);
        for (u = 0; u < c; u += 1)
          if (i[u] !== a[u])
            return u;
        return c;
      }
      function yo(i) {
        return i && i.toLowerCase().replace("_", "-");
      }
      function Dd(i) {
        for (var a = 0, u, c, d, m; a < i.length; ) {
          for (m = yo(i[a]).split("-"), u = m.length, c = yo(i[a + 1]), c = c ? c.split("-") : null; u > 0; ) {
            if (d = Ur(m.slice(0, u).join("-")), d)
              return d;
            if (c && c.length >= u && Od(m, c) >= u - 1)
              break;
            u--;
          }
          a++;
        }
        return Xn;
      }
      function Td(i) {
        return i.match("^[^/\\\\]*$") != null;
      }
      function Ur(i) {
        var a = null, u;
        if (Pe[i] === void 0 && e && e.exports && Td(i))
          try {
            a = Xn._abbr, u = Sn, u("./locale/" + i), Qt(a);
          } catch {
            Pe[i] = null;
          }
        return Pe[i];
      }
      function Qt(i, a) {
        var u;
        return i && (p(a) ? u = It(i) : u = ba(i, a), u ? Xn = u : typeof console < "u" && console.warn && console.warn(
          "Locale " + i + " not found. Did you forget to load it?"
        )), Xn._abbr;
      }
      function ba(i, a) {
        if (a !== null) {
          var u, c = _o;
          if (a.abbr = i, Pe[i] != null)
            P(
              "defineLocaleOverride",
              "use moment.updateLocale(localeName, config) to change an existing locale. moment.defineLocale(localeName, config) should only be used for creating a new locale See http://momentjs.com/guides/#/warnings/define-locale/ for more info."
            ), c = Pe[i]._config;
          else if (a.parentLocale != null)
            if (Pe[a.parentLocale] != null)
              c = Pe[a.parentLocale]._config;
            else if (u = Ur(a.parentLocale), u != null)
              c = u._config;
            else
              return Qn[a.parentLocale] || (Qn[a.parentLocale] = []), Qn[a.parentLocale].push({
                name: i,
                config: a
              }), null;
          return Pe[i] = new Q(G(c, a)), Qn[i] && Qn[i].forEach(function(d) {
            ba(d.name, d.config);
          }), Qt(i), Pe[i];
        } else
          return delete Pe[i], null;
      }
      function Ad(i, a) {
        if (a != null) {
          var u, c, d = _o;
          Pe[i] != null && Pe[i].parentLocale != null ? Pe[i].set(G(Pe[i]._config, a)) : (c = Ur(i), c != null && (d = c._config), a = G(d, a), c == null && (a.abbr = i), u = new Q(a), u.parentLocale = Pe[i], Pe[i] = u), Qt(i);
        } else
          Pe[i] != null && (Pe[i].parentLocale != null ? (Pe[i] = Pe[i].parentLocale, i === Qt() && Qt(i)) : Pe[i] != null && delete Pe[i]);
        return Pe[i];
      }
      function It(i) {
        var a;
        if (i && i._locale && i._locale._abbr && (i = i._locale._abbr), !i)
          return Xn;
        if (!o(i)) {
          if (a = Ur(i), a)
            return a;
          i = [i];
        }
        return Dd(i);
      }
      function Ed() {
        return le(Pe);
      }
      function Ma(i) {
        var a, u = i._a;
        return u && W(i).overflow === -2 && (a = u[Nt] < 0 || u[Nt] > 11 ? Nt : u[bt] < 1 || u[bt] > Fr(u[qe], u[Nt]) ? bt : u[Fe] < 0 || u[Fe] > 24 || u[Fe] === 24 && (u[yt] !== 0 || u[Rt] !== 0 || u[cn] !== 0) ? Fe : u[yt] < 0 || u[yt] > 59 ? yt : u[Rt] < 0 || u[Rt] > 59 ? Rt : u[cn] < 0 || u[cn] > 999 ? cn : -1, W(i)._overflowDayOfYear && (a < qe || a > bt) && (a = bt), W(i)._overflowWeeks && a === -1 && (a = Yl), W(i)._overflowWeekday && a === -1 && (a = Cl), W(i).overflow = a), i;
      }
      var Pd = /^\s*((?:[+-]\d{6}|\d{4})-(?:\d\d-\d\d|W\d\d-\d|W\d\d|\d\d\d|\d\d))(?:(T| )(\d\d(?::\d\d(?::\d\d(?:[.,]\d+)?)?)?)([+-]\d\d(?::?\d\d)?|\s*Z)?)?$/, Nd = /^\s*((?:[+-]\d{6}|\d{4})(?:\d\d\d\d|W\d\d\d|W\d\d|\d\d\d|\d\d|))(?:(T| )(\d\d(?:\d\d(?:\d\d(?:[.,]\d+)?)?)?)([+-]\d\d(?::?\d\d)?|\s*Z)?)?$/, Rd = /Z|[+-]\d\d(?::?\d\d)?/, Wr = [
        ["YYYYYY-MM-DD", /[+-]\d{6}-\d\d-\d\d/],
        ["YYYY-MM-DD", /\d{4}-\d\d-\d\d/],
        ["GGGG-[W]WW-E", /\d{4}-W\d\d-\d/],
        ["GGGG-[W]WW", /\d{4}-W\d\d/, !1],
        ["YYYY-DDD", /\d{4}-\d{3}/],
        ["YYYY-MM", /\d{4}-\d\d/, !1],
        ["YYYYYYMMDD", /[+-]\d{10}/],
        ["YYYYMMDD", /\d{8}/],
        ["GGGG[W]WWE", /\d{4}W\d{3}/],
        ["GGGG[W]WW", /\d{4}W\d{2}/, !1],
        ["YYYYDDD", /\d{7}/],
        ["YYYYMM", /\d{6}/, !1],
        ["YYYY", /\d{4}/, !1]
      ], Oa = [
        ["HH:mm:ss.SSSS", /\d\d:\d\d:\d\d\.\d+/],
        ["HH:mm:ss,SSSS", /\d\d:\d\d:\d\d,\d+/],
        ["HH:mm:ss", /\d\d:\d\d:\d\d/],
        ["HH:mm", /\d\d:\d\d/],
        ["HHmmss.SSSS", /\d\d\d\d\d\d\.\d+/],
        ["HHmmss,SSSS", /\d\d\d\d\d\d,\d+/],
        ["HHmmss", /\d\d\d\d\d\d/],
        ["HHmm", /\d\d\d\d/],
        ["HH", /\d\d/]
      ], kd = /^\/?Date\((-?\d+)/i, Id = /^(?:(Mon|Tue|Wed|Thu|Fri|Sat|Sun),?\s)?(\d{1,2})\s(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s(\d{2,4})\s(\d\d):(\d\d)(?::(\d\d))?\s(?:(UT|GMT|[ECMP][SD]T)|([Zz])|([+-]\d{4}))$/, Yd = {
        UT: 0,
        GMT: 0,
        EDT: -4 * 60,
        EST: -5 * 60,
        CDT: -5 * 60,
        CST: -6 * 60,
        MDT: -6 * 60,
        MST: -7 * 60,
        PDT: -7 * 60,
        PST: -8 * 60
      };
      function mo(i) {
        var a, u, c = i._i, d = Pd.exec(c) || Nd.exec(c), m, T, te, ue, Se = Wr.length, Xe = Oa.length;
        if (d) {
          for (W(i).iso = !0, a = 0, u = Se; a < u; a++)
            if (Wr[a][1].exec(d[1])) {
              T = Wr[a][0], m = Wr[a][2] !== !1;
              break;
            }
          if (T == null) {
            i._isValid = !1;
            return;
          }
          if (d[3]) {
            for (a = 0, u = Xe; a < u; a++)
              if (Oa[a][1].exec(d[3])) {
                te = (d[2] || " ") + Oa[a][0];
                break;
              }
            if (te == null) {
              i._isValid = !1;
              return;
            }
          }
          if (!m && te != null) {
            i._isValid = !1;
            return;
          }
          if (d[4])
            if (Rd.exec(d[4]))
              ue = "Z";
            else {
              i._isValid = !1;
              return;
            }
          i._f = T + (te || "") + (ue || ""), Ta(i);
        } else
          i._isValid = !1;
      }
      function Cd(i, a, u, c, d, m) {
        var T = [
          jd(i),
          ro.indexOf(a),
          parseInt(u, 10),
          parseInt(c, 10),
          parseInt(d, 10)
        ];
        return m && T.push(parseInt(m, 10)), T;
      }
      function jd(i) {
        var a = parseInt(i, 10);
        return a <= 49 ? 2e3 + a : a <= 999 ? 1900 + a : a;
      }
      function Ld(i) {
        return i.replace(/\([^()]*\)|[\n\t]/g, " ").replace(/(\s\s+)/g, " ").replace(/^\s\s*/, "").replace(/\s\s*$/, "");
      }
      function xd(i, a, u) {
        if (i) {
          var c = lo.indexOf(i), d = new Date(
            a[0],
            a[1],
            a[2]
          ).getDay();
          if (c !== d)
            return W(u).weekdayMismatch = !0, u._isValid = !1, !1;
        }
        return !0;
      }
      function Fd(i, a, u) {
        if (i)
          return Yd[i];
        if (a)
          return 0;
        var c = parseInt(u, 10), d = c % 100, m = (c - d) / 100;
        return m * 60 + d;
      }
      function po(i) {
        var a = Id.exec(Ld(i._i)), u;
        if (a) {
          if (u = Cd(
            a[4],
            a[3],
            a[2],
            a[5],
            a[6],
            a[7]
          ), !xd(a[1], u, i))
            return;
          i._a = u, i._tzm = Fd(a[8], a[9], a[10]), i._d = Zn.apply(null, i._a), i._d.setUTCMinutes(i._d.getUTCMinutes() - i._tzm), W(i).rfc2822 = !0;
        } else
          i._isValid = !1;
      }
      function Bd(i) {
        var a = kd.exec(i._i);
        if (a !== null) {
          i._d = /* @__PURE__ */ new Date(+a[1]);
          return;
        }
        if (mo(i), i._isValid === !1)
          delete i._isValid;
        else
          return;
        if (po(i), i._isValid === !1)
          delete i._isValid;
        else
          return;
        i._strict ? i._isValid = !1 : r.createFromInputFallback(i);
      }
      r.createFromInputFallback = S(
        "value provided is not in a recognized RFC2822 or ISO format. moment construction falls back to js Date(), which is not reliable across all browsers and versions. Non RFC2822/ISO date formats are discouraged. Please refer to http://momentjs.com/guides/#/warnings/js-date/ for more info.",
        function(i) {
          i._d = /* @__PURE__ */ new Date(i._i + (i._useUTC ? " UTC" : ""));
        }
      );
      function On(i, a, u) {
        return i ?? a ?? u;
      }
      function Ud(i) {
        var a = new Date(r.now());
        return i._useUTC ? [
          a.getUTCFullYear(),
          a.getUTCMonth(),
          a.getUTCDate()
        ] : [a.getFullYear(), a.getMonth(), a.getDate()];
      }
      function Da(i) {
        var a, u, c = [], d, m, T;
        if (!i._d) {
          for (d = Ud(i), i._w && i._a[bt] == null && i._a[Nt] == null && Wd(i), i._dayOfYear != null && (T = On(i._a[qe], d[qe]), (i._dayOfYear > Jn(T) || i._dayOfYear === 0) && (W(i)._overflowDayOfYear = !0), u = Zn(T, 0, i._dayOfYear), i._a[Nt] = u.getUTCMonth(), i._a[bt] = u.getUTCDate()), a = 0; a < 3 && i._a[a] == null; ++a)
            i._a[a] = c[a] = d[a];
          for (; a < 7; a++)
            i._a[a] = c[a] = i._a[a] == null ? a === 2 ? 1 : 0 : i._a[a];
          i._a[Fe] === 24 && i._a[yt] === 0 && i._a[Rt] === 0 && i._a[cn] === 0 && (i._nextDay = !0, i._a[Fe] = 0), i._d = (i._useUTC ? Zn : ql).apply(
            null,
            c
          ), m = i._useUTC ? i._d.getUTCDay() : i._d.getDay(), i._tzm != null && i._d.setUTCMinutes(i._d.getUTCMinutes() - i._tzm), i._nextDay && (i._a[Fe] = 24), i._w && typeof i._w.d < "u" && i._w.d !== m && (W(i).weekdayMismatch = !0);
        }
      }
      function Wd(i) {
        var a, u, c, d, m, T, te, ue, Se;
        a = i._w, a.GG != null || a.W != null || a.E != null ? (m = 1, T = 4, u = On(
          a.GG,
          i._a[qe],
          Kn(Te(), 1, 4).year
        ), c = On(a.W, 1), d = On(a.E, 1), (d < 1 || d > 7) && (ue = !0)) : (m = i._locale._week.dow, T = i._locale._week.doy, Se = Kn(Te(), m, T), u = On(a.gg, i._a[qe], Se.year), c = On(a.w, Se.week), a.d != null ? (d = a.d, (d < 0 || d > 6) && (ue = !0)) : a.e != null ? (d = a.e + m, (a.e < 0 || a.e > 6) && (ue = !0)) : d = m), c < 1 || c > kt(u, m, T) ? W(i)._overflowWeeks = !0 : ue != null ? W(i)._overflowWeekday = !0 : (te = co(u, c, d, m, T), i._a[qe] = te.year, i._dayOfYear = te.dayOfYear);
      }
      r.ISO_8601 = function() {
      }, r.RFC_2822 = function() {
      };
      function Ta(i) {
        if (i._f === r.ISO_8601) {
          mo(i);
          return;
        }
        if (i._f === r.RFC_2822) {
          po(i);
          return;
        }
        i._a = [], W(i).empty = !0;
        var a = "" + i._i, u, c, d, m, T, te = a.length, ue = 0, Se, Xe;
        for (d = ot(i._f, i._locale).match(ke) || [], Xe = d.length, u = 0; u < Xe; u++)
          m = d[u], c = (a.match(Rl(m, i)) || [])[0], c && (T = a.substr(0, a.indexOf(c)), T.length > 0 && W(i).unusedInput.push(T), a = a.slice(
            a.indexOf(c) + c.length
          ), ue += c.length), Oe[m] ? (c ? W(i).empty = !1 : W(i).unusedTokens.push(m), Il(m, c, i)) : i._strict && !c && W(i).unusedTokens.push(m);
        W(i).charsLeftOver = te - ue, a.length > 0 && W(i).unusedInput.push(a), i._a[Fe] <= 12 && W(i).bigHour === !0 && i._a[Fe] > 0 && (W(i).bigHour = void 0), W(i).parsedDateParts = i._a.slice(0), W(i).meridiem = i._meridiem, i._a[Fe] = Hd(
          i._locale,
          i._a[Fe],
          i._meridiem
        ), Se = W(i).era, Se !== null && (i._a[qe] = i._locale.erasConvertYear(Se, i._a[qe])), Da(i), Ma(i);
      }
      function Hd(i, a, u) {
        var c;
        return u == null ? a : i.meridiemHour != null ? i.meridiemHour(a, u) : (i.isPM != null && (c = i.isPM(u), c && a < 12 && (a += 12), !c && a === 12 && (a = 0)), a);
      }
      function Gd(i) {
        var a, u, c, d, m, T, te = !1, ue = i._f.length;
        if (ue === 0) {
          W(i).invalidFormat = !0, i._d = /* @__PURE__ */ new Date(NaN);
          return;
        }
        for (d = 0; d < ue; d++)
          m = 0, T = !1, a = A({}, i), i._useUTC != null && (a._useUTC = i._useUTC), a._f = i._f[d], Ta(a), Z(a) && (T = !0), m += W(a).charsLeftOver, m += W(a).unusedTokens.length * 10, W(a).score = m, te ? m < c && (c = m, u = a) : (c == null || m < c || T) && (c = m, u = a, T && (te = !0));
        H(i, u || a);
      }
      function $d(i) {
        if (!i._d) {
          var a = un(i._i), u = a.day === void 0 ? a.date : a.day;
          i._a = F(
            [a.year, a.month, u, a.hour, a.minute, a.second, a.millisecond],
            function(c) {
              return c && parseInt(c, 10);
            }
          ), Da(i);
        }
      }
      function Vd(i) {
        var a = new y(Ma(go(i)));
        return a._nextDay && (a.add(1, "d"), a._nextDay = void 0), a;
      }
      function go(i) {
        var a = i._i, u = i._f;
        return i._locale = i._locale || It(i._l), a === null || u === void 0 && a === "" ? U({ nullInput: !0 }) : (typeof a == "string" && (i._i = a = i._locale.preparse(a)), h(a) ? new y(Ma(a)) : (O(a) ? i._d = a : o(u) ? Gd(i) : u ? Ta(i) : zd(i), Z(i) || (i._d = null), i));
      }
      function zd(i) {
        var a = i._i;
        p(a) ? i._d = new Date(r.now()) : O(a) ? i._d = new Date(a.valueOf()) : typeof a == "string" ? Bd(i) : o(a) ? (i._a = F(a.slice(0), function(u) {
          return parseInt(u, 10);
        }), Da(i)) : l(a) ? $d(i) : _(a) ? i._d = new Date(a) : r.createFromInputFallback(i);
      }
      function vo(i, a, u, c, d) {
        var m = {};
        return (a === !0 || a === !1) && (c = a, a = void 0), (u === !0 || u === !1) && (c = u, u = void 0), (l(i) && w(i) || o(i) && i.length === 0) && (i = void 0), m._isAMomentObject = !0, m._useUTC = m._isUTC = d, m._l = u, m._i = i, m._f = a, m._strict = c, Vd(m);
      }
      function Te(i, a, u, c) {
        return vo(i, a, u, c, !1);
      }
      var qd = S(
        "moment().min is deprecated, use moment.max instead. http://momentjs.com/guides/#/warnings/min-max/",
        function() {
          var i = Te.apply(null, arguments);
          return this.isValid() && i.isValid() ? i < this ? this : i : U();
        }
      ), Jd = S(
        "moment().max is deprecated, use moment.min instead. http://momentjs.com/guides/#/warnings/min-max/",
        function() {
          var i = Te.apply(null, arguments);
          return this.isValid() && i.isValid() ? i > this ? this : i : U();
        }
      );
      function wo(i, a) {
        var u, c;
        if (a.length === 1 && o(a[0]) && (a = a[0]), !a.length)
          return Te();
        for (u = a[0], c = 1; c < a.length; ++c)
          (!a[c].isValid() || a[c][i](u)) && (u = a[c]);
        return u;
      }
      function Zd() {
        var i = [].slice.call(arguments, 0);
        return wo("isBefore", i);
      }
      function Kd() {
        var i = [].slice.call(arguments, 0);
        return wo("isAfter", i);
      }
      var Qd = function() {
        return Date.now ? Date.now() : +/* @__PURE__ */ new Date();
      }, er = [
        "year",
        "quarter",
        "month",
        "week",
        "day",
        "hour",
        "minute",
        "second",
        "millisecond"
      ];
      function Xd(i) {
        var a, u = !1, c, d = er.length;
        for (a in i)
          if (f(i, a) && !(Ye.call(er, a) !== -1 && (i[a] == null || !isNaN(i[a]))))
            return !1;
        for (c = 0; c < d; ++c)
          if (i[er[c]]) {
            if (u)
              return !1;
            parseFloat(i[er[c]]) !== ee(i[er[c]]) && (u = !0);
          }
        return !0;
      }
      function ef() {
        return this._isValid;
      }
      function tf() {
        return mt(NaN);
      }
      function Hr(i) {
        var a = un(i), u = a.year || 0, c = a.quarter || 0, d = a.month || 0, m = a.week || a.isoWeek || 0, T = a.day || 0, te = a.hour || 0, ue = a.minute || 0, Se = a.second || 0, Xe = a.millisecond || 0;
        this._isValid = Xd(a), this._milliseconds = +Xe + Se * 1e3 + // 1000
        ue * 6e4 + // 1000 * 60
        te * 1e3 * 60 * 60, this._days = +T + m * 7, this._months = +d + c * 3 + u * 12, this._data = {}, this._locale = It(), this._bubble();
      }
      function Gr(i) {
        return i instanceof Hr;
      }
      function Aa(i) {
        return i < 0 ? Math.round(-1 * i) * -1 : Math.round(i);
      }
      function nf(i, a, u) {
        var c = Math.min(i.length, a.length), d = Math.abs(i.length - a.length), m = 0, T;
        for (T = 0; T < c; T++)
          (u && i[T] !== a[T] || !u && ee(i[T]) !== ee(a[T])) && m++;
        return m + d;
      }
      function So(i, a) {
        X(i, 0, 0, function() {
          var u = this.utcOffset(), c = "+";
          return u < 0 && (u = -u, c = "-"), c + xe(~~(u / 60), 2) + a + xe(~~u % 60, 2);
        });
      }
      So("Z", ":"), So("ZZ", ""), z("Z", Lr), z("ZZ", Lr), we(["Z", "ZZ"], function(i, a, u) {
        u._useUTC = !0, u._tzm = Ea(Lr, i);
      });
      var rf = /([\+\-]|\d\d)/gi;
      function Ea(i, a) {
        var u = (a || "").match(i), c, d, m;
        return u === null ? null : (c = u[u.length - 1] || [], d = (c + "").match(rf) || ["-", 0, 0], m = +(d[1] * 60) + ee(d[2]), m === 0 ? 0 : d[0] === "+" ? m : -m);
      }
      function Pa(i, a) {
        var u, c;
        return a._isUTC ? (u = a.clone(), c = (h(i) || O(i) ? i.valueOf() : Te(i).valueOf()) - u.valueOf(), u._d.setTime(u._d.valueOf() + c), r.updateOffset(u, !1), u) : Te(i).local();
      }
      function Na(i) {
        return -Math.round(i._d.getTimezoneOffset());
      }
      r.updateOffset = function() {
      };
      function af(i, a, u) {
        var c = this._offset || 0, d;
        if (!this.isValid())
          return i != null ? this : NaN;
        if (i != null) {
          if (typeof i == "string") {
            if (i = Ea(Lr, i), i === null)
              return this;
          } else
            Math.abs(i) < 16 && !u && (i = i * 60);
          return !this._isUTC && a && (d = Na(this)), this._offset = i, this._isUTC = !0, d != null && this.add(d, "m"), c !== i && (!a || this._changeInProgress ? Do(
            this,
            mt(i - c, "m"),
            1,
            !1
          ) : this._changeInProgress || (this._changeInProgress = !0, r.updateOffset(this, !0), this._changeInProgress = null)), this;
        } else
          return this._isUTC ? c : Na(this);
      }
      function sf(i, a) {
        return i != null ? (typeof i != "string" && (i = -i), this.utcOffset(i, a), this) : -this.utcOffset();
      }
      function of(i) {
        return this.utcOffset(0, i);
      }
      function uf(i) {
        return this._isUTC && (this.utcOffset(0, i), this._isUTC = !1, i && this.subtract(Na(this), "m")), this;
      }
      function cf() {
        if (this._tzm != null)
          this.utcOffset(this._tzm, !1, !0);
        else if (typeof this._i == "string") {
          var i = Ea(Pl, this._i);
          i != null ? this.utcOffset(i) : this.utcOffset(0, !0);
        }
        return this;
      }
      function lf(i) {
        return this.isValid() ? (i = i ? Te(i).utcOffset() : 0, (this.utcOffset() - i) % 60 === 0) : !1;
      }
      function df() {
        return this.utcOffset() > this.clone().month(0).utcOffset() || this.utcOffset() > this.clone().month(5).utcOffset();
      }
      function ff() {
        if (!p(this._isDSTShifted))
          return this._isDSTShifted;
        var i = {}, a;
        return A(i, this), i = go(i), i._a ? (a = i._isUTC ? E(i._a) : Te(i._a), this._isDSTShifted = this.isValid() && nf(i._a, a.toArray()) > 0) : this._isDSTShifted = !1, this._isDSTShifted;
      }
      function hf() {
        return this.isValid() ? !this._isUTC : !1;
      }
      function _f() {
        return this.isValid() ? this._isUTC : !1;
      }
      function bo() {
        return this.isValid() ? this._isUTC && this._offset === 0 : !1;
      }
      var yf = /^(-|\+)?(?:(\d*)[. ])?(\d+):(\d+)(?::(\d+)(\.\d*)?)?$/, mf = /^(-|\+)?P(?:([-+]?[0-9,.]*)Y)?(?:([-+]?[0-9,.]*)M)?(?:([-+]?[0-9,.]*)W)?(?:([-+]?[0-9,.]*)D)?(?:T(?:([-+]?[0-9,.]*)H)?(?:([-+]?[0-9,.]*)M)?(?:([-+]?[0-9,.]*)S)?)?$/;
      function mt(i, a) {
        var u = i, c = null, d, m, T;
        return Gr(i) ? u = {
          ms: i._milliseconds,
          d: i._days,
          M: i._months
        } : _(i) || !isNaN(+i) ? (u = {}, a ? u[a] = +i : u.milliseconds = +i) : (c = yf.exec(i)) ? (d = c[1] === "-" ? -1 : 1, u = {
          y: 0,
          d: ee(c[bt]) * d,
          h: ee(c[Fe]) * d,
          m: ee(c[yt]) * d,
          s: ee(c[Rt]) * d,
          ms: ee(Aa(c[cn] * 1e3)) * d
          // the millisecond decimal point is included in the match
        }) : (c = mf.exec(i)) ? (d = c[1] === "-" ? -1 : 1, u = {
          y: ln(c[2], d),
          M: ln(c[3], d),
          w: ln(c[4], d),
          d: ln(c[5], d),
          h: ln(c[6], d),
          m: ln(c[7], d),
          s: ln(c[8], d)
        }) : u == null ? u = {} : typeof u == "object" && ("from" in u || "to" in u) && (T = pf(
          Te(u.from),
          Te(u.to)
        ), u = {}, u.ms = T.milliseconds, u.M = T.months), m = new Hr(u), Gr(i) && f(i, "_locale") && (m._locale = i._locale), Gr(i) && f(i, "_isValid") && (m._isValid = i._isValid), m;
      }
      mt.fn = Hr.prototype, mt.invalid = tf;
      function ln(i, a) {
        var u = i && parseFloat(i.replace(",", "."));
        return (isNaN(u) ? 0 : u) * a;
      }
      function Mo(i, a) {
        var u = {};
        return u.months = a.month() - i.month() + (a.year() - i.year()) * 12, i.clone().add(u.months, "M").isAfter(a) && --u.months, u.milliseconds = +a - +i.clone().add(u.months, "M"), u;
      }
      function pf(i, a) {
        var u;
        return i.isValid() && a.isValid() ? (a = Pa(a, i), i.isBefore(a) ? u = Mo(i, a) : (u = Mo(a, i), u.milliseconds = -u.milliseconds, u.months = -u.months), u) : { milliseconds: 0, months: 0 };
      }
      function Oo(i, a) {
        return function(u, c) {
          var d, m;
          return c !== null && !isNaN(+c) && (P(
            a,
            "moment()." + a + "(period, number) is deprecated. Please use moment()." + a + "(number, period). See http://momentjs.com/guides/#/warnings/add-inverted-param/ for more info."
          ), m = u, u = c, c = m), d = mt(u, c), Do(this, d, i), this;
        };
      }
      function Do(i, a, u, c) {
        var d = a._milliseconds, m = Aa(a._days), T = Aa(a._months);
        i.isValid() && (c = c ?? !0, T && ao(i, se(i, "Month") + T * u), m && re(i, "Date", se(i, "Date") + m * u), d && i._d.setTime(i._d.valueOf() + d * u), c && r.updateOffset(i, m || T));
      }
      var gf = Oo(1, "add"), vf = Oo(-1, "subtract");
      function To(i) {
        return typeof i == "string" || i instanceof String;
      }
      function wf(i) {
        return h(i) || O(i) || To(i) || _(i) || bf(i) || Sf(i) || i === null || i === void 0;
      }
      function Sf(i) {
        var a = l(i) && !w(i), u = !1, c = [
          "years",
          "year",
          "y",
          "months",
          "month",
          "M",
          "days",
          "day",
          "d",
          "dates",
          "date",
          "D",
          "hours",
          "hour",
          "h",
          "minutes",
          "minute",
          "m",
          "seconds",
          "second",
          "s",
          "milliseconds",
          "millisecond",
          "ms"
        ], d, m, T = c.length;
        for (d = 0; d < T; d += 1)
          m = c[d], u = u || f(i, m);
        return a && u;
      }
      function bf(i) {
        var a = o(i), u = !1;
        return a && (u = i.filter(function(c) {
          return !_(c) && To(i);
        }).length === 0), a && u;
      }
      function Mf(i) {
        var a = l(i) && !w(i), u = !1, c = [
          "sameDay",
          "nextDay",
          "lastDay",
          "nextWeek",
          "lastWeek",
          "sameElse"
        ], d, m;
        for (d = 0; d < c.length; d += 1)
          m = c[d], u = u || f(i, m);
        return a && u;
      }
      function Of(i, a) {
        var u = i.diff(a, "days", !0);
        return u < -6 ? "sameElse" : u < -1 ? "lastWeek" : u < 0 ? "lastDay" : u < 1 ? "sameDay" : u < 2 ? "nextDay" : u < 7 ? "nextWeek" : "sameElse";
      }
      function Df(i, a) {
        arguments.length === 1 && (arguments[0] ? wf(arguments[0]) ? (i = arguments[0], a = void 0) : Mf(arguments[0]) && (a = arguments[0], i = void 0) : (i = void 0, a = void 0));
        var u = i || Te(), c = Pa(u, this).startOf("day"), d = r.calendarFormat(this, c) || "sameElse", m = a && (k(a[d]) ? a[d].call(this, u) : a[d]);
        return this.format(
          m || this.localeData().calendar(d, this, Te(u))
        );
      }
      function Tf() {
        return new y(this);
      }
      function Af(i, a) {
        var u = h(i) ? i : Te(i);
        return this.isValid() && u.isValid() ? (a = tt(a) || "millisecond", a === "millisecond" ? this.valueOf() > u.valueOf() : u.valueOf() < this.clone().startOf(a).valueOf()) : !1;
      }
      function Ef(i, a) {
        var u = h(i) ? i : Te(i);
        return this.isValid() && u.isValid() ? (a = tt(a) || "millisecond", a === "millisecond" ? this.valueOf() < u.valueOf() : this.clone().endOf(a).valueOf() < u.valueOf()) : !1;
      }
      function Pf(i, a, u, c) {
        var d = h(i) ? i : Te(i), m = h(a) ? a : Te(a);
        return this.isValid() && d.isValid() && m.isValid() ? (c = c || "()", (c[0] === "(" ? this.isAfter(d, u) : !this.isBefore(d, u)) && (c[1] === ")" ? this.isBefore(m, u) : !this.isAfter(m, u))) : !1;
      }
      function Nf(i, a) {
        var u = h(i) ? i : Te(i), c;
        return this.isValid() && u.isValid() ? (a = tt(a) || "millisecond", a === "millisecond" ? this.valueOf() === u.valueOf() : (c = u.valueOf(), this.clone().startOf(a).valueOf() <= c && c <= this.clone().endOf(a).valueOf())) : !1;
      }
      function Rf(i, a) {
        return this.isSame(i, a) || this.isAfter(i, a);
      }
      function kf(i, a) {
        return this.isSame(i, a) || this.isBefore(i, a);
      }
      function If(i, a, u) {
        var c, d, m;
        if (!this.isValid())
          return NaN;
        if (c = Pa(i, this), !c.isValid())
          return NaN;
        switch (d = (c.utcOffset() - this.utcOffset()) * 6e4, a = tt(a), a) {
          case "year":
            m = $r(this, c) / 12;
            break;
          case "month":
            m = $r(this, c);
            break;
          case "quarter":
            m = $r(this, c) / 3;
            break;
          case "second":
            m = (this - c) / 1e3;
            break;
          case "minute":
            m = (this - c) / 6e4;
            break;
          case "hour":
            m = (this - c) / 36e5;
            break;
          case "day":
            m = (this - c - d) / 864e5;
            break;
          case "week":
            m = (this - c - d) / 6048e5;
            break;
          default:
            m = this - c;
        }
        return u ? m : V(m);
      }
      function $r(i, a) {
        if (i.date() < a.date())
          return -$r(a, i);
        var u = (a.year() - i.year()) * 12 + (a.month() - i.month()), c = i.clone().add(u, "months"), d, m;
        return a - c < 0 ? (d = i.clone().add(u - 1, "months"), m = (a - c) / (c - d)) : (d = i.clone().add(u + 1, "months"), m = (a - c) / (d - c)), -(u + m) || 0;
      }
      r.defaultFormat = "YYYY-MM-DDTHH:mm:ssZ", r.defaultFormatUtc = "YYYY-MM-DDTHH:mm:ss[Z]";
      function Yf() {
        return this.clone().locale("en").format("ddd MMM DD YYYY HH:mm:ss [GMT]ZZ");
      }
      function Cf(i) {
        if (!this.isValid())
          return null;
        var a = i !== !0, u = a ? this.clone().utc() : this;
        return u.year() < 0 || u.year() > 9999 ? bn(
          u,
          a ? "YYYYYY-MM-DD[T]HH:mm:ss.SSS[Z]" : "YYYYYY-MM-DD[T]HH:mm:ss.SSSZ"
        ) : k(Date.prototype.toISOString) ? a ? this.toDate().toISOString() : new Date(this.valueOf() + this.utcOffset() * 60 * 1e3).toISOString().replace("Z", bn(u, "Z")) : bn(
          u,
          a ? "YYYY-MM-DD[T]HH:mm:ss.SSS[Z]" : "YYYY-MM-DD[T]HH:mm:ss.SSSZ"
        );
      }
      function jf() {
        if (!this.isValid())
          return "moment.invalid(/* " + this._i + " */)";
        var i = "moment", a = "", u, c, d, m;
        return this.isLocal() || (i = this.utcOffset() === 0 ? "moment.utc" : "moment.parseZone", a = "Z"), u = "[" + i + '("]', c = 0 <= this.year() && this.year() <= 9999 ? "YYYY" : "YYYYYY", d = "-MM-DD[T]HH:mm:ss.SSS", m = a + '[")]', this.format(u + c + d + m);
      }
      function Lf(i) {
        i || (i = this.isUtc() ? r.defaultFormatUtc : r.defaultFormat);
        var a = bn(this, i);
        return this.localeData().postformat(a);
      }
      function xf(i, a) {
        return this.isValid() && (h(i) && i.isValid() || Te(i).isValid()) ? mt({ to: this, from: i }).locale(this.locale()).humanize(!a) : this.localeData().invalidDate();
      }
      function Ff(i) {
        return this.from(Te(), i);
      }
      function Bf(i, a) {
        return this.isValid() && (h(i) && i.isValid() || Te(i).isValid()) ? mt({ from: this, to: i }).locale(this.locale()).humanize(!a) : this.localeData().invalidDate();
      }
      function Uf(i) {
        return this.to(Te(), i);
      }
      function Ao(i) {
        var a;
        return i === void 0 ? this._locale._abbr : (a = It(i), a != null && (this._locale = a), this);
      }
      var Eo = S(
        "moment().lang() is deprecated. Instead, use moment().localeData() to get the language configuration. Use moment().locale() to change languages.",
        function(i) {
          return i === void 0 ? this.localeData() : this.locale(i);
        }
      );
      function Po() {
        return this._locale;
      }
      var Vr = 1e3, Dn = 60 * Vr, zr = 60 * Dn, No = (365 * 400 + 97) * 24 * zr;
      function Tn(i, a) {
        return (i % a + a) % a;
      }
      function Ro(i, a, u) {
        return i < 100 && i >= 0 ? new Date(i + 400, a, u) - No : new Date(i, a, u).valueOf();
      }
      function ko(i, a, u) {
        return i < 100 && i >= 0 ? Date.UTC(i + 400, a, u) - No : Date.UTC(i, a, u);
      }
      function Wf(i) {
        var a, u;
        if (i = tt(i), i === void 0 || i === "millisecond" || !this.isValid())
          return this;
        switch (u = this._isUTC ? ko : Ro, i) {
          case "year":
            a = u(this.year(), 0, 1);
            break;
          case "quarter":
            a = u(
              this.year(),
              this.month() - this.month() % 3,
              1
            );
            break;
          case "month":
            a = u(this.year(), this.month(), 1);
            break;
          case "week":
            a = u(
              this.year(),
              this.month(),
              this.date() - this.weekday()
            );
            break;
          case "isoWeek":
            a = u(
              this.year(),
              this.month(),
              this.date() - (this.isoWeekday() - 1)
            );
            break;
          case "day":
          case "date":
            a = u(this.year(), this.month(), this.date());
            break;
          case "hour":
            a = this._d.valueOf(), a -= Tn(
              a + (this._isUTC ? 0 : this.utcOffset() * Dn),
              zr
            );
            break;
          case "minute":
            a = this._d.valueOf(), a -= Tn(a, Dn);
            break;
          case "second":
            a = this._d.valueOf(), a -= Tn(a, Vr);
            break;
        }
        return this._d.setTime(a), r.updateOffset(this, !0), this;
      }
      function Hf(i) {
        var a, u;
        if (i = tt(i), i === void 0 || i === "millisecond" || !this.isValid())
          return this;
        switch (u = this._isUTC ? ko : Ro, i) {
          case "year":
            a = u(this.year() + 1, 0, 1) - 1;
            break;
          case "quarter":
            a = u(
              this.year(),
              this.month() - this.month() % 3 + 3,
              1
            ) - 1;
            break;
          case "month":
            a = u(this.year(), this.month() + 1, 1) - 1;
            break;
          case "week":
            a = u(
              this.year(),
              this.month(),
              this.date() - this.weekday() + 7
            ) - 1;
            break;
          case "isoWeek":
            a = u(
              this.year(),
              this.month(),
              this.date() - (this.isoWeekday() - 1) + 7
            ) - 1;
            break;
          case "day":
          case "date":
            a = u(this.year(), this.month(), this.date() + 1) - 1;
            break;
          case "hour":
            a = this._d.valueOf(), a += zr - Tn(
              a + (this._isUTC ? 0 : this.utcOffset() * Dn),
              zr
            ) - 1;
            break;
          case "minute":
            a = this._d.valueOf(), a += Dn - Tn(a, Dn) - 1;
            break;
          case "second":
            a = this._d.valueOf(), a += Vr - Tn(a, Vr) - 1;
            break;
        }
        return this._d.setTime(a), r.updateOffset(this, !0), this;
      }
      function Gf() {
        return this._d.valueOf() - (this._offset || 0) * 6e4;
      }
      function $f() {
        return Math.floor(this.valueOf() / 1e3);
      }
      function Vf() {
        return new Date(this.valueOf());
      }
      function zf() {
        var i = this;
        return [
          i.year(),
          i.month(),
          i.date(),
          i.hour(),
          i.minute(),
          i.second(),
          i.millisecond()
        ];
      }
      function qf() {
        var i = this;
        return {
          years: i.year(),
          months: i.month(),
          date: i.date(),
          hours: i.hours(),
          minutes: i.minutes(),
          seconds: i.seconds(),
          milliseconds: i.milliseconds()
        };
      }
      function Jf() {
        return this.isValid() ? this.toISOString() : null;
      }
      function Zf() {
        return Z(this);
      }
      function Kf() {
        return H({}, W(this));
      }
      function Qf() {
        return W(this).overflow;
      }
      function Xf() {
        return {
          input: this._i,
          format: this._f,
          locale: this._locale,
          isUTC: this._isUTC,
          strict: this._strict
        };
      }
      X("N", 0, 0, "eraAbbr"), X("NN", 0, 0, "eraAbbr"), X("NNN", 0, 0, "eraAbbr"), X("NNNN", 0, 0, "eraName"), X("NNNNN", 0, 0, "eraNarrow"), X("y", ["y", 1], "yo", "eraYear"), X("y", ["yy", 2], 0, "eraYear"), X("y", ["yyy", 3], 0, "eraYear"), X("y", ["yyyy", 4], 0, "eraYear"), z("N", Ra), z("NN", Ra), z("NNN", Ra), z("NNNN", lh), z("NNNNN", dh), we(
        ["N", "NN", "NNN", "NNNN", "NNNNN"],
        function(i, a, u, c) {
          var d = u._locale.erasParse(i, c, u._strict);
          d ? W(u).era = d : W(u).invalidEra = i;
        }
      ), z("y", Mn), z("yy", Mn), z("yyy", Mn), z("yyyy", Mn), z("yo", fh), we(["y", "yy", "yyy", "yyyy"], qe), we(["yo"], function(i, a, u, c) {
        var d;
        u._locale._eraYearOrdinalRegex && (d = i.match(u._locale._eraYearOrdinalRegex)), u._locale.eraYearOrdinalParse ? a[qe] = u._locale.eraYearOrdinalParse(i, d) : a[qe] = parseInt(i, 10);
      });
      function eh(i, a) {
        var u, c, d, m = this._eras || It("en")._eras;
        for (u = 0, c = m.length; u < c; ++u) {
          switch (typeof m[u].since) {
            case "string":
              d = r(m[u].since).startOf("day"), m[u].since = d.valueOf();
              break;
          }
          switch (typeof m[u].until) {
            case "undefined":
              m[u].until = 1 / 0;
              break;
            case "string":
              d = r(m[u].until).startOf("day").valueOf(), m[u].until = d.valueOf();
              break;
          }
        }
        return m;
      }
      function th(i, a, u) {
        var c, d, m = this.eras(), T, te, ue;
        for (i = i.toUpperCase(), c = 0, d = m.length; c < d; ++c)
          if (T = m[c].name.toUpperCase(), te = m[c].abbr.toUpperCase(), ue = m[c].narrow.toUpperCase(), u)
            switch (a) {
              case "N":
              case "NN":
              case "NNN":
                if (te === i)
                  return m[c];
                break;
              case "NNNN":
                if (T === i)
                  return m[c];
                break;
              case "NNNNN":
                if (ue === i)
                  return m[c];
                break;
            }
          else if ([T, te, ue].indexOf(i) >= 0)
            return m[c];
      }
      function nh(i, a) {
        var u = i.since <= i.until ? 1 : -1;
        return a === void 0 ? r(i.since).year() : r(i.since).year() + (a - i.offset) * u;
      }
      function rh() {
        var i, a, u, c = this.localeData().eras();
        for (i = 0, a = c.length; i < a; ++i)
          if (u = this.clone().startOf("day").valueOf(), c[i].since <= u && u <= c[i].until || c[i].until <= u && u <= c[i].since)
            return c[i].name;
        return "";
      }
      function ih() {
        var i, a, u, c = this.localeData().eras();
        for (i = 0, a = c.length; i < a; ++i)
          if (u = this.clone().startOf("day").valueOf(), c[i].since <= u && u <= c[i].until || c[i].until <= u && u <= c[i].since)
            return c[i].narrow;
        return "";
      }
      function ah() {
        var i, a, u, c = this.localeData().eras();
        for (i = 0, a = c.length; i < a; ++i)
          if (u = this.clone().startOf("day").valueOf(), c[i].since <= u && u <= c[i].until || c[i].until <= u && u <= c[i].since)
            return c[i].abbr;
        return "";
      }
      function sh() {
        var i, a, u, c, d = this.localeData().eras();
        for (i = 0, a = d.length; i < a; ++i)
          if (u = d[i].since <= d[i].until ? 1 : -1, c = this.clone().startOf("day").valueOf(), d[i].since <= c && c <= d[i].until || d[i].until <= c && c <= d[i].since)
            return (this.year() - r(d[i].since).year()) * u + d[i].offset;
        return this.year();
      }
      function oh(i) {
        return f(this, "_erasNameRegex") || ka.call(this), i ? this._erasNameRegex : this._erasRegex;
      }
      function uh(i) {
        return f(this, "_erasAbbrRegex") || ka.call(this), i ? this._erasAbbrRegex : this._erasRegex;
      }
      function ch(i) {
        return f(this, "_erasNarrowRegex") || ka.call(this), i ? this._erasNarrowRegex : this._erasRegex;
      }
      function Ra(i, a) {
        return a.erasAbbrRegex(i);
      }
      function lh(i, a) {
        return a.erasNameRegex(i);
      }
      function dh(i, a) {
        return a.erasNarrowRegex(i);
      }
      function fh(i, a) {
        return a._eraYearOrdinalRegex || Mn;
      }
      function ka() {
        var i = [], a = [], u = [], c = [], d, m, T = this.eras();
        for (d = 0, m = T.length; d < m; ++d)
          a.push(rt(T[d].name)), i.push(rt(T[d].abbr)), u.push(rt(T[d].narrow)), c.push(rt(T[d].name)), c.push(rt(T[d].abbr)), c.push(rt(T[d].narrow));
        this._erasRegex = new RegExp("^(" + c.join("|") + ")", "i"), this._erasNameRegex = new RegExp("^(" + a.join("|") + ")", "i"), this._erasAbbrRegex = new RegExp("^(" + i.join("|") + ")", "i"), this._erasNarrowRegex = new RegExp(
          "^(" + u.join("|") + ")",
          "i"
        );
      }
      X(0, ["gg", 2], 0, function() {
        return this.weekYear() % 100;
      }), X(0, ["GG", 2], 0, function() {
        return this.isoWeekYear() % 100;
      });
      function qr(i, a) {
        X(0, [i, i.length], 0, a);
      }
      qr("gggg", "weekYear"), qr("ggggg", "weekYear"), qr("GGGG", "isoWeekYear"), qr("GGGGG", "isoWeekYear"), Ue("weekYear", "gg"), Ue("isoWeekYear", "GG"), D("weekYear", 1), D("isoWeekYear", 1), z("G", jr), z("g", jr), z("GG", De, ze), z("gg", De, ze), z("GGGG", pa, ma), z("gggg", pa, ma), z("GGGGG", Cr, Ir), z("ggggg", Cr, Ir), qn(
        ["gggg", "ggggg", "GGGG", "GGGGG"],
        function(i, a, u, c) {
          a[c.substr(0, 2)] = ee(i);
        }
      ), qn(["gg", "GG"], function(i, a, u, c) {
        a[c] = r.parseTwoDigitYear(i);
      });
      function hh(i) {
        return Io.call(
          this,
          i,
          this.week(),
          this.weekday(),
          this.localeData()._week.dow,
          this.localeData()._week.doy
        );
      }
      function _h(i) {
        return Io.call(
          this,
          i,
          this.isoWeek(),
          this.isoWeekday(),
          1,
          4
        );
      }
      function yh() {
        return kt(this.year(), 1, 4);
      }
      function mh() {
        return kt(this.isoWeekYear(), 1, 4);
      }
      function ph() {
        var i = this.localeData()._week;
        return kt(this.year(), i.dow, i.doy);
      }
      function gh() {
        var i = this.localeData()._week;
        return kt(this.weekYear(), i.dow, i.doy);
      }
      function Io(i, a, u, c, d) {
        var m;
        return i == null ? Kn(this, c, d).year : (m = kt(i, c, d), a > m && (a = m), vh.call(this, i, a, u, c, d));
      }
      function vh(i, a, u, c, d) {
        var m = co(i, a, u, c, d), T = Zn(m.year, 0, m.dayOfYear);
        return this.year(T.getUTCFullYear()), this.month(T.getUTCMonth()), this.date(T.getUTCDate()), this;
      }
      X("Q", 0, "Qo", "quarter"), Ue("quarter", "Q"), D("quarter", 7), z("Q", _t), we("Q", function(i, a) {
        a[Nt] = (ee(i) - 1) * 3;
      });
      function wh(i) {
        return i == null ? Math.ceil((this.month() + 1) / 3) : this.month((i - 1) * 3 + this.month() % 3);
      }
      X("D", ["DD", 2], "Do", "date"), Ue("date", "D"), D("date", 9), z("D", De), z("DD", De, ze), z("Do", function(i, a) {
        return i ? a._dayOfMonthOrdinalParse || a._ordinalParse : a._dayOfMonthOrdinalParseLenient;
      }), we(["D", "DD"], bt), we("Do", function(i, a) {
        a[bt] = ee(i.match(De)[0]);
      });
      var Yo = ae("Date", !0);
      X("DDD", ["DDDD", 3], "DDDo", "dayOfYear"), Ue("dayOfYear", "DDD"), D("dayOfYear", 4), z("DDD", Yr), z("DDDD", eo), we(["DDD", "DDDD"], function(i, a, u) {
        u._dayOfYear = ee(i);
      });
      function Sh(i) {
        var a = Math.round(
          (this.clone().startOf("day") - this.clone().startOf("year")) / 864e5
        ) + 1;
        return i == null ? a : this.add(i - a, "d");
      }
      X("m", ["mm", 2], 0, "minute"), Ue("minute", "m"), D("minute", 14), z("m", De), z("mm", De, ze), we(["m", "mm"], yt);
      var bh = ae("Minutes", !1);
      X("s", ["ss", 2], 0, "second"), Ue("second", "s"), D("second", 15), z("s", De), z("ss", De, ze), we(["s", "ss"], Rt);
      var Mh = ae("Seconds", !1);
      X("S", 0, 0, function() {
        return ~~(this.millisecond() / 100);
      }), X(0, ["SS", 2], 0, function() {
        return ~~(this.millisecond() / 10);
      }), X(0, ["SSS", 3], 0, "millisecond"), X(0, ["SSSS", 4], 0, function() {
        return this.millisecond() * 10;
      }), X(0, ["SSSSS", 5], 0, function() {
        return this.millisecond() * 100;
      }), X(0, ["SSSSSS", 6], 0, function() {
        return this.millisecond() * 1e3;
      }), X(0, ["SSSSSSS", 7], 0, function() {
        return this.millisecond() * 1e4;
      }), X(0, ["SSSSSSSS", 8], 0, function() {
        return this.millisecond() * 1e5;
      }), X(0, ["SSSSSSSSS", 9], 0, function() {
        return this.millisecond() * 1e6;
      }), Ue("millisecond", "ms"), D("millisecond", 16), z("S", Yr, _t), z("SS", Yr, ze), z("SSS", Yr, eo);
      var Xt, Co;
      for (Xt = "SSSS"; Xt.length <= 9; Xt += "S")
        z(Xt, Mn);
      function Oh(i, a) {
        a[cn] = ee(("0." + i) * 1e3);
      }
      for (Xt = "S"; Xt.length <= 9; Xt += "S")
        we(Xt, Oh);
      Co = ae("Milliseconds", !1), X("z", 0, 0, "zoneAbbr"), X("zz", 0, 0, "zoneName");
      function Dh() {
        return this._isUTC ? "UTC" : "";
      }
      function Th() {
        return this._isUTC ? "Coordinated Universal Time" : "";
      }
      var Y = y.prototype;
      Y.add = gf, Y.calendar = Df, Y.clone = Tf, Y.diff = If, Y.endOf = Hf, Y.format = Lf, Y.from = xf, Y.fromNow = Ff, Y.to = Bf, Y.toNow = Uf, Y.get = de, Y.invalidAt = Qf, Y.isAfter = Af, Y.isBefore = Ef, Y.isBetween = Pf, Y.isSame = Nf, Y.isSameOrAfter = Rf, Y.isSameOrBefore = kf, Y.isValid = Zf, Y.lang = Eo, Y.locale = Ao, Y.localeData = Po, Y.max = Jd, Y.min = qd, Y.parsingFlags = Kf, Y.set = fe, Y.startOf = Wf, Y.subtract = vf, Y.toArray = zf, Y.toObject = qf, Y.toDate = Vf, Y.toISOString = Cf, Y.inspect = jf, typeof Symbol < "u" && Symbol.for != null && (Y[Symbol.for("nodejs.util.inspect.custom")] = function() {
        return "Moment<" + this.format() + ">";
      }), Y.toJSON = Jf, Y.toString = Yf, Y.unix = $f, Y.valueOf = Gf, Y.creationData = Xf, Y.eraName = rh, Y.eraNarrow = ih, Y.eraAbbr = ah, Y.eraYear = sh, Y.year = uo, Y.isLeapYear = zl, Y.weekYear = hh, Y.isoWeekYear = _h, Y.quarter = Y.quarters = wh, Y.month = so, Y.daysInMonth = Gl, Y.week = Y.weeks = Xl, Y.isoWeek = Y.isoWeeks = ed, Y.weeksInYear = ph, Y.weeksInWeekYear = gh, Y.isoWeeksInYear = yh, Y.isoWeeksInISOWeekYear = mh, Y.date = Yo, Y.day = Y.days = hd, Y.weekday = _d, Y.isoWeekday = yd, Y.dayOfYear = Sh, Y.hour = Y.hours = bd, Y.minute = Y.minutes = bh, Y.second = Y.seconds = Mh, Y.millisecond = Y.milliseconds = Co, Y.utcOffset = af, Y.utc = of, Y.local = uf, Y.parseZone = cf, Y.hasAlignedHourOffset = lf, Y.isDST = df, Y.isLocal = hf, Y.isUtcOffset = _f, Y.isUtc = bo, Y.isUTC = bo, Y.zoneAbbr = Dh, Y.zoneName = Th, Y.dates = S(
        "dates accessor is deprecated. Use date instead.",
        Yo
      ), Y.months = S(
        "months accessor is deprecated. Use month instead",
        so
      ), Y.years = S(
        "years accessor is deprecated. Use year instead",
        uo
      ), Y.zone = S(
        "moment().zone is deprecated, use moment().utcOffset instead. http://momentjs.com/guides/#/warnings/zone/",
        sf
      ), Y.isDSTShifted = S(
        "isDSTShifted is deprecated. See http://momentjs.com/guides/#/warnings/dst-shifted/ for more information",
        ff
      );
      function Ah(i) {
        return Te(i * 1e3);
      }
      function Eh() {
        return Te.apply(null, arguments).parseZone();
      }
      function jo(i) {
        return i;
      }
      var me = Q.prototype;
      me.calendar = Ie, me.longDateFormat = Kt, me.invalidDate = da, me.ordinal = ha, me.preparse = jo, me.postformat = jo, me.relativeTime = ya, me.pastFuture = Vn, me.set = j, me.eras = eh, me.erasParse = th, me.erasConvertYear = nh, me.erasAbbrRegex = uh, me.erasNameRegex = oh, me.erasNarrowRegex = ch, me.months = Bl, me.monthsShort = Ul, me.monthsParse = Hl, me.monthsRegex = Vl, me.monthsShortRegex = $l, me.week = Jl, me.firstDayOfYear = Ql, me.firstDayOfWeek = Kl, me.weekdays = ud, me.weekdaysMin = ld, me.weekdaysShort = cd, me.weekdaysParse = fd, me.weekdaysRegex = md, me.weekdaysShortRegex = pd, me.weekdaysMinRegex = gd, me.isPM = wd, me.meridiem = Md;
      function Jr(i, a, u, c) {
        var d = It(), m = E().set(c, a);
        return d[u](m, i);
      }
      function Lo(i, a, u) {
        if (_(i) && (a = i, i = void 0), i = i || "", a != null)
          return Jr(i, a, u, "month");
        var c, d = [];
        for (c = 0; c < 12; c++)
          d[c] = Jr(i, c, u, "month");
        return d;
      }
      function Ia(i, a, u, c) {
        typeof i == "boolean" ? (_(a) && (u = a, a = void 0), a = a || "") : (a = i, u = a, i = !1, _(a) && (u = a, a = void 0), a = a || "");
        var d = It(), m = i ? d._week.dow : 0, T, te = [];
        if (u != null)
          return Jr(a, (u + m) % 7, c, "day");
        for (T = 0; T < 7; T++)
          te[T] = Jr(a, (T + m) % 7, c, "day");
        return te;
      }
      function Ph(i, a) {
        return Lo(i, a, "months");
      }
      function Nh(i, a) {
        return Lo(i, a, "monthsShort");
      }
      function Rh(i, a, u) {
        return Ia(i, a, u, "weekdays");
      }
      function kh(i, a, u) {
        return Ia(i, a, u, "weekdaysShort");
      }
      function Ih(i, a, u) {
        return Ia(i, a, u, "weekdaysMin");
      }
      Qt("en", {
        eras: [
          {
            since: "0001-01-01",
            until: 1 / 0,
            offset: 1,
            name: "Anno Domini",
            narrow: "AD",
            abbr: "AD"
          },
          {
            since: "0000-12-31",
            until: -1 / 0,
            offset: 1,
            name: "Before Christ",
            narrow: "BC",
            abbr: "BC"
          }
        ],
        dayOfMonthOrdinalParse: /\d{1,2}(th|st|nd|rd)/,
        ordinal: function(i) {
          var a = i % 10, u = ee(i % 100 / 10) === 1 ? "th" : a === 1 ? "st" : a === 2 ? "nd" : a === 3 ? "rd" : "th";
          return i + u;
        }
      }), r.lang = S(
        "moment.lang is deprecated. Use moment.locale instead.",
        Qt
      ), r.langData = S(
        "moment.langData is deprecated. Use moment.localeData instead.",
        It
      );
      var Yt = Math.abs;
      function Yh() {
        var i = this._data;
        return this._milliseconds = Yt(this._milliseconds), this._days = Yt(this._days), this._months = Yt(this._months), i.milliseconds = Yt(i.milliseconds), i.seconds = Yt(i.seconds), i.minutes = Yt(i.minutes), i.hours = Yt(i.hours), i.months = Yt(i.months), i.years = Yt(i.years), this;
      }
      function xo(i, a, u, c) {
        var d = mt(a, u);
        return i._milliseconds += c * d._milliseconds, i._days += c * d._days, i._months += c * d._months, i._bubble();
      }
      function Ch(i, a) {
        return xo(this, i, a, 1);
      }
      function jh(i, a) {
        return xo(this, i, a, -1);
      }
      function Fo(i) {
        return i < 0 ? Math.floor(i) : Math.ceil(i);
      }
      function Lh() {
        var i = this._milliseconds, a = this._days, u = this._months, c = this._data, d, m, T, te, ue;
        return i >= 0 && a >= 0 && u >= 0 || i <= 0 && a <= 0 && u <= 0 || (i += Fo(Ya(u) + a) * 864e5, a = 0, u = 0), c.milliseconds = i % 1e3, d = V(i / 1e3), c.seconds = d % 60, m = V(d / 60), c.minutes = m % 60, T = V(m / 60), c.hours = T % 24, a += V(T / 24), ue = V(Bo(a)), u += ue, a -= Fo(Ya(ue)), te = V(u / 12), u %= 12, c.days = a, c.months = u, c.years = te, this;
      }
      function Bo(i) {
        return i * 4800 / 146097;
      }
      function Ya(i) {
        return i * 146097 / 4800;
      }
      function xh(i) {
        if (!this.isValid())
          return NaN;
        var a, u, c = this._milliseconds;
        if (i = tt(i), i === "month" || i === "quarter" || i === "year")
          switch (a = this._days + c / 864e5, u = this._months + Bo(a), i) {
            case "month":
              return u;
            case "quarter":
              return u / 3;
            case "year":
              return u / 12;
          }
        else
          switch (a = this._days + Math.round(Ya(this._months)), i) {
            case "week":
              return a / 7 + c / 6048e5;
            case "day":
              return a + c / 864e5;
            case "hour":
              return a * 24 + c / 36e5;
            case "minute":
              return a * 1440 + c / 6e4;
            case "second":
              return a * 86400 + c / 1e3;
            case "millisecond":
              return Math.floor(a * 864e5) + c;
            default:
              throw new Error("Unknown unit " + i);
          }
      }
      function Fh() {
        return this.isValid() ? this._milliseconds + this._days * 864e5 + this._months % 12 * 2592e6 + ee(this._months / 12) * 31536e6 : NaN;
      }
      function Ct(i) {
        return function() {
          return this.as(i);
        };
      }
      var Bh = Ct("ms"), Uh = Ct("s"), Wh = Ct("m"), Hh = Ct("h"), Gh = Ct("d"), $h = Ct("w"), Vh = Ct("M"), zh = Ct("Q"), qh = Ct("y");
      function Jh() {
        return mt(this);
      }
      function Zh(i) {
        return i = tt(i), this.isValid() ? this[i + "s"]() : NaN;
      }
      function dn(i) {
        return function() {
          return this.isValid() ? this._data[i] : NaN;
        };
      }
      var Kh = dn("milliseconds"), Qh = dn("seconds"), Xh = dn("minutes"), e_ = dn("hours"), t_ = dn("days"), n_ = dn("months"), r_ = dn("years");
      function i_() {
        return V(this.days() / 7);
      }
      var jt = Math.round, An = {
        ss: 44,
        // a few seconds to seconds
        s: 45,
        // seconds to minute
        m: 45,
        // minutes to hour
        h: 22,
        // hours to day
        d: 26,
        // days to month/week
        w: null,
        // weeks to month
        M: 11
        // months to year
      };
      function a_(i, a, u, c, d) {
        return d.relativeTime(a || 1, !!u, i, c);
      }
      function s_(i, a, u, c) {
        var d = mt(i).abs(), m = jt(d.as("s")), T = jt(d.as("m")), te = jt(d.as("h")), ue = jt(d.as("d")), Se = jt(d.as("M")), Xe = jt(d.as("w")), Lt = jt(d.as("y")), en = m <= u.ss && ["s", m] || m < u.s && ["ss", m] || T <= 1 && ["m"] || T < u.m && ["mm", T] || te <= 1 && ["h"] || te < u.h && ["hh", te] || ue <= 1 && ["d"] || ue < u.d && ["dd", ue];
        return u.w != null && (en = en || Xe <= 1 && ["w"] || Xe < u.w && ["ww", Xe]), en = en || Se <= 1 && ["M"] || Se < u.M && ["MM", Se] || Lt <= 1 && ["y"] || ["yy", Lt], en[2] = a, en[3] = +i > 0, en[4] = c, a_.apply(null, en);
      }
      function o_(i) {
        return i === void 0 ? jt : typeof i == "function" ? (jt = i, !0) : !1;
      }
      function u_(i, a) {
        return An[i] === void 0 ? !1 : a === void 0 ? An[i] : (An[i] = a, i === "s" && (An.ss = a - 1), !0);
      }
      function c_(i, a) {
        if (!this.isValid())
          return this.localeData().invalidDate();
        var u = !1, c = An, d, m;
        return typeof i == "object" && (a = i, i = !1), typeof i == "boolean" && (u = i), typeof a == "object" && (c = Object.assign({}, An, a), a.s != null && a.ss == null && (c.ss = a.s - 1)), d = this.localeData(), m = s_(this, !u, c, d), u && (m = d.pastFuture(+this, m)), d.postformat(m);
      }
      var Ca = Math.abs;
      function En(i) {
        return (i > 0) - (i < 0) || +i;
      }
      function Zr() {
        if (!this.isValid())
          return this.localeData().invalidDate();
        var i = Ca(this._milliseconds) / 1e3, a = Ca(this._days), u = Ca(this._months), c, d, m, T, te = this.asSeconds(), ue, Se, Xe, Lt;
        return te ? (c = V(i / 60), d = V(c / 60), i %= 60, c %= 60, m = V(u / 12), u %= 12, T = i ? i.toFixed(3).replace(/\.?0+$/, "") : "", ue = te < 0 ? "-" : "", Se = En(this._months) !== En(te) ? "-" : "", Xe = En(this._days) !== En(te) ? "-" : "", Lt = En(this._milliseconds) !== En(te) ? "-" : "", ue + "P" + (m ? Se + m + "Y" : "") + (u ? Se + u + "M" : "") + (a ? Xe + a + "D" : "") + (d || c || i ? "T" : "") + (d ? Lt + d + "H" : "") + (c ? Lt + c + "M" : "") + (i ? Lt + T + "S" : "")) : "P0D";
      }
      var he = Hr.prototype;
      he.isValid = ef, he.abs = Yh, he.add = Ch, he.subtract = jh, he.as = xh, he.asMilliseconds = Bh, he.asSeconds = Uh, he.asMinutes = Wh, he.asHours = Hh, he.asDays = Gh, he.asWeeks = $h, he.asMonths = Vh, he.asQuarters = zh, he.asYears = qh, he.valueOf = Fh, he._bubble = Lh, he.clone = Jh, he.get = Zh, he.milliseconds = Kh, he.seconds = Qh, he.minutes = Xh, he.hours = e_, he.days = t_, he.weeks = i_, he.months = n_, he.years = r_, he.humanize = c_, he.toISOString = Zr, he.toString = Zr, he.toJSON = Zr, he.locale = Ao, he.localeData = Po, he.toIsoString = S(
        "toIsoString() is deprecated. Please use toISOString() instead (notice the capitals)",
        Zr
      ), he.lang = Eo, X("X", 0, 0, "unix"), X("x", 0, 0, "valueOf"), z("x", jr), z("X", Nl), we("X", function(i, a, u) {
        u._d = new Date(parseFloat(i) * 1e3);
      }), we("x", function(i, a, u) {
        u._d = new Date(ee(i));
      });
      //! moment.js
      return r.version = "2.29.4", s(Te), r.fn = Y, r.min = Zd, r.max = Kd, r.now = Qd, r.utc = E, r.unix = Ah, r.months = Ph, r.isDate = O, r.locale = Qt, r.invalid = U, r.duration = mt, r.isMoment = h, r.weekdays = Rh, r.parseZone = Eh, r.localeData = It, r.isDuration = Gr, r.monthsShort = Nh, r.weekdaysMin = Ih, r.defineLocale = ba, r.updateLocale = Ad, r.locales = Ed, r.weekdaysShort = kh, r.normalizeUnits = tt, r.relativeTimeRounding = o_, r.relativeTimeThreshold = u_, r.calendarFormat = Of, r.prototype = Y, r.HTML5_FMT = {
        DATETIME_LOCAL: "YYYY-MM-DDTHH:mm",
        // <input type="datetime-local" />
        DATETIME_LOCAL_SECONDS: "YYYY-MM-DDTHH:mm:ss",
        // <input type="datetime-local" step="1" />
        DATETIME_LOCAL_MS: "YYYY-MM-DDTHH:mm:ss.SSS",
        // <input type="datetime-local" step="0.001" />
        DATE: "YYYY-MM-DD",
        // <input type="date" />
        TIME: "HH:mm",
        // <input type="time" />
        TIME_SECONDS: "HH:mm:ss",
        // <input type="time" step="1" />
        TIME_MS: "HH:mm:ss.SSS",
        // <input type="time" step="0.001" />
        WEEK: "GGGG-[W]WW",
        // <input type="week" />
        MONTH: "YYYY-MM"
        // <input type="month" />
      }, r;
    });
  }(gw)), pi;
}
(function(e, t) {
  (function(n, r) {
    r(typeof Sn == "function" ? Gn() : n.moment);
  })(b, function(n) {
    //! moment.js locale configuration
    var r = /^(janvier|février|mars|avril|mai|juin|juillet|août|septembre|octobre|novembre|décembre)/i, s = /(janv\.?|févr\.?|mars|avr\.?|mai|juin|juil\.?|août|sept\.?|oct\.?|nov\.?|déc\.?)/i, o = /(janv\.?|févr\.?|mars|avr\.?|mai|juin|juil\.?|août|sept\.?|oct\.?|nov\.?|déc\.?|janvier|février|mars|avril|mai|juin|juillet|août|septembre|octobre|novembre|décembre)/i, l = [
      /^janv/i,
      /^févr/i,
      /^mars/i,
      /^avr/i,
      /^mai/i,
      /^juin/i,
      /^juil/i,
      /^août/i,
      /^sept/i,
      /^oct/i,
      /^nov/i,
      /^déc/i
    ], f = n.defineLocale("fr", {
      months: "janvier_février_mars_avril_mai_juin_juillet_août_septembre_octobre_novembre_décembre".split(
        "_"
      ),
      monthsShort: "janv._févr._mars_avr._mai_juin_juil._août_sept._oct._nov._déc.".split(
        "_"
      ),
      monthsRegex: o,
      monthsShortRegex: o,
      monthsStrictRegex: r,
      monthsShortStrictRegex: s,
      monthsParse: l,
      longMonthsParse: l,
      shortMonthsParse: l,
      weekdays: "dimanche_lundi_mardi_mercredi_jeudi_vendredi_samedi".split("_"),
      weekdaysShort: "dim._lun._mar._mer._jeu._ven._sam.".split("_"),
      weekdaysMin: "di_lu_ma_me_je_ve_sa".split("_"),
      weekdaysParseExact: !0,
      longDateFormat: {
        LT: "HH:mm",
        LTS: "HH:mm:ss",
        L: "DD/MM/YYYY",
        LL: "D MMMM YYYY",
        LLL: "D MMMM YYYY HH:mm",
        LLLL: "dddd D MMMM YYYY HH:mm"
      },
      calendar: {
        sameDay: "[Aujourd’hui à] LT",
        nextDay: "[Demain à] LT",
        nextWeek: "dddd [à] LT",
        lastDay: "[Hier à] LT",
        lastWeek: "dddd [dernier à] LT",
        sameElse: "L"
      },
      relativeTime: {
        future: "dans %s",
        past: "il y a %s",
        s: "quelques secondes",
        ss: "%d secondes",
        m: "une minute",
        mm: "%d minutes",
        h: "une heure",
        hh: "%d heures",
        d: "un jour",
        dd: "%d jours",
        w: "une semaine",
        ww: "%d semaines",
        M: "un mois",
        MM: "%d mois",
        y: "un an",
        yy: "%d ans"
      },
      dayOfMonthOrdinalParse: /\d{1,2}(er|)/,
      ordinal: function(w, p) {
        switch (p) {
          case "D":
            return w + (w === 1 ? "er" : "");
          default:
          case "M":
          case "Q":
          case "DDD":
          case "d":
            return w + (w === 1 ? "er" : "e");
          case "w":
          case "W":
            return w + (w === 1 ? "re" : "e");
        }
      },
      week: {
        dow: 1,
        // Monday is the first day of the week.
        doy: 4
        // The week that contains Jan 4th is the first week of the year.
      }
    });
    return f;
  });
})();
(function(e, t) {
  (function(n, r) {
    r(typeof Sn == "function" ? Gn() : n.moment);
  })(b, function(n) {
    //! moment.js locale configuration
    function r(o, l, f, w) {
      var p = {
        m: ["eine Minute", "einer Minute"],
        h: ["eine Stunde", "einer Stunde"],
        d: ["ein Tag", "einem Tag"],
        dd: [o + " Tage", o + " Tagen"],
        w: ["eine Woche", "einer Woche"],
        M: ["ein Monat", "einem Monat"],
        MM: [o + " Monate", o + " Monaten"],
        y: ["ein Jahr", "einem Jahr"],
        yy: [o + " Jahre", o + " Jahren"]
      };
      return l ? p[f][0] : p[f][1];
    }
    var s = n.defineLocale("de", {
      months: "Januar_Februar_März_April_Mai_Juni_Juli_August_September_Oktober_November_Dezember".split(
        "_"
      ),
      monthsShort: "Jan._Feb._März_Apr._Mai_Juni_Juli_Aug._Sep._Okt._Nov._Dez.".split("_"),
      monthsParseExact: !0,
      weekdays: "Sonntag_Montag_Dienstag_Mittwoch_Donnerstag_Freitag_Samstag".split(
        "_"
      ),
      weekdaysShort: "So._Mo._Di._Mi._Do._Fr._Sa.".split("_"),
      weekdaysMin: "So_Mo_Di_Mi_Do_Fr_Sa".split("_"),
      weekdaysParseExact: !0,
      longDateFormat: {
        LT: "HH:mm",
        LTS: "HH:mm:ss",
        L: "DD.MM.YYYY",
        LL: "D. MMMM YYYY",
        LLL: "D. MMMM YYYY HH:mm",
        LLLL: "dddd, D. MMMM YYYY HH:mm"
      },
      calendar: {
        sameDay: "[heute um] LT [Uhr]",
        sameElse: "L",
        nextDay: "[morgen um] LT [Uhr]",
        nextWeek: "dddd [um] LT [Uhr]",
        lastDay: "[gestern um] LT [Uhr]",
        lastWeek: "[letzten] dddd [um] LT [Uhr]"
      },
      relativeTime: {
        future: "in %s",
        past: "vor %s",
        s: "ein paar Sekunden",
        ss: "%d Sekunden",
        m: r,
        mm: "%d Minuten",
        h: r,
        hh: "%d Stunden",
        d: r,
        dd: r,
        w: r,
        ww: "%d Wochen",
        M: r,
        MM: r,
        y: r,
        yy: r
      },
      dayOfMonthOrdinalParse: /\d{1,2}\./,
      ordinal: "%d.",
      week: {
        dow: 1,
        // Monday is the first day of the week.
        doy: 4
        // The week that contains Jan 4th is the first week of the year.
      }
    });
    return s;
  });
})();
(function(e, t) {
  (function(n, r) {
    r(typeof Sn == "function" ? Gn() : n.moment);
  })(b, function(n) {
    //! moment.js locale configuration
    var r = "jan._feb._mrt._apr._mei_jun._jul._aug._sep._okt._nov._dec.".split("_"), s = "jan_feb_mrt_apr_mei_jun_jul_aug_sep_okt_nov_dec".split("_"), o = [
      /^jan/i,
      /^feb/i,
      /^maart|mrt.?$/i,
      /^apr/i,
      /^mei$/i,
      /^jun[i.]?$/i,
      /^jul[i.]?$/i,
      /^aug/i,
      /^sep/i,
      /^okt/i,
      /^nov/i,
      /^dec/i
    ], l = /^(januari|februari|maart|april|mei|ju[nl]i|augustus|september|oktober|november|december|jan\.?|feb\.?|mrt\.?|apr\.?|ju[nl]\.?|aug\.?|sep\.?|okt\.?|nov\.?|dec\.?)/i, f = n.defineLocale("nl", {
      months: "januari_februari_maart_april_mei_juni_juli_augustus_september_oktober_november_december".split(
        "_"
      ),
      monthsShort: function(w, p) {
        return w ? /-MMM-/.test(p) ? s[w.month()] : r[w.month()] : r;
      },
      monthsRegex: l,
      monthsShortRegex: l,
      monthsStrictRegex: /^(januari|februari|maart|april|mei|ju[nl]i|augustus|september|oktober|november|december)/i,
      monthsShortStrictRegex: /^(jan\.?|feb\.?|mrt\.?|apr\.?|mei|ju[nl]\.?|aug\.?|sep\.?|okt\.?|nov\.?|dec\.?)/i,
      monthsParse: o,
      longMonthsParse: o,
      shortMonthsParse: o,
      weekdays: "zondag_maandag_dinsdag_woensdag_donderdag_vrijdag_zaterdag".split("_"),
      weekdaysShort: "zo._ma._di._wo._do._vr._za.".split("_"),
      weekdaysMin: "zo_ma_di_wo_do_vr_za".split("_"),
      weekdaysParseExact: !0,
      longDateFormat: {
        LT: "HH:mm",
        LTS: "HH:mm:ss",
        L: "DD-MM-YYYY",
        LL: "D MMMM YYYY",
        LLL: "D MMMM YYYY HH:mm",
        LLLL: "dddd D MMMM YYYY HH:mm"
      },
      calendar: {
        sameDay: "[vandaag om] LT",
        nextDay: "[morgen om] LT",
        nextWeek: "dddd [om] LT",
        lastDay: "[gisteren om] LT",
        lastWeek: "[afgelopen] dddd [om] LT",
        sameElse: "L"
      },
      relativeTime: {
        future: "over %s",
        past: "%s geleden",
        s: "een paar seconden",
        ss: "%d seconden",
        m: "één minuut",
        mm: "%d minuten",
        h: "één uur",
        hh: "%d uur",
        d: "één dag",
        dd: "%d dagen",
        w: "één week",
        ww: "%d weken",
        M: "één maand",
        MM: "%d maanden",
        y: "één jaar",
        yy: "%d jaar"
      },
      dayOfMonthOrdinalParse: /\d{1,2}(ste|de)/,
      ordinal: function(w) {
        return w + (w === 1 || w === 8 || w >= 20 ? "ste" : "de");
      },
      week: {
        dow: 1,
        // Monday is the first day of the week.
        doy: 4
        // The week that contains Jan 4th is the first week of the year.
      }
    });
    return f;
  });
})();
(function(e, t) {
  (function(n, r) {
    r(typeof Sn == "function" ? Gn() : n.moment);
  })(b, function(n) {
    //! moment.js locale configuration
    var r = n.defineLocale("it", {
      months: "gennaio_febbraio_marzo_aprile_maggio_giugno_luglio_agosto_settembre_ottobre_novembre_dicembre".split(
        "_"
      ),
      monthsShort: "gen_feb_mar_apr_mag_giu_lug_ago_set_ott_nov_dic".split("_"),
      weekdays: "domenica_lunedì_martedì_mercoledì_giovedì_venerdì_sabato".split(
        "_"
      ),
      weekdaysShort: "dom_lun_mar_mer_gio_ven_sab".split("_"),
      weekdaysMin: "do_lu_ma_me_gi_ve_sa".split("_"),
      longDateFormat: {
        LT: "HH:mm",
        LTS: "HH:mm:ss",
        L: "DD/MM/YYYY",
        LL: "D MMMM YYYY",
        LLL: "D MMMM YYYY HH:mm",
        LLLL: "dddd D MMMM YYYY HH:mm"
      },
      calendar: {
        sameDay: function() {
          return "[Oggi a" + (this.hours() > 1 ? "lle " : this.hours() === 0 ? " " : "ll'") + "]LT";
        },
        nextDay: function() {
          return "[Domani a" + (this.hours() > 1 ? "lle " : this.hours() === 0 ? " " : "ll'") + "]LT";
        },
        nextWeek: function() {
          return "dddd [a" + (this.hours() > 1 ? "lle " : this.hours() === 0 ? " " : "ll'") + "]LT";
        },
        lastDay: function() {
          return "[Ieri a" + (this.hours() > 1 ? "lle " : this.hours() === 0 ? " " : "ll'") + "]LT";
        },
        lastWeek: function() {
          switch (this.day()) {
            case 0:
              return "[La scorsa] dddd [a" + (this.hours() > 1 ? "lle " : this.hours() === 0 ? " " : "ll'") + "]LT";
            default:
              return "[Lo scorso] dddd [a" + (this.hours() > 1 ? "lle " : this.hours() === 0 ? " " : "ll'") + "]LT";
          }
        },
        sameElse: "L"
      },
      relativeTime: {
        future: "tra %s",
        past: "%s fa",
        s: "alcuni secondi",
        ss: "%d secondi",
        m: "un minuto",
        mm: "%d minuti",
        h: "un'ora",
        hh: "%d ore",
        d: "un giorno",
        dd: "%d giorni",
        w: "una settimana",
        ww: "%d settimane",
        M: "un mese",
        MM: "%d mesi",
        y: "un anno",
        yy: "%d anni"
      },
      dayOfMonthOrdinalParse: /\d{1,2}º/,
      ordinal: "%dº",
      week: {
        dow: 1,
        // Monday is the first day of the week.
        doy: 4
        // The week that contains Jan 4th is the first week of the year.
      }
    });
    return r;
  });
})();
(function(e, t) {
  (function(n, r) {
    r(typeof Sn == "function" ? Gn() : n.moment);
  })(b, function(n) {
    //! moment.js locale configuration
    var r = n.defineLocale("pt", {
      months: "janeiro_fevereiro_março_abril_maio_junho_julho_agosto_setembro_outubro_novembro_dezembro".split(
        "_"
      ),
      monthsShort: "jan_fev_mar_abr_mai_jun_jul_ago_set_out_nov_dez".split("_"),
      weekdays: "Domingo_Segunda-feira_Terça-feira_Quarta-feira_Quinta-feira_Sexta-feira_Sábado".split(
        "_"
      ),
      weekdaysShort: "Dom_Seg_Ter_Qua_Qui_Sex_Sáb".split("_"),
      weekdaysMin: "Do_2ª_3ª_4ª_5ª_6ª_Sá".split("_"),
      weekdaysParseExact: !0,
      longDateFormat: {
        LT: "HH:mm",
        LTS: "HH:mm:ss",
        L: "DD/MM/YYYY",
        LL: "D [de] MMMM [de] YYYY",
        LLL: "D [de] MMMM [de] YYYY HH:mm",
        LLLL: "dddd, D [de] MMMM [de] YYYY HH:mm"
      },
      calendar: {
        sameDay: "[Hoje às] LT",
        nextDay: "[Amanhã às] LT",
        nextWeek: "dddd [às] LT",
        lastDay: "[Ontem às] LT",
        lastWeek: function() {
          return this.day() === 0 || this.day() === 6 ? "[Último] dddd [às] LT" : "[Última] dddd [às] LT";
        },
        sameElse: "L"
      },
      relativeTime: {
        future: "em %s",
        past: "há %s",
        s: "segundos",
        ss: "%d segundos",
        m: "um minuto",
        mm: "%d minutos",
        h: "uma hora",
        hh: "%d horas",
        d: "um dia",
        dd: "%d dias",
        w: "uma semana",
        ww: "%d semanas",
        M: "um mês",
        MM: "%d meses",
        y: "um ano",
        yy: "%d anos"
      },
      dayOfMonthOrdinalParse: /\d{1,2}º/,
      ordinal: "%dº",
      week: {
        dow: 1,
        // Monday is the first day of the week.
        doy: 4
        // The week that contains Jan 4th is the first week of the year.
      }
    });
    return r;
  });
})();
(function(e, t) {
  (function(n, r) {
    r(typeof Sn == "function" ? Gn() : n.moment);
  })(b, function(n) {
    //! moment.js locale configuration
    var r = n.defineLocale("nb", {
      months: "januar_februar_mars_april_mai_juni_juli_august_september_oktober_november_desember".split(
        "_"
      ),
      monthsShort: "jan._feb._mars_apr._mai_juni_juli_aug._sep._okt._nov._des.".split("_"),
      monthsParseExact: !0,
      weekdays: "søndag_mandag_tirsdag_onsdag_torsdag_fredag_lørdag".split("_"),
      weekdaysShort: "sø._ma._ti._on._to._fr._lø.".split("_"),
      weekdaysMin: "sø_ma_ti_on_to_fr_lø".split("_"),
      weekdaysParseExact: !0,
      longDateFormat: {
        LT: "HH:mm",
        LTS: "HH:mm:ss",
        L: "DD.MM.YYYY",
        LL: "D. MMMM YYYY",
        LLL: "D. MMMM YYYY [kl.] HH:mm",
        LLLL: "dddd D. MMMM YYYY [kl.] HH:mm"
      },
      calendar: {
        sameDay: "[i dag kl.] LT",
        nextDay: "[i morgen kl.] LT",
        nextWeek: "dddd [kl.] LT",
        lastDay: "[i går kl.] LT",
        lastWeek: "[forrige] dddd [kl.] LT",
        sameElse: "L"
      },
      relativeTime: {
        future: "om %s",
        past: "%s siden",
        s: "noen sekunder",
        ss: "%d sekunder",
        m: "ett minutt",
        mm: "%d minutter",
        h: "en time",
        hh: "%d timer",
        d: "en dag",
        dd: "%d dager",
        w: "en uke",
        ww: "%d uker",
        M: "en måned",
        MM: "%d måneder",
        y: "ett år",
        yy: "%d år"
      },
      dayOfMonthOrdinalParse: /\d{1,2}\./,
      ordinal: "%d.",
      week: {
        dow: 1,
        // Monday is the first day of the week.
        doy: 4
        // The week that contains Jan 4th is the first week of the year.
      }
    });
    return r;
  });
})();
class kn {
  static addDays(t, n = /* @__PURE__ */ new Date()) {
    return R(n).add(t, "days").toDate();
  }
  static removeDays(t, n = /* @__PURE__ */ new Date()) {
    return R(n).subtract(t, "days").toDate();
  }
  static addMonths(t, n = /* @__PURE__ */ new Date()) {
    return R(n).add(t, "months").toDate();
  }
  static removeMonths(t, n = /* @__PURE__ */ new Date()) {
    return R(n).subtract(t, "months").toDate();
  }
  static addYears(t, n = /* @__PURE__ */ new Date()) {
    return R(n).add(t, "years").toDate();
  }
  static removeYears(t, n = /* @__PURE__ */ new Date()) {
    return R(n).subtract(t, "years").toDate();
  }
  static addHours(t, n = /* @__PURE__ */ new Date()) {
    return R(n).add(t, "hours").toDate();
  }
  static removeHours(t, n = /* @__PURE__ */ new Date()) {
    return R(n).subtract(t, "hours").toDate();
  }
  static addMinutes(t, n = /* @__PURE__ */ new Date()) {
    return R(n).add(t, "minutes").toDate();
  }
  static removeMinutes(t, n = /* @__PURE__ */ new Date()) {
    return R(n).subtract(t, "minutes").toDate();
  }
  static addSeconds(t, n = /* @__PURE__ */ new Date()) {
    return R(n).add(t, "seconds").toDate();
  }
  static removeSeconds(t, n = /* @__PURE__ */ new Date()) {
    return R(n).subtract(t, "seconds").toDate();
  }
  static diff(t, n, r, s) {
    return R(t).diff(R(n), r, s);
  }
  static format(t, n) {
    return t.toLocaleDateString(n, { year: "numeric", month: "long", day: "numeric", weekday: "long" });
  }
  static momentFormat(t, n = R.defaultFormat) {
    return R(t).format(n);
  }
  static formatTime(t) {
    return R(t).format("HH:mm");
  }
  static isSameDate(t, n, r) {
    return r === "day" ? R(t).isSame(R(n), "day") : r === "month" ? R(t).isSame(R(n), "month") : r === "year" ? R(t).isSame(R(n), "year") : kn.isSameDate(t, n, "day") && kn.isSameDate(t, n, "month") && kn.isSameDate(t, n, "year");
  }
  static isBefore(t, n, r) {
    return R(t).isBefore(n, r);
  }
  static isAfter(t, n, r) {
    return R(t).isAfter(n, r);
  }
  static allDay() {
    return [
      {
        day_id: 1,
        name: "monday"
      },
      {
        day_id: 2,
        name: "tuesday"
      },
      {
        day_id: 3,
        name: "wednesday"
      },
      {
        day_id: 4,
        name: "thursday"
      },
      {
        day_id: 5,
        name: "friday"
      },
      {
        day_id: 6,
        name: "saturday"
      },
      {
        day_id: 7,
        name: "sunday"
      }
    ];
  }
  static dateIsBetween(t, n, r, s = "()") {
    return R(t).isBetween(n.start, n.end, r, s);
  }
  static timeIsBetween(t, n) {
    if (!n.start || !n.end)
      return !1;
    const r = Number(t.replace(":", "")), s = Number(n.start.replace(":", "")), o = Number(n.end.replace(":", ""));
    return r >= s && r <= o;
  }
  static getDayIdLikeMomentJs(t) {
    return R(t).isoWeekday();
  }
  static generateTimesBetween(t, n, r = 15) {
    const s = Math.floor(
      Math.abs(
        +`${t.getHours()}.${t.getMinutes()}` - +`${n.getHours()}.${n.getMinutes()}`
      ) * 100
    ) / 100 * (60 / r), o = new Date(t), l = [];
    for (let f = 0; f <= s; f++) {
      const w = kn.addMinutes(f * r, o);
      l.push(kn.formatTime(w));
    }
    return l;
  }
  static calendar(t) {
    return R(t).calendar();
  }
  static calendarSentence(t, n) {
    return R(n).calendar(t);
  }
  static setLocale(t, n) {
    R.locale(t, n);
  }
}
var ar = {}, Li = {}, Ve = {};
Object.defineProperty(Ve, "__esModule", { value: !0 });
Ve.TargetTypeEnum = Ve.BindingTypeEnum = Ve.BindingScopeEnum = void 0;
var vw = {
  Request: "Request",
  Singleton: "Singleton",
  Transient: "Transient"
};
Ve.BindingScopeEnum = vw;
var ww = {
  ConstantValue: "ConstantValue",
  Constructor: "Constructor",
  DynamicValue: "DynamicValue",
  Factory: "Factory",
  Function: "Function",
  Instance: "Instance",
  Invalid: "Invalid",
  Provider: "Provider"
};
Ve.BindingTypeEnum = ww;
var Sw = {
  ClassProperty: "ClassProperty",
  ConstructorArgument: "ConstructorArgument",
  Variable: "Variable"
};
Ve.TargetTypeEnum = Sw;
var Pt = {};
Object.defineProperty(Pt, "__esModule", { value: !0 });
Pt.id = void 0;
var bw = 0;
function Mw() {
  return bw++;
}
Pt.id = Mw;
Object.defineProperty(Li, "__esModule", { value: !0 });
Li.Binding = void 0;
var pu = Ve, Ow = Pt, Dw = function() {
  function e(t, n) {
    this.id = (0, Ow.id)(), this.activated = !1, this.serviceIdentifier = t, this.scope = n, this.type = pu.BindingTypeEnum.Invalid, this.constraint = function(r) {
      return !0;
    }, this.implementationType = null, this.cache = null, this.factory = null, this.provider = null, this.onActivation = null, this.onDeactivation = null, this.dynamicValue = null;
  }
  return e.prototype.clone = function() {
    var t = new e(this.serviceIdentifier, this.scope);
    return t.activated = t.scope === pu.BindingScopeEnum.Singleton ? this.activated : !1, t.implementationType = this.implementationType, t.dynamicValue = this.dynamicValue, t.scope = this.scope, t.type = this.type, t.factory = this.factory, t.provider = this.provider, t.constraint = this.constraint, t.onActivation = this.onActivation, t.onDeactivation = this.onDeactivation, t.cache = this.cache, t;
  }, e;
}();
Li.Binding = Dw;
var x = {};
Object.defineProperty(x, "__esModule", { value: !0 });
x.STACK_OVERFLOW = x.CIRCULAR_DEPENDENCY_IN_FACTORY = x.ON_DEACTIVATION_ERROR = x.PRE_DESTROY_ERROR = x.POST_CONSTRUCT_ERROR = x.ASYNC_UNBIND_REQUIRED = x.MULTIPLE_POST_CONSTRUCT_METHODS = x.MULTIPLE_PRE_DESTROY_METHODS = x.CONTAINER_OPTIONS_INVALID_SKIP_BASE_CHECK = x.CONTAINER_OPTIONS_INVALID_AUTO_BIND_INJECTABLE = x.CONTAINER_OPTIONS_INVALID_DEFAULT_SCOPE = x.CONTAINER_OPTIONS_MUST_BE_AN_OBJECT = x.ARGUMENTS_LENGTH_MISMATCH = x.INVALID_DECORATOR_OPERATION = x.INVALID_TO_SELF_VALUE = x.LAZY_IN_SYNC = x.INVALID_FUNCTION_BINDING = x.INVALID_MIDDLEWARE_RETURN = x.NO_MORE_SNAPSHOTS_AVAILABLE = x.INVALID_BINDING_TYPE = x.NOT_IMPLEMENTED = x.CIRCULAR_DEPENDENCY = x.UNDEFINED_INJECT_ANNOTATION = x.MISSING_INJECT_ANNOTATION = x.MISSING_INJECTABLE_ANNOTATION = x.NOT_REGISTERED = x.CANNOT_UNBIND = x.AMBIGUOUS_MATCH = x.KEY_NOT_FOUND = x.NULL_ARGUMENT = x.DUPLICATED_METADATA = x.DUPLICATED_INJECTABLE_DECORATOR = void 0;
x.DUPLICATED_INJECTABLE_DECORATOR = "Cannot apply @injectable decorator multiple times.";
x.DUPLICATED_METADATA = "Metadata key was used more than once in a parameter:";
x.NULL_ARGUMENT = "NULL argument";
x.KEY_NOT_FOUND = "Key Not Found";
x.AMBIGUOUS_MATCH = "Ambiguous match found for serviceIdentifier:";
x.CANNOT_UNBIND = "Could not unbind serviceIdentifier:";
x.NOT_REGISTERED = "No matching bindings found for serviceIdentifier:";
x.MISSING_INJECTABLE_ANNOTATION = "Missing required @injectable annotation in:";
x.MISSING_INJECT_ANNOTATION = "Missing required @inject or @multiInject annotation in:";
var Tw = function(e) {
  return "@inject called with undefined this could mean that the class " + e + " has a circular dependency problem. You can use a LazyServiceIdentifer to  overcome this limitation.";
};
x.UNDEFINED_INJECT_ANNOTATION = Tw;
x.CIRCULAR_DEPENDENCY = "Circular dependency found:";
x.NOT_IMPLEMENTED = "Sorry, this feature is not fully implemented yet.";
x.INVALID_BINDING_TYPE = "Invalid binding type:";
x.NO_MORE_SNAPSHOTS_AVAILABLE = "No snapshot available to restore.";
x.INVALID_MIDDLEWARE_RETURN = "Invalid return type in middleware. Middleware must return!";
x.INVALID_FUNCTION_BINDING = "Value provided to function binding must be a function!";
var Aw = function(e) {
  return "You are attempting to construct '" + e + `' in a synchronous way
 but it has asynchronous dependencies.`;
};
x.LAZY_IN_SYNC = Aw;
x.INVALID_TO_SELF_VALUE = "The toSelf function can only be applied when a constructor is used as service identifier";
x.INVALID_DECORATOR_OPERATION = "The @inject @multiInject @tagged and @named decorators must be applied to the parameters of a class constructor or a class property.";
var Ew = function() {
  for (var e = [], t = 0; t < arguments.length; t++)
    e[t] = arguments[t];
  return "The number of constructor arguments in the derived class " + (e[0] + " must be >= than the number of constructor arguments of its base class.");
};
x.ARGUMENTS_LENGTH_MISMATCH = Ew;
x.CONTAINER_OPTIONS_MUST_BE_AN_OBJECT = "Invalid Container constructor argument. Container options must be an object.";
x.CONTAINER_OPTIONS_INVALID_DEFAULT_SCOPE = "Invalid Container option. Default scope must be a string ('singleton' or 'transient').";
x.CONTAINER_OPTIONS_INVALID_AUTO_BIND_INJECTABLE = "Invalid Container option. Auto bind injectable must be a boolean";
x.CONTAINER_OPTIONS_INVALID_SKIP_BASE_CHECK = "Invalid Container option. Skip base check must be a boolean";
x.MULTIPLE_PRE_DESTROY_METHODS = "Cannot apply @preDestroy decorator multiple times in the same class";
x.MULTIPLE_POST_CONSTRUCT_METHODS = "Cannot apply @postConstruct decorator multiple times in the same class";
x.ASYNC_UNBIND_REQUIRED = "Attempting to unbind dependency with asynchronous destruction (@preDestroy or onDeactivation)";
var Pw = function(e, t) {
  return "@postConstruct error in class " + e + ": " + t;
};
x.POST_CONSTRUCT_ERROR = Pw;
var Nw = function(e, t) {
  return "@preDestroy error in class " + e + ": " + t;
};
x.PRE_DESTROY_ERROR = Nw;
var Rw = function(e, t) {
  return "onDeactivation() error in class " + e + ": " + t;
};
x.ON_DEACTIVATION_ERROR = Rw;
var kw = function(e, t) {
  return "It looks like there is a circular dependency in one of the '" + e + "' bindings. Please investigate bindings with" + ("service identifier '" + t + "'.");
};
x.CIRCULAR_DEPENDENCY_IN_FACTORY = kw;
x.STACK_OVERFLOW = "Maximum call stack size exceeded";
var Le = {};
(function(e) {
  Object.defineProperty(e, "__esModule", { value: !0 }), e.NON_CUSTOM_TAG_KEYS = e.PRE_DESTROY = e.POST_CONSTRUCT = e.DESIGN_PARAM_TYPES = e.PARAM_TYPES = e.TAGGED_PROP = e.TAGGED = e.MULTI_INJECT_TAG = e.INJECT_TAG = e.OPTIONAL_TAG = e.UNMANAGED_TAG = e.NAME_TAG = e.NAMED_TAG = void 0, e.NAMED_TAG = "named", e.NAME_TAG = "name", e.UNMANAGED_TAG = "unmanaged", e.OPTIONAL_TAG = "optional", e.INJECT_TAG = "inject", e.MULTI_INJECT_TAG = "multi_inject", e.TAGGED = "inversify:tagged", e.TAGGED_PROP = "inversify:tagged_props", e.PARAM_TYPES = "inversify:paramtypes", e.DESIGN_PARAM_TYPES = "design:paramtypes", e.POST_CONSTRUCT = "post_construct", e.PRE_DESTROY = "pre_destroy";
  function t() {
    return [
      e.INJECT_TAG,
      e.MULTI_INJECT_TAG,
      e.NAME_TAG,
      e.UNMANAGED_TAG,
      e.NAMED_TAG,
      e.OPTIONAL_TAG
    ];
  }
  e.NON_CUSTOM_TAG_KEYS = t();
})(Le);
var Mr = {}, Iw = b && b.__createBinding || (Object.create ? function(e, t, n, r) {
  r === void 0 && (r = n), Object.defineProperty(e, r, { enumerable: !0, get: function() {
    return t[n];
  } });
} : function(e, t, n, r) {
  r === void 0 && (r = n), e[r] = t[n];
}), Yw = b && b.__setModuleDefault || (Object.create ? function(e, t) {
  Object.defineProperty(e, "default", { enumerable: !0, value: t });
} : function(e, t) {
  e.default = t;
}), Cw = b && b.__importStar || function(e) {
  if (e && e.__esModule)
    return e;
  var t = {};
  if (e != null)
    for (var n in e)
      n !== "default" && Object.prototype.hasOwnProperty.call(e, n) && Iw(t, e, n);
  return Yw(t, e), t;
};
Object.defineProperty(Mr, "__esModule", { value: !0 });
Mr.MetadataReader = void 0;
var Va = Cw(Le), jw = function() {
  function e() {
  }
  return e.prototype.getConstructorMetadata = function(t) {
    var n = Reflect.getMetadata(Va.PARAM_TYPES, t), r = Reflect.getMetadata(Va.TAGGED, t);
    return {
      compilerGeneratedMetadata: n,
      userGeneratedMetadata: r || {}
    };
  }, e.prototype.getPropertiesMetadata = function(t) {
    var n = Reflect.getMetadata(Va.TAGGED_PROP, t) || [];
    return n;
  }, e;
}();
Mr.MetadataReader = jw;
var Vt = {}, xi = {};
Object.defineProperty(xi, "__esModule", { value: !0 });
xi.BindingCount = void 0;
var Lw = {
  MultipleBindingsAvailable: 2,
  NoBindingsAvailable: 0,
  OnlyOneBindingAvailable: 1
};
xi.BindingCount = Lw;
var vn = {}, xw = b && b.__createBinding || (Object.create ? function(e, t, n, r) {
  r === void 0 && (r = n), Object.defineProperty(e, r, { enumerable: !0, get: function() {
    return t[n];
  } });
} : function(e, t, n, r) {
  r === void 0 && (r = n), e[r] = t[n];
}), Fw = b && b.__setModuleDefault || (Object.create ? function(e, t) {
  Object.defineProperty(e, "default", { enumerable: !0, value: t });
} : function(e, t) {
  e.default = t;
}), Bw = b && b.__importStar || function(e) {
  if (e && e.__esModule)
    return e;
  var t = {};
  if (e != null)
    for (var n in e)
      n !== "default" && Object.prototype.hasOwnProperty.call(e, n) && xw(t, e, n);
  return Fw(t, e), t;
};
Object.defineProperty(vn, "__esModule", { value: !0 });
vn.tryAndThrowErrorIfStackOverflow = vn.isStackOverflowExeption = void 0;
var Uw = Bw(x);
function Qc(e) {
  return e instanceof RangeError || e.message === Uw.STACK_OVERFLOW;
}
vn.isStackOverflowExeption = Qc;
var Ww = function(e, t) {
  try {
    return e();
  } catch (n) {
    throw Qc(n) && (n = t()), n;
  }
};
vn.tryAndThrowErrorIfStackOverflow = Ww;
var We = {}, Hw = b && b.__createBinding || (Object.create ? function(e, t, n, r) {
  r === void 0 && (r = n), Object.defineProperty(e, r, { enumerable: !0, get: function() {
    return t[n];
  } });
} : function(e, t, n, r) {
  r === void 0 && (r = n), e[r] = t[n];
}), Gw = b && b.__setModuleDefault || (Object.create ? function(e, t) {
  Object.defineProperty(e, "default", { enumerable: !0, value: t });
} : function(e, t) {
  e.default = t;
}), $w = b && b.__importStar || function(e) {
  if (e && e.__esModule)
    return e;
  var t = {};
  if (e != null)
    for (var n in e)
      n !== "default" && Object.prototype.hasOwnProperty.call(e, n) && Hw(t, e, n);
  return Gw(t, e), t;
};
Object.defineProperty(We, "__esModule", { value: !0 });
We.getSymbolDescription = We.circularDependencyToException = We.listMetadataForTarget = We.listRegisteredBindingsForServiceIdentifier = We.getServiceIdentifierAsString = We.getFunctionName = void 0;
var Vw = $w(x);
function Xc(e) {
  if (typeof e == "function") {
    var t = e;
    return t.name;
  } else {
    if (typeof e == "symbol")
      return e.toString();
    var t = e;
    return t;
  }
}
We.getServiceIdentifierAsString = Xc;
function zw(e, t, n) {
  var r = "", s = n(e, t);
  return s.length !== 0 && (r = `
Registered bindings:`, s.forEach(function(o) {
    var l = "Object";
    o.implementationType !== null && (l = nl(o.implementationType)), r = r + `
 ` + l, o.constraint.metaData && (r = r + " - " + o.constraint.metaData);
  })), r;
}
We.listRegisteredBindingsForServiceIdentifier = zw;
function el(e, t) {
  return e.parentRequest === null ? !1 : e.parentRequest.serviceIdentifier === t ? !0 : el(e.parentRequest, t);
}
function qw(e) {
  function t(r, s) {
    s === void 0 && (s = []);
    var o = Xc(r.serviceIdentifier);
    return s.push(o), r.parentRequest !== null ? t(r.parentRequest, s) : s;
  }
  var n = t(e);
  return n.reverse().join(" --> ");
}
function tl(e) {
  e.childRequests.forEach(function(t) {
    if (el(t, t.serviceIdentifier)) {
      var n = qw(t);
      throw new Error(Vw.CIRCULAR_DEPENDENCY + " " + n);
    } else
      tl(t);
  });
}
We.circularDependencyToException = tl;
function Jw(e, t) {
  if (t.isTagged() || t.isNamed()) {
    var n = "", r = t.getNamedTag(), s = t.getCustomTags();
    return r !== null && (n += r.toString() + `
`), s !== null && s.forEach(function(o) {
      n += o.toString() + `
`;
    }), " " + e + `
 ` + e + " - " + n;
  } else
    return " " + e;
}
We.listMetadataForTarget = Jw;
function nl(e) {
  if (e.name)
    return e.name;
  var t = e.toString(), n = t.match(/^function\s*([^\s(]+)/);
  return n ? n[1] : "Anonymous function: " + t;
}
We.getFunctionName = nl;
function Zw(e) {
  return e.toString().slice(7, -1);
}
We.getSymbolDescription = Zw;
var Fi = {};
Object.defineProperty(Fi, "__esModule", { value: !0 });
Fi.Context = void 0;
var Kw = Pt, Qw = function() {
  function e(t) {
    this.id = (0, Kw.id)(), this.container = t;
  }
  return e.prototype.addPlan = function(t) {
    this.plan = t;
  }, e.prototype.setCurrentRequest = function(t) {
    this.currentRequest = t;
  }, e;
}();
Fi.Context = Qw;
var st = {}, Xw = b && b.__createBinding || (Object.create ? function(e, t, n, r) {
  r === void 0 && (r = n), Object.defineProperty(e, r, { enumerable: !0, get: function() {
    return t[n];
  } });
} : function(e, t, n, r) {
  r === void 0 && (r = n), e[r] = t[n];
}), eS = b && b.__setModuleDefault || (Object.create ? function(e, t) {
  Object.defineProperty(e, "default", { enumerable: !0, value: t });
} : function(e, t) {
  e.default = t;
}), tS = b && b.__importStar || function(e) {
  if (e && e.__esModule)
    return e;
  var t = {};
  if (e != null)
    for (var n in e)
      n !== "default" && Object.prototype.hasOwnProperty.call(e, n) && Xw(t, e, n);
  return eS(t, e), t;
};
Object.defineProperty(st, "__esModule", { value: !0 });
st.Metadata = void 0;
var nS = tS(Le), rS = function() {
  function e(t, n) {
    this.key = t, this.value = n;
  }
  return e.prototype.toString = function() {
    return this.key === nS.NAMED_TAG ? "named: " + String(this.value).toString() + " " : "tagged: { key:" + this.key.toString() + ", value: " + String(this.value) + " }";
  }, e;
}();
st.Metadata = rS;
var Bi = {};
Object.defineProperty(Bi, "__esModule", { value: !0 });
Bi.Plan = void 0;
var iS = function() {
  function e(t, n) {
    this.parentContext = t, this.rootRequest = n;
  }
  return e;
}();
Bi.Plan = iS;
var rl = {}, Or = {};
Object.defineProperty(Or, "__esModule", { value: !0 });
Or.LazyServiceIdentifer = void 0;
var aS = function() {
  function e(t) {
    this._cb = t;
  }
  return e.prototype.unwrap = function() {
    return this._cb();
  }, e;
}();
Or.LazyServiceIdentifer = aS;
var Dr = {}, Ui = {};
Object.defineProperty(Ui, "__esModule", { value: !0 });
Ui.QueryableString = void 0;
var sS = function() {
  function e(t) {
    this.str = t;
  }
  return e.prototype.startsWith = function(t) {
    return this.str.indexOf(t) === 0;
  }, e.prototype.endsWith = function(t) {
    var n = "", r = t.split("").reverse().join("");
    return n = this.str.split("").reverse().join(""), this.startsWith.call({ str: n }, r);
  }, e.prototype.contains = function(t) {
    return this.str.indexOf(t) !== -1;
  }, e.prototype.equals = function(t) {
    return this.str === t;
  }, e.prototype.value = function() {
    return this.str;
  }, e;
}();
Ui.QueryableString = sS;
var oS = b && b.__createBinding || (Object.create ? function(e, t, n, r) {
  r === void 0 && (r = n), Object.defineProperty(e, r, { enumerable: !0, get: function() {
    return t[n];
  } });
} : function(e, t, n, r) {
  r === void 0 && (r = n), e[r] = t[n];
}), uS = b && b.__setModuleDefault || (Object.create ? function(e, t) {
  Object.defineProperty(e, "default", { enumerable: !0, value: t });
} : function(e, t) {
  e.default = t;
}), cS = b && b.__importStar || function(e) {
  if (e && e.__esModule)
    return e;
  var t = {};
  if (e != null)
    for (var n in e)
      n !== "default" && Object.prototype.hasOwnProperty.call(e, n) && oS(t, e, n);
  return uS(t, e), t;
};
Object.defineProperty(Dr, "__esModule", { value: !0 });
Dr.Target = void 0;
var Ft = cS(Le), lS = Pt, dS = We, gu = st, fS = Ui, hS = function() {
  function e(t, n, r, s) {
    this.id = (0, lS.id)(), this.type = t, this.serviceIdentifier = r;
    var o = typeof n == "symbol" ? (0, dS.getSymbolDescription)(n) : n;
    this.name = new fS.QueryableString(o || ""), this.identifier = n, this.metadata = new Array();
    var l = null;
    typeof s == "string" ? l = new gu.Metadata(Ft.NAMED_TAG, s) : s instanceof gu.Metadata && (l = s), l !== null && this.metadata.push(l);
  }
  return e.prototype.hasTag = function(t) {
    for (var n = 0, r = this.metadata; n < r.length; n++) {
      var s = r[n];
      if (s.key === t)
        return !0;
    }
    return !1;
  }, e.prototype.isArray = function() {
    return this.hasTag(Ft.MULTI_INJECT_TAG);
  }, e.prototype.matchesArray = function(t) {
    return this.matchesTag(Ft.MULTI_INJECT_TAG)(t);
  }, e.prototype.isNamed = function() {
    return this.hasTag(Ft.NAMED_TAG);
  }, e.prototype.isTagged = function() {
    return this.metadata.some(function(t) {
      return Ft.NON_CUSTOM_TAG_KEYS.every(function(n) {
        return t.key !== n;
      });
    });
  }, e.prototype.isOptional = function() {
    return this.matchesTag(Ft.OPTIONAL_TAG)(!0);
  }, e.prototype.getNamedTag = function() {
    return this.isNamed() ? this.metadata.filter(function(t) {
      return t.key === Ft.NAMED_TAG;
    })[0] : null;
  }, e.prototype.getCustomTags = function() {
    return this.isTagged() ? this.metadata.filter(function(t) {
      return Ft.NON_CUSTOM_TAG_KEYS.every(function(n) {
        return t.key !== n;
      });
    }) : null;
  }, e.prototype.matchesNamedTag = function(t) {
    return this.matchesTag(Ft.NAMED_TAG)(t);
  }, e.prototype.matchesTag = function(t) {
    var n = this;
    return function(r) {
      for (var s = 0, o = n.metadata; s < o.length; s++) {
        var l = o[s];
        if (l.key === t && l.value === r)
          return !0;
      }
      return !1;
    };
  }, e;
}();
Dr.Target = hS;
(function(e) {
  var t = b && b.__createBinding || (Object.create ? function(U, K, ie, A) {
    A === void 0 && (A = ie), Object.defineProperty(U, A, { enumerable: !0, get: function() {
      return K[ie];
    } });
  } : function(U, K, ie, A) {
    A === void 0 && (A = ie), U[A] = K[ie];
  }), n = b && b.__setModuleDefault || (Object.create ? function(U, K) {
    Object.defineProperty(U, "default", { enumerable: !0, value: K });
  } : function(U, K) {
    U.default = K;
  }), r = b && b.__importStar || function(U) {
    if (U && U.__esModule)
      return U;
    var K = {};
    if (U != null)
      for (var ie in U)
        ie !== "default" && Object.prototype.hasOwnProperty.call(U, ie) && t(K, U, ie);
    return n(K, U), K;
  }, s = b && b.__spreadArray || function(U, K, ie) {
    if (ie || arguments.length === 2)
      for (var A = 0, y = K.length, h; A < y; A++)
        (h || !(A in K)) && (h || (h = Array.prototype.slice.call(K, 0, A)), h[A] = K[A]);
    return U.concat(h || Array.prototype.slice.call(K));
  };
  Object.defineProperty(e, "__esModule", { value: !0 }), e.getFunctionName = e.getBaseClassDependencyCount = e.getDependencies = void 0;
  var o = Or, l = r(x), f = Ve, w = r(Le), p = We;
  Object.defineProperty(e, "getFunctionName", { enumerable: !0, get: function() {
    return p.getFunctionName;
  } });
  var _ = Dr;
  function O(U, K) {
    var ie = (0, p.getFunctionName)(K);
    return F(U, ie, K, !1);
  }
  e.getDependencies = O;
  function F(U, K, ie, A) {
    var y = U.getConstructorMetadata(ie), h = y.compilerGeneratedMetadata;
    if (h === void 0) {
      var v = l.MISSING_INJECTABLE_ANNOTATION + " " + K + ".";
      throw new Error(v);
    }
    var S = y.userGeneratedMetadata, g = Object.keys(S), P = ie.length === 0 && g.length > 0, k = g.length > ie.length, j = P || k ? g.length : ie.length, G = E(A, K, h, S, j), Q = W(U, ie, K), le = s(s([], G, !0), Q, !0);
    return le;
  }
  function H(U, K, ie, A, y) {
    var h = y[U.toString()] || [], v = Z(h), S = v.unmanaged !== !0, g = A[U], P = v.inject || v.multiInject;
    if (g = P || g, g instanceof o.LazyServiceIdentifer && (g = g.unwrap()), S) {
      var k = g === Object, j = g === Function, G = g === void 0, Q = k || j || G;
      if (!K && Q) {
        var le = l.MISSING_INJECT_ANNOTATION + " argument " + U + " in class " + ie + ".";
        throw new Error(le);
      }
      var J = new _.Target(f.TargetTypeEnum.ConstructorArgument, v.targetName, g);
      return J.metadata = h, J;
    }
    return null;
  }
  function E(U, K, ie, A, y) {
    for (var h = [], v = 0; v < y; v++) {
      var S = v, g = H(S, U, K, ie, A);
      g !== null && h.push(g);
    }
    return h;
  }
  function $(U, K, ie, A) {
    var y = U || K;
    if (y === void 0) {
      var h = l.MISSING_INJECTABLE_ANNOTATION + " for property " + String(ie) + " in class " + A + ".";
      throw new Error(h);
    }
    return y;
  }
  function W(U, K, ie) {
    for (var A = U.getPropertiesMetadata(K), y = [], h = Object.getOwnPropertySymbols(A), v = Object.keys(A), S = v.concat(h), g = 0, P = S; g < P.length; g++) {
      var k = P[g], j = A[k], G = Z(j), Q = G.targetName || k, le = $(G.inject, G.multiInject, k, ie), J = new _.Target(f.TargetTypeEnum.ClassProperty, Q, le);
      J.metadata = j, y.push(J);
    }
    var Ie = Object.getPrototypeOf(K.prototype).constructor;
    if (Ie !== Object) {
      var xe = W(U, Ie, ie);
      y = s(s([], y, !0), xe, !0);
    }
    return y;
  }
  function ve(U, K) {
    var ie = Object.getPrototypeOf(K.prototype).constructor;
    if (ie !== Object) {
      var A = (0, p.getFunctionName)(ie), y = F(U, A, ie, !0), h = y.map(function(g) {
        return g.metadata.filter(function(P) {
          return P.key === w.UNMANAGED_TAG;
        });
      }), v = [].concat.apply([], h).length, S = y.length - v;
      return S > 0 ? S : ve(U, ie);
    } else
      return 0;
  }
  e.getBaseClassDependencyCount = ve;
  function Z(U) {
    var K = {};
    return U.forEach(function(ie) {
      K[ie.key.toString()] = ie.value;
    }), {
      inject: K[w.INJECT_TAG],
      multiInject: K[w.MULTI_INJECT_TAG],
      targetName: K[w.NAME_TAG],
      unmanaged: K[w.UNMANAGED_TAG]
    };
  }
})(rl);
var Wi = {};
Object.defineProperty(Wi, "__esModule", { value: !0 });
Wi.Request = void 0;
var _S = Pt, yS = function() {
  function e(t, n, r, s, o) {
    this.id = (0, _S.id)(), this.serviceIdentifier = t, this.parentContext = n, this.parentRequest = r, this.target = o, this.childRequests = [], this.bindings = Array.isArray(s) ? s : [s], this.requestScope = r === null ? /* @__PURE__ */ new Map() : null;
  }
  return e.prototype.addChildRequest = function(t, n, r) {
    var s = new e(t, this.parentContext, this, n, r);
    return this.childRequests.push(s), s;
  }, e;
}();
Wi.Request = yS;
var mS = b && b.__createBinding || (Object.create ? function(e, t, n, r) {
  r === void 0 && (r = n), Object.defineProperty(e, r, { enumerable: !0, get: function() {
    return t[n];
  } });
} : function(e, t, n, r) {
  r === void 0 && (r = n), e[r] = t[n];
}), pS = b && b.__setModuleDefault || (Object.create ? function(e, t) {
  Object.defineProperty(e, "default", { enumerable: !0, value: t });
} : function(e, t) {
  e.default = t;
}), il = b && b.__importStar || function(e) {
  if (e && e.__esModule)
    return e;
  var t = {};
  if (e != null)
    for (var n in e)
      n !== "default" && Object.prototype.hasOwnProperty.call(e, n) && mS(t, e, n);
  return pS(t, e), t;
};
Object.defineProperty(Vt, "__esModule", { value: !0 });
Vt.getBindingDictionary = Vt.createMockRequest = Vt.plan = void 0;
var oi = xi, ms = il(x), al = Ve, vu = il(Le), gS = vn, In = We, sl = Fi, ps = st, vS = Bi, za = rl, Js = Wi, ol = Dr;
function ul(e) {
  return e._bindingDictionary;
}
Vt.getBindingDictionary = ul;
function wS(e, t, n, r, s, o) {
  var l = e ? vu.MULTI_INJECT_TAG : vu.INJECT_TAG, f = new ps.Metadata(l, n), w = new ol.Target(t, r, n, f);
  if (s !== void 0) {
    var p = new ps.Metadata(s, o);
    w.metadata.push(p);
  }
  return w;
}
function wu(e, t, n, r, s) {
  var o = yr(n.container, s.serviceIdentifier), l = [];
  return o.length === oi.BindingCount.NoBindingsAvailable && n.container.options.autoBindInjectable && typeof s.serviceIdentifier == "function" && e.getConstructorMetadata(s.serviceIdentifier).compilerGeneratedMetadata && (n.container.bind(s.serviceIdentifier).toSelf(), o = yr(n.container, s.serviceIdentifier)), t ? l = o : l = o.filter(function(f) {
    var w = new Js.Request(f.serviceIdentifier, n, r, f, s);
    return f.constraint(w);
  }), SS(s.serviceIdentifier, l, s, n.container), l;
}
function SS(e, t, n, r) {
  switch (t.length) {
    case oi.BindingCount.NoBindingsAvailable:
      if (n.isOptional())
        return t;
      var s = (0, In.getServiceIdentifierAsString)(e), o = ms.NOT_REGISTERED;
      throw o += (0, In.listMetadataForTarget)(s, n), o += (0, In.listRegisteredBindingsForServiceIdentifier)(r, s, yr), new Error(o);
    case oi.BindingCount.OnlyOneBindingAvailable:
      return t;
    case oi.BindingCount.MultipleBindingsAvailable:
    default:
      if (n.isArray())
        return t;
      var s = (0, In.getServiceIdentifierAsString)(e), o = ms.AMBIGUOUS_MATCH + " " + s;
      throw o += (0, In.listRegisteredBindingsForServiceIdentifier)(r, s, yr), new Error(o);
  }
}
function cl(e, t, n, r, s, o) {
  var l, f;
  if (s === null) {
    l = wu(e, t, r, null, o), f = new Js.Request(n, r, null, l, o);
    var w = new vS.Plan(r, f);
    r.addPlan(w);
  } else
    l = wu(e, t, r, s, o), f = s.addChildRequest(o.serviceIdentifier, l, o);
  l.forEach(function(p) {
    var _ = null;
    if (o.isArray())
      _ = f.addChildRequest(p.serviceIdentifier, p, o);
    else {
      if (p.cache)
        return;
      _ = f;
    }
    if (p.type === al.BindingTypeEnum.Instance && p.implementationType !== null) {
      var O = (0, za.getDependencies)(e, p.implementationType);
      if (!r.container.options.skipBaseClassChecks) {
        var F = (0, za.getBaseClassDependencyCount)(e, p.implementationType);
        if (O.length < F) {
          var H = ms.ARGUMENTS_LENGTH_MISMATCH((0, za.getFunctionName)(p.implementationType));
          throw new Error(H);
        }
      }
      O.forEach(function(E) {
        cl(e, !1, E.serviceIdentifier, r, _, E);
      });
    }
  });
}
function yr(e, t) {
  var n = [], r = ul(e);
  return r.hasKey(t) ? n = r.get(t) : e.parent !== null && (n = yr(e.parent, t)), n;
}
function bS(e, t, n, r, s, o, l, f) {
  f === void 0 && (f = !1);
  var w = new sl.Context(t), p = wS(n, r, s, "", o, l);
  try {
    return cl(e, f, s, w, null, p), w;
  } catch (_) {
    throw (0, gS.isStackOverflowExeption)(_) && (0, In.circularDependencyToException)(w.plan.rootRequest), _;
  }
}
Vt.plan = bS;
function MS(e, t, n, r) {
  var s = new ol.Target(al.TargetTypeEnum.Variable, "", t, new ps.Metadata(n, r)), o = new sl.Context(e), l = new Js.Request(t, o, null, [], s);
  return l;
}
Vt.createMockRequest = MS;
var sr = {}, hn = {}, qa = {}, Fn = {};
Object.defineProperty(Fn, "__esModule", { value: !0 });
Fn.AsyncContainerModule = Fn.ContainerModule = void 0;
var ll = Pt, OS = function() {
  function e(t) {
    this.id = (0, ll.id)(), this.registry = t;
  }
  return e;
}();
Fn.ContainerModule = OS;
var DS = function() {
  function e(t) {
    this.id = (0, ll.id)(), this.registry = t;
  }
  return e;
}();
Fn.AsyncContainerModule = DS;
var $e = {}, Hi = {};
Object.defineProperty(Hi, "__esModule", { value: !0 });
Hi.getFirstArrayDuplicate = void 0;
function TS(e) {
  for (var t = /* @__PURE__ */ new Set(), n = 0, r = e; n < r.length; n++) {
    var s = r[n];
    if (t.has(s))
      return s;
    t.add(s);
  }
}
Hi.getFirstArrayDuplicate = TS;
var AS = b && b.__createBinding || (Object.create ? function(e, t, n, r) {
  r === void 0 && (r = n), Object.defineProperty(e, r, { enumerable: !0, get: function() {
    return t[n];
  } });
} : function(e, t, n, r) {
  r === void 0 && (r = n), e[r] = t[n];
}), ES = b && b.__setModuleDefault || (Object.create ? function(e, t) {
  Object.defineProperty(e, "default", { enumerable: !0, value: t });
} : function(e, t) {
  e.default = t;
}), dl = b && b.__importStar || function(e) {
  if (e && e.__esModule)
    return e;
  var t = {};
  if (e != null)
    for (var n in e)
      n !== "default" && Object.prototype.hasOwnProperty.call(e, n) && AS(t, e, n);
  return ES(t, e), t;
};
Object.defineProperty($e, "__esModule", { value: !0 });
$e.createTaggedDecorator = $e.tagProperty = $e.tagParameter = $e.decorate = void 0;
var Gi = dl(x), fl = dl(Le), PS = Hi;
function NS(e) {
  return e.prototype !== void 0;
}
function RS(e) {
  if (e !== void 0)
    throw new Error(Gi.INVALID_DECORATOR_OPERATION);
}
function hl(e, t, n, r) {
  RS(t), yl(fl.TAGGED, e, n.toString(), r);
}
$e.tagParameter = hl;
function _l(e, t, n) {
  if (NS(e))
    throw new Error(Gi.INVALID_DECORATOR_OPERATION);
  yl(fl.TAGGED_PROP, e.constructor, t, n);
}
$e.tagProperty = _l;
function kS(e) {
  var t = [];
  if (Array.isArray(e)) {
    t = e;
    var n = (0, PS.getFirstArrayDuplicate)(t.map(function(r) {
      return r.key;
    }));
    if (n !== void 0)
      throw new Error(Gi.DUPLICATED_METADATA + " " + n.toString());
  } else
    t = [e];
  return t;
}
function yl(e, t, n, r) {
  var s = kS(r), o = {};
  Reflect.hasOwnMetadata(e, t) && (o = Reflect.getMetadata(e, t));
  var l = o[n];
  if (l === void 0)
    l = [];
  else
    for (var f = function(O) {
      if (s.some(function(F) {
        return F.key === O.key;
      }))
        throw new Error(Gi.DUPLICATED_METADATA + " " + O.key.toString());
    }, w = 0, p = l; w < p.length; w++) {
      var _ = p[w];
      f(_);
    }
  l.push.apply(l, s), o[n] = l, Reflect.defineMetadata(e, o, t);
}
function IS(e) {
  return function(t, n, r) {
    typeof r == "number" ? hl(t, n, r, e) : _l(t, n, e);
  };
}
$e.createTaggedDecorator = IS;
function Su(e, t) {
  Reflect.decorate(e, t);
}
function YS(e, t) {
  return function(n, r) {
    t(n, r, e);
  };
}
function CS(e, t, n) {
  typeof n == "number" ? Su([YS(n, e)], t) : typeof n == "string" ? Reflect.decorate([e], t, n) : Su([e], t);
}
$e.decorate = CS;
var $i = {}, jS = b && b.__createBinding || (Object.create ? function(e, t, n, r) {
  r === void 0 && (r = n), Object.defineProperty(e, r, { enumerable: !0, get: function() {
    return t[n];
  } });
} : function(e, t, n, r) {
  r === void 0 && (r = n), e[r] = t[n];
}), LS = b && b.__setModuleDefault || (Object.create ? function(e, t) {
  Object.defineProperty(e, "default", { enumerable: !0, value: t });
} : function(e, t) {
  e.default = t;
}), ml = b && b.__importStar || function(e) {
  if (e && e.__esModule)
    return e;
  var t = {};
  if (e != null)
    for (var n in e)
      n !== "default" && Object.prototype.hasOwnProperty.call(e, n) && jS(t, e, n);
  return LS(t, e), t;
};
Object.defineProperty($i, "__esModule", { value: !0 });
var pl = $i.injectable = void 0, xS = ml(x), Ja = ml(Le);
function FS() {
  return function(e) {
    if (Reflect.hasOwnMetadata(Ja.PARAM_TYPES, e))
      throw new Error(xS.DUPLICATED_INJECTABLE_DECORATOR);
    var t = Reflect.getMetadata(Ja.DESIGN_PARAM_TYPES, e) || [];
    return Reflect.defineMetadata(Ja.PARAM_TYPES, t, e), e;
  };
}
pl = $i.injectable = FS;
var Vi = {};
Object.defineProperty(Vi, "__esModule", { value: !0 });
Vi.tagged = void 0;
var BS = st, US = $e;
function WS(e, t) {
  return (0, US.createTaggedDecorator)(new BS.Metadata(e, t));
}
Vi.tagged = WS;
var zi = {}, HS = b && b.__createBinding || (Object.create ? function(e, t, n, r) {
  r === void 0 && (r = n), Object.defineProperty(e, r, { enumerable: !0, get: function() {
    return t[n];
  } });
} : function(e, t, n, r) {
  r === void 0 && (r = n), e[r] = t[n];
}), GS = b && b.__setModuleDefault || (Object.create ? function(e, t) {
  Object.defineProperty(e, "default", { enumerable: !0, value: t });
} : function(e, t) {
  e.default = t;
}), $S = b && b.__importStar || function(e) {
  if (e && e.__esModule)
    return e;
  var t = {};
  if (e != null)
    for (var n in e)
      n !== "default" && Object.prototype.hasOwnProperty.call(e, n) && HS(t, e, n);
  return GS(t, e), t;
};
Object.defineProperty(zi, "__esModule", { value: !0 });
zi.named = void 0;
var VS = $S(Le), zS = st, qS = $e;
function JS(e) {
  return (0, qS.createTaggedDecorator)(new zS.Metadata(VS.NAMED_TAG, e));
}
zi.named = JS;
var qi = {}, Tr = {};
Object.defineProperty(Tr, "__esModule", { value: !0 });
Tr.injectBase = void 0;
var ZS = x, KS = st, QS = $e;
function XS(e) {
  return function(t) {
    return function(n, r, s) {
      if (t === void 0) {
        var o = typeof n == "function" ? n.name : n.constructor.name;
        throw new Error((0, ZS.UNDEFINED_INJECT_ANNOTATION)(o));
      }
      return (0, QS.createTaggedDecorator)(new KS.Metadata(e, t))(n, r, s);
    };
  };
}
Tr.injectBase = XS;
var e0 = b && b.__createBinding || (Object.create ? function(e, t, n, r) {
  r === void 0 && (r = n), Object.defineProperty(e, r, { enumerable: !0, get: function() {
    return t[n];
  } });
} : function(e, t, n, r) {
  r === void 0 && (r = n), e[r] = t[n];
}), t0 = b && b.__setModuleDefault || (Object.create ? function(e, t) {
  Object.defineProperty(e, "default", { enumerable: !0, value: t });
} : function(e, t) {
  e.default = t;
}), n0 = b && b.__importStar || function(e) {
  if (e && e.__esModule)
    return e;
  var t = {};
  if (e != null)
    for (var n in e)
      n !== "default" && Object.prototype.hasOwnProperty.call(e, n) && e0(t, e, n);
  return t0(t, e), t;
};
Object.defineProperty(qi, "__esModule", { value: !0 });
var r0 = qi.inject = void 0, i0 = n0(Le), a0 = Tr, s0 = (0, a0.injectBase)(i0.INJECT_TAG);
r0 = qi.inject = s0;
var Ji = {}, o0 = b && b.__createBinding || (Object.create ? function(e, t, n, r) {
  r === void 0 && (r = n), Object.defineProperty(e, r, { enumerable: !0, get: function() {
    return t[n];
  } });
} : function(e, t, n, r) {
  r === void 0 && (r = n), e[r] = t[n];
}), u0 = b && b.__setModuleDefault || (Object.create ? function(e, t) {
  Object.defineProperty(e, "default", { enumerable: !0, value: t });
} : function(e, t) {
  e.default = t;
}), c0 = b && b.__importStar || function(e) {
  if (e && e.__esModule)
    return e;
  var t = {};
  if (e != null)
    for (var n in e)
      n !== "default" && Object.prototype.hasOwnProperty.call(e, n) && o0(t, e, n);
  return u0(t, e), t;
};
Object.defineProperty(Ji, "__esModule", { value: !0 });
Ji.optional = void 0;
var l0 = c0(Le), d0 = st, f0 = $e;
function h0() {
  return (0, f0.createTaggedDecorator)(new d0.Metadata(l0.OPTIONAL_TAG, !0));
}
Ji.optional = h0;
var Zi = {}, _0 = b && b.__createBinding || (Object.create ? function(e, t, n, r) {
  r === void 0 && (r = n), Object.defineProperty(e, r, { enumerable: !0, get: function() {
    return t[n];
  } });
} : function(e, t, n, r) {
  r === void 0 && (r = n), e[r] = t[n];
}), y0 = b && b.__setModuleDefault || (Object.create ? function(e, t) {
  Object.defineProperty(e, "default", { enumerable: !0, value: t });
} : function(e, t) {
  e.default = t;
}), m0 = b && b.__importStar || function(e) {
  if (e && e.__esModule)
    return e;
  var t = {};
  if (e != null)
    for (var n in e)
      n !== "default" && Object.prototype.hasOwnProperty.call(e, n) && _0(t, e, n);
  return y0(t, e), t;
};
Object.defineProperty(Zi, "__esModule", { value: !0 });
Zi.unmanaged = void 0;
var p0 = m0(Le), g0 = st, v0 = $e;
function w0() {
  return function(e, t, n) {
    var r = new g0.Metadata(p0.UNMANAGED_TAG, !0);
    (0, v0.tagParameter)(e, t, n, r);
  };
}
Zi.unmanaged = w0;
var Ki = {}, S0 = b && b.__createBinding || (Object.create ? function(e, t, n, r) {
  r === void 0 && (r = n), Object.defineProperty(e, r, { enumerable: !0, get: function() {
    return t[n];
  } });
} : function(e, t, n, r) {
  r === void 0 && (r = n), e[r] = t[n];
}), b0 = b && b.__setModuleDefault || (Object.create ? function(e, t) {
  Object.defineProperty(e, "default", { enumerable: !0, value: t });
} : function(e, t) {
  e.default = t;
}), M0 = b && b.__importStar || function(e) {
  if (e && e.__esModule)
    return e;
  var t = {};
  if (e != null)
    for (var n in e)
      n !== "default" && Object.prototype.hasOwnProperty.call(e, n) && S0(t, e, n);
  return b0(t, e), t;
};
Object.defineProperty(Ki, "__esModule", { value: !0 });
Ki.multiInject = void 0;
var O0 = M0(Le), D0 = Tr, T0 = (0, D0.injectBase)(O0.MULTI_INJECT_TAG);
Ki.multiInject = T0;
var Qi = {}, A0 = b && b.__createBinding || (Object.create ? function(e, t, n, r) {
  r === void 0 && (r = n), Object.defineProperty(e, r, { enumerable: !0, get: function() {
    return t[n];
  } });
} : function(e, t, n, r) {
  r === void 0 && (r = n), e[r] = t[n];
}), E0 = b && b.__setModuleDefault || (Object.create ? function(e, t) {
  Object.defineProperty(e, "default", { enumerable: !0, value: t });
} : function(e, t) {
  e.default = t;
}), P0 = b && b.__importStar || function(e) {
  if (e && e.__esModule)
    return e;
  var t = {};
  if (e != null)
    for (var n in e)
      n !== "default" && Object.prototype.hasOwnProperty.call(e, n) && A0(t, e, n);
  return E0(t, e), t;
};
Object.defineProperty(Qi, "__esModule", { value: !0 });
Qi.targetName = void 0;
var N0 = P0(Le), R0 = st, k0 = $e;
function I0(e) {
  return function(t, n, r) {
    var s = new R0.Metadata(N0.NAME_TAG, e);
    (0, k0.tagParameter)(t, n, r, s);
  };
}
Qi.targetName = I0;
var Xi = {}, Ar = {};
Object.defineProperty(Ar, "__esModule", { value: !0 });
Ar.propertyEventDecorator = void 0;
var Y0 = st;
function C0(e, t) {
  return function() {
    return function(n, r) {
      var s = new Y0.Metadata(e, r);
      if (Reflect.hasOwnMetadata(e, n.constructor))
        throw new Error(t);
      Reflect.defineMetadata(e, s, n.constructor);
    };
  };
}
Ar.propertyEventDecorator = C0;
var j0 = b && b.__createBinding || (Object.create ? function(e, t, n, r) {
  r === void 0 && (r = n), Object.defineProperty(e, r, { enumerable: !0, get: function() {
    return t[n];
  } });
} : function(e, t, n, r) {
  r === void 0 && (r = n), e[r] = t[n];
}), L0 = b && b.__setModuleDefault || (Object.create ? function(e, t) {
  Object.defineProperty(e, "default", { enumerable: !0, value: t });
} : function(e, t) {
  e.default = t;
}), gl = b && b.__importStar || function(e) {
  if (e && e.__esModule)
    return e;
  var t = {};
  if (e != null)
    for (var n in e)
      n !== "default" && Object.prototype.hasOwnProperty.call(e, n) && j0(t, e, n);
  return L0(t, e), t;
};
Object.defineProperty(Xi, "__esModule", { value: !0 });
Xi.postConstruct = void 0;
var x0 = gl(x), F0 = gl(Le), B0 = Ar, U0 = (0, B0.propertyEventDecorator)(F0.POST_CONSTRUCT, x0.MULTIPLE_POST_CONSTRUCT_METHODS);
Xi.postConstruct = U0;
var ea = {}, W0 = b && b.__createBinding || (Object.create ? function(e, t, n, r) {
  r === void 0 && (r = n), Object.defineProperty(e, r, { enumerable: !0, get: function() {
    return t[n];
  } });
} : function(e, t, n, r) {
  r === void 0 && (r = n), e[r] = t[n];
}), H0 = b && b.__setModuleDefault || (Object.create ? function(e, t) {
  Object.defineProperty(e, "default", { enumerable: !0, value: t });
} : function(e, t) {
  e.default = t;
}), vl = b && b.__importStar || function(e) {
  if (e && e.__esModule)
    return e;
  var t = {};
  if (e != null)
    for (var n in e)
      n !== "default" && Object.prototype.hasOwnProperty.call(e, n) && W0(t, e, n);
  return H0(t, e), t;
};
Object.defineProperty(ea, "__esModule", { value: !0 });
ea.preDestroy = void 0;
var G0 = vl(x), $0 = vl(Le), V0 = Ar, z0 = (0, V0.propertyEventDecorator)($0.PRE_DESTROY, G0.MULTIPLE_PRE_DESTROY_METHODS);
ea.preDestroy = z0;
var ta = {};
Object.defineProperty(ta, "__esModule", { value: !0 });
ta.interfaces = void 0;
var gs;
(function(e) {
})(gs || (gs = {}));
ta.interfaces = gs;
var gt = {}, q0 = b && b.__createBinding || (Object.create ? function(e, t, n, r) {
  r === void 0 && (r = n), Object.defineProperty(e, r, { enumerable: !0, get: function() {
    return t[n];
  } });
} : function(e, t, n, r) {
  r === void 0 && (r = n), e[r] = t[n];
}), J0 = b && b.__setModuleDefault || (Object.create ? function(e, t) {
  Object.defineProperty(e, "default", { enumerable: !0, value: t });
} : function(e, t) {
  e.default = t;
}), Z0 = b && b.__importStar || function(e) {
  if (e && e.__esModule)
    return e;
  var t = {};
  if (e != null)
    for (var n in e)
      n !== "default" && Object.prototype.hasOwnProperty.call(e, n) && q0(t, e, n);
  return J0(t, e), t;
};
Object.defineProperty(gt, "__esModule", { value: !0 });
gt.typeConstraint = gt.namedConstraint = gt.taggedConstraint = gt.traverseAncerstors = void 0;
var K0 = Z0(Le), Q0 = st, wl = function(e, t) {
  var n = e.parentRequest;
  return n !== null ? t(n) ? !0 : wl(n, t) : !1;
};
gt.traverseAncerstors = wl;
var Sl = function(e) {
  return function(t) {
    var n = function(r) {
      return r !== null && r.target !== null && r.target.matchesTag(e)(t);
    };
    return n.metaData = new Q0.Metadata(e, t), n;
  };
};
gt.taggedConstraint = Sl;
var X0 = Sl(K0.NAMED_TAG);
gt.namedConstraint = X0;
var eb = function(e) {
  return function(t) {
    var n = null;
    if (t !== null)
      if (n = t.bindings[0], typeof e == "string") {
        var r = n.serviceIdentifier;
        return r === e;
      } else {
        var s = t.bindings[0].implementationType;
        return e === s;
      }
    return !1;
  };
};
gt.typeConstraint = eb;
var Bt = {}, bl = {};
(function(e) {
  Object.defineProperty(e, "__esModule", { value: !0 }), e.FactoryType = void 0, function(t) {
    t.DynamicValue = "toDynamicValue", t.Factory = "toFactory", t.Provider = "toProvider";
  }(e.FactoryType || (e.FactoryType = {}));
})(bl);
var bu;
function Ml() {
  if (bu)
    return Bt;
  bu = 1;
  var e = b && b.__createBinding || (Object.create ? function(_, O, F, H) {
    H === void 0 && (H = F), Object.defineProperty(_, H, { enumerable: !0, get: function() {
      return O[F];
    } });
  } : function(_, O, F, H) {
    H === void 0 && (H = F), _[H] = O[F];
  }), t = b && b.__setModuleDefault || (Object.create ? function(_, O) {
    Object.defineProperty(_, "default", { enumerable: !0, value: O });
  } : function(_, O) {
    _.default = O;
  }), n = b && b.__importStar || function(_) {
    if (_ && _.__esModule)
      return _;
    var O = {};
    if (_ != null)
      for (var F in _)
        F !== "default" && Object.prototype.hasOwnProperty.call(_, F) && e(O, _, F);
    return t(O, _), O;
  };
  Object.defineProperty(Bt, "__esModule", { value: !0 }), Bt.getFactoryDetails = Bt.ensureFullyBound = Bt.multiBindToService = void 0;
  var r = Ol(), s = n(x), o = Ve, l = bl, f = function(_) {
    return function(O) {
      return function() {
        for (var F = [], H = 0; H < arguments.length; H++)
          F[H] = arguments[H];
        return F.forEach(function(E) {
          return _.bind(E).toService(O);
        });
      };
    };
  };
  Bt.multiBindToService = f;
  var w = function(_) {
    var O = null;
    switch (_.type) {
      case o.BindingTypeEnum.ConstantValue:
      case o.BindingTypeEnum.Function:
        O = _.cache;
        break;
      case o.BindingTypeEnum.Constructor:
      case o.BindingTypeEnum.Instance:
        O = _.implementationType;
        break;
      case o.BindingTypeEnum.DynamicValue:
        O = _.dynamicValue;
        break;
      case o.BindingTypeEnum.Provider:
        O = _.provider;
        break;
      case o.BindingTypeEnum.Factory:
        O = _.factory;
        break;
    }
    if (O === null) {
      var F = (0, r.getServiceIdentifierAsString)(_.serviceIdentifier);
      throw new Error(s.INVALID_BINDING_TYPE + " " + F);
    }
  };
  Bt.ensureFullyBound = w;
  var p = function(_) {
    switch (_.type) {
      case o.BindingTypeEnum.Factory:
        return { factory: _.factory, factoryType: l.FactoryType.Factory };
      case o.BindingTypeEnum.Provider:
        return { factory: _.provider, factoryType: l.FactoryType.Provider };
      case o.BindingTypeEnum.DynamicValue:
        return { factory: _.dynamicValue, factoryType: l.FactoryType.DynamicValue };
      default:
        throw new Error("Unexpected factory type " + _.type);
    }
  };
  return Bt.getFactoryDetails = p, Bt;
}
var Mu;
function Ol() {
  return Mu || (Mu = 1, function(e) {
    var t = b && b.__createBinding || (Object.create ? function(g, P, k, j) {
      j === void 0 && (j = k), Object.defineProperty(g, j, { enumerable: !0, get: function() {
        return P[k];
      } });
    } : function(g, P, k, j) {
      j === void 0 && (j = k), g[j] = P[k];
    }), n = b && b.__setModuleDefault || (Object.create ? function(g, P) {
      Object.defineProperty(g, "default", { enumerable: !0, value: P });
    } : function(g, P) {
      g.default = P;
    }), r = b && b.__importStar || function(g) {
      if (g && g.__esModule)
        return g;
      var P = {};
      if (g != null)
        for (var k in g)
          k !== "default" && Object.prototype.hasOwnProperty.call(g, k) && t(P, g, k);
      return n(P, g), P;
    };
    Object.defineProperty(e, "__esModule", { value: !0 }), e.multiBindToService = e.getServiceIdentifierAsString = e.typeConstraint = e.namedConstraint = e.taggedConstraint = e.traverseAncerstors = e.decorate = e.interfaces = e.id = e.MetadataReader = e.preDestroy = e.postConstruct = e.targetName = e.multiInject = e.unmanaged = e.optional = e.LazyServiceIdentifer = e.inject = e.named = e.tagged = e.injectable = e.createTaggedDecorator = e.ContainerModule = e.AsyncContainerModule = e.TargetTypeEnum = e.BindingTypeEnum = e.BindingScopeEnum = e.Container = e.METADATA_KEY = void 0;
    var s = r(Le);
    e.METADATA_KEY = s;
    var o = El();
    Object.defineProperty(e, "Container", { enumerable: !0, get: function() {
      return o.Container;
    } });
    var l = Ve;
    Object.defineProperty(e, "BindingScopeEnum", { enumerable: !0, get: function() {
      return l.BindingScopeEnum;
    } }), Object.defineProperty(e, "BindingTypeEnum", { enumerable: !0, get: function() {
      return l.BindingTypeEnum;
    } }), Object.defineProperty(e, "TargetTypeEnum", { enumerable: !0, get: function() {
      return l.TargetTypeEnum;
    } });
    var f = Fn;
    Object.defineProperty(e, "AsyncContainerModule", { enumerable: !0, get: function() {
      return f.AsyncContainerModule;
    } }), Object.defineProperty(e, "ContainerModule", { enumerable: !0, get: function() {
      return f.ContainerModule;
    } });
    var w = $e;
    Object.defineProperty(e, "createTaggedDecorator", { enumerable: !0, get: function() {
      return w.createTaggedDecorator;
    } });
    var p = $i;
    Object.defineProperty(e, "injectable", { enumerable: !0, get: function() {
      return p.injectable;
    } });
    var _ = Vi;
    Object.defineProperty(e, "tagged", { enumerable: !0, get: function() {
      return _.tagged;
    } });
    var O = zi;
    Object.defineProperty(e, "named", { enumerable: !0, get: function() {
      return O.named;
    } });
    var F = qi;
    Object.defineProperty(e, "inject", { enumerable: !0, get: function() {
      return F.inject;
    } });
    var H = Or;
    Object.defineProperty(e, "LazyServiceIdentifer", { enumerable: !0, get: function() {
      return H.LazyServiceIdentifer;
    } });
    var E = Ji;
    Object.defineProperty(e, "optional", { enumerable: !0, get: function() {
      return E.optional;
    } });
    var $ = Zi;
    Object.defineProperty(e, "unmanaged", { enumerable: !0, get: function() {
      return $.unmanaged;
    } });
    var W = Ki;
    Object.defineProperty(e, "multiInject", { enumerable: !0, get: function() {
      return W.multiInject;
    } });
    var ve = Qi;
    Object.defineProperty(e, "targetName", { enumerable: !0, get: function() {
      return ve.targetName;
    } });
    var Z = Xi;
    Object.defineProperty(e, "postConstruct", { enumerable: !0, get: function() {
      return Z.postConstruct;
    } });
    var U = ea;
    Object.defineProperty(e, "preDestroy", { enumerable: !0, get: function() {
      return U.preDestroy;
    } });
    var K = Mr;
    Object.defineProperty(e, "MetadataReader", { enumerable: !0, get: function() {
      return K.MetadataReader;
    } });
    var ie = Pt;
    Object.defineProperty(e, "id", { enumerable: !0, get: function() {
      return ie.id;
    } });
    var A = ta;
    Object.defineProperty(e, "interfaces", { enumerable: !0, get: function() {
      return A.interfaces;
    } });
    var y = $e;
    Object.defineProperty(e, "decorate", { enumerable: !0, get: function() {
      return y.decorate;
    } });
    var h = gt;
    Object.defineProperty(e, "traverseAncerstors", { enumerable: !0, get: function() {
      return h.traverseAncerstors;
    } }), Object.defineProperty(e, "taggedConstraint", { enumerable: !0, get: function() {
      return h.taggedConstraint;
    } }), Object.defineProperty(e, "namedConstraint", { enumerable: !0, get: function() {
      return h.namedConstraint;
    } }), Object.defineProperty(e, "typeConstraint", { enumerable: !0, get: function() {
      return h.typeConstraint;
    } });
    var v = We;
    Object.defineProperty(e, "getServiceIdentifierAsString", { enumerable: !0, get: function() {
      return v.getServiceIdentifierAsString;
    } });
    var S = Ml();
    Object.defineProperty(e, "multiBindToService", { enumerable: !0, get: function() {
      return S.multiBindToService;
    } });
  }(qa)), qa;
}
var qt = {};
Object.defineProperty(qt, "__esModule", { value: !0 });
qt.isPromiseOrContainsPromise = qt.isPromise = void 0;
function vs(e) {
  var t = typeof e == "object" && e !== null || typeof e == "function";
  return t && typeof e.then == "function";
}
qt.isPromise = vs;
function tb(e) {
  return vs(e) ? !0 : Array.isArray(e) && e.some(vs);
}
qt.isPromiseOrContainsPromise = tb;
var Ou;
function nb() {
  if (Ou)
    return hn;
  Ou = 1;
  var e = b && b.__awaiter || function(p, _, O, F) {
    function H(E) {
      return E instanceof O ? E : new O(function($) {
        $(E);
      });
    }
    return new (O || (O = Promise))(function(E, $) {
      function W(U) {
        try {
          Z(F.next(U));
        } catch (K) {
          $(K);
        }
      }
      function ve(U) {
        try {
          Z(F.throw(U));
        } catch (K) {
          $(K);
        }
      }
      function Z(U) {
        U.done ? E(U.value) : H(U.value).then(W, ve);
      }
      Z((F = F.apply(p, _ || [])).next());
    });
  }, t = b && b.__generator || function(p, _) {
    var O = { label: 0, sent: function() {
      if (E[0] & 1)
        throw E[1];
      return E[1];
    }, trys: [], ops: [] }, F, H, E, $;
    return $ = { next: W(0), throw: W(1), return: W(2) }, typeof Symbol == "function" && ($[Symbol.iterator] = function() {
      return this;
    }), $;
    function W(Z) {
      return function(U) {
        return ve([Z, U]);
      };
    }
    function ve(Z) {
      if (F)
        throw new TypeError("Generator is already executing.");
      for (; O; )
        try {
          if (F = 1, H && (E = Z[0] & 2 ? H.return : Z[0] ? H.throw || ((E = H.return) && E.call(H), 0) : H.next) && !(E = E.call(H, Z[1])).done)
            return E;
          switch (H = 0, E && (Z = [Z[0] & 2, E.value]), Z[0]) {
            case 0:
            case 1:
              E = Z;
              break;
            case 4:
              return O.label++, { value: Z[1], done: !1 };
            case 5:
              O.label++, H = Z[1], Z = [0];
              continue;
            case 7:
              Z = O.ops.pop(), O.trys.pop();
              continue;
            default:
              if (E = O.trys, !(E = E.length > 0 && E[E.length - 1]) && (Z[0] === 6 || Z[0] === 2)) {
                O = 0;
                continue;
              }
              if (Z[0] === 3 && (!E || Z[1] > E[0] && Z[1] < E[3])) {
                O.label = Z[1];
                break;
              }
              if (Z[0] === 6 && O.label < E[1]) {
                O.label = E[1], E = Z;
                break;
              }
              if (E && O.label < E[2]) {
                O.label = E[2], O.ops.push(Z);
                break;
              }
              E[2] && O.ops.pop(), O.trys.pop();
              continue;
          }
          Z = _.call(p, O);
        } catch (U) {
          Z = [6, U], H = 0;
        } finally {
          F = E = 0;
        }
      if (Z[0] & 5)
        throw Z[1];
      return { value: Z[0] ? Z[1] : void 0, done: !0 };
    }
  };
  Object.defineProperty(hn, "__esModule", { value: !0 }), hn.saveToScope = hn.tryGetFromScope = void 0;
  var n = Ol(), r = qt, s = function(p, _) {
    return _.scope === n.BindingScopeEnum.Singleton && _.activated ? _.cache : _.scope === n.BindingScopeEnum.Request && p.has(_.id) ? p.get(_.id) : null;
  };
  hn.tryGetFromScope = s;
  var o = function(p, _, O) {
    _.scope === n.BindingScopeEnum.Singleton && f(_, O), _.scope === n.BindingScopeEnum.Request && l(p, _, O);
  };
  hn.saveToScope = o;
  var l = function(p, _, O) {
    p.has(_.id) || p.set(_.id, O);
  }, f = function(p, _) {
    p.cache = _, p.activated = !0, (0, r.isPromise)(_) && w(p, _);
  }, w = function(p, _) {
    return e(void 0, void 0, void 0, function() {
      var O, F;
      return t(this, function(H) {
        switch (H.label) {
          case 0:
            return H.trys.push([0, 2, , 3]), [4, _];
          case 1:
            return O = H.sent(), p.cache = O, [3, 3];
          case 2:
            throw F = H.sent(), p.cache = null, p.activated = !1, F;
          case 3:
            return [2];
        }
      });
    });
  };
  return hn;
}
var na = {}, Bn = b && b.__assign || function() {
  return Bn = Object.assign || function(e) {
    for (var t, n = 1, r = arguments.length; n < r; n++) {
      t = arguments[n];
      for (var s in t)
        Object.prototype.hasOwnProperty.call(t, s) && (e[s] = t[s]);
    }
    return e;
  }, Bn.apply(this, arguments);
}, rb = b && b.__createBinding || (Object.create ? function(e, t, n, r) {
  r === void 0 && (r = n), Object.defineProperty(e, r, { enumerable: !0, get: function() {
    return t[n];
  } });
} : function(e, t, n, r) {
  r === void 0 && (r = n), e[r] = t[n];
}), ib = b && b.__setModuleDefault || (Object.create ? function(e, t) {
  Object.defineProperty(e, "default", { enumerable: !0, value: t });
} : function(e, t) {
  e.default = t;
}), ab = b && b.__importStar || function(e) {
  if (e && e.__esModule)
    return e;
  var t = {};
  if (e != null)
    for (var n in e)
      n !== "default" && Object.prototype.hasOwnProperty.call(e, n) && rb(t, e, n);
  return ib(t, e), t;
}, Dl = b && b.__awaiter || function(e, t, n, r) {
  function s(o) {
    return o instanceof n ? o : new n(function(l) {
      l(o);
    });
  }
  return new (n || (n = Promise))(function(o, l) {
    function f(_) {
      try {
        p(r.next(_));
      } catch (O) {
        l(O);
      }
    }
    function w(_) {
      try {
        p(r.throw(_));
      } catch (O) {
        l(O);
      }
    }
    function p(_) {
      _.done ? o(_.value) : s(_.value).then(f, w);
    }
    p((r = r.apply(e, t || [])).next());
  });
}, Tl = b && b.__generator || function(e, t) {
  var n = { label: 0, sent: function() {
    if (o[0] & 1)
      throw o[1];
    return o[1];
  }, trys: [], ops: [] }, r, s, o, l;
  return l = { next: f(0), throw: f(1), return: f(2) }, typeof Symbol == "function" && (l[Symbol.iterator] = function() {
    return this;
  }), l;
  function f(p) {
    return function(_) {
      return w([p, _]);
    };
  }
  function w(p) {
    if (r)
      throw new TypeError("Generator is already executing.");
    for (; n; )
      try {
        if (r = 1, s && (o = p[0] & 2 ? s.return : p[0] ? s.throw || ((o = s.return) && o.call(s), 0) : s.next) && !(o = o.call(s, p[1])).done)
          return o;
        switch (s = 0, o && (p = [p[0] & 2, o.value]), p[0]) {
          case 0:
          case 1:
            o = p;
            break;
          case 4:
            return n.label++, { value: p[1], done: !1 };
          case 5:
            n.label++, s = p[1], p = [0];
            continue;
          case 7:
            p = n.ops.pop(), n.trys.pop();
            continue;
          default:
            if (o = n.trys, !(o = o.length > 0 && o[o.length - 1]) && (p[0] === 6 || p[0] === 2)) {
              n = 0;
              continue;
            }
            if (p[0] === 3 && (!o || p[1] > o[0] && p[1] < o[3])) {
              n.label = p[1];
              break;
            }
            if (p[0] === 6 && n.label < o[1]) {
              n.label = o[1], o = p;
              break;
            }
            if (o && n.label < o[2]) {
              n.label = o[2], n.ops.push(p);
              break;
            }
            o[2] && n.ops.pop(), n.trys.pop();
            continue;
        }
        p = t.call(e, n);
      } catch (_) {
        p = [6, _], s = 0;
      } finally {
        r = o = 0;
      }
    if (p[0] & 5)
      throw p[1];
    return { value: p[0] ? p[1] : void 0, done: !0 };
  }
}, sb = b && b.__spreadArray || function(e, t, n) {
  if (n || arguments.length === 2)
    for (var r = 0, s = t.length, o; r < s; r++)
      (o || !(r in t)) && (o || (o = Array.prototype.slice.call(t, 0, r)), o[r] = t[r]);
  return e.concat(o || Array.prototype.slice.call(t));
};
Object.defineProperty(na, "__esModule", { value: !0 });
na.resolveInstance = void 0;
var ws = x, Zs = Ve, Ss = ab(Le), Ks = qt;
function ob(e, t) {
  return e.reduce(function(n, r) {
    var s = t(r), o = r.target.type;
    return o === Zs.TargetTypeEnum.ConstructorArgument ? n.constructorInjections.push(s) : (n.propertyRequests.push(r), n.propertyInjections.push(s)), n.isAsync || (n.isAsync = (0, Ks.isPromiseOrContainsPromise)(s)), n;
  }, { constructorInjections: [], propertyInjections: [], propertyRequests: [], isAsync: !1 });
}
function ub(e, t, n) {
  var r;
  if (t.length > 0) {
    var s = ob(t, n), o = Bn(Bn({}, s), { constr: e });
    s.isAsync ? r = cb(o) : r = Al(o);
  } else
    r = new e();
  return r;
}
function Al(e) {
  var t, n = new ((t = e.constr).bind.apply(t, sb([void 0], e.constructorInjections, !1)))();
  return e.propertyRequests.forEach(function(r, s) {
    var o = r.target.identifier, l = e.propertyInjections[s];
    n[o] = l;
  }), n;
}
function cb(e) {
  return Dl(this, void 0, void 0, function() {
    var t, n;
    return Tl(this, function(r) {
      switch (r.label) {
        case 0:
          return [4, Du(e.constructorInjections)];
        case 1:
          return t = r.sent(), [4, Du(e.propertyInjections)];
        case 2:
          return n = r.sent(), [2, Al(Bn(Bn({}, e), { constructorInjections: t, propertyInjections: n }))];
      }
    });
  });
}
function Du(e) {
  return Dl(this, void 0, void 0, function() {
    var t, n, r, s;
    return Tl(this, function(o) {
      for (t = [], n = 0, r = e; n < r.length; n++)
        s = r[n], Array.isArray(s) ? t.push(Promise.all(s)) : t.push(s);
      return [2, Promise.all(t)];
    });
  });
}
function Tu(e, t) {
  var n = lb(e, t);
  return (0, Ks.isPromise)(n) ? n.then(function() {
    return t;
  }) : t;
}
function lb(e, t) {
  var n, r;
  if (Reflect.hasMetadata(Ss.POST_CONSTRUCT, e)) {
    var s = Reflect.getMetadata(Ss.POST_CONSTRUCT, e);
    try {
      return (r = (n = t)[s.value]) === null || r === void 0 ? void 0 : r.call(n);
    } catch (o) {
      throw new Error((0, ws.POST_CONSTRUCT_ERROR)(e.name, o.message));
    }
  }
}
function db(e, t) {
  e.scope !== Zs.BindingScopeEnum.Singleton && fb(e, t);
}
function fb(e, t) {
  var n = "Class cannot be instantiated in " + (e.scope === Zs.BindingScopeEnum.Request ? "request" : "transient") + " scope.";
  if (typeof e.onDeactivation == "function")
    throw new Error((0, ws.ON_DEACTIVATION_ERROR)(t.name, n));
  if (Reflect.hasMetadata(Ss.PRE_DESTROY, t))
    throw new Error((0, ws.PRE_DESTROY_ERROR)(t.name, n));
}
function hb(e, t, n, r) {
  db(e, t);
  var s = ub(t, n, r);
  return (0, Ks.isPromise)(s) ? s.then(function(o) {
    return Tu(t, o);
  }) : Tu(t, s);
}
na.resolveInstance = hb;
var Au;
function _b() {
  if (Au)
    return sr;
  Au = 1;
  var e = b && b.__createBinding || (Object.create ? function(v, S, g, P) {
    P === void 0 && (P = g), Object.defineProperty(v, P, { enumerable: !0, get: function() {
      return S[g];
    } });
  } : function(v, S, g, P) {
    P === void 0 && (P = g), v[P] = S[g];
  }), t = b && b.__setModuleDefault || (Object.create ? function(v, S) {
    Object.defineProperty(v, "default", { enumerable: !0, value: S });
  } : function(v, S) {
    v.default = S;
  }), n = b && b.__importStar || function(v) {
    if (v && v.__esModule)
      return v;
    var S = {};
    if (v != null)
      for (var g in v)
        g !== "default" && Object.prototype.hasOwnProperty.call(v, g) && e(S, v, g);
    return t(S, v), S;
  }, r = b && b.__awaiter || function(v, S, g, P) {
    function k(j) {
      return j instanceof g ? j : new g(function(G) {
        G(j);
      });
    }
    return new (g || (g = Promise))(function(j, G) {
      function Q(Ie) {
        try {
          J(P.next(Ie));
        } catch (xe) {
          G(xe);
        }
      }
      function le(Ie) {
        try {
          J(P.throw(Ie));
        } catch (xe) {
          G(xe);
        }
      }
      function J(Ie) {
        Ie.done ? j(Ie.value) : k(Ie.value).then(Q, le);
      }
      J((P = P.apply(v, S || [])).next());
    });
  }, s = b && b.__generator || function(v, S) {
    var g = { label: 0, sent: function() {
      if (j[0] & 1)
        throw j[1];
      return j[1];
    }, trys: [], ops: [] }, P, k, j, G;
    return G = { next: Q(0), throw: Q(1), return: Q(2) }, typeof Symbol == "function" && (G[Symbol.iterator] = function() {
      return this;
    }), G;
    function Q(J) {
      return function(Ie) {
        return le([J, Ie]);
      };
    }
    function le(J) {
      if (P)
        throw new TypeError("Generator is already executing.");
      for (; g; )
        try {
          if (P = 1, k && (j = J[0] & 2 ? k.return : J[0] ? k.throw || ((j = k.return) && j.call(k), 0) : k.next) && !(j = j.call(k, J[1])).done)
            return j;
          switch (k = 0, j && (J = [J[0] & 2, j.value]), J[0]) {
            case 0:
            case 1:
              j = J;
              break;
            case 4:
              return g.label++, { value: J[1], done: !1 };
            case 5:
              g.label++, k = J[1], J = [0];
              continue;
            case 7:
              J = g.ops.pop(), g.trys.pop();
              continue;
            default:
              if (j = g.trys, !(j = j.length > 0 && j[j.length - 1]) && (J[0] === 6 || J[0] === 2)) {
                g = 0;
                continue;
              }
              if (J[0] === 3 && (!j || J[1] > j[0] && J[1] < j[3])) {
                g.label = J[1];
                break;
              }
              if (J[0] === 6 && g.label < j[1]) {
                g.label = j[1], j = J;
                break;
              }
              if (j && g.label < j[2]) {
                g.label = j[2], g.ops.push(J);
                break;
              }
              j[2] && g.ops.pop(), g.trys.pop();
              continue;
          }
          J = S.call(v, g);
        } catch (Ie) {
          J = [6, Ie], k = 0;
        } finally {
          P = j = 0;
        }
      if (J[0] & 5)
        throw J[1];
      return { value: J[0] ? J[1] : void 0, done: !0 };
    }
  };
  Object.defineProperty(sr, "__esModule", { value: !0 }), sr.resolve = void 0;
  var o = n(x), l = Ve, f = Vt, w = nb(), p = qt, _ = Ml(), O = vn, F = na, H = function(v) {
    return function(S) {
      S.parentContext.setCurrentRequest(S);
      var g = S.bindings, P = S.childRequests, k = S.target && S.target.isArray(), j = !S.parentRequest || !S.parentRequest.target || !S.target || !S.parentRequest.target.matchesArray(S.target.serviceIdentifier);
      if (k && j)
        return P.map(function(Q) {
          var le = H(v);
          return le(Q);
        });
      if (S.target.isOptional() && g.length === 0)
        return;
      var G = g[0];
      return ve(v, S, G);
    };
  }, E = function(v, S) {
    var g = (0, _.getFactoryDetails)(v);
    return (0, O.tryAndThrowErrorIfStackOverflow)(function() {
      return g.factory.bind(v)(S);
    }, function() {
      return new Error(o.CIRCULAR_DEPENDENCY_IN_FACTORY(g.factoryType, S.currentRequest.serviceIdentifier.toString()));
    });
  }, $ = function(v, S, g) {
    var P, k = S.childRequests;
    switch ((0, _.ensureFullyBound)(g), g.type) {
      case l.BindingTypeEnum.ConstantValue:
      case l.BindingTypeEnum.Function:
        P = g.cache;
        break;
      case l.BindingTypeEnum.Constructor:
        P = g.implementationType;
        break;
      case l.BindingTypeEnum.Instance:
        P = (0, F.resolveInstance)(g, g.implementationType, k, H(v));
        break;
      default:
        P = E(g, S.parentContext);
    }
    return P;
  }, W = function(v, S, g) {
    var P = (0, w.tryGetFromScope)(v, S);
    return P !== null || (P = g(), (0, w.saveToScope)(v, S, P)), P;
  }, ve = function(v, S, g) {
    return W(v, g, function() {
      var P = $(v, S, g);
      return (0, p.isPromise)(P) ? P = P.then(function(k) {
        return Z(S, g, k);
      }) : P = Z(S, g, P), P;
    });
  };
  function Z(v, S, g) {
    var P = U(v.parentContext, S, g), k = y(v.parentContext.container), j, G = k.next();
    do {
      j = G.value;
      var Q = v.parentContext, le = v.serviceIdentifier, J = A(j, le);
      (0, p.isPromise)(P) ? P = ie(J, Q, P) : P = K(J, Q, P), G = k.next();
    } while (G.done !== !0 && !(0, f.getBindingDictionary)(j).hasKey(v.serviceIdentifier));
    return P;
  }
  var U = function(v, S, g) {
    var P;
    return typeof S.onActivation == "function" ? P = S.onActivation(v, g) : P = g, P;
  }, K = function(v, S, g) {
    for (var P = v.next(); !P.done; ) {
      if (g = P.value(S, g), (0, p.isPromise)(g))
        return ie(v, S, g);
      P = v.next();
    }
    return g;
  }, ie = function(v, S, g) {
    return r(void 0, void 0, void 0, function() {
      var P, k;
      return s(this, function(j) {
        switch (j.label) {
          case 0:
            return [4, g];
          case 1:
            P = j.sent(), k = v.next(), j.label = 2;
          case 2:
            return k.done ? [3, 4] : [4, k.value(S, P)];
          case 3:
            return P = j.sent(), k = v.next(), [3, 2];
          case 4:
            return [2, P];
        }
      });
    });
  }, A = function(v, S) {
    var g = v._activations;
    return g.hasKey(S) ? g.get(S).values() : [].values();
  }, y = function(v) {
    for (var S = [v], g = v.parent; g !== null; )
      S.push(g), g = g.parent;
    var P = function() {
      var j = S.pop();
      return j !== void 0 ? { done: !1, value: j } : { done: !0, value: void 0 };
    }, k = {
      next: P
    };
    return k;
  };
  function h(v) {
    var S = H(v.plan.rootRequest.requestScope);
    return S(v.plan.rootRequest);
  }
  return sr.resolve = h, sr;
}
var ra = {}, ia = {}, aa = {}, Er = {}, or = {}, ur = {}, Eu;
function Qs() {
  if (Eu)
    return ur;
  Eu = 1, Object.defineProperty(ur, "__esModule", { value: !0 }), ur.BindingWhenSyntax = void 0;
  var e = Xs(), t = gt, n = function() {
    function r(s) {
      this._binding = s;
    }
    return r.prototype.when = function(s) {
      return this._binding.constraint = s, new e.BindingOnSyntax(this._binding);
    }, r.prototype.whenTargetNamed = function(s) {
      return this._binding.constraint = (0, t.namedConstraint)(s), new e.BindingOnSyntax(this._binding);
    }, r.prototype.whenTargetIsDefault = function() {
      return this._binding.constraint = function(s) {
        if (s === null)
          return !1;
        var o = s.target !== null && !s.target.isNamed() && !s.target.isTagged();
        return o;
      }, new e.BindingOnSyntax(this._binding);
    }, r.prototype.whenTargetTagged = function(s, o) {
      return this._binding.constraint = (0, t.taggedConstraint)(s)(o), new e.BindingOnSyntax(this._binding);
    }, r.prototype.whenInjectedInto = function(s) {
      return this._binding.constraint = function(o) {
        return o !== null && (0, t.typeConstraint)(s)(o.parentRequest);
      }, new e.BindingOnSyntax(this._binding);
    }, r.prototype.whenParentNamed = function(s) {
      return this._binding.constraint = function(o) {
        return o !== null && (0, t.namedConstraint)(s)(o.parentRequest);
      }, new e.BindingOnSyntax(this._binding);
    }, r.prototype.whenParentTagged = function(s, o) {
      return this._binding.constraint = function(l) {
        return l !== null && (0, t.taggedConstraint)(s)(o)(l.parentRequest);
      }, new e.BindingOnSyntax(this._binding);
    }, r.prototype.whenAnyAncestorIs = function(s) {
      return this._binding.constraint = function(o) {
        return o !== null && (0, t.traverseAncerstors)(o, (0, t.typeConstraint)(s));
      }, new e.BindingOnSyntax(this._binding);
    }, r.prototype.whenNoAncestorIs = function(s) {
      return this._binding.constraint = function(o) {
        return o !== null && !(0, t.traverseAncerstors)(o, (0, t.typeConstraint)(s));
      }, new e.BindingOnSyntax(this._binding);
    }, r.prototype.whenAnyAncestorNamed = function(s) {
      return this._binding.constraint = function(o) {
        return o !== null && (0, t.traverseAncerstors)(o, (0, t.namedConstraint)(s));
      }, new e.BindingOnSyntax(this._binding);
    }, r.prototype.whenNoAncestorNamed = function(s) {
      return this._binding.constraint = function(o) {
        return o !== null && !(0, t.traverseAncerstors)(o, (0, t.namedConstraint)(s));
      }, new e.BindingOnSyntax(this._binding);
    }, r.prototype.whenAnyAncestorTagged = function(s, o) {
      return this._binding.constraint = function(l) {
        return l !== null && (0, t.traverseAncerstors)(l, (0, t.taggedConstraint)(s)(o));
      }, new e.BindingOnSyntax(this._binding);
    }, r.prototype.whenNoAncestorTagged = function(s, o) {
      return this._binding.constraint = function(l) {
        return l !== null && !(0, t.traverseAncerstors)(l, (0, t.taggedConstraint)(s)(o));
      }, new e.BindingOnSyntax(this._binding);
    }, r.prototype.whenAnyAncestorMatches = function(s) {
      return this._binding.constraint = function(o) {
        return o !== null && (0, t.traverseAncerstors)(o, s);
      }, new e.BindingOnSyntax(this._binding);
    }, r.prototype.whenNoAncestorMatches = function(s) {
      return this._binding.constraint = function(o) {
        return o !== null && !(0, t.traverseAncerstors)(o, s);
      }, new e.BindingOnSyntax(this._binding);
    }, r;
  }();
  return ur.BindingWhenSyntax = n, ur;
}
var Pu;
function Xs() {
  if (Pu)
    return or;
  Pu = 1, Object.defineProperty(or, "__esModule", { value: !0 }), or.BindingOnSyntax = void 0;
  var e = Qs(), t = function() {
    function n(r) {
      this._binding = r;
    }
    return n.prototype.onActivation = function(r) {
      return this._binding.onActivation = r, new e.BindingWhenSyntax(this._binding);
    }, n.prototype.onDeactivation = function(r) {
      return this._binding.onDeactivation = r, new e.BindingWhenSyntax(this._binding);
    }, n;
  }();
  return or.BindingOnSyntax = t, or;
}
Object.defineProperty(Er, "__esModule", { value: !0 });
Er.BindingWhenOnSyntax = void 0;
var yb = Xs(), mb = Qs(), pb = function() {
  function e(t) {
    this._binding = t, this._bindingWhenSyntax = new mb.BindingWhenSyntax(this._binding), this._bindingOnSyntax = new yb.BindingOnSyntax(this._binding);
  }
  return e.prototype.when = function(t) {
    return this._bindingWhenSyntax.when(t);
  }, e.prototype.whenTargetNamed = function(t) {
    return this._bindingWhenSyntax.whenTargetNamed(t);
  }, e.prototype.whenTargetIsDefault = function() {
    return this._bindingWhenSyntax.whenTargetIsDefault();
  }, e.prototype.whenTargetTagged = function(t, n) {
    return this._bindingWhenSyntax.whenTargetTagged(t, n);
  }, e.prototype.whenInjectedInto = function(t) {
    return this._bindingWhenSyntax.whenInjectedInto(t);
  }, e.prototype.whenParentNamed = function(t) {
    return this._bindingWhenSyntax.whenParentNamed(t);
  }, e.prototype.whenParentTagged = function(t, n) {
    return this._bindingWhenSyntax.whenParentTagged(t, n);
  }, e.prototype.whenAnyAncestorIs = function(t) {
    return this._bindingWhenSyntax.whenAnyAncestorIs(t);
  }, e.prototype.whenNoAncestorIs = function(t) {
    return this._bindingWhenSyntax.whenNoAncestorIs(t);
  }, e.prototype.whenAnyAncestorNamed = function(t) {
    return this._bindingWhenSyntax.whenAnyAncestorNamed(t);
  }, e.prototype.whenAnyAncestorTagged = function(t, n) {
    return this._bindingWhenSyntax.whenAnyAncestorTagged(t, n);
  }, e.prototype.whenNoAncestorNamed = function(t) {
    return this._bindingWhenSyntax.whenNoAncestorNamed(t);
  }, e.prototype.whenNoAncestorTagged = function(t, n) {
    return this._bindingWhenSyntax.whenNoAncestorTagged(t, n);
  }, e.prototype.whenAnyAncestorMatches = function(t) {
    return this._bindingWhenSyntax.whenAnyAncestorMatches(t);
  }, e.prototype.whenNoAncestorMatches = function(t) {
    return this._bindingWhenSyntax.whenNoAncestorMatches(t);
  }, e.prototype.onActivation = function(t) {
    return this._bindingOnSyntax.onActivation(t);
  }, e.prototype.onDeactivation = function(t) {
    return this._bindingOnSyntax.onDeactivation(t);
  }, e;
}();
Er.BindingWhenOnSyntax = pb;
Object.defineProperty(aa, "__esModule", { value: !0 });
aa.BindingInSyntax = void 0;
var Za = Ve, Ka = Er, gb = function() {
  function e(t) {
    this._binding = t;
  }
  return e.prototype.inRequestScope = function() {
    return this._binding.scope = Za.BindingScopeEnum.Request, new Ka.BindingWhenOnSyntax(this._binding);
  }, e.prototype.inSingletonScope = function() {
    return this._binding.scope = Za.BindingScopeEnum.Singleton, new Ka.BindingWhenOnSyntax(this._binding);
  }, e.prototype.inTransientScope = function() {
    return this._binding.scope = Za.BindingScopeEnum.Transient, new Ka.BindingWhenOnSyntax(this._binding);
  }, e;
}();
aa.BindingInSyntax = gb;
Object.defineProperty(ia, "__esModule", { value: !0 });
ia.BindingInWhenOnSyntax = void 0;
var vb = aa, wb = Xs(), Sb = Qs(), bb = function() {
  function e(t) {
    this._binding = t, this._bindingWhenSyntax = new Sb.BindingWhenSyntax(this._binding), this._bindingOnSyntax = new wb.BindingOnSyntax(this._binding), this._bindingInSyntax = new vb.BindingInSyntax(t);
  }
  return e.prototype.inRequestScope = function() {
    return this._bindingInSyntax.inRequestScope();
  }, e.prototype.inSingletonScope = function() {
    return this._bindingInSyntax.inSingletonScope();
  }, e.prototype.inTransientScope = function() {
    return this._bindingInSyntax.inTransientScope();
  }, e.prototype.when = function(t) {
    return this._bindingWhenSyntax.when(t);
  }, e.prototype.whenTargetNamed = function(t) {
    return this._bindingWhenSyntax.whenTargetNamed(t);
  }, e.prototype.whenTargetIsDefault = function() {
    return this._bindingWhenSyntax.whenTargetIsDefault();
  }, e.prototype.whenTargetTagged = function(t, n) {
    return this._bindingWhenSyntax.whenTargetTagged(t, n);
  }, e.prototype.whenInjectedInto = function(t) {
    return this._bindingWhenSyntax.whenInjectedInto(t);
  }, e.prototype.whenParentNamed = function(t) {
    return this._bindingWhenSyntax.whenParentNamed(t);
  }, e.prototype.whenParentTagged = function(t, n) {
    return this._bindingWhenSyntax.whenParentTagged(t, n);
  }, e.prototype.whenAnyAncestorIs = function(t) {
    return this._bindingWhenSyntax.whenAnyAncestorIs(t);
  }, e.prototype.whenNoAncestorIs = function(t) {
    return this._bindingWhenSyntax.whenNoAncestorIs(t);
  }, e.prototype.whenAnyAncestorNamed = function(t) {
    return this._bindingWhenSyntax.whenAnyAncestorNamed(t);
  }, e.prototype.whenAnyAncestorTagged = function(t, n) {
    return this._bindingWhenSyntax.whenAnyAncestorTagged(t, n);
  }, e.prototype.whenNoAncestorNamed = function(t) {
    return this._bindingWhenSyntax.whenNoAncestorNamed(t);
  }, e.prototype.whenNoAncestorTagged = function(t, n) {
    return this._bindingWhenSyntax.whenNoAncestorTagged(t, n);
  }, e.prototype.whenAnyAncestorMatches = function(t) {
    return this._bindingWhenSyntax.whenAnyAncestorMatches(t);
  }, e.prototype.whenNoAncestorMatches = function(t) {
    return this._bindingWhenSyntax.whenNoAncestorMatches(t);
  }, e.prototype.onActivation = function(t) {
    return this._bindingOnSyntax.onActivation(t);
  }, e.prototype.onDeactivation = function(t) {
    return this._bindingOnSyntax.onDeactivation(t);
  }, e;
}();
ia.BindingInWhenOnSyntax = bb;
var Mb = b && b.__createBinding || (Object.create ? function(e, t, n, r) {
  r === void 0 && (r = n), Object.defineProperty(e, r, { enumerable: !0, get: function() {
    return t[n];
  } });
} : function(e, t, n, r) {
  r === void 0 && (r = n), e[r] = t[n];
}), Ob = b && b.__setModuleDefault || (Object.create ? function(e, t) {
  Object.defineProperty(e, "default", { enumerable: !0, value: t });
} : function(e, t) {
  e.default = t;
}), Db = b && b.__importStar || function(e) {
  if (e && e.__esModule)
    return e;
  var t = {};
  if (e != null)
    for (var n in e)
      n !== "default" && Object.prototype.hasOwnProperty.call(e, n) && Mb(t, e, n);
  return Ob(t, e), t;
};
Object.defineProperty(ra, "__esModule", { value: !0 });
ra.BindingToSyntax = void 0;
var Nu = Db(x), et = Ve, Ru = ia, Nn = Er, Tb = function() {
  function e(t) {
    this._binding = t;
  }
  return e.prototype.to = function(t) {
    return this._binding.type = et.BindingTypeEnum.Instance, this._binding.implementationType = t, new Ru.BindingInWhenOnSyntax(this._binding);
  }, e.prototype.toSelf = function() {
    if (typeof this._binding.serviceIdentifier != "function")
      throw new Error("" + Nu.INVALID_TO_SELF_VALUE);
    var t = this._binding.serviceIdentifier;
    return this.to(t);
  }, e.prototype.toConstantValue = function(t) {
    return this._binding.type = et.BindingTypeEnum.ConstantValue, this._binding.cache = t, this._binding.dynamicValue = null, this._binding.implementationType = null, this._binding.scope = et.BindingScopeEnum.Singleton, new Nn.BindingWhenOnSyntax(this._binding);
  }, e.prototype.toDynamicValue = function(t) {
    return this._binding.type = et.BindingTypeEnum.DynamicValue, this._binding.cache = null, this._binding.dynamicValue = t, this._binding.implementationType = null, new Ru.BindingInWhenOnSyntax(this._binding);
  }, e.prototype.toConstructor = function(t) {
    return this._binding.type = et.BindingTypeEnum.Constructor, this._binding.implementationType = t, this._binding.scope = et.BindingScopeEnum.Singleton, new Nn.BindingWhenOnSyntax(this._binding);
  }, e.prototype.toFactory = function(t) {
    return this._binding.type = et.BindingTypeEnum.Factory, this._binding.factory = t, this._binding.scope = et.BindingScopeEnum.Singleton, new Nn.BindingWhenOnSyntax(this._binding);
  }, e.prototype.toFunction = function(t) {
    if (typeof t != "function")
      throw new Error(Nu.INVALID_FUNCTION_BINDING);
    var n = this.toConstantValue(t);
    return this._binding.type = et.BindingTypeEnum.Function, this._binding.scope = et.BindingScopeEnum.Singleton, n;
  }, e.prototype.toAutoFactory = function(t) {
    return this._binding.type = et.BindingTypeEnum.Factory, this._binding.factory = function(n) {
      var r = function() {
        return n.container.get(t);
      };
      return r;
    }, this._binding.scope = et.BindingScopeEnum.Singleton, new Nn.BindingWhenOnSyntax(this._binding);
  }, e.prototype.toAutoNamedFactory = function(t) {
    return this._binding.type = et.BindingTypeEnum.Factory, this._binding.factory = function(n) {
      return function(r) {
        return n.container.getNamed(t, r);
      };
    }, new Nn.BindingWhenOnSyntax(this._binding);
  }, e.prototype.toProvider = function(t) {
    return this._binding.type = et.BindingTypeEnum.Provider, this._binding.provider = t, this._binding.scope = et.BindingScopeEnum.Singleton, new Nn.BindingWhenOnSyntax(this._binding);
  }, e.prototype.toService = function(t) {
    this.toDynamicValue(function(n) {
      return n.container.get(t);
    });
  }, e;
}();
ra.BindingToSyntax = Tb;
var sa = {};
Object.defineProperty(sa, "__esModule", { value: !0 });
sa.ContainerSnapshot = void 0;
var Ab = function() {
  function e() {
  }
  return e.of = function(t, n, r, s, o) {
    var l = new e();
    return l.bindings = t, l.middleware = n, l.deactivations = s, l.activations = r, l.moduleActivationStore = o, l;
  }, e;
}();
sa.ContainerSnapshot = Ab;
var Pr = {}, oa = {};
Object.defineProperty(oa, "__esModule", { value: !0 });
oa.isClonable = void 0;
function Eb(e) {
  return typeof e == "object" && e !== null && "clone" in e && typeof e.clone == "function";
}
oa.isClonable = Eb;
var Pb = b && b.__createBinding || (Object.create ? function(e, t, n, r) {
  r === void 0 && (r = n), Object.defineProperty(e, r, { enumerable: !0, get: function() {
    return t[n];
  } });
} : function(e, t, n, r) {
  r === void 0 && (r = n), e[r] = t[n];
}), Nb = b && b.__setModuleDefault || (Object.create ? function(e, t) {
  Object.defineProperty(e, "default", { enumerable: !0, value: t });
} : function(e, t) {
  e.default = t;
}), Rb = b && b.__importStar || function(e) {
  if (e && e.__esModule)
    return e;
  var t = {};
  if (e != null)
    for (var n in e)
      n !== "default" && Object.prototype.hasOwnProperty.call(e, n) && Pb(t, e, n);
  return Nb(t, e), t;
};
Object.defineProperty(Pr, "__esModule", { value: !0 });
Pr.Lookup = void 0;
var _n = Rb(x), kb = oa, Ib = function() {
  function e() {
    this._map = /* @__PURE__ */ new Map();
  }
  return e.prototype.getMap = function() {
    return this._map;
  }, e.prototype.add = function(t, n) {
    if (t == null)
      throw new Error(_n.NULL_ARGUMENT);
    if (n == null)
      throw new Error(_n.NULL_ARGUMENT);
    var r = this._map.get(t);
    r !== void 0 ? r.push(n) : this._map.set(t, [n]);
  }, e.prototype.get = function(t) {
    if (t == null)
      throw new Error(_n.NULL_ARGUMENT);
    var n = this._map.get(t);
    if (n !== void 0)
      return n;
    throw new Error(_n.KEY_NOT_FOUND);
  }, e.prototype.remove = function(t) {
    if (t == null)
      throw new Error(_n.NULL_ARGUMENT);
    if (!this._map.delete(t))
      throw new Error(_n.KEY_NOT_FOUND);
  }, e.prototype.removeIntersection = function(t) {
    var n = this;
    this.traverse(function(r, s) {
      var o = t.hasKey(r) ? t.get(r) : void 0;
      if (o !== void 0) {
        var l = s.filter(function(f) {
          return !o.some(function(w) {
            return f === w;
          });
        });
        n._setValue(r, l);
      }
    });
  }, e.prototype.removeByCondition = function(t) {
    var n = this, r = [];
    return this._map.forEach(function(s, o) {
      for (var l = [], f = 0, w = s; f < w.length; f++) {
        var p = w[f], _ = t(p);
        _ ? r.push(p) : l.push(p);
      }
      n._setValue(o, l);
    }), r;
  }, e.prototype.hasKey = function(t) {
    if (t == null)
      throw new Error(_n.NULL_ARGUMENT);
    return this._map.has(t);
  }, e.prototype.clone = function() {
    var t = new e();
    return this._map.forEach(function(n, r) {
      n.forEach(function(s) {
        return t.add(r, (0, kb.isClonable)(s) ? s.clone() : s);
      });
    }), t;
  }, e.prototype.traverse = function(t) {
    this._map.forEach(function(n, r) {
      t(r, n);
    });
  }, e.prototype._setValue = function(t, n) {
    n.length > 0 ? this._map.set(t, n) : this._map.delete(t);
  }, e;
}();
Pr.Lookup = Ib;
var ua = {};
Object.defineProperty(ua, "__esModule", { value: !0 });
ua.ModuleActivationStore = void 0;
var ku = Pr, Yb = function() {
  function e() {
    this._map = /* @__PURE__ */ new Map();
  }
  return e.prototype.remove = function(t) {
    if (this._map.has(t)) {
      var n = this._map.get(t);
      return this._map.delete(t), n;
    }
    return this._getEmptyHandlersStore();
  }, e.prototype.addDeactivation = function(t, n, r) {
    this._getModuleActivationHandlers(t).onDeactivations.add(n, r);
  }, e.prototype.addActivation = function(t, n, r) {
    this._getModuleActivationHandlers(t).onActivations.add(n, r);
  }, e.prototype.clone = function() {
    var t = new e();
    return this._map.forEach(function(n, r) {
      t._map.set(r, {
        onActivations: n.onActivations.clone(),
        onDeactivations: n.onDeactivations.clone()
      });
    }), t;
  }, e.prototype._getModuleActivationHandlers = function(t) {
    var n = this._map.get(t);
    return n === void 0 && (n = this._getEmptyHandlersStore(), this._map.set(t, n)), n;
  }, e.prototype._getEmptyHandlersStore = function() {
    var t = {
      onActivations: new ku.Lookup(),
      onDeactivations: new ku.Lookup()
    };
    return t;
  }, e;
}();
ua.ModuleActivationStore = Yb;
var Iu;
function El() {
  if (Iu)
    return ar;
  Iu = 1;
  var e = b && b.__assign || function() {
    return e = Object.assign || function(A) {
      for (var y, h = 1, v = arguments.length; h < v; h++) {
        y = arguments[h];
        for (var S in y)
          Object.prototype.hasOwnProperty.call(y, S) && (A[S] = y[S]);
      }
      return A;
    }, e.apply(this, arguments);
  }, t = b && b.__createBinding || (Object.create ? function(A, y, h, v) {
    v === void 0 && (v = h), Object.defineProperty(A, v, { enumerable: !0, get: function() {
      return y[h];
    } });
  } : function(A, y, h, v) {
    v === void 0 && (v = h), A[v] = y[h];
  }), n = b && b.__setModuleDefault || (Object.create ? function(A, y) {
    Object.defineProperty(A, "default", { enumerable: !0, value: y });
  } : function(A, y) {
    A.default = y;
  }), r = b && b.__importStar || function(A) {
    if (A && A.__esModule)
      return A;
    var y = {};
    if (A != null)
      for (var h in A)
        h !== "default" && Object.prototype.hasOwnProperty.call(A, h) && t(y, A, h);
    return n(y, A), y;
  }, s = b && b.__awaiter || function(A, y, h, v) {
    function S(g) {
      return g instanceof h ? g : new h(function(P) {
        P(g);
      });
    }
    return new (h || (h = Promise))(function(g, P) {
      function k(Q) {
        try {
          G(v.next(Q));
        } catch (le) {
          P(le);
        }
      }
      function j(Q) {
        try {
          G(v.throw(Q));
        } catch (le) {
          P(le);
        }
      }
      function G(Q) {
        Q.done ? g(Q.value) : S(Q.value).then(k, j);
      }
      G((v = v.apply(A, y || [])).next());
    });
  }, o = b && b.__generator || function(A, y) {
    var h = { label: 0, sent: function() {
      if (g[0] & 1)
        throw g[1];
      return g[1];
    }, trys: [], ops: [] }, v, S, g, P;
    return P = { next: k(0), throw: k(1), return: k(2) }, typeof Symbol == "function" && (P[Symbol.iterator] = function() {
      return this;
    }), P;
    function k(G) {
      return function(Q) {
        return j([G, Q]);
      };
    }
    function j(G) {
      if (v)
        throw new TypeError("Generator is already executing.");
      for (; h; )
        try {
          if (v = 1, S && (g = G[0] & 2 ? S.return : G[0] ? S.throw || ((g = S.return) && g.call(S), 0) : S.next) && !(g = g.call(S, G[1])).done)
            return g;
          switch (S = 0, g && (G = [G[0] & 2, g.value]), G[0]) {
            case 0:
            case 1:
              g = G;
              break;
            case 4:
              return h.label++, { value: G[1], done: !1 };
            case 5:
              h.label++, S = G[1], G = [0];
              continue;
            case 7:
              G = h.ops.pop(), h.trys.pop();
              continue;
            default:
              if (g = h.trys, !(g = g.length > 0 && g[g.length - 1]) && (G[0] === 6 || G[0] === 2)) {
                h = 0;
                continue;
              }
              if (G[0] === 3 && (!g || G[1] > g[0] && G[1] < g[3])) {
                h.label = G[1];
                break;
              }
              if (G[0] === 6 && h.label < g[1]) {
                h.label = g[1], g = G;
                break;
              }
              if (g && h.label < g[2]) {
                h.label = g[2], h.ops.push(G);
                break;
              }
              g[2] && h.ops.pop(), h.trys.pop();
              continue;
          }
          G = y.call(A, h);
        } catch (Q) {
          G = [6, Q], S = 0;
        } finally {
          v = g = 0;
        }
      if (G[0] & 5)
        throw G[1];
      return { value: G[0] ? G[1] : void 0, done: !0 };
    }
  }, l = b && b.__spreadArray || function(A, y, h) {
    if (h || arguments.length === 2)
      for (var v = 0, S = y.length, g; v < S; v++)
        (g || !(v in y)) && (g || (g = Array.prototype.slice.call(y, 0, v)), g[v] = y[v]);
    return A.concat(g || Array.prototype.slice.call(y));
  };
  Object.defineProperty(ar, "__esModule", { value: !0 }), ar.Container = void 0;
  var f = Li, w = r(x), p = Ve, _ = r(Le), O = Mr, F = Vt, H = _b(), E = ra, $ = qt, W = Pt, ve = We, Z = sa, U = Pr, K = ua, ie = function() {
    function A(y) {
      var h = y || {};
      if (typeof h != "object")
        throw new Error("" + w.CONTAINER_OPTIONS_MUST_BE_AN_OBJECT);
      if (h.defaultScope === void 0)
        h.defaultScope = p.BindingScopeEnum.Transient;
      else if (h.defaultScope !== p.BindingScopeEnum.Singleton && h.defaultScope !== p.BindingScopeEnum.Transient && h.defaultScope !== p.BindingScopeEnum.Request)
        throw new Error("" + w.CONTAINER_OPTIONS_INVALID_DEFAULT_SCOPE);
      if (h.autoBindInjectable === void 0)
        h.autoBindInjectable = !1;
      else if (typeof h.autoBindInjectable != "boolean")
        throw new Error("" + w.CONTAINER_OPTIONS_INVALID_AUTO_BIND_INJECTABLE);
      if (h.skipBaseClassChecks === void 0)
        h.skipBaseClassChecks = !1;
      else if (typeof h.skipBaseClassChecks != "boolean")
        throw new Error("" + w.CONTAINER_OPTIONS_INVALID_SKIP_BASE_CHECK);
      this.options = {
        autoBindInjectable: h.autoBindInjectable,
        defaultScope: h.defaultScope,
        skipBaseClassChecks: h.skipBaseClassChecks
      }, this.id = (0, W.id)(), this._bindingDictionary = new U.Lookup(), this._snapshots = [], this._middleware = null, this._activations = new U.Lookup(), this._deactivations = new U.Lookup(), this.parent = null, this._metadataReader = new O.MetadataReader(), this._moduleActivationStore = new K.ModuleActivationStore();
    }
    return A.merge = function(y, h) {
      for (var v = [], S = 2; S < arguments.length; S++)
        v[S - 2] = arguments[S];
      var g = new A(), P = l([y, h], v, !0).map(function(G) {
        return (0, F.getBindingDictionary)(G);
      }), k = (0, F.getBindingDictionary)(g);
      function j(G, Q) {
        G.traverse(function(le, J) {
          J.forEach(function(Ie) {
            Q.add(Ie.serviceIdentifier, Ie.clone());
          });
        });
      }
      return P.forEach(function(G) {
        j(G, k);
      }), g;
    }, A.prototype.load = function() {
      for (var y = [], h = 0; h < arguments.length; h++)
        y[h] = arguments[h];
      for (var v = this._getContainerModuleHelpersFactory(), S = 0, g = y; S < g.length; S++) {
        var P = g[S], k = v(P.id);
        P.registry(k.bindFunction, k.unbindFunction, k.isboundFunction, k.rebindFunction, k.unbindAsyncFunction, k.onActivationFunction, k.onDeactivationFunction);
      }
    }, A.prototype.loadAsync = function() {
      for (var y = [], h = 0; h < arguments.length; h++)
        y[h] = arguments[h];
      return s(this, void 0, void 0, function() {
        var v, S, g, P, k;
        return o(this, function(j) {
          switch (j.label) {
            case 0:
              v = this._getContainerModuleHelpersFactory(), S = 0, g = y, j.label = 1;
            case 1:
              return S < g.length ? (P = g[S], k = v(P.id), [4, P.registry(k.bindFunction, k.unbindFunction, k.isboundFunction, k.rebindFunction, k.unbindAsyncFunction, k.onActivationFunction, k.onDeactivationFunction)]) : [3, 4];
            case 2:
              j.sent(), j.label = 3;
            case 3:
              return S++, [3, 1];
            case 4:
              return [2];
          }
        });
      });
    }, A.prototype.unload = function() {
      for (var y = this, h = [], v = 0; v < arguments.length; v++)
        h[v] = arguments[v];
      h.forEach(function(S) {
        var g = y._removeModuleBindings(S.id);
        y._deactivateSingletons(g), y._removeModuleHandlers(S.id);
      });
    }, A.prototype.unloadAsync = function() {
      for (var y = [], h = 0; h < arguments.length; h++)
        y[h] = arguments[h];
      return s(this, void 0, void 0, function() {
        var v, S, g, P;
        return o(this, function(k) {
          switch (k.label) {
            case 0:
              v = 0, S = y, k.label = 1;
            case 1:
              return v < S.length ? (g = S[v], P = this._removeModuleBindings(g.id), [4, this._deactivateSingletonsAsync(P)]) : [3, 4];
            case 2:
              k.sent(), this._removeModuleHandlers(g.id), k.label = 3;
            case 3:
              return v++, [3, 1];
            case 4:
              return [2];
          }
        });
      });
    }, A.prototype.bind = function(y) {
      var h = this.options.defaultScope || p.BindingScopeEnum.Transient, v = new f.Binding(y, h);
      return this._bindingDictionary.add(y, v), new E.BindingToSyntax(v);
    }, A.prototype.rebind = function(y) {
      return this.unbind(y), this.bind(y);
    }, A.prototype.rebindAsync = function(y) {
      return s(this, void 0, void 0, function() {
        return o(this, function(h) {
          switch (h.label) {
            case 0:
              return [4, this.unbindAsync(y)];
            case 1:
              return h.sent(), [2, this.bind(y)];
          }
        });
      });
    }, A.prototype.unbind = function(y) {
      if (this._bindingDictionary.hasKey(y)) {
        var h = this._bindingDictionary.get(y);
        this._deactivateSingletons(h);
      }
      this._removeServiceFromDictionary(y);
    }, A.prototype.unbindAsync = function(y) {
      return s(this, void 0, void 0, function() {
        var h;
        return o(this, function(v) {
          switch (v.label) {
            case 0:
              return this._bindingDictionary.hasKey(y) ? (h = this._bindingDictionary.get(y), [4, this._deactivateSingletonsAsync(h)]) : [3, 2];
            case 1:
              v.sent(), v.label = 2;
            case 2:
              return this._removeServiceFromDictionary(y), [2];
          }
        });
      });
    }, A.prototype.unbindAll = function() {
      var y = this;
      this._bindingDictionary.traverse(function(h, v) {
        y._deactivateSingletons(v);
      }), this._bindingDictionary = new U.Lookup();
    }, A.prototype.unbindAllAsync = function() {
      return s(this, void 0, void 0, function() {
        var y, h = this;
        return o(this, function(v) {
          switch (v.label) {
            case 0:
              return y = [], this._bindingDictionary.traverse(function(S, g) {
                y.push(h._deactivateSingletonsAsync(g));
              }), [4, Promise.all(y)];
            case 1:
              return v.sent(), this._bindingDictionary = new U.Lookup(), [2];
          }
        });
      });
    }, A.prototype.onActivation = function(y, h) {
      this._activations.add(y, h);
    }, A.prototype.onDeactivation = function(y, h) {
      this._deactivations.add(y, h);
    }, A.prototype.isBound = function(y) {
      var h = this._bindingDictionary.hasKey(y);
      return !h && this.parent && (h = this.parent.isBound(y)), h;
    }, A.prototype.isCurrentBound = function(y) {
      return this._bindingDictionary.hasKey(y);
    }, A.prototype.isBoundNamed = function(y, h) {
      return this.isBoundTagged(y, _.NAMED_TAG, h);
    }, A.prototype.isBoundTagged = function(y, h, v) {
      var S = !1;
      if (this._bindingDictionary.hasKey(y)) {
        var g = this._bindingDictionary.get(y), P = (0, F.createMockRequest)(this, y, h, v);
        S = g.some(function(k) {
          return k.constraint(P);
        });
      }
      return !S && this.parent && (S = this.parent.isBoundTagged(y, h, v)), S;
    }, A.prototype.snapshot = function() {
      this._snapshots.push(Z.ContainerSnapshot.of(this._bindingDictionary.clone(), this._middleware, this._activations.clone(), this._deactivations.clone(), this._moduleActivationStore.clone()));
    }, A.prototype.restore = function() {
      var y = this._snapshots.pop();
      if (y === void 0)
        throw new Error(w.NO_MORE_SNAPSHOTS_AVAILABLE);
      this._bindingDictionary = y.bindings, this._activations = y.activations, this._deactivations = y.deactivations, this._middleware = y.middleware, this._moduleActivationStore = y.moduleActivationStore;
    }, A.prototype.createChild = function(y) {
      var h = new A(y || this.options);
      return h.parent = this, h;
    }, A.prototype.applyMiddleware = function() {
      for (var y = [], h = 0; h < arguments.length; h++)
        y[h] = arguments[h];
      var v = this._middleware ? this._middleware : this._planAndResolve();
      this._middleware = y.reduce(function(S, g) {
        return g(S);
      }, v);
    }, A.prototype.applyCustomMetadataReader = function(y) {
      this._metadataReader = y;
    }, A.prototype.get = function(y) {
      var h = this._getNotAllArgs(y, !1);
      return this._getButThrowIfAsync(h);
    }, A.prototype.getAsync = function(y) {
      return s(this, void 0, void 0, function() {
        var h;
        return o(this, function(v) {
          return h = this._getNotAllArgs(y, !1), [2, this._get(h)];
        });
      });
    }, A.prototype.getTagged = function(y, h, v) {
      var S = this._getNotAllArgs(y, !1, h, v);
      return this._getButThrowIfAsync(S);
    }, A.prototype.getTaggedAsync = function(y, h, v) {
      return s(this, void 0, void 0, function() {
        var S;
        return o(this, function(g) {
          return S = this._getNotAllArgs(y, !1, h, v), [2, this._get(S)];
        });
      });
    }, A.prototype.getNamed = function(y, h) {
      return this.getTagged(y, _.NAMED_TAG, h);
    }, A.prototype.getNamedAsync = function(y, h) {
      return this.getTaggedAsync(y, _.NAMED_TAG, h);
    }, A.prototype.getAll = function(y) {
      var h = this._getAllArgs(y);
      return this._getButThrowIfAsync(h);
    }, A.prototype.getAllAsync = function(y) {
      var h = this._getAllArgs(y);
      return this._getAll(h);
    }, A.prototype.getAllTagged = function(y, h, v) {
      var S = this._getNotAllArgs(y, !0, h, v);
      return this._getButThrowIfAsync(S);
    }, A.prototype.getAllTaggedAsync = function(y, h, v) {
      var S = this._getNotAllArgs(y, !0, h, v);
      return this._getAll(S);
    }, A.prototype.getAllNamed = function(y, h) {
      return this.getAllTagged(y, _.NAMED_TAG, h);
    }, A.prototype.getAllNamedAsync = function(y, h) {
      return this.getAllTaggedAsync(y, _.NAMED_TAG, h);
    }, A.prototype.resolve = function(y) {
      var h = this.isBound(y);
      h || this.bind(y).toSelf();
      var v = this.get(y);
      return h || this.unbind(y), v;
    }, A.prototype._preDestroy = function(y, h) {
      if (Reflect.hasMetadata(_.PRE_DESTROY, y)) {
        var v = Reflect.getMetadata(_.PRE_DESTROY, y);
        return h[v.value]();
      }
    }, A.prototype._removeModuleHandlers = function(y) {
      var h = this._moduleActivationStore.remove(y);
      this._activations.removeIntersection(h.onActivations), this._deactivations.removeIntersection(h.onDeactivations);
    }, A.prototype._removeModuleBindings = function(y) {
      return this._bindingDictionary.removeByCondition(function(h) {
        return h.moduleId === y;
      });
    }, A.prototype._deactivate = function(y, h) {
      var v = this, S = Object.getPrototypeOf(h).constructor;
      try {
        if (this._deactivations.hasKey(y.serviceIdentifier)) {
          var g = this._deactivateContainer(h, this._deactivations.get(y.serviceIdentifier).values());
          if ((0, $.isPromise)(g))
            return this._handleDeactivationError(g.then(function() {
              return v._propagateContainerDeactivationThenBindingAndPreDestroyAsync(y, h, S);
            }), S);
        }
        var P = this._propagateContainerDeactivationThenBindingAndPreDestroy(y, h, S);
        if ((0, $.isPromise)(P))
          return this._handleDeactivationError(P, S);
      } catch (k) {
        throw new Error(w.ON_DEACTIVATION_ERROR(S.name, k.message));
      }
    }, A.prototype._handleDeactivationError = function(y, h) {
      return s(this, void 0, void 0, function() {
        var v;
        return o(this, function(S) {
          switch (S.label) {
            case 0:
              return S.trys.push([0, 2, , 3]), [4, y];
            case 1:
              return S.sent(), [3, 3];
            case 2:
              throw v = S.sent(), new Error(w.ON_DEACTIVATION_ERROR(h.name, v.message));
            case 3:
              return [2];
          }
        });
      });
    }, A.prototype._deactivateContainer = function(y, h) {
      for (var v = this, S = h.next(); S.value; ) {
        var g = S.value(y);
        if ((0, $.isPromise)(g))
          return g.then(function() {
            return v._deactivateContainerAsync(y, h);
          });
        S = h.next();
      }
    }, A.prototype._deactivateContainerAsync = function(y, h) {
      return s(this, void 0, void 0, function() {
        var v;
        return o(this, function(S) {
          switch (S.label) {
            case 0:
              v = h.next(), S.label = 1;
            case 1:
              return v.value ? [4, v.value(y)] : [3, 3];
            case 2:
              return S.sent(), v = h.next(), [3, 1];
            case 3:
              return [2];
          }
        });
      });
    }, A.prototype._getContainerModuleHelpersFactory = function() {
      var y = this, h = function(Q, le) {
        Q._binding.moduleId = le;
      }, v = function(Q) {
        return function(le) {
          var J = y.bind(le);
          return h(J, Q), J;
        };
      }, S = function() {
        return function(Q) {
          return y.unbind(Q);
        };
      }, g = function() {
        return function(Q) {
          return y.unbindAsync(Q);
        };
      }, P = function() {
        return function(Q) {
          return y.isBound(Q);
        };
      }, k = function(Q) {
        return function(le) {
          var J = y.rebind(le);
          return h(J, Q), J;
        };
      }, j = function(Q) {
        return function(le, J) {
          y._moduleActivationStore.addActivation(Q, le, J), y.onActivation(le, J);
        };
      }, G = function(Q) {
        return function(le, J) {
          y._moduleActivationStore.addDeactivation(Q, le, J), y.onDeactivation(le, J);
        };
      };
      return function(Q) {
        return {
          bindFunction: v(Q),
          isboundFunction: P(),
          onActivationFunction: j(Q),
          onDeactivationFunction: G(Q),
          rebindFunction: k(Q),
          unbindFunction: S(),
          unbindAsyncFunction: g()
        };
      };
    }, A.prototype._getAll = function(y) {
      return Promise.all(this._get(y));
    }, A.prototype._get = function(y) {
      var h = e(e({}, y), { contextInterceptor: function(S) {
        return S;
      }, targetType: p.TargetTypeEnum.Variable });
      if (this._middleware) {
        var v = this._middleware(h);
        if (v == null)
          throw new Error(w.INVALID_MIDDLEWARE_RETURN);
        return v;
      }
      return this._planAndResolve()(h);
    }, A.prototype._getButThrowIfAsync = function(y) {
      var h = this._get(y);
      if ((0, $.isPromiseOrContainsPromise)(h))
        throw new Error(w.LAZY_IN_SYNC(y.serviceIdentifier));
      return h;
    }, A.prototype._getAllArgs = function(y) {
      var h = {
        avoidConstraints: !0,
        isMultiInject: !0,
        serviceIdentifier: y
      };
      return h;
    }, A.prototype._getNotAllArgs = function(y, h, v, S) {
      var g = {
        avoidConstraints: !1,
        isMultiInject: h,
        serviceIdentifier: y,
        key: v,
        value: S
      };
      return g;
    }, A.prototype._planAndResolve = function() {
      var y = this;
      return function(h) {
        var v = (0, F.plan)(y._metadataReader, y, h.isMultiInject, h.targetType, h.serviceIdentifier, h.key, h.value, h.avoidConstraints);
        v = h.contextInterceptor(v);
        var S = (0, H.resolve)(v);
        return S;
      };
    }, A.prototype._deactivateIfSingleton = function(y) {
      var h = this;
      if (y.activated)
        return (0, $.isPromise)(y.cache) ? y.cache.then(function(v) {
          return h._deactivate(y, v);
        }) : this._deactivate(y, y.cache);
    }, A.prototype._deactivateSingletons = function(y) {
      for (var h = 0, v = y; h < v.length; h++) {
        var S = v[h], g = this._deactivateIfSingleton(S);
        if ((0, $.isPromise)(g))
          throw new Error(w.ASYNC_UNBIND_REQUIRED);
      }
    }, A.prototype._deactivateSingletonsAsync = function(y) {
      return s(this, void 0, void 0, function() {
        var h = this;
        return o(this, function(v) {
          switch (v.label) {
            case 0:
              return [4, Promise.all(y.map(function(S) {
                return h._deactivateIfSingleton(S);
              }))];
            case 1:
              return v.sent(), [2];
          }
        });
      });
    }, A.prototype._propagateContainerDeactivationThenBindingAndPreDestroy = function(y, h, v) {
      return this.parent ? this._deactivate.bind(this.parent)(y, h) : this._bindingDeactivationAndPreDestroy(y, h, v);
    }, A.prototype._propagateContainerDeactivationThenBindingAndPreDestroyAsync = function(y, h, v) {
      return s(this, void 0, void 0, function() {
        return o(this, function(S) {
          switch (S.label) {
            case 0:
              return this.parent ? [4, this._deactivate.bind(this.parent)(y, h)] : [3, 2];
            case 1:
              return S.sent(), [3, 4];
            case 2:
              return [4, this._bindingDeactivationAndPreDestroyAsync(y, h, v)];
            case 3:
              S.sent(), S.label = 4;
            case 4:
              return [2];
          }
        });
      });
    }, A.prototype._removeServiceFromDictionary = function(y) {
      try {
        this._bindingDictionary.remove(y);
      } catch {
        throw new Error(w.CANNOT_UNBIND + " " + (0, ve.getServiceIdentifierAsString)(y));
      }
    }, A.prototype._bindingDeactivationAndPreDestroy = function(y, h, v) {
      var S = this;
      if (typeof y.onDeactivation == "function") {
        var g = y.onDeactivation(h);
        if ((0, $.isPromise)(g))
          return g.then(function() {
            return S._preDestroy(v, h);
          });
      }
      return this._preDestroy(v, h);
    }, A.prototype._bindingDeactivationAndPreDestroyAsync = function(y, h, v) {
      return s(this, void 0, void 0, function() {
        return o(this, function(S) {
          switch (S.label) {
            case 0:
              return typeof y.onDeactivation != "function" ? [3, 2] : [4, y.onDeactivation(h)];
            case 1:
              S.sent(), S.label = 2;
            case 2:
              return [4, this._preDestroy(v, h)];
            case 3:
              return S.sent(), [2];
          }
        });
      });
    }, A;
  }();
  return ar.Container = ie, ar;
}
var Cb = El(), jb = Object.defineProperty, Lb = Object.getOwnPropertyDescriptor, xb = (e, t, n, r) => {
  for (var s = r > 1 ? void 0 : r ? Lb(t, n) : t, o = e.length - 1, l; o >= 0; o--)
    (l = e[o]) && (s = (r ? l(t, n, s) : l(s)) || s);
  return r && s && jb(t, n, s), s;
};
let Yu = class {
  transform(e) {
    return {
      notification: null,
      sitename: "",
      assets: { logo_fullsize: "", logo_square: "", shop_image: "" },
      countries: [],
      email: "",
      info: [],
      is_marketplace: !1,
      is_multifirm: !1,
      is_store: !1,
      languages: [],
      name: e.name,
      phone: "",
      uuid: "",
      id: e.id,
      default_language: e.default_language,
      labels: {
        at_table: "",
        table_number: "",
        searchfield_placeholder: "",
        cash_pickup: "",
        cash_delivery: "",
        cash_table: "",
        locator_title: ""
      },
      address: {
        address: e.street_nr || "",
        zipcode: e.zipcode || "",
        locality: e.locality || "",
        country: {
          id: "",
          iso: "",
          name: ""
        },
        geocode: e.geocode
      },
      settings: {
        order_reference_method: "full",
        currency: {
          code: "",
          name: "",
          symbol: ""
        },
        app_url: "",
        links: [],
        is_offline: !1,
        use_quick_auth: !1,
        tracking: [],
        social_media: {
          facebook: "",
          twitter: "",
          instagram: "",
          google: "",
          linkedin: ""
        },
        payment_methods: [],
        service_fee: [],
        delivery_methods: {},
        checkout_options: [],
        group_same_products_for_order: !1,
        custom_delivery_payment_methods: [],
        custom_pickup_payment_methods: [],
        modules: {
          has_module_invoicing: !1,
          use_loyalty: !1,
          uses_coupons: !1
        },
        theme: {
          button_radius: "",
          call_to_actions: {},
          container_color: "",
          container_contrast_color: "",
          font_general: null,
          font_header_title: null,
          main_color: "",
          main_color_contrast_color: "",
          main_gradient_color_from: "",
          main_gradient_color_to: "",
          main_gradient_contrast_color: "",
          name: "",
          show_delivery_method_first: !1,
          show_download_banner: !1,
          show_location_info_header: !1,
          show_menu_preview: !1,
          show_timepicker_modal: !1,
          show_webshop_sidebar: !1,
          show_modifier_images: !1,
          success_gradient_color_from: "",
          success_gradient_color_to: "",
          success_gradient_contrast_color: "",
          tab_color: "",
          tab_contrast_color: "",
          theme_color: "",
          theme_color_contrast_color: "",
          well_color: "",
          well_contrast_color: "",
          content_color: "",
          content_contrast_color: "",
          hide_email: !1,
          map_marker: "",
          only_show_available_parent_categories: !1,
          show_comments_step_product_basket: !1
        },
        periods: {
          timeslot_days: [],
          delivery_hours: [],
          pickup_hours: [],
          holiday_period: [],
          opening_hours: e.opening_hours || []
        }
      }
    };
  }
};
Yu = xb([
  pl()
], Yu);
const Xb = Cb.Container;
export {
  Zb as AnnouncementsService,
  Re as ApiConfiguration,
  Kb as AppService,
  Cy as AppVersionService,
  Fb as AuthService,
  Bb as BrandService,
  Ub as CustomerService,
  kn as DateUtils,
  l_ as DeliveryMethodEnum,
  d_ as DeliveryMethodNames,
  Xb as EoContainer,
  r0 as EoInject,
  pl as EoInjectable,
  Wb as FirmService,
  ui as HttpError,
  xv as ImageSourceValidator,
  ys as IndexDBService,
  Hb as InventoryService,
  Yu as LocationToFirm,
  h_ as LocatorThemeFlowEnum,
  Qb as LoyaltyService,
  Gb as MarketplaceService,
  __ as NavigationThemeFlowEnum,
  $b as OrderService,
  f_ as PaymentMethodMethodEnum,
  Vb as PaymentService,
  yu as PlatformUtils,
  y_ as ProductNavigationThemeFlowEnum,
  zb as ProductService,
  qb as ReservationService,
  m_ as TemplateEnum,
  Jb as UuidService
};
